import { http } from 'helpers'
import { ProHardware, StorefrontCategory, StoreProduct } from 'types'
import type { ProjectsHardwareResponse, ProjectsResponse, QuantityLimitOptions } from './types'

export const api = {
  getDcProjects: (): Promise<ProjectsResponse> => http.get(`hardware/import/projects`),
  getDcHardware: (projectId: string): Promise<ProjectsHardwareResponse> =>
    http.get(`hardware/import/hardware/${projectId}`),
  getProducts: () =>
    http.get<Record<StorefrontCategory, StoreProduct[]>>(`stores/product-variants`),
  getProductsManagement: () =>
    http.get<StoreProduct[]>('stores/product-variants/product-management'),
  getQuantityLimit: ({ variantId, email }: QuantityLimitOptions) =>
    http.get<{ quantityLimit: number } | string>(
      `stores/product-variants/${variantId}/limit?email=${encodeURIComponent(email)}`,
    ),
  putProduct: (variantId: string, product: Partial<StoreProduct> & Pick<StoreProduct, 'id'>) =>
    http.put<StoreProduct[]>(`stores/product-variants/${variantId}`, product),
  getProHardware: () => http.get<ProHardware[]>(`prohardware`),
  bulkUpdateProHardware: (data: ProHardware[]) =>
    http.post<ProHardware[]>('prohardware/bulk', { data }),
  deleteProHardware: (id: string) => http.delete<void>(`prohardware/${id}`),
}
