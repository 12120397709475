import { ArrowLeft, ArrowRight, PaginatorButton } from './styles'

import type { ButtonProps, ButtonType } from './types'
import type { VFC } from 'react'

const buttonDict: Record<ButtonType, JSX.Element> = {
  first: (
    <>
      <ArrowLeft />
      <ArrowLeft />
    </>
  ),
  last: (
    <>
      <ArrowRight />
      <ArrowRight />
    </>
  ),
  next: <ArrowRight />,
  prev: <ArrowLeft />,
}

const makeButtonContent = (type: ButtonType) => buttonDict[type]

export const Button: VFC<ButtonProps> = ({ disabled, onClick, selected, type }) => {
  if (!onClick) return null
  return (
    <PaginatorButton disabled={disabled} onClick={onClick} selected={selected}>
      {makeButtonContent(type)}
    </PaginatorButton>
  )
}
