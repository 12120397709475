import { ProductType } from 'types'

export enum UserRole {
  AGENT = 'Agent',
  MANAGER = 'Manager',
  ADMIN = 'Admin',
  SYS_ADMIN = 'SysAdmin',
}

export enum QuoteStatus {
  DRAFT = 'Draft',
  SENT = 'Sent',
  PENDING_FULFILMENT = 'Pending Fulfilment',
  PARTIALLY_COMPLETED = 'Partially Completed',
  FINAL_PAYMENT_PENDING = 'Final Payment Pending',
  COMPLETED = 'Completed',
}

export const USER_ROLE_OPTIONS = Object.values(UserRole).map((x) => ({
  label: x,
  value: x,
}))

export enum AsyncFuncState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  NULL = 'null',
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export enum PaymentTerm {
  PREPAY = 'Prepayment',
  NET15 = 'Net 15',
  NET30 = 'Net 30',
  NET45 = 'Net 45',
}

export enum QuoteEvent {
  SENT = 'Sent quote to client',
  RE_SENT = 'Re-sent quote to client',
  SENT_UPDATE = 'Sent updated quote to client',
  RE_SENT_OLD = 'Re-sent old quote to client',
}

export enum DraftStatus {
  DRAFT = 'Draft',
  SENT = 'Sent',
  CLIENT_SIGNED = 'Client Signed',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
}

export enum CustomerCategory {
  ROUTING = 'Routing',
  SWITCHING = 'Switching',
  PROTECT = 'Protect',
  ACCESS = 'Access',
  WIRELESS = 'Wireless',
  TALK = 'Talk',
  CABLING = 'Cabling',
  OTHER = 'Other',
  OS_CONSOLE = 'OS Console',
}

export const MAX_BACKORDERS = 30

export const CUSTOMER_CATEGORY_OPTIONS = Object.values(CustomerCategory).map((x) => ({
  label: x,
  value: x,
}))

export enum RewardKeys {
  APPAREL = 'apparel',
  REFERRALS = 'referrals',
  SALES = 'sales-target',
  SUBSCRIPTIONS = 'subscriptions',
}

export enum SubCategory {
  GATEWAYS = 'Gateways',
  HD_AP = 'HD AP',
  AP = 'AP',
  AC_AP = 'AC AP',
  POWER = 'Power',
  HI_P2P = 'Hi-End Point to Point',
  XG = 'XG',
  MESH_AP = 'Mesh AP',
  ACCESSORIES = 'Accessories',
  OTHER = 'Other',
  MGMT = 'Management',
  INWALL_AP = 'In-Wall AP',
  SWITCHES = 'Switches',
  CABLES = 'Cables',
  LTE = 'LTE',
  PHONES = 'Phones',
  OS_CONSOLE = 'OS Console',
}

export const SUB_CATEGORY_OPTIONS = Object.values(SubCategory).map((x) => ({
  label: x,
  value: x,
}))

export enum Category {
  ROUTING_SWITCHING = 'Routing & Switching',
  LED = 'LED',
  PROTECT = 'Protect',
  VOIP = 'VoIP',
  WIRELESS = 'Wireless',
  POE_INJECTORS = 'POE Injectors',
  OS_CONSOLE = 'OS Console',
}

export const CATEGORY_OPTIONS = Object.values(Category).map((x) => ({
  label: x,
  value: x,
}))

export const talkSubsPrice = 9.99

export const emptyLocation = {
  formatted: '',
  street: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  other: '',
}

/** Type to topology category dictionary. */
export const typeToTopology = {
  [ProductType.ACCESS]: CustomerCategory.ACCESS,
  [ProductType.ACCESSORIES]: CustomerCategory.OTHER,
  [ProductType.APPAREL]: CustomerCategory.OTHER,
  [ProductType.CONNECT]: CustomerCategory.OTHER,
  [ProductType.LED]: CustomerCategory.OTHER,
  // [ProductType.MULTI_POINT]: CustomerCategory.WIRELESS,
  // [ProductType.PTP_BRIDGING]: CustomerCategory.WIRELESS,
  // [ProductType.ROUTERS_AND_MESH]: CustomerCategory.ROUTING,
  [ProductType.ROUTING]: CustomerCategory.ROUTING,
  [ProductType.SERVER]: CustomerCategory.OTHER,
  // [ProductType.SOLAR]: CustomerCategory.OTHER,
  [ProductType.SURVEILLANCE]: CustomerCategory.PROTECT,
  [ProductType.UNIFI_OS_GATEWAYS]: CustomerCategory.ROUTING,
  [ProductType.VOIP]: CustomerCategory.TALK,
  // [ProductType.WIRED_TECHNOLOGIES]: CustomerCategory.WIRELESS,
  [ProductType.WIRELESS]: CustomerCategory.WIRELESS,
}

export const TALK_SUBS_COMMISSION = '20%'
export const TALK_SUBS_COMMISSION_MULTIPLIER = 0.2
