import { isNil } from 'ramda'

import { useOverState } from 'store'

export const useIsMyClient = (currentClient?: string) => {
  const { limited } = useOverState().clients
  const myProfessionalId = useOverState().app.user?.professionalId

  if (isNil(currentClient)) return false

  return limited.hash[currentClient]?.professionalId === myProfessionalId
}
