import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { Dropdown } from '@ubnt/ui-components'
import { FormSection } from '.'
import { DropdownInput, dropdowns } from './generalFields'
import { Input } from './styles'
import { Schema } from '../ProjectFormProvider'
import { LocationDropdown } from './LocationDropdown'
import { useScrollIntoView } from 'hooks'

export const GeneralForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<Schema>()

  const isBuildingSize = (label: string) => label === 'Building Size'

  // Workaround to scroll erroring inputs into view since RHF seems to be buggy.
  const nameRef = useScrollIntoView<HTMLInputElement>('name' in errors || 'location' in errors, [
    errors,
  ])

  const renderInputFields = () => (
    <Controller
      control={control}
      name='name'
      render={({ field }) => (
        <Input
          {...field}
          ref={nameRef}
          inputClassName='input__name'
          variant='secondary'
          label='Project Name'
          autoComplete='chrome-off'
          invalid={errors['name']?.message}
          name='name'
          size='body'
          width={336}
          autoFocus
        />
      )}
    />
  )

  const renderDropdown = (input: DropdownInput) => (
    <Controller
      control={control}
      name={input.name}
      key={input.label}
      render={({ field }) => (
        <Dropdown
          {...field}
          invalid={errors[input.name]?.message}
          label={input.label}
          options={input.options}
          value={field.value}
          readOnly={isBuildingSize(input.label)}
          inputClassName={isBuildingSize(input.label) ? 'dropdown--readOnly' : ''}
          variant='secondary'
          onChange={({ value }) => field.onChange(value)}
          width={164}
        />
      )}
    />
  )

  return (
    <FormSection main>
      {renderInputFields()}
      <LocationDropdown />
      <PreferenceSection>{dropdowns.map(renderDropdown)}</PreferenceSection>
    </FormSection>
  )
}

const PreferenceSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 8px;
  margin: 16px 0px 8px;
  align-items: end;
  width: 100%;

  .dropdown--readOnly {
    background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  }
`
