import { isNotNil } from 'ramda'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@ubnt/ui-components'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import { FlexRow } from 'components'
import { ControlledInputWithUnit } from 'components/ControlledInputWithUnit'
import { useSysAdmin } from 'components/Roles'
import { useActions, useOverState } from 'store'
import { PayoutStatus } from 'store/payments/types'

interface Props {
  rate?: number
  paidOut?: PayoutStatus
  professionalId: string
  quoteId: string
}

type FormFields = {
  commissionRate: string
}

const errorMessage = 'Invalid value'

const schema = yup.object().shape({
  commissionRate: yup
    .number()
    .typeError(errorMessage)
    .min(0, errorMessage)
    .max(100, errorMessage)
    .required(),
})

export const CommissionRate: FC<Props> = ({ rate, paidOut, professionalId, quoteId }) => {
  const isSysAdmin = useSysAdmin()
  const actions = useActions()
  const invoiceId = useOverState().paymentDetails.data?.invoice?.id

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      commissionRate: '0',
    },
  })

  useEffect(() => {
    if (rate) {
      reset({
        commissionRate: rate.toString(),
      })
    }
  }, [rate])

  const onSubmit = ({ commissionRate }: FormFields) => {
    if (commissionRate && invoiceId)
      actions.paymentDetails.updateCommissionRate({
        commissionRate: Number(commissionRate),
        invoiceId,
        professionalId,
        quoteId,
      })
  }

  const form = () => (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ControlledInputWithUnit
        control={control}
        name='commissionRate'
        iconAfter={<>%</>}
        width='60px'
        invalid={errors.commissionRate?.message}
      />
      <FlexRow style={{ marginLeft: 10 }} justify='flex-end' width='auto' gap={2}>
        <Button
          variant='primary'
          disabled={isNotNil(errors.commissionRate?.message)}
          onClick={handleSubmit(onSubmit)}
          size='small'
          style={{ fontSize: 11, marginRight: 6 }}
        >
          Save
        </Button>
        <Button
          variant='primary'
          color='danger'
          onClick={() =>
            invoiceId && actions.paymentDetails.rejectInvoice({ invoiceId, professionalId })
          }
          size='small'
          style={{ fontSize: 11 }}
        >
          Reject
        </Button>
      </FlexRow>
    </Form>
  )

  return <>{isSysAdmin && paidOut === PayoutStatus.PROCESSING ? form() : `${rate} %`}</>
}

const Form = styled.form`
  display: flex;
`
