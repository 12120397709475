import { FC, useCallback, useMemo, useState } from 'react'
import { ArrowDownPrimaryIconL } from '@ubnt/icons'
import { Popover } from '@ubnt/ui-components'

import {
  DateRangePicker as SDateRangePicker,
  Label,
  LabelWrap,
  LabelWrapProps,
  Toggle,
} from './styles'
import { presets as defaultPresets } from './presets'
import { dateOr, dateOnly } from 'helpers'

import type {
  DateRangePickerProps as UIDateRangePickerProps,
  DateRangePreset,
} from '@ubnt/ui-components/DatePicker/DateRangePicker'

export interface DateRangePickerProps extends LabelWrapProps {
  dateRange: {
    start: Date
    end: Date
  }
  defaultDate?: string
  disabledDays?: UIDateRangePickerProps['disabledDays']
  onRangeChange: (range: DateRangePickerProps['dateRange']) => void
  presets?: boolean | DateRangePreset[]
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  dateRange,
  defaultDate = 'MM/DD/YYYY',
  disabledDays,
  onRangeChange,
  presets,
  ...rest
}) => {
  const [open, setIsOpen] = useState(false)

  const formatDate = useMemo(() => dateOr(defaultDate), [dateOr, defaultDate])

  const _presets = presets === true ? defaultPresets : presets || undefined
  const selectedPreset = useMemo(
    () =>
      _presets &&
      _presets.find(
        ({ range }) =>
          dateOnly(range.startDate) === dateOnly(dateRange.start) &&
          dateOnly(range.endDate) === dateOnly(dateRange.end),
      ),
    [dateRange, _presets],
  )

  const label =
    (selectedPreset?.label as string) ||
    `${formatDate(dateRange.start.toISOString())} - ${formatDate(dateRange.end.toISOString())}`

  const onRangeSelected = useCallback<UIDateRangePickerProps['onRangeSelected']>(
    ({ startDate: start, endDate: end }) => {
      onRangeChange({ start, end })
      setIsOpen(false)
    },
    [onRangeChange],
  )

  const renderLabel = useCallback(
    () => (
      <LabelWrap {...rest}>
        <Label>Date</Label>
        <Toggle>
          {label}
          <ArrowDownPrimaryIconL />
        </Toggle>
      </LabelWrap>
    ),
    [label],
  )

  return (
    <Popover
      align='bottomLeft'
      classNameCard='date-picker'
      open={open}
      onChange={setIsOpen}
      renderToggle={renderLabel}
    >
      <SDateRangePicker
        disabledDays={disabledDays}
        initialStartDate={dateRange.start}
        initialEndDate={dateRange.end}
        onRangeSelected={onRangeSelected}
        presets={_presets}
      />
    </Popover>
  )
}
