import type { FC, KeyboardEvent } from 'react'
import { useActions, useOverState } from 'store'
import { Modal } from '@ubnt/ui-components/Modal'
import { Text } from '@ubnt/ui-components/Text'

export const NewContactFound: FC = () => {
  const { modal, modalProps } = useOverState().modal
  const { closeModal, setModal } = useActions().modal

  const handleAdd = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    setModal({
      modal: 'add_contact',
      props: {
        newContact: modalProps.email,
        sendToClient: {
          updateEmailList: modalProps.setEmailArray,
          resetEmail: modalProps.resetMail,
        },
      },
    })
  }
  const handleCancel = (event: React.MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>) => {
    event.preventDefault()
    closeModal()
    modalProps.resetMail()
  }

  return (
    <Modal
      title={modalProps.modalName}
      isOpen={modal === 'new_contact_found'}
      onRequestClose={handleCancel}
      size='small'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Remove Email Address',
          onClick: handleCancel,
          variant: 'tertiary',
        },
        {
          text: 'Add to Contact',
          variant: 'primary',
          onClick: handleAdd,
        },
      ]}
    >
      <Text size='body' style={{ paddingTop: '26px' }}>
        <Text size='body' weight='bold'>{`${modalProps.email}`}</Text> is not currently listed as a
        contact under this client. Do you want to add it as an additional contact?
      </Text>
    </Modal>
  )
}
