import { ArrowDownPrimaryIconL } from '@ubnt/icons'
import styled from 'styled-components'
import { Text } from '@ubnt/ui-components'

import { Badge } from 'components'

interface AccordionLableProps {
  itemExpanded: boolean
  togglePanelOpen: () => void
  label: string
  paid?: boolean
  cancelled?: boolean
}

export const AccordionLabel = ({
  itemExpanded,
  togglePanelOpen,
  label,
  paid,
  cancelled,
}: AccordionLableProps) => {
  return (
    <AccordionLabelWrapper onClick={() => togglePanelOpen()}>
      <Wrapper>
        <Text size='body' weight='bold'>
          {label}
        </Text>
        {paid && !cancelled && (
          <Badge type='blue' size='small' style={{ marginLeft: '5px' }}>
            Paid
          </Badge>
        )}

        {cancelled && (
          <Badge type='red' size='small' style={{ marginLeft: '5px' }}>
            Cancelled
          </Badge>
        )}
      </Wrapper>
      <StyledArrowDownPrimaryIconL className={itemExpanded ? 'expanded' : ''} />
    </AccordionLabelWrapper>
  )
}

const AccordionLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 36px;

  :hover {
    cursor: pointer;
  }

  > svg {
    transition: color 100ms ease, transform 200ms cubic-bezier(0.4, 0, 0, 1.4);
  }

  .expanded {
    transform: rotate(180deg);
  }
`

const StyledArrowDownPrimaryIconL = styled(ArrowDownPrimaryIconL)`
  color: ${({ theme }) => theme.colors.Light.color.neutral.n8};
`

const Wrapper = styled.div`
  display: flex;
`
