import { FC } from 'react'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { TooltipProps } from '@ubnt/ui-components/Tooltip/Tooltip'

interface Props extends TooltipProps {
  show: boolean
}

export const ConditionalTooltip: FC<Props> = ({ children, show, ...props }) =>
  show ? <Tooltip {...props}>{children}</Tooltip> : <>{children}</>
