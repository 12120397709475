import { branch, debounce, noop, parallel, pipe, when } from 'overmind'
import { QuoteStatus } from 'shared'
import { Context } from 'store'
import { toast } from 'store/helpers'
import * as modalActions from 'store/modal/actions'
import { handleAsyncAction, runOnSuccess, toast as opToast } from 'store/operators'
import type { WithClientId, WithOnSuccess } from 'store/types'
import type { Client, Quote, QuoteListItem } from 'types'
import * as op from './operators'
import type {
  AddProHardwareOpts,
  AddProServiceOpts,
  AdjustOptions,
  CloneQuoteOptions,
  CreateDraftOptions,
  DeleteDraft,
  DeleteProItemOpts,
  DraftBase,
  HardwareQuote,
  ProductQuote,
  UpdateDraftOptions,
  WithQuote,
  WithQuoteId,
  WithResend,
  WithTaxRate,
} from './types'

const handleCMAction = handleAsyncAction('clientManagement')

export const setCurrentQuoteFromKey = <A extends WithQuote>({ state }: Context, { quote }: A) =>
  (state.clientManagement.currentQuote = quote)

export const addProHardware = handleCMAction<AddProHardwareOpts, void>({
  before: op.ifAdjustable,
  action: pipe(
    branch(
      op.addProHardware,
      modalActions.closeModal,
      opToast('', 'Hardware has been added to quote'),
    ),
    op.getQuoteById,
    setCurrentQuoteFromKey,
  ),
})

export const addProService = handleCMAction<AddProServiceOpts, void>({
  before: op.ifAdjustable,
  action: pipe(
    branch(
      op.addProService,
      modalActions.closeModal,
      opToast('', 'Service has been added to quote'),
    ),
    op.getQuoteById,
    setCurrentQuoteFromKey,
  ),
})

export const changeAdjusting = handleCMAction<AdjustOptions & WithOnSuccess>({
  before: op.ifStatusIs([
    QuoteStatus.SENT,
    QuoteStatus.PENDING_FULFILMENT,
    QuoteStatus.PARTIALLY_COMPLETED,
    QuoteStatus.FINAL_PAYMENT_PENDING,
  ]),
  action: pipe(branch(op.adjust, setCurrentQuoteFromKey), runOnSuccess()),
})

export type CreateDraft = CreateDraftOptions & { history: (s: string) => void }

export const createDraft = handleCMAction<CreateDraft, void>({
  action: pipe(
    op.updateDefaultTax(),
    op.createQuote,
    (_, { history, quote }) => history(quote.id),
    modalActions.closeModal,
  ),
  errorTitle: 'Create draft error',
})

export const cloneQuote = handleCMAction<CloneQuoteOptions, void>({
  action: pipe(op.cloneQuote, op.getQuotes, opToast('Success', 'Successfully Duplicated Quote')),
  errorTitle: 'Duplicate quote error',
})

export const completeQuote = handleCMAction<WithQuoteId & WithOnSuccess>(
  pipe(branch(op.completeQuote, setCurrentQuoteFromKey), runOnSuccess()),
)

export const deleteProHardware = handleCMAction<DeleteProItemOpts, void>({
  before: op.ifAdjustable,
  action: pipe(
    branch(
      op.deleteProService('prohardware'),
      opToast('Hardware removed', 'Your quote has been successfully updated'),
    ),
    op.getQuoteById,
    setCurrentQuoteFromKey,
  ),
})

export const deleteProService = handleCMAction<DeleteProItemOpts, void>({
  before: op.ifAdjustable,
  action: pipe(
    branch(
      op.deleteProService('proservices'),
      opToast('Service removed', 'Your quote has been successfully updated'),
    ),
    op.getQuoteById,
    setCurrentQuoteFromKey,
  ),
})

export const getEmailPreview = handleCMAction<WithQuoteId, void>(op.getEmailPreview)

export const getQuotesByProject = op.getQuotes

export const editDraftDetails = handleCMAction<
  UpdateDraftOptions & DraftBase & WithOnSuccess,
  void
>(
  pipe(
    branch(parallel(op.updateQuote, op.updateDefaultTax())),
    branch(op.getQuoteById, setCurrentQuoteFromKey),
    runOnSuccess(),
    modalActions.closeModal,
    opToast('Updated Draft Quote', 'Your quote has been successfully updated'),
  ),
)

export const deleteDraft = handleCMAction<DeleteDraft, void>(
  pipe(branch(op.deleteQuote), op.getQuotes, opToast('Success', 'Successfully Deleted Draft')),
)

export const toastOnError = <A extends { error?: string }>({ state }: Context, value: A) => {
  if (value.error) toast(state, 'Error', value.error, 'danger', 6000)
}

export const getQuote = handleCMAction<WithQuoteId & WithTaxRate, void>({
  errorTitle: 'Get draft error',
  action: pipe(
    op.getQuoteById,
    branch(parallel(toastOnError, setCurrentQuoteFromKey)),
    when(
      (_context, value) =>
        value.quote.disputes.length > 0 && value.quote.status === QuoteStatus.FINAL_PAYMENT_PENDING,
      {
        true: op.showDispute,
        false: noop(),
      },
    ),
  ),
})

export const resetEmailPreview = ({ state }: Context) => {
  state.clientManagement.emailPreview = null
}

export const restoreHardware = ({ state }: Context) => {
  if (state.clientManagement.currentQuote) {
    state.clientManagement.currentQuote.productVariantQuotes = [...state.clientManagement.deleting]
  }
}

export const setCurrentClientId = ({ state }: Context, { clientId }: WithClientId) => {
  state.clientManagement.currentClient.id = clientId
}

export const setCurrentClient = (
  { state }: Context,
  {
    id,
    name,
    email,
    firstName,
    lastName,
    phone,
    taxExempt,
    taxCertificates,
  }: Pick<
    Client,
    'id' | 'name' | 'email' | 'firstName' | 'lastName' | 'phone' | 'taxExempt' | 'taxCertificates'
  >,
) => {
  state.clientManagement.currentClient = {
    id,
    name,
    email,
    firstName,
    lastName,
    phone,
    taxExempt,
    taxCertificates,
  }
}

export const resetCurrentClient = ({ state }: Context) => {
  state.clientManagement.currentClient = {
    id: '',
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    taxExempt: false,
    taxCertificates: [],
  }
}

export const setCurrentQuote = ({ state }: Context, value: Quote) => {
  state.clientManagement.currentQuote = value
}

export const resetCurrentQuote = ({ state }: Context) => {
  state.clientManagement.currentQuote = null
}

export const setQuotePending = handleCMAction<WithQuoteId & WithOnSuccess>(
  pipe(branch(op.setQuotePending, setCurrentQuoteFromKey), runOnSuccess()),
)

export const setQuotes = ({ state }: Context, value: QuoteListItem[]) => {
  state.clientManagement.quotes = value
}

export const addProduct = handleCMAction<{ quoteId: string; data: ProductQuote }, void>({
  action: pipe(
    branch(op.addProduct, opToast('Product Added', 'Your quote has been successfully updated')),
    op.getQuoteById,
    setCurrentQuoteFromKey,
    modalActions.closeModal,
  ),
})

export const bulkAddHardware = handleCMAction<
  {
    quoteId: string
    data: HardwareQuote[]
  },
  void
>({
  action: pipe(
    branch(op.bulkAddHardware, opToast('Product Added', 'Hardware successfully imported')),
    op.getQuoteById,
    setCurrentQuoteFromKey,
    modalActions.closeModal,
  ),
})

export const backupHardware = ({ state }: Context) => {
  state.clientManagement.deleting = state.clientManagement.currentQuote
    ? [...state.clientManagement.currentQuote.productVariantQuotes]
    : []
}

export const deleteHardware = handleCMAction<{ quoteId: string; productVariantId: string }, void>({
  onError: restoreHardware,
  action: pipe(
    branch(
      parallel(backupHardware, op.deleteHardware),
      opToast('Product Removed', 'Your quote has been successfully updated'),
    ),
    op.getQuoteById,
    setCurrentQuoteFromKey,
  ),
  errorTitle: 'Update draft error',
  errorDescription: 'Unable to remove products from draft',
})

type SendToClient = UpdateDraftOptions & WithOnSuccess & WithResend & WithClientId

export const sendToClient = handleCMAction<SendToClient, void>({
  before: op.ifDraftIsNotEmpty,
  action: pipe(
    branch(op.updateQuote),
    branch(op.saveQuoteVersion),
    branch(op.getQuoteById, setCurrentQuoteFromKey, ({ state }: Context) =>
      toast(
        state,
        'Done',
        `Quote ${state.clientManagement.currentQuote?.quoteName} successfully sent to client`,
        'success',
      ),
    ),
    runOnSuccess(),
  ),
})

export const updateQuote = handleCMAction<UpdateDraftOptions, void>(op.updateQuote)

export const updateMessage = pipe<UpdateDraftOptions, UpdateDraftOptions, void>(
  debounce(1500),
  op.updateQuote,
)

type getDraftForModalOpts = modalActions.ModalAny & WithQuoteId

export const getQuoteOpenModal = handleCMAction<getDraftForModalOpts, void>({
  errorTitle: 'Get draft error',
  action: pipe(op.getQuoteForModal, parallel(op.setCurrentQuoteForModal, modalActions.setModal)),
})
