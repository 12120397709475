import { FC } from 'react'
import { isEmpty, isNil } from 'ramda'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert, Button } from '@ubnt/ui-components'
import styled from 'styled-components'

import { useActions, useOverState } from 'store'
import { makeAlertText } from './helpers'
import { isAdjusting } from 'helpers/quote'
import { QuoteStatus } from 'shared'

const partiallyCompleteText = 'In order to adjust your quote, please click Edit Quote below'

interface Props {
  item: 'hardware' | 'proHardware' | 'proService'
}

export const AddItemNotification: FC<Props> = ({ item }) => {
  const { closeModal, setModal, setSidebar } = useActions().modal
  const { currentQuote, currentClient } = useOverState().clientManagement
  const { current: currentProject } = useOverState().projects
  const { getProjectsAndContracts } = useActions().projects
  const { clientManagement: actions } = useActions()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleCreateQuote = () => {
    setModal({
      modal: 'edit_quote',
      props: {
        modalName: 'Create a Quote',
        projectId: currentProject.id,
        clientId: currentClient.id,
      },
    })
  }

  const handleCreateProject = () => {
    setSidebar({
      sidebar: 'project_details',
      props: {
        clientId: currentClient.id,
        editable: true,
        newProject: true,
        onClose: () =>
          getProjectsAndContracts({
            clientId: currentClient.id,
            projectId: null,
            history: (projectId: string) => navigate(`${pathname}/project/${projectId}`),
          }),
      },
    })
    closeModal()
  }

  const handleEditQuote = () => {
    if (!currentQuote) return
    return actions.changeAdjusting({
      quote: currentQuote,
      action: isAdjusting(currentQuote) ? 'discard' : 'start',
      onSuccess: closeModal,
    })
  }

  const firstSelectClientNotice = (text: string) => (
    <Wrapper>
      <Alert type='warning' text={text} />
    </Wrapper>
  )

  const firstCreateProjectNotice = (text: string) => (
    <Wrapper>
      <Alert type='warning' text={text} />
      <Button variant='inline' size='small' onClick={handleCreateProject}>
        Create a Project
      </Button>
    </Wrapper>
  )

  const firstCreateQuoteNotice = (text: string) => (
    <Wrapper>
      <Alert type='warning' text={text} />
      <Button variant='inline' size='small' onClick={handleCreateQuote}>
        Create a Quote
      </Button>
    </Wrapper>
  )

  const quotePartiallyCompletedNotice = (text: string) => (
    <Wrapper>
      <Alert type='warning' text={text} />
      <Button variant='inline' size='small' onClick={handleEditQuote}>
        Edit Quote
      </Button>
    </Wrapper>
  )

  const renderNotification = () => {
    if (
      currentQuote?.status === QuoteStatus.DRAFT ||
      currentQuote?.status === QuoteStatus.COMPLETED
    ) {
      return
    }

    if (currentQuote?.status === QuoteStatus.PARTIALLY_COMPLETED && item === 'hardware') {
      return
    }

    if (isEmpty(currentClient.id)) return firstSelectClientNotice(makeAlertText(item, 'client'))

    if (isNil(currentProject.data?.id)) {
      return firstCreateProjectNotice(makeAlertText(item, 'project'))
    }
    if (isNil(currentQuote)) return firstCreateQuoteNotice(makeAlertText(item, 'quote'))

    if (!isAdjusting(currentQuote)) {
      return quotePartiallyCompletedNotice(partiallyCompleteText)
    }
  }

  return <>{renderNotification()}</>
}

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.Light.color.neutral.n1};

  > div > div:first-child {
    display: none;
  }
`
