import { Controller, useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import { omit } from 'ramda'

import { FormSection } from './FormSection'
import { Schema } from '../ProjectFormProvider'
import { ErrorMessage } from './styles'
import { NoteText } from './NoteText'
import { ButtonsContainer } from 'components'

export const CameraForm = () => {
  const { control, formState, setValue, getFieldState, getValues } = useFormContext<Schema>()
  const { error } = getFieldState('cameras', formState)

  const handleChange = (value: number) => setValue('cameras.qty', value)

  useEffect(() => {
    const { cameras } = getValues()
    if (cameras.qty === 0) setValue('cameras.qty', 1)
  }, [])

  return (
    <FormSection main>
      <Controller
        control={control}
        name='cameras.qty'
        render={({ field }) => (
          <ButtonsContainer
            label='Cameras'
            min={0}
            onDecrease={handleChange}
            onIncrease={handleChange}
            value={field.value}
          />
        )}
      />
      {error?.message && <ErrorMessage color='danger'>{error?.message}</ErrorMessage>}
      <Controller
        control={control}
        name='cameras.note'
        render={({ field }) => (
          <NoteText
            {...omit(['ref'], field)}
            setValue={(_, value) => field.onChange(value)}
            note={field.value}
          />
        )}
      />
    </FormSection>
  )
}
