import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '@ubnt/ui-components'
import { GoogleAddressAutoComplete } from 'components'
import { ControlledInput } from 'components/ControlledInput'
import { memoizeWith, toString } from 'ramda'
import React, { ChangeEvent, FC, KeyboardEventHandler, useEffect, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { emailSchema, emptyLocation, locationSchema, nameSchema, phoneSchema } from 'shared'
import { emailRegex } from 'shared/regex'
import { useActions, useEffects, useOverState } from 'store'
import { handleError as handleAxiosError } from 'store/helpers'
import type { Location, Professional } from 'types'
import * as yup from 'yup'
import {
  Avatar,
  ChangeLogo,
  FlexColumn2,
  Form,
  LogoSection,
  Row,
  UbiquitiLogo,
  FlexColumn,
} from './styles'

type FormFields = {
  email: string
  name: string
  location: Location
  phone: string
}

const EditCompanyForm: FC = () => {
  const { app, professionals } = useEffects()
  const {
    app: { toast },
    modal: { closeModal },
    professionals: { updateProfessional },
  } = useActions()
  const { modal, modalProps } = useOverState().modal

  const professional = modalProps.professional as Professional

  const [emailErrMessage, setErrMessage] = useState('')
  const [avatar, setAvatar] = useState<string | null>(null)
  const [googleInput, setGoogleInput] = useState('')

  useEffect(() => {
    if (professional.location) {
      setGoogleInput(professional.location.formatted.split(',')[0])
      setValue('location', professional.location, { shouldValidate: false })
    }
  }, [])

  const validateName = memoizeWith(toString, (name?: string) => {
    if (!name || name.length < 3) return false
    if (name.toLowerCase() === professional.name.toLowerCase()) return true
    return app.api
      .validateName({ name, client: true, pro: true })
      .then((isDuplicate) => !isDuplicate)
  })

  const validateEmail = memoizeWith(toString, (email?: string) => {
    if (!email || !emailRegex.test(email.toLowerCase())) return false
    if (email.toLowerCase() === professional.email.toLowerCase()) return true
    return app.api
      .validateEmail({ email, client: true, pro: true }) //
      .then(({ isInvalid, message }) => {
        setErrMessage(message)
        return !isInvalid
      })
  })

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: nameSchema(validateName),
        location: locationSchema,
        email: emailSchema(validateEmail),
        phone: phoneSchema(),
      }),
    [validateEmail],
  )

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      location: emptyLocation,
    },
  })

  const name = useWatch({ control, name: 'name' })

  useEffect(() => {
    if (professional) {
      setAvatar(professional.avatar)
      reset({
        name: professional.name,
        email: professional.email,
        phone: professional.phone,
        location: professional.location,
      })
    }
  }, [professional])

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    closeModal()
  }

  const onSubmit = (data: FormFields) => {
    updateProfessional({
      ...data,
      avatar,
      phone: data.phone.toString(),
    })
  }

  const handleEnterKey: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') handleSubmit(onSubmit)
  }

  const handleUploadLogo = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) return

    const data = new FormData()
    data.append('file', event.target.files[0])

    return professionals.api
      .uploadLogo(data)
      .then(({ key }) => setAvatar(key))
      .catch((error) =>
        toast({
          title: 'Error processing your request',
          message: handleAxiosError(error),
          type: 'danger',
        }),
      )
  }

  const handleChangeLocation = (location: Location) => {
    setValue('location.street', location.street ?? '', { shouldValidate: true })
    setValue('location.streetNumber', location.streetNumber ?? '', { shouldValidate: true })
    setValue('location.state', location.state ?? '', { shouldValidate: true })
    setValue('location.city', location.city ?? '', { shouldValidate: true })
    setValue('location.country', location.country, { shouldValidate: true })
    setValue('location.formatted', location.formatted, { shouldValidate: true })
    setValue('location.zipCode', location.zipCode ?? '', { shouldValidate: true })
    setGoogleInput(location.formatted.split(',')[0])
  }

  return (
    <Modal
      title='Edit Company Profile'
      isOpen={modal === 'company_profile'}
      onRequestClose={closeModal}
      size='medium'
      shouldCloseOnOverlayClick={false}
      allowOverflow
      actions={[
        {
          text: 'Cancel',
          onClick: handleCancel,
          variant: 'tertiary',
        },
        {
          text: 'Done',
          variant: 'primary',
          onClick: handleSubmit(onSubmit),
        },
      ]}
    >
      <Form>
        <FlexColumn>
          <LogoSection onChange={handleUploadLogo}>
            {name === 'Ubiquiti' ? (
              <UbiquitiLogo />
            ) : (
              <Avatar
                name={[name]}
                logoUrl={avatar}
                imgSize={112}
                fontSize={50}
                fontWeight='bold'
                borderRadius='15px'
              />
            )}
            <ChangeLogo>Change Image</ChangeLogo>
          </LogoSection>
        </FlexColumn>
        <FlexColumn2>
          <ControlledInput
            control={control}
            name='name'
            label='Company Name'
            autoComplete='off'
            autoFocus
            invalid={errors.name?.message}
            onKeyDown={handleEnterKey}
            width='100%'
          />
          <Row>
            <ControlledInput
              control={control}
              name='email'
              label='Contact Email'
              autoComplete='off'
              invalid={emailErrMessage || errors.email?.message}
              onKeyDown={handleEnterKey}
            />
            <ControlledInput
              control={control}
              name='phone'
              label='Contact Number (Optional)'
              autoComplete='off'
              invalid={errors.phone?.message}
              onKeyDown={handleEnterKey}
            />
          </Row>
          <GoogleAddressAutoComplete
            name='location'
            label='Address'
            handleChange={handleChangeLocation}
            value={googleInput}
            setValue={setGoogleInput}
            width={null}
            invalid={errors.location?.streetNumber?.message || errors.location?.street?.message}
          />
          <ControlledInput
            control={control}
            name='location.other'
            label='PO Box, Apartment, Unit, Suite, or Floor'
            autoComplete='off'
            invalid={errors.location?.other?.message}
            onKeyDown={handleEnterKey}
            width='100%'
          />
          <Row>
            <ControlledInput
              control={control}
              name='location.zipCode'
              label='Postal Code'
              autoComplete='off'
              invalid={errors.location?.zipCode?.message}
              onKeyDown={handleEnterKey}
            />
            <ControlledInput
              control={control}
              name='location.city'
              label='City'
              autoComplete='off'
              invalid={errors.location?.city?.message}
              onKeyDown={handleEnterKey}
            />
          </Row>
          <Row>
            <ControlledInput
              control={control}
              name='location.state'
              label='State'
              autoComplete='off'
              invalid={errors.location?.state?.message}
              onKeyDown={handleEnterKey}
            />
            <ControlledInput
              control={control}
              name='location.country'
              label='Country'
              autoComplete='off'
              invalid={errors.location?.country?.message}
              onKeyDown={handleEnterKey}
            />
          </Row>
        </FlexColumn2>
      </Form>
    </Modal>
  )
}

export default EditCompanyForm
