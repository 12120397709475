import styled from 'styled-components'
import { Text } from '@ubnt/ui-components/Text'

export const Body = styled.div`
  overflow-y: auto;
`

export const SubHeader = styled(Text)`
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  padding: 16px 0 8px;
`

export const StyledText = styled(Text)`
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.65);
`

export const Link = styled.a`
  font-size: 14px;
  text-decoration: none;
  display: block;
  margin-bottom: 4px;
`

export const Table = styled.table`
  margin-bottom: 10px;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n6};
    padding: 5px 10px;
  }
`

export const TableHeader = styled.th<{ width: string }>`
  width: ${({ width }) => width};
`

export const TableData = styled.td<{ width: string }>`
  width: ${({ width }) => width};
`
