import styled from 'styled-components'

export const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n3};
  color: ${({ theme }) => theme.colors.textColor.white.w85};
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  padding-top: 8px;
`
