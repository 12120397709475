import { FC } from 'react'
import { Input } from '@ubnt/ui-components/Input'

import type { InputProps } from '@ubnt/ui-components/Input/Input'

export interface InputWithUnitProps extends Partial<InputProps> {
  disabled?: boolean
}

export const InputWithUnit: FC<InputWithUnitProps> = ({
  forwardedRef,
  disabled = false,
  ...rest
}) => {
  return <Input forwardedRef={forwardedRef as undefined} disabled={disabled} {...rest} />
}
