import React, { FC } from 'react'

import { useRole, Roles } from './Roles'
import { UserRole } from 'shared'

const roles = [UserRole.MANAGER, UserRole.ADMIN, UserRole.SYS_ADMIN]

export const RolesManager: FC = ({ children }) => <Roles roles={roles}>{children}</Roles>

export const useManager = () => useRole(roles)
