import { Modal as UIModal } from '@ubnt/ui-components'
import styled from 'styled-components'

import { AddItemNotification } from 'components/Notifications'
import { Info } from './Info'
import { ProductForm } from './ProductForm'
import ProductInfo from './ProductInfo'
import { useActions, useOverState } from 'store'

import type { VFC } from 'react'

export const ProductModal: VFC = () => {
  const { modalProps, modal } = useOverState().modal
  const { products } = useOverState().hardware
  const product = products.idHash[modalProps.id]
  const { closeModal } = useActions().modal

  return (
    <Modal
      title={product.title}
      isOpen={modal === 'product'}
      onRequestClose={closeModal}
      size='medium'
      shouldCloseOnOverlayClick={false}
      contentClassName='modal__content'
    >
      <Info product={product} />
      <AddItemNotification item='hardware' />
      <Container>
        <ProductInfo product={product} />
        <ProductForm product={product} />
      </Container>
    </Modal>
  )
}

const Modal = styled(UIModal)`
  padding: 16 20 0;
  max-height: 537px;
  .modal__content {
    margin-bottom: 0;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
`
