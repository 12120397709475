import { http } from 'helpers'
import type { Files, FilesName, Project, ProjectName } from './types'
import type { NewProject, NewWifi, Plan } from 'app/marketplace/ProjectSidebar/Form/types'
import type { UpdateProjectOptions } from './types'

export const api = {
  create: (clientId: string, body: NewProject) =>
    http.post<Project>(`projects/professional`, { clientId, ...parseProject(body) }),
  getProject: (clientId: string, projectId: string) =>
    http.get<Project>(`projects/client/${clientId}/${projectId}`),
  getProjects: (clientId: string) => http.get<ProjectName[]>(`projects/client/${clientId}`),
  getUploadUrls: (clientId: string) => (files: Plan[]) =>
    http.post<Files>(`projects/files/upload/`, { clientId, files: files.map((file) => file.name) }),
  update: ({ clientId, projectId, project }: UpdateProjectOptions) =>
    http.put<Project>(`projects/${projectId}/professional`, { clientId, ...parseProject(project) }),
  uploadFile: ([{ file }, { name, url }]: [Plan, FilesName]) =>
    http.put(url, file).then(() => name),
  delete: (projectId: string) => http.delete<void>(`projects/${projectId}`),
}

const parseWifi = (wifi: NewWifi[]) => wifi.map((item) => ({ ...item, plan: item.plan?.name }))
const parseProject = (project: NewProject) => ({
  ...project,
  wifiIndoor: parseWifi(project.wifiIndoor),
  wifiOutdoor: parseWifi(project.wifiOutdoor),
})
