import {
  all,
  allPass,
  any,
  anyPass,
  equals,
  filter,
  includes,
  isNotNil,
  pathEq,
  paths,
  pipe,
  prop,
  propEq,
  reduce,
  reject,
  where,
  __,
} from 'ramda'

import { isNotEmpty } from 'helpers'
import { MAX_BACKORDERS, QuoteStatus } from 'shared'

import type { Hardware, Quote, QuoteProHardware, QuoteProService, UIHardware } from 'types'

const adjustableStatuses = [
  QuoteStatus.SENT,
  QuoteStatus.PENDING_FULFILMENT,
  QuoteStatus.PARTIALLY_COMPLETED,
]
const backorderStatuses = [QuoteStatus.DRAFT, QuoteStatus.SENT]

const isBackorder = (x: UIHardware) => x.backordered > 1 || (!x.inStock && x.backorderable)

const calculateBackorderDiff: (xs: UIHardware[]) => number = pipe(
  filter(isBackorder),
  reduce((acc, x) => acc - x.backordered + (x.adjusted.qty ?? x.qty), 0),
)

// Exports

export interface ServiceItem {
  qty?: number
  price: number
}

export const getCommission = (commission: number, total: number) =>
  Math.ceil(total * commission) / 100

export const isTaxValid: (x: Quote) => boolean = pipe(
  paths([
    ['taxData', 'proHardware'],
    ['taxData', 'proService'],
  ]),
  all(isNotNil),
)

export const isAdjusting: (x: Quote) => boolean = anyPass([
  where({
    adjusting: equals(true),
    status: includes(__, adjustableStatuses),
  }),
  where({
    adjusting: equals(true),
    disputes: isNotEmpty,
    status: equals(QuoteStatus.FINAL_PAYMENT_PENDING),
  }),
])

export const checkProItemsEditable = (quote: Quote) =>
  quote.status === QuoteStatus.DRAFT || isAdjusting(quote)

export const checkUIHardwareEditable = (quote: Quote) =>
  quote.status === QuoteStatus.DRAFT || (quote.status === QuoteStatus.SENT && quote.adjusting)

export const rejectRemoved: <A extends UIHardware | QuoteProHardware | QuoteProService>(
  x: A[],
) => A[] = reject(pathEq('removed', ['adjusted', 'type']))

export const hasUiItems: (x: Quote) => boolean = pipe(
  prop('productVariantQuotes'),
  rejectRemoved,
  isNotEmpty,
)

export const hasOutOfStock = pipe<[Quote], Quote['productVariantQuotes'], any, boolean>(
  prop('productVariantQuotes'),
  rejectRemoved,
  any(
    allPass([
      anyPass([propEq(false, 'inStock'), pathEq(false, ['productVariant', 'inStock'])]),
      pathEq(false, ['productVariant', 'backorderable']),
    ]),
  ),
)

export const hasTooManyBackorders = (current: number, quote: Quote) =>
  backorderStatuses.includes(quote.status) &&
  current + calculateBackorderDiff(quote.productVariantQuotes) > MAX_BACKORDERS

export const isAdjustable: (x: QuoteStatus) => boolean = includes(__, adjustableStatuses)

export const isBackorderItem = (item: Hardware) => !item.inStock && item.continueSelling
