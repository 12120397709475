import { DropdownOption } from '@ubnt/ui-components/Dropdown/types'
import { append, flatten, map, pipe, pluck, reject, sort, uniq, values } from 'ramda'
import { ProHardware, ProService } from 'types'

const appendCustomData = (proData: ProHardware[] | ProService[], key: string) =>
  pipe<[string[]], string[], string[], string[], string[]>(
    append(pluck<string, any>(key, proData) as any),
    flatten,
    uniq,
    sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1)),
  )

export const makeDropdownOpts = (x: string) => ({ label: x, value: x })

export const appendAndParseDropdownOpts = (
  list: string[],
  proData: ProHardware[] | ProService[],
  key: string,
) => {
  return pipe<[string[]], string[], DropdownOption[]>(
    appendCustomData(proData, key),
    map(makeDropdownOpts),
  )(list)
}

export const appendAndParseDropdownOptsFromApi = (
  list: Record<string, string>,
  proHardware: ProHardware[],
  key: string,
) =>
  pipe<[Record<string, string>], string[], string[], string[], { label: string; value: string }[]>(
    values,
    reject((x) => x === 'Others'),
    appendCustomData(proHardware, key),
    map(makeDropdownOpts),
  )(list)
