import { derived } from 'overmind'
import { AsyncFuncState } from 'shared'
import { ProfessionalState } from './types'

export const state: ProfessionalState = {
  professionals: derived(({ professionalsHash }: ProfessionalState) =>
    Object.values(professionalsHash),
  ),
  status: AsyncFuncState.NULL,
  professionalsHash: {},
}
