import { toLocaleCurrency } from 'helpers'
import styled from 'styled-components'

import type { TableColumnConfig } from '@ubnt/ui-components/Table/TableTypes'
import type { ProductItem } from './types'

export const renderClient = ({ name, thumbnail }: { name: string; thumbnail: string }) => (
  <span className='cell-logo'>
    <StyledImage src={thumbnail} />
    <span className='cell-logo-name'>{name}</span>
  </span>
)

export const columns: TableColumnConfig<ProductItem>[] = [
  {
    id: 'name',
    label: 'Device',
    sortable: true,
    minWidth: 140,
    renderCell: (row) => renderClient(row),
  },
  {
    id: 'qty',
    label: 'Amount',
    sortable: true,
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'price',
    label: 'Price',
    sortable: true,
    minWidth: 100,
    align: 'center',
    renderCell: ({ price }) => toLocaleCurrency(price),
  },
]

const StyledImage = styled.img`
  display: block;
  border-radius: 15%;
  background: transparent;
  text-indent: -9999px;
  text-align: center;
  width: 50px;
`
