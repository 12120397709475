import { ExclamationMarkIconL, NotificationIconL } from '@ubnt/icons'

import { Container, IconWrapper } from './styles'

import type { FC } from 'react'
import type { BubbleContainerProps } from './types'

export const BubbleContainer: FC<BubbleContainerProps> = ({
  children,
  className,
  icon,
  iconSize = 'small',
  padding,
  radius = 'big',
  variant = 'primary',
  verticalSpacing = 16,
  type = 'default',
}) => (
  <Container
    className={className}
    padding={padding}
    radius={radius}
    row={Boolean(icon)}
    variant={variant}
    verticalSpacing={verticalSpacing}
    type={type}
  >
    {icon && (
      <IconWrapper type={type}>
        {type === 'warn' && <ExclamationMarkIconL size={iconSize} variant='fill' />}
        {type !== 'warn' && <NotificationIconL size={iconSize} variant='fill' />}
      </IconWrapper>
    )}
    {children}
  </Container>
)
