import styled from 'styled-components'
import { Link as BaseLink } from 'react-router-dom'

export const RouterLink = styled(BaseLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.Light.color.blue.b6};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.Light.color.blue.b6};
  }
`
