import styled from 'styled-components'

export const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  padding: 8px 0 8px 32px;
  > * {
    margin-bottom: 15px;
  }
`

export const ConditionalOverlay = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: ${({ show }) => (show ? 'center' : '')};
  align-items: ${({ show }) => (show ? 'center' : '')};
  padding: 12px 0 12px 32px;
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`
