import { FC, useContext, useEffect, useState } from 'react'
import { SidePanel as UISidePanel } from '@ubnt/ui-components/SidePanel'
import { filter, mergeRight, pick } from 'ramda'
import styled from 'styled-components'

import { Loader } from 'components'
import { useSlider } from 'hooks'
import { useActions, useOverState } from 'store'
import type { ModalType, ProjectSidebar as SidebarType } from 'store/modal/types'
import type { Project } from 'store/projects/types'
import { CheckedDeployment, ProjectFormContext } from './ProjectContext'
import { formMap, tabs } from './data'
import type { NewProject } from './Form/types'
import { ProjectFormProvider, Schema } from './ProjectFormProvider'
import { SidebarBody } from './styles'

interface SidebarProps {
  initProject: Project | NewProject
}

export const EMPTIES = {
  doors: {
    qty: 0,
    note: '',
  },
  cameras: {
    qty: 0,
    note: '',
  },
  voIP: {
    qty: 0,
    note: '',
  },
  wifiIndoor: [],
  wifiOutdoor: [],
}

const parseChecked = (checked: CheckedDeployment) => {
  const data = {
    wifiIndoor: checked.wifi,
    wifiOutdoor: checked.wifi,
    voIP: checked.phoneSystem,
    cameras: checked.camera,
    doors: checked.doorAccess,
  }
  return Object.keys(filter((x) => !x, data))
}

const parseFormData = ({ checked, ...data }: Schema) => {
  const empties = pick(parseChecked(checked), EMPTIES)
  return mergeRight(data, empties)
}

export const Sidebar: FC<SidebarProps> = ({ initProject }) => {
  const { sidebar, sidebarProps } = useOverState().modal as ModalType<unknown, SidebarType>
  const { creating } = useOverState().projects
  const {
    clientManagement: { status },
  } = useOverState()
  const { app, modal, projects } = useActions()

  const formContext = useContext(ProjectFormContext)
  const [selectedTab, setSelectedTab] = useState<string | number>(tabs[0].value)

  const onClose = () => {
    modal.closeSidebar()
    sidebarProps.onClose()
    projects.resetCreating()
  }
  const { Slider, closeSlider } = useSlider(onClose)

  useEffect(() => {
    if (!initProject) return
    if (!initProject.id) setSelectedTab(tabs[1].value)
  }, [initProject])

  const handleNext = (payload: Schema) => {
    const onSuccess = () => {
      closeSlider()
      app.toast({ title: 'Project saved', message: 'The project has been successfully saved' })
    }

    if (formContext.editable) {
      const opts = {
        projectId: initProject.id ?? creating,
        clientId: sidebarProps.clientId,
        onSuccess,
        project: parseFormData(payload),
      }

      return projects.createOrUpdateProject(opts)
    }

    return onSuccess()
  }

  const FormComponent = formMap[selectedTab] || null

  return (
    <Slider>
      <SidePanel
        header={sidebarProps.newProject ? 'Add Project' : initProject?.name}
        in={sidebar === 'project_details'}
        onClose={closeSlider}
        tabs={sidebarProps.newProject ? undefined : tabs}
        tabValue={selectedTab}
        onTabChange={setSelectedTab}
      >
        <SidebarBody>
          <ProjectFormProvider>
            <Loader status={status}>
              {initProject && (
                <FormComponent
                  handleNext={handleNext}
                  handlePrev={closeSlider}
                  project={initProject as Project}
                />
              )}
            </Loader>
          </ProjectFormProvider>
        </SidebarBody>
      </SidePanel>
    </Slider>
  )
}

const SidePanel = styled(UISidePanel)`
  > div {
    background-color: ${({ theme }) => theme.colors.Light.color.neutral.n1};
  }
`
