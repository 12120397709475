import { drop, isNil, update } from 'ramda'
import type { ClientData } from 'types/client.type'
import type { FormFields } from './types'

export const parseClient = (data: Omit<FormFields, 'projectSelection'>) => ({
  firstName: data.clients[0].firstName,
  lastName: data.clients[0].lastName,
  name: data.clients[0].name,
  email: data.clients[0].email,
  phone: data.clients[0].phone,
  taxExempt: data.tax.exempt,
  taxCertificates: data.tax.file,
  clientUsers: drop(1, data.clients),
})

interface CreateOrUpdateContact {
  idx: number | null
  client: ClientData
  setClientsArr: (value: React.SetStateAction<ClientData[]>) => void
}

export const createOrUpdateContact = ({ idx, client, setClientsArr }: CreateOrUpdateContact) =>
  isNil(idx)
    ? setClientsArr((prev) => [...prev, client])
    : setClientsArr((prev) => update(idx, client, prev))

type HandleDelete = (
  setClientsArr: React.Dispatch<React.SetStateAction<ClientData[]>>,
) => (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void

export const handleDelete: HandleDelete = (setClientsArr) => (event) => {
  setClientsArr((prev) =>
    // @ts-ignore
    prev.filter((x) => x.email !== event.target.getAttribute('data-email')),
  )
}

type HandleEdit = (arg: {
  contacts: ClientData[]
  setCurrentClientIndex: React.Dispatch<React.SetStateAction<number | null>>
  setCurrentClient: React.Dispatch<React.SetStateAction<ClientData>>
  setFormHeader: (header: string) => void
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  firstFormStep: 'add_client' | 'edit_client'
}) => (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void

export const handleEdit: HandleEdit =
  ({
    contacts,
    setCurrentClientIndex,
    setCurrentClient,
    setFormHeader,
    setCurrentStep,
    firstFormStep,
  }) =>
  (event) => {
    const idx = contacts.findIndex(
      // @ts-ignore
      (x) => x.email === event.target.getAttribute('data-email'),
    )
    const contact = contacts[idx]
    setCurrentClientIndex(idx)

    setCurrentClient(contact)
    if (idx) {
      setFormHeader('Edit Contact')
      setCurrentStep('add_edit_contact')
      return
    }
    setFormHeader('Edit Client')
    setCurrentStep(firstFormStep)
  }

type HandleAdd = (
  setCurrentClient: (value: React.SetStateAction<ClientData>) => void,
  setFormHeader: (header: string) => void,
  setCurrentStep: (value: React.SetStateAction<string>) => void,
) => () => void

export const handleAdd: HandleAdd = (setCurrentClient, setFormHeader, setCurrentStep) => () => {
  setCurrentClient({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    name: '',
  })

  setFormHeader('Add Additional Contact')
  setCurrentStep('add_edit_contact')
}

export const isVisibleBackButton = (clientIdx: number | null, currentStep: string) =>
  !isNil(clientIdx) || currentStep === 'add_edit_contact'
