import { http } from 'helpers'

import type { RewardPoints } from 'types'

const listRewards = (professionalId = '') =>
  http.get<RewardPoints[]>(`rewards${professionalId && `?professionalId=${professionalId}`}`)

export const api = {
  listRewards,
}
