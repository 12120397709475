import type { Context } from 'store'
import { WithQuoteId } from 'store/clientmanagement/types'
import type { UpdateCommissionRate, WithInvoiceId, UpdateCommissionAmount } from './types'

export const getFulfillments = ({ effects, state }: Context, { quoteId }: WithQuoteId) => {
  return effects.paymentDetails.api //
    .getFulfillments(quoteId)
    .then((data) => {
      state.paymentDetails.fulfillments = data
    })
}

export const getPaymentDetails = ({ state, effects }: Context, value: WithQuoteId) =>
  effects.paymentDetails.api
    .getPaymentDetails(value) //
    .then((data) => {
      state.paymentDetails.data = data
    })

export const rejectInvocie = ({ effects }: Context, { invoiceId }: WithInvoiceId) =>
  effects.paymentDetails.api.rejectInvoice(invoiceId)

export const updateCommissionRate = (
  { effects }: Context,
  { commissionRate, invoiceId, quoteId }: UpdateCommissionRate,
) => effects.paymentDetails.api.updateCommissionRate(commissionRate, invoiceId, quoteId)

export const updateCommissionAmount = (
  { effects }: Context,
  { commissionAmount, invoiceId }: UpdateCommissionAmount,
) => effects.paymentDetails.api.updateCommissionAmount(commissionAmount, invoiceId)
