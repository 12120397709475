import { Text } from '@ubnt/ui-components/Text'
import { Tooltip } from '@ubnt/ui-components/Tooltip'

import { InfoIcon } from '../InfoIcon'
import { TitleRow } from './styles'

import type { FC } from 'react'
import type { ProgressTitleProps } from './types'

export const Title: FC<ProgressTitleProps> = ({ renderIcon, title, tooltip }) => (
  <TitleRow grow={1} justify='flex-start'>
    {renderIcon && renderIcon()}
    <Text size='body' weight='bold'>
      {title}
    </Text>
    {tooltip && (
      <Tooltip description={tooltip} position='bottomLeft' width={265}>
        <InfoIcon />
      </Tooltip>
    )}
  </TitleRow>
)
