import { Button, ButtonGroup as UIButtonGroup } from '@ubnt/ui-components/Button'
import { Checkbox } from '@ubnt/ui-components/Checkbox'
import { Avatar, ControlledInput, FlexColumn, FlexRow, useSysAdmin } from 'components'
import { File } from 'components/File'
import { useRole } from 'components/Roles/Roles'
import { isNotEmpty, makeUrl } from 'helpers'
import { any, isEmpty } from 'ramda'
import { ChangeEvent, FC, KeyboardEventHandler } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { UserRole } from 'shared'
import { useActions, useEffects, useOverState } from 'store'
import { handleError as handleAxiosError } from 'store/helpers'
import styled from 'styled-components'
import type { FormFields } from '../types'

interface Props {
  clientIndex: number
  emailError: string
  isEditMode: boolean
  isAddContactForm?: boolean
  close: (event: React.MouseEvent<Element, MouseEvent>) => void
  onSubmit: () => void
}

export const ClientForm: FC<Props> = ({
  clientIndex,
  emailError,
  isEditMode,
  isAddContactForm = false,
  close,
  onSubmit,
}) => {
  const { id: clientId } = useOverState().clientManagement.currentClient
  const { uploadTaxCertificate } = useEffects().clients.api
  const { toast } = useActions().app

  const isSysAdmin = useSysAdmin()

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useFormContext<FormFields>()

  const { remove } = useFieldArray<FormFields, 'clients'>({
    control,
    name: 'clients',
  })

  const handleEnterKey: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const handleUploadTaxCertificate =
    (onChange: (...event: any[]) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly || !event?.target?.files) return

      const data = new FormData()
      data.append('file', event.target.files[0])
      if (clientId) data.append('clientId', clientId)

      return uploadTaxCertificate(data)
        .then((taxFile) => {
          onChange({ file: [taxFile], exempt: false }) // Reset 'exempt' to false so a new tax file will have to be reviewed before approval.
        })
        .catch(handleRequestError)
    }

  const handleRemoveTaxCertificate =
    (onChange: (...event: any[]) => void) => (fileName: string) => {
      if (isReadOnly) return

      onChange({ file: getValues().tax.file.filter(({ key }) => key !== fileName), exempt: false })
    }

  const handleRequestError = (error: any) =>
    toast({
      title: 'Error processing your request',
      message: handleAxiosError(error),
      type: 'danger',
    })

  const [firstName, lastName, email] = useWatch({
    control,
    name: [
      `clients.${clientIndex}.firstName`,
      `clients.${clientIndex}.lastName`,
      `clients.${clientIndex}.email`,
    ],
  })
  const taxFiles = useWatch({ control, name: 'tax.file' })

  const name = [firstName, lastName]

  const isAddAdditionalContactForm = clientIndex > 0 && !isEditMode
  const isClientOwner = clientIndex === 0
  const isReadOnly = useRole([UserRole.AGENT, UserRole.SYS_ADMIN])
  const taxFileKey = !isAddContactForm && isNotEmpty(taxFiles) ? `?filename=${taxFiles[0].key}` : ''
  const isDisableNext =
    isNotEmpty(errors) || isNotEmpty(emailError) || any(isEmpty, [firstName, lastName, email])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FlexColumn grow={1} justify='space-between'>
        <FlexColumn alignItems='center'>
          <Wrapper>
            <Avatar name={name} logoUrl='' imgSize={112} fontSize={38} fontWeight='bold' />
          </Wrapper>

          {isClientOwner && (
            <ControlledInput
              name={`clients.${clientIndex}.name`}
              control={control}
              label='Company Name (Optional)'
              width='100%'
              invalid={errors?.clients?.[clientIndex]?.name?.message}
              onKeyDown={handleEnterKey}
              marginBottom={15}
            />
          )}

          <ControlledInput
            name={`clients.${clientIndex}.firstName`}
            control={control}
            label='First Name'
            width='100%'
            invalid={errors?.clients?.[clientIndex]?.firstName?.message}
            onKeyDown={handleEnterKey}
            marginBottom={15}
          />

          <ControlledInput
            name={`clients.${clientIndex}.lastName`}
            control={control}
            label='Last Name'
            width='100%'
            invalid={errors?.clients?.[clientIndex]?.lastName?.message}
            onKeyDown={handleEnterKey}
            marginBottom={15}
          />

          <ControlledInput
            name={`clients.${clientIndex}.email`}
            control={control}
            label='Email Address'
            width='100%'
            invalid={emailError || errors?.clients?.[clientIndex]?.email?.message}
            onKeyDown={handleEnterKey}
            marginBottom={15}
          />

          <ControlledInput
            name={`clients.${clientIndex}.phone`}
            control={control}
            label='Contact Number (Optional)'
            width='100%'
            invalid={errors?.clients?.[clientIndex]?.phone?.message}
            onKeyDown={handleEnterKey}
            marginBottom={15}
          />

          {clientIndex === 0 && !isAddContactForm && (
            <FlexRow>
              <Controller
                control={control}
                name='tax'
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id='taxExempt'
                    name='taxExempt'
                    checked={value.exempt}
                    disabled={!isSysAdmin || isEmpty(taxFiles)}
                    onChange={() => onChange({ ...getValues().tax, exempt: !value.exempt })}
                  >
                    Tax Exempt
                  </Checkbox>
                )}
              />

              <Controller
                control={control}
                name='tax'
                render={({ field: { onChange } }) => (
                  <File
                    readOnly={isReadOnly}
                    text='Upload Tax Exempt Certificate'
                    onChange={handleUploadTaxCertificate(onChange)}
                    onClose={handleRemoveTaxCertificate(onChange)}
                    files={taxFiles}
                    makeUrl={() =>
                      makeUrl(
                        `clients/${clientId ? clientId : 'temp'}/tax-certificate${taxFileKey}`,
                      )
                    }
                    accept='application/pdf'
                  />
                )}
              />
            </FlexRow>
          )}
        </FlexColumn>

        <ButtonGroup>
          {isAddAdditionalContactForm || isEditMode ? (
            <Button
              variant='tertiary'
              onClick={() => {
                onSubmit()
                if (isAddAdditionalContactForm) remove(clientIndex)
              }}
            >
              Back
            </Button>
          ) : (
            <Button variant='tertiary' onClick={close}>
              Cancel
            </Button>
          )}
          <Button variant='primary' disabled={isDisableNext}>
            Next
          </Button>
        </ButtonGroup>
      </FlexColumn>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  height: 100%;
`

export const Wrapper = styled.div`
  margin: 16px 0;
`

export const BackBtnWrapper = styled.div`
  position: absolute;
  top: 28px;
  left: 28px;
`

export const ButtonGroup = styled(UIButtonGroup)`
  width: 100%;
  justify-content: flex-end;
`
