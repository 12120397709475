import { UserTableType } from 'app/users/types'
import { branch, debounce, filter, pipe } from 'overmind'
import { UserRole } from 'shared'
import type { Context } from 'store'
import * as modalActions from 'store/modal/actions'
import { handleAsyncAction, toast as opToast } from 'store/operators'
import { WithPage } from 'store/types'
import type { User } from 'types'
import * as op from './operators'
import { GetUserOptions } from './types'

const handleAction = handleAsyncAction('users')

export const getCompanyMembers = handleAction<void>({
  action: op.getCompanyMemebers,
  before: filter(({ state }: Context) => state.app.user?.role !== UserRole.AGENT),
})

export const getUsers = handleAction<GetUserOptions, void>(op.listUsers)

export const getSearchedUsers = handleAction<GetUserOptions, void>({
  before: debounce(600),
  action: op.listUsers,
})

export const updateUser = handleAction<
  {
    user: Pick<User, 'role' | 'ssoId'>
    type: 'pro' | 'client'
  } & WithPage<UserTableType>,
  void
>(
  pipe(
    branch(op.updateUser),
    op.listUsers,
    opToast('Success', 'Successfully Updated User'),
    modalActions.closeModal,
  ),
)

export const removeUserFromCompany = handleAction<{ id: string }, void>(
  pipe(
    op.removeUserFromCompany,
    op.getCompanyMemebers,
    opToast('Success', 'Successfully Deleted User'),
    modalActions.closeModal,
  ),
)

export const deleteUser = handleAction<
  { id: string; getAllProUsers: boolean } & WithPage<User>,
  void
>(
  pipe(
    op.deleteUser,
    op.getUsers,
    opToast('Success', 'Soft Deleted User'),
    modalActions.closeModal,
  ),
)

export const setUser = ({ state }: Context, user: User) => {
  state.app.user = { ...state.app.user, ...user }
}
