import { useEffect } from 'react'

import { useActions, useOverState } from 'store'

export const NewsListener = () => {
  const { app, news } = useOverState()
  const actions = useActions()

  useEffect(() => {
    if (app.user) actions.news.list()
  }, [app.user])

  useEffect(() => {
    if (news.list.length > 0) {
      actions.modal.setModal({ modal: 'news', props: null })
    }
  }, [news.list])

  return null
}
