import { useNavigate, useLocation } from 'react-router-dom'
import { Modal } from '@ubnt/ui-components/Modal'

import { FlexRow } from 'components'
import ContactsForm from 'images/ContactsForm.png'
import EditQuoteForm from 'images/EditQuoteForm.png'
import { useOverState } from 'store'
import { Body, Link, StyledText, SubHeader, Table, TableData, TableHeader } from './styles'
import texts from './texts'

import type { FC } from 'react'

const firstColumnWidth = '30%'
const secondColumnWidth = '70%'

export const TermsAndConditionsModal: FC = () => {
  const { modal } = useOverState().modal
  const location = useLocation()
  const navigate = useNavigate()

  const handleClose = () => navigate(location.pathname)

  return (
    <Modal
      title='Program Details'
      isOpen={modal === 'terms_and_conditions'}
      onRequestClose={handleClose}
      size='large'
      height='large'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Done',
          variant: 'primary',
          onClick: handleClose,
        },
      ]}
    >
      <Body>
        <SubHeader>{texts.onboarding.header}</SubHeader>
        <StyledText>{texts.onboarding.text}</StyledText>

        <SubHeader>{texts.commissions.header}</SubHeader>
        <StyledText>{texts.commissions.text}</StyledText>

        <SubHeader>{texts.commissionTable.header}</SubHeader>
        <Table>
          <tbody>
            <tr>
              <TableHeader width={firstColumnWidth}>Category</TableHeader>
              <TableHeader width={secondColumnWidth}>Commissions</TableHeader>
            </tr>
            <tr>
              <TableData width={firstColumnWidth}>UniFi Hardware</TableData>
              <TableData width={secondColumnWidth}>{texts.commissionTable.text.hardware}</TableData>
            </tr>
            <tr>
              <TableData width={firstColumnWidth}>UniFi Talk Subscriptions</TableData>
              <TableData width={secondColumnWidth}>{texts.commissionTable.text.talk}</TableData>
            </tr>
          </tbody>
        </Table>

        <SubHeader>{texts.unifiTalkSubs.header}</SubHeader>
        <StyledText>{texts.unifiTalkSubs.text.first}</StyledText>
        <StyledText>{texts.unifiTalkSubs.bullet.first}</StyledText>
        <StyledText>{texts.unifiTalkSubs.bullet.second}</StyledText>
        <StyledText>{texts.unifiTalkSubs.bullet.third}</StyledText>
        <FlexRow justify='center' verticalSpacing={16}>
          <img src={ContactsForm} alt='Example of contact emails' height='500px' />
        </FlexRow>
        <StyledText>{texts.unifiTalkSubs.text.second}</StyledText>

        <SubHeader>{texts.sellProItems.header}</SubHeader>
        <StyledText>{texts.sellProItems.text}</StyledText>

        <SubHeader>{texts.payProItems.header}</SubHeader>
        <StyledText>{texts.payProItems.text.first}</StyledText>
        <FlexRow justify='center' verticalSpacing={16}>
          <img src={EditQuoteForm} alt='Example of quote details' height='500px' />
        </FlexRow>
        {texts.payProItems.text.second.map((text) => (
          <StyledText key={text}>{text}</StyledText>
        ))}

        <SubHeader>{texts.stripeAccount.header}</SubHeader>
        <StyledText>{texts.stripeAccount.text}</StyledText>

        <SubHeader>{texts.paymentTiming.header}</SubHeader>
        <StyledText>{texts.paymentTiming.text}</StyledText>

        <SubHeader>{texts.inviteTeamMembers.header}</SubHeader>
        <StyledText>{texts.inviteTeamMembers.text}</StyledText>

        <SubHeader>{texts.returns.header}</SubHeader>
        <StyledText>{texts.returns.text}</StyledText>

        <SubHeader>{texts.rewards.header}</SubHeader>
        <StyledText dangerouslySetInnerHTML={{ __html: texts.rewards.text }} />

        <SubHeader>{texts.rulesOfConduct.header}</SubHeader>
        <StyledText>{texts.rulesOfConduct.text}</StyledText>

        <SubHeader>{texts.ppts.header}</SubHeader>
        <StyledText>{texts.ppts.text}</StyledText>
        <Link href='https://www.ui.com/legal/privacypolicy/' style={{ marginTop: '8px' }}>
          Privacy Policy
        </Link>
        <Link href='https://www.ui.com/legal/termsofservice/'>Terms of Service</Link>

        <SubHeader>{texts.revisions.header}</SubHeader>
        <StyledText>{texts.revisions.text}</StyledText>
      </Body>
    </Modal>
  )
}
