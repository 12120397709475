import styled, { css } from 'styled-components'
import { Text } from '@ubnt/ui-components'
import { ArrowLeftPrimaryIconL, ArrowRightPrimaryIconL } from '@ubnt/icons'

import type { PaginatorButtonProps, ContainerProps } from './types'

const arrow = css`
  height: 7px;
  width: auto;
`

export const ArrowLeft = styled(ArrowLeftPrimaryIconL)`
  ${arrow}
`

export const ArrowRight = styled(ArrowRightPrimaryIconL)`
  ${arrow}
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${({ align }) => align};

  padding: 5px 15px;
`

export const Label = styled(Text)`
  margin-right: 12px;
`

export const PaginatorButton = styled.button<PaginatorButtonProps>`
  display: flex;
  align-items: center;

  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.Light.color.neutral.n2 : 'inherit'};
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 6px 8px;

  cursor: ${({ disabled, selected }) => (disabled || selected ? 'default' : 'pointer')};

  &:disabled {
    border-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  }

  &:hover {
    background-color: ${({ disabled, theme }) =>
      disabled ? 'inherit' : theme.colors.Light.color.neutral.n2};
  }

  &:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-of-type {
    border-radius: 0px 4px 4px 0px;
  }
`
