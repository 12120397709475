import { ClientTableType } from 'app/client/constants'
import { indexById } from 'helpers'
import { filter } from 'overmind'
import { mapObjIndexed, omit } from 'ramda'
import type { Context } from 'store'
import type { WithClientId, WithPage, WithProfessionalId } from 'store/types'
import type { Client } from 'types'

interface WithUploadedTaxFile {
  uploadedTaxFile: boolean
}

export interface AddClientOptions extends WithUploadedTaxFile {
  client: Partial<Client> & { clientUsers?: Partial<Client>[] }
  email: boolean
  onSuccess?: CallableFunction
  fetchAllClients: boolean
}

export type UpdateClientOptions = WithClientId & WithClient & WithUploadedTaxFile

export interface WithClient {
  client: Partial<Client> & { clientUsers?: Partial<Client>[] }
}

export const addClient =
  <A extends AddClientOptions>() =>
  ({ effects }: Context, value: A): Promise<A & WithClientId> =>
    effects.clients.api
      .addClient(value.client, value.email, value.uploadedTaxFile)
      .then(({ id }) => ({ ...value, clientId: id }))

export const changeProfessional = <A extends WithClientId & WithProfessionalId>(
  { effects }: Context,
  value: A,
) => effects.clients.api.changeProfessional(value.clientId, value)

export const clientsIsEmpty = <A extends WithPage<ClientTableType>>() =>
  filter<any>(({ state }: Context, { all }: A) => {
    if (all) return state.clients.limited.list.length === 0
    return state.clients.clients.list.length === 0
  })

export const getClients = <A extends WithPage<ClientTableType> & Partial<AddClientOptions>>(
  { state, effects }: Context,
  { page, all, search, sortingColumn, order, fetchAllClients = false }: A,
) =>
  effects.clients.api
    .getClients(page, all || fetchAllClients, search, sortingColumn, order) //
    .then((data) => {
      if (Array.isArray(data)) {
        state.clients.limited.hash = indexById(data)
        return
      }

      state.clients.clients.hash = indexById(data.data)
      state.clients.clients.page = omit(['data'], data)
    })

export const removeEmptyFields = <A extends WithClient>(_: Context, value: A) => ({
  ...value,
  client: {
    ...mapObjIndexed((x) => x ?? undefined, value.client),
    clientUsers:
      value.client.clientUsers &&
      value.client.clientUsers.map((x) => mapObjIndexed((x) => x ?? undefined, x)),
  },
})

export const updateClient = <A extends UpdateClientOptions>(
  { effects }: Context,
  { clientId, client, uploadedTaxFile }: A,
) => effects.clients.api.updateClient(clientId, client, uploadedTaxFile)

export const deleteClient = <A extends WithClientId>({ effects }: Context, { clientId }: A) =>
  effects.clients.api.deleteClient(clientId)

export const addContact = <A extends Omit<UpdateClientOptions, 'uploadedTaxFile'>>(
  { state, effects }: Context,
  { clientId, client }: A,
) =>
  effects.clients.api.addContact(clientId, client).then(() => {
    state.modal.modalProps.sendToClient.resetEmail()
    state.modal.modalProps.sendToClient.updateEmailList(client.email)
  })
