import { Context } from 'store'
import { WithQuoteId } from 'store/clientmanagement/types'
import type {
  ModalOptions,
  PaymentDetailsSidebar,
  ProItemsQuoteDetailsSidebar,
  ProjectNoteModal,
  ProjectRemoveModal,
  ProjectSidebar,
  SendQuoteSidebar,
  TalkCommissionSidebar,
} from './types'

export const closeModal = ({ state }: Context) => {
  state.modal.modal = null
  state.modal.modalProps = {}
}

export const closeSidebar = ({ state }: Context) => {
  state.modal.sidebar = null
  state.modal.sidebarProps = null
}

export interface SidebarPayment {
  sidebar: 'payment_details'
  props: PaymentDetailsSidebar
}

export interface SidebarProItemQuote {
  sidebar: 'pro_item_quote'
  props: ProItemsQuoteDetailsSidebar
}

export interface SidebarTalkComm {
  sidebar: 'talk_comm_details'
  props: TalkCommissionSidebar
}

export interface SidebarProject {
  sidebar: 'project_details'
  props: ProjectSidebar
}

export interface SidebarSendQuote {
  sidebar: 'send_to_client'
  props: SendQuoteSidebar
}

export type SidebarOptions =
  | SidebarPayment
  | SidebarProject
  | SidebarSendQuote
  | SidebarProItemQuote
  | SidebarTalkComm

export const setSidebar = <A extends SidebarOptions>({ state }: Context, { sidebar, props }: A) => {
  state.modal.sidebar = sidebar
  state.modal.sidebarProps = props
}

export interface ModalAny {
  modal: Exclude<ModalOptions, 'project_note'>
  props: any
}

export interface ModalConfirmComplete {
  modal: 'confirm_complete_quote'
  props: WithQuoteId
}

export interface ModalProjectNote {
  modal: 'project_note'
  props: ProjectNoteModal
}

export interface RemoveProjectModal {
  modal: 'remove_project'
  props: ProjectRemoveModal
}

export type SetModal = ModalAny | ModalConfirmComplete | ModalProjectNote | RemoveProjectModal

export const setModal = <A extends SetModal>({ state }: Context, { modal, props }: A) => {
  state.modal.modal = modal
  state.modal.modalProps = props
}

export const setModalName = <A extends { name: string }>({ state }: Context, { name }: A) => {
  state.modal.modalProps = { modalName: name }
}
