import {
  fromPairs,
  groupBy as ramdaGroupBy,
  isNil,
  map,
  pathEq,
  pipe,
  reject,
  toPairs,
} from 'ramda'
import { ContractHardware } from 'app/marketplace/contracts/topology/models/topology'
import { CustomerCategory, typeToTopology } from 'shared'

import { CustomerProductVariantQuote, ProductType, Quote, UIHardware, User } from 'types'

export * from './address'
export * from './array'
export * from './http'
export * from './invoices'
export * from './logic'
export * from './numbers'
export * from './quote'
export * from './text'
export * from './time'
export * from './unitSystem'
export * from './render'
export * from './browser'

export const groupBy = <T>(array: T[], key: keyof T) => {
  return array.reduce((result, currentValue) => {
    result[currentValue[key]] = result[currentValue[key]] || []
    result[currentValue[key]].push(currentValue)

    return result
  }, {} as any)
}

export const groupWith = <T>(array: T[], key: keyof T) => Object.values(groupBy(array, key))

export const groupBy2 = <T, K extends keyof T>(prop: K, array: T[]): Map<T[K], T[]> =>
  array.reduce((accumulated, current) => {
    const key = current[prop]
    let existingArray = accumulated.get(key)
    if (!existingArray) {
      existingArray = []
      accumulated.set(key, existingArray)
    }
    existingArray.push(current)

    return accumulated
  }, new Map<T[K], T[]>())

export const isEmptyObj = (obj: any) => JSON.stringify(obj) === '{}'

export const prop = (prop: string) => (obj: any) => obj[prop]

const parseProductVariantQuote = (h: UIHardware) => ({
  adjusted: h.adjusted,
  backorderable: h.backorderable,
  backordered: h.backordered,
  id: h.productVariantId,
  inStock: h.inStock,
  name: h.productVariant.name,
  price: h.price,
  quantity: h.qty,
  title: h.productVariant.title,
  shortDescription: h.productVariant.shortDescription,
  talkSubsQty: h.talkSubsQty,
  thumbnail: h.productVariant.thumbnail,
  type: h.productVariant.type,
  url: h.productVariant.url,
})

const parseToTopologyCategory = (product: CustomerProductVariantQuote) => {
  const topologyCategory =
    product.type === ProductType.ROUTING &&
    (product.title.toLowerCase().includes('switch') ||
      product.shortDescription.toLowerCase().includes('switch'))
      ? CustomerCategory.SWITCHING
      : product.type === ProductType.ROUTING
      ? CustomerCategory.ROUTING
      : product.type !== null
      ? typeToTopology[product.type]
      : CustomerCategory.OTHER
  return { ...product, type: topologyCategory }
}

const addMissingCategories = (init: ContractHardware) =>
  Object.values(CustomerCategory).reduce(
    (o, key) => (o[key] ? o : Object.assign(o, { [key]: [] })),
    init,
  )

export const parseHardwareForCustomer = (contract: Quote): ContractHardware => {
  const groupByCategory = pipe<[UIHardware[]], any, any, any, any, ContractHardware>(
    reject(pathEq('removed', ['adjusted', 'type'])),
    map(parseProductVariantQuote),
    map(parseToTopologyCategory),
    ramdaGroupBy(prop('type')),
    addMissingCategories,
  )

  return groupByCategory(contract.productVariantQuotes)
}

export const buildName = ({
  firstName,
  lastName,
  username,
}: Pick<User, 'firstName' | 'lastName' | 'username'>) =>
  firstName && lastName ? [firstName, lastName] : firstName ? [firstName] : [username]

type RejectNilValues = <A extends Record<string | number, unknown>>(x: A) => Partial<A>
export const rejectNilValues: RejectNilValues = pipe<any, any, any, any>(
  toPairs,
  reject(([, value]) => isNil(value)),
  fromPairs,
)

export const addVariantTitle = (item: any, section: 'shortDescription' | 'title') => {
  if (!item.variantTitle || item.variantTitle === 'Default') return item[section]
  return section === 'title'
    ? `${item.title} ${item.variantTitle}`
    : `${item.variantTitle}. ${item.shortDescription}`
}
