import { NewProject } from 'app/marketplace/ProjectSidebar/Form/types'
import type { AsyncFuncState } from 'shared'
import type { WithOnSuccess } from 'store/types'
import type { Base, Location } from 'types'

export interface ProjectsState {
  creating: string | null
  current: {
    id: string | null
    data: Project | null
  }
  hash: Record<string, ProjectName>
  list: ProjectName[]
  status: AsyncFuncState
}

export interface ProjectName {
  id: string
  clientId: string
  name: string
  createdAt: string
}
export interface FilesName {
  name: string
  url: string
}

export interface Files {
  data: FilesName[]
}

export interface Client {
  firstName: string
  lastName: string
  logo?: string
}

export interface FloorPlan {
  plan: string
  thumb?: string
}

export interface Project extends Base {
  clientId: string
  client: Client
  name: string
  location: Location
  buildingType: BuildingType
  buildingSize: ProjectSize
  userQty: Size
  wifiIndoor: BuildingWiFi[]
  wifiOutdoor: BuildingWiFi[]
  doors: BuildingDoors
  cameras: BuildingCameras
  voIP: BuildingVoIP
  contractCount: number
}

export interface BuildingCameras {
  qty: number
  note: string
}

export interface BuildingDoors {
  qty: number
  note: string
}

export enum BuildingType {
  HOME = 'Home',
  OFFICE = 'Office',
  SCHOOL = 'School',
  HOTEL = 'Hotel',
  STADIUM = 'Stadium',
  OTHER = 'Other',
}

export interface BuildingVoIP {
  qty: number
  note: string
}

export interface BuildingWiFi {
  id: string
  name: number | string
  area: number
  roomQty: number
  note: string
  plans: FloorPlan[]
}

export enum Preference {
  PERFOMANCE = 'Performance',
  VALUE = 'Value',
}

export enum ProjectSize {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'Extra Large',
}

export enum Size {
  XSMALL = 'Extra Small',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'Extra Large',
}

export interface CreateProjectOptions extends WithNewProject, WithOnSuccess {
  projectId?: string | null
}

export interface WithNewProject {
  clientId: string
  project: NewProject
}

export interface WithProjectId {
  projectId: string | null
}

export type UpdateProjectOptions = WithProjectId & WithNewProject & WithOnSuccess
