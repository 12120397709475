import { DefaultTheme } from 'styled-components'
import cssVariables from './cssVariables'

export const theme: DefaultTheme = {
  borderRadius: cssVariables.borderRadius,
  borderColor: cssVariables.Light.color.neutral.n4,
  background: cssVariables.Light.color.neutral.n1,
  colors: cssVariables,
  pallette: {
    blue: {
      main: cssVariables.Light.color.blue.b6,
      hover: cssVariables.Light.color.blue.b5,
    },
    green: {
      main: cssVariables.Light.color.green.g6,
    },
  },
  boxShadow: '0 4px 20px 0 rgba(21,23,37,.2)',
}
