import { Text } from '@ubnt/ui-components/Text'
import { map } from 'ramda'
import styled from 'styled-components'

import { FlexRow as UIFlexRow } from 'components'
import { Footer } from './styles'

import type { TooltipItem } from './types'

const renderItem = (item: TooltipItem) => (
  <FlexRow key={`${item.label}-${item.value}`}>
    <Text size='caption' color='primary'>
      {item.label}
    </Text>
    <Text size='caption' color='primary'>
      {item.value}
    </Text>
  </FlexRow>
)

export const renderFooter = (footer?: string | JSX.Element) => footer && <Footer>{footer}</Footer>

export const renderItems = map(renderItem)

const FlexRow = styled(UIFlexRow)`
  margin-bottom: 4px;
`
