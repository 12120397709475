import { Text, Tooltip } from '@ubnt/ui-components'
import styled from 'styled-components'
import { isNotNil } from 'ramda'

import { CardContainer } from './CardContainer'
import { useOverState } from 'store'

interface Props {
  label: string
  value: string | number | undefined
  section?: string
  icon?: JSX.Element
}

export const Card = ({ label, value, section, icon }: Props) => {
  const { projects } = useOverState()
  const { data } = projects.current
  const isBuildingType = label === 'Building Type'
  const noteInWifi = section === 'note-wifi'

  if (!value) return null
  return (
    <CardContainer key={data?.id} isNote={noteInWifi}>
      {noteInWifi ? (
        <Text size='caption' color='tertiary'>
          {label}
        </Text>
      ) : (
        <Text size='body' color='secondary'>
          {label}
        </Text>
      )}

      {isNotNil(value) && (
        <TextContainer>
          {isBuildingType && icon}
          <Tooltip description={value} tooltipClassName='card__tooltip' portal>
            <Text size='body' truncate className={noteInWifi ? 'card__text' : 'card__text--small'}>
              {value}
            </Text>
          </Tooltip>
        </TextContainer>
      )}
    </CardContainer>
  )
}

const TextContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
  .card__icon {
    color: ${({ theme }) => theme.colors.Light.color.neutral.n8};
  }
  .card__text {
    max-width: 302px;
  }
  .card__text--small {
    max-width: 230px;
  }
`
