import { Button, Input as UiInput, Text } from '@ubnt/ui-components'
import { VFC, FormEvent, useEffect, useMemo, useState } from 'react'

import { ProductDisplayStatus, ProductType, StoreProduct } from 'types'
import { TalkSub } from './TalkSub'
import { checkUIHardwareEditable } from 'helpers/quote'
import { isNotEmpty, toLocaleCurrency } from 'helpers'
import styled from 'styled-components'
import { useActions, useOverState } from 'store'
import { useManager } from 'components'

const ADDABLE_STATUSES: (ProductDisplayStatus | null)[] = [
  ProductDisplayStatus.AVAILABLE,
  ProductDisplayStatus.BACKORDER,
]

interface Props {
  product: StoreProduct
}

const useFormData = (product: StoreProduct) => {
  const isManager = useManager()
  const {
    clientManagement: { currentQuote },
  } = useOverState()

  const [quantity, setQuantity] = useState(1)
  const [quantityError, setQuantityError] = useState<string>('')
  const [talkSubsQty, setTalkSubsQty] = useState(0)

  const currentHardware = useMemo(
    () => currentQuote?.productVariantQuotes.find((x) => x.productVariant.id === product.id),
    [currentQuote, product],
  )

  const disableInput =
    !isManager ||
    !currentQuote ||
    !ADDABLE_STATUSES.includes(product.status) ||
    !checkUIHardwareEditable(currentQuote)

  useEffect(() => {
    setQuantity(currentHardware?.adjusted.qty ?? currentHardware?.qty ?? 1)
    setTalkSubsQty(currentHardware?.talkSubsQty ?? 0)
  }, [currentHardware])

  return {
    disabled: {
      input: disableInput,
      submit:
        disableInput ||
        Boolean(quantityError) ||
        (quantity === currentHardware?.qty && talkSubsQty === currentHardware?.talkSubsQty),
    },
    label: currentHardware && currentHardware.qty > 0 ? 'Save' : 'Add to Quote',
    quantity,
    quantityError,
    quoteId: currentQuote?.id,
    setQuantity,
    setQuantityError,
    setTalkSubsQty,
    talkSubsQty,
  }
}

export const ProductForm: VFC<Props> = ({ product }) => {
  const { addProduct } = useActions().clientManagement
  const { getQuantityLimit, resetQuantityLimit } = useActions().hardware
  const {
    clientManagement: {
      currentClient: { email },
    },
    hardware: { quantityLimit },
  } = useOverState()
  const {
    disabled,
    label,
    quantity,
    quantityError,
    quoteId,
    setQuantity,
    setQuantityError,
    setTalkSubsQty,
    talkSubsQty,
  } = useFormData(product)

  useEffect(() => {
    isNotEmpty(email) && getQuantityLimit({ variantId: product.id, email })
    return resetQuantityLimit
  }, [])

  const handleChangeAmount = (_: unknown, value: string | number) => {
    const newValue = Math.abs(+value) || 0
    setQuantity(newValue)

    setQuantityError(() =>
      newValue < 1
        ? 'Must be minimum of 1'
        : quantityLimit !== null && newValue > quantityLimit
        ? `This item has a limit of ${quantityLimit} per customer`
        : '',
    )
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    !disabled.submit &&
      quoteId &&
      addProduct({
        quoteId,
        data: {
          id: product.id,
          qty: quantity,
          talkSubsQty,
        },
      })
  }

  return (
    <RightContainer>
      <Form onSubmit={handleSubmit}>
        <Text weight='bold' size='body'>
          Order Details
        </Text>
        <TextWrapper>
          <Text size='body'>MSRP</Text>
          <Text color='primary' weight='bold' size='body'>
            {toLocaleCurrency(product.price)}
          </Text>
        </TextWrapper>
        <UiInput
          autoComplete='test'
          id='units'
          variant='secondary'
          arrowSize='small'
          type='number'
          label='Number of Units'
          width={212}
          invalid={quantityError}
          value={quantity}
          disabled={disabled.input}
          onChange={handleChangeAmount}
        />
        {product.type === ProductType.VOIP && (
          <TalkSub
            talkSubsQty={talkSubsQty}
            setTalkSubsQty={setTalkSubsQty}
            disabled={disabled.input}
          />
        )}
        <TotalWrapper>
          <Text color='primary' weight='bold' size='body'>
            Total
          </Text>
          <Text color='primary' weight='bold' size='body'>
            {toLocaleCurrency(product.price * quantity)}
          </Text>
        </TotalWrapper>
        <Button disabled={disabled.submit} type='submit' variant='primary'>
          {label}
        </Button>
      </Form>
    </RightContainer>
  )
}

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border: ${({ theme }) => ` 1px solid ${theme.colors.Light.color.neutral.n3}`};
  border-radius: 10px;
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0 10px;
`

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`
