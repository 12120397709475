import React, { FC, useCallback } from 'react'

import { NavItem, NavWrapper } from './styles'

export interface NavigationItem {
  id: string
  label: string
}

export interface NavProps {
  className?: string
  items: NavigationItem[]
  onSelectItem: (item: NavigationItem) => void
  selectedItem: string | null | undefined
}

export const Nav: FC<NavProps> = ({ className, items, onSelectItem, selectedItem }) => {
  const handleClick = useCallback(
    (item: NavigationItem) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.currentTarget.blur()
      onSelectItem(item)
    },
    [onSelectItem],
  )

  const renderItems = useCallback(
    () =>
      items.map((x) => (
        <NavItem key={x.id} onClick={handleClick(x)} selected={selectedItem === x.id}>
          {x.label}
        </NavItem>
      )),
    [items, selectedItem],
  )

  return <NavWrapper className={className}>{renderItems()}</NavWrapper>
}
