import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '@ubnt/ui-components'
import { isNotEmpty } from 'helpers'
import { memoizeWith, omit } from 'ramda'
import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { emailRegex } from 'shared'
import { useActions, useEffects, useOverState } from 'store'
import { parseClient } from './helpers'
import { editClientSchema } from './schemas'
import { ClientForm, ContactsForm } from './sharedComponents'
import type { ExistingClient, FormFields } from './types'

interface Props {
  client?: ExistingClient
}

// selectedClient comes from the edit quote modal in /marketplace.
// existingClient comes from the edit client modal in /clients and /company.
export const EditClient: FC<Props> = ({ client: selectedClient }) => {
  const {
    clientManagement: { quotes },
    modal: {
      modal,
      modalProps: { modalName, client: existingClient },
    },
  } = useOverState()
  const {
    app: { toast },
    clientManagement: { resetCurrentClient, resetCurrentQuote },
    clients: { updateClient },
    modal: { closeModal, setModalName },
  } = useActions()
  const { app, clients } = useEffects()

  const { pathname } = useLocation()

  const [currentStep, setCurrentStep] = useState(0)
  const [currentClientIndex, setCurrentClientIndex] = useState<number | null>(0)
  const [emailError, setEmailError] = useState('')
  const [isEditMode, setIsEditMode] = useState(false)
  const [persistedEmail, setPersistedEmail] = useState('') // The client email needs to be persisited for the final validation that is done before submitting the form.

  const validateEmail = memoizeWith(toString, (email?: string) => {
    if (!email || !emailRegex.test(email.toLowerCase())) return false
    if (email === persistedEmail) return true
    return app.api
      .validateEmail({ email, client: true, pro: true, user: true })
      .then(({ isInvalid, message }) => {
        setEmailError(message)
        return !isInvalid
      })
  })

  const methods = useForm<FormFields>({
    resolver: yupResolver(editClientSchema(validateEmail)),
    mode: 'onChange',
    defaultValues: {
      clientId: '',
      clients: [],
      tax: {
        exempt: false,
        file: [],
      },
    },
  })

  const clientId = useWatch({ control: methods.control, name: 'clientId' })

  useEffect(() => {
    return pathname.includes('marketplace') ? () => null : resetCurrentClient
  }, [])

  useEffect(() => {
    if (existingClient) {
      updateDefaultValues(existingClient)
      setPersistedEmail(existingClient.email)
    }
  }, [existingClient])

  useEffect(() => {
    if (selectedClient) {
      updateDefaultValues(selectedClient)
      setPersistedEmail(selectedClient.email)
    }
  }, [selectedClient])

  const updateDefaultValues = (existingClient: ExistingClient) => {
    clients.api
      .getContacts(existingClient.id)
      .then((contacts) => {
        const _contacts = contacts
          .filter((x) => x.email !== existingClient.email)
          .map((x) => omit(['avatar'], x))

        methods.reset({
          clientId: existingClient.id,
          clients: [existingToNew(existingClient), ..._contacts],
          tax: {
            exempt: existingClient.taxExempt,
            file: existingClient.taxCertificates,
          },
        })
      })
      .catch(() =>
        toast({
          title: 'Error fetching contacts',
          message: `Couldn't fetch client contacts.`,
          type: 'danger',
        }),
      )
  }

  const handleClose = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    event.preventDefault()
    closeModal()

    // Reset current quote if modal is accessed from /marketplace -> quote list view -> Edit.
    if (quotes.length) resetCurrentQuote()
  }

  const handleEditClient = () => {
    updateClient({
      clientId: methods.getValues().clientId,
      client: parseClient(methods.getValues()),
      all: false,
      uploadedTaxFile: isNotEmpty(methods.getValues().tax.file),
    })
  }

  const renderForms = () => (
    <FormProvider {...methods}>
      {currentStep === 0 && currentClientIndex !== null && clientId && (
        <ClientForm
          clientIndex={currentClientIndex}
          emailError={emailError}
          close={handleClose}
          onSubmit={() => {
            setCurrentStep((prev) => prev + 1)
            setModalName({ name: 'Contacts' })
            setCurrentClientIndex(null)
            setIsEditMode(false)
          }}
          isEditMode={isEditMode}
        />
      )}

      {currentStep === 1 && (
        <ContactsForm
          add={(index: number) => {
            setCurrentStep(0)
            setModalName({ name: 'Add Additional Contact' })
            setCurrentClientIndex(index)
          }}
          edit={(index: number) => {
            setCurrentStep(0)
            setModalName({ name: 'Edit Client' })
            setCurrentClientIndex(index)
            setIsEditMode(true)
          }}
          back={(event: React.MouseEvent<Element, MouseEvent>) => {
            event.preventDefault()
            setCurrentStep((prev) => prev - 1)
            setModalName({ name: 'Edit Client' })
            setCurrentClientIndex(0)
          }}
          onSubmit={handleEditClient}
        />
      )}
    </FormProvider>
  )

  return modal === 'edit_client' ? (
    <Modal
      title={modalName ?? 'Edit Client'}
      isOpen={modal === 'edit_client'}
      onRequestClose={handleClose}
      size='small'
      height='medium'
      shouldCloseOnOverlayClick={false}
    >
      {renderForms()}
    </Modal>
  ) : (
    renderForms()
  )
}

const existingToNew = (existing: ExistingClient) => ({
  email: existing.email,
  firstName: existing.firstName,
  lastName: existing.lastName,
  name: existing.name,
  phone: existing.phone === '-' ? '' : existing.phone,
})
