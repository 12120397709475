import { useEffect, useLayoutEffect, useRef, useState } from 'react'

export interface ElementSize {
  height: number
  width: number
}

export const useElementSize = () => {
  const [size, setSize] = useState<ElementSize>({ height: 0, width: 0 })
  const ref = useRef<HTMLElement>(null)

  const updateSize = () => {
    if (ref.current) {
      setSize({
        height: ref.current.offsetHeight,
        width: ref.current.offsetWidth,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize, false)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useLayoutEffect(() => {
    updateSize()
  }, [])

  return { ref, size }
}
