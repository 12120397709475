import { useState, useEffect } from 'react'

import { emailRegex } from 'shared/regex'
import { useEffects } from 'store'

const INITIAL_STATE = { isInvalid: false, message: '' }

interface EmailValidation {
  isInvalid: boolean
  message: string
}

export const useDebounceValidation = () => {
  const { app } = useEffects()

  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const [resolver, setResolver] = useState<(value: EmailValidation) => void>()

  const validator = (email?: string) => {
    clearTimeout(timer)
    resolver?.(INITIAL_STATE)

    return new Promise<EmailValidation>((resolve) => {
      if (!email || !emailRegex.test(email.toLowerCase())) {
        return resolve(INITIAL_STATE)
      }

      setResolver(() => resolve)
      setTimer(
        setTimeout(
          () =>
            app.api
              .validateEmail({ email, client: true, pro: true, user: true }) //
              .then(resolve),
          400,
        ),
      )
    })
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer)
      resolver?.(INITIAL_STATE)
    }
  }, [])

  return validator
}
