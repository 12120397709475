import { Text } from '@ubnt/ui-components/Text'
import { makeAwsUrl } from 'helpers'
import { FC } from 'react'
import { truncateFileName } from './helper'
import { CloseIcon, Img, ImgContainer, Item } from './styles'

interface Props {
  url: string
  thumb?: string
  onClose?: () => void
  alt?: string
  makeUrl: (x: string) => string
}

export const Thumbnail: FC<Props> = ({ url, thumb, onClose, alt = 'file thumbnail', makeUrl }) => {
  const link = makeUrl(url)
  const img = makeAwsUrl(thumb ?? url)

  return (
    <Item>
      <ImgContainer>
        {onClose && <CloseIcon onClick={onClose} />}
        <a href={link}>
          <Img src={img} alt={alt} />
        </a>
      </ImgContainer>
      <a href={link}>
        <Text size='label'>{truncateFileName(url)}</Text>
      </a>
    </Item>
  )
}
