import styled from 'styled-components'
import { Text } from '@ubnt/ui-components/Text'

export const Container = styled.article`
  display: grid;
  grid-template-columns: repeat(2, fit-content(100px));
  grid-template-rows: repeat(2, auto);
  align-items: center;
  margin-bottom: 24px;
  min-width: 140px;
`

export const Label = styled(Text)`
  grid-area: 1 / 1 / 1 / -1;
`

export const Number = styled(Text)`
  margin-right: 4px;
`
