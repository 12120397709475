import { useEffect } from 'react'
import { useActions } from 'store'
import { Content } from './Content'
import { Container } from './styles'

import type { FC } from 'react'

export const BackorderBubble: FC = () => {
  const actions = useActions()

  useEffect(() => {
    actions.backorders.count()
  }, [])

  return (
    <Container>
      <Content />
    </Container>
  )
}
