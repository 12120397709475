import { pipe, values, map as Rmap, splitEvery, omit, evolve, when, includes, always } from 'ramda'
import { indexById } from 'helpers'
import type { Context } from 'store'
import type { ProService } from 'types/services'
import type { EditedServices } from './types'
import type { WithId } from 'store/types'

export const getProServices = ({ effects, state }: Context) =>
  effects.services.api
    .getProServices() //
    .then((services) => {
      state.services.services.hash = indexById(services)
    })

export interface DeleteProService {
  id: string
}

export const deleteProService = ({ effects, state }: Context, { id }: WithId) =>
  effects.services.api
    .deleteProService(id) //
    .then(() => {
      state.services.services.hash = omit([id], state.services.services.hash)
    })

export const parseServices = <A extends EditedServices>(
  _: Context,
  value: A,
): Omit<A, 'services'> & { services: ProService[] } =>
  evolve(
    {
      services: pipe<any, any, any>(
        values,
        Rmap(
          evolve({
            id: when(includes('temp'), always(undefined)),
            price: Number,
          }) as any,
        ),
      ),
    },
    value,
  )

export const bulkUpdateProServices = <A extends { services: ProService[] }>(
  { effects }: Context,
  value: A,
) => {
  const updateChunks = splitEvery(50, value.services)

  return Promise.all(updateChunks.map(effects.services.api.bulkUpdateProServices)) //
    .then((data) => ({
      ...value,
      services: data.flat(),
    }))
}
