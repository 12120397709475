import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '@ubnt/ui-components/Modal'
import { FlexRow, GoogleAddressAutoComplete } from 'components'
import { ControlledInput } from 'components/ControlledInput'
import React, { FC, KeyboardEventHandler, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { emptyLocation, locationSchema } from 'shared'
import { useActions, useOverState } from 'store'
import styled from 'styled-components'
import type { Location } from 'types'

const buildFormattedAddress = (l: Location) =>
  `${l.streetNumber} ${l.street}, ${l.other ? `${l.other}, ` : ''}${l.city}, ${l.zipCode}, ${
    l.state
  }, ${l.country}`

type FormFields = Location

const AddressForm: FC = () => {
  const {
    modal: { closeModal },
  } = useActions()
  const { modal, modalProps } = useOverState().modal

  const [googleInput, setGoogleInput] = useState('')

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<FormFields>({
    resolver: yupResolver(locationSchema),
    mode: 'onChange',
    defaultValues: emptyLocation,
  })

  useEffect(() => {
    if (modalProps.location) {
      reset({
        ...modalProps.location,
      })
      trigger()
      setGoogleInput(modalProps.location.formatted?.split(',')[0])
    }
  }, [modalProps.location])

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    closeModal()
  }

  const onSubmit = (data: FormFields) => {
    modalProps.updateLocation({
      ...data,
      formatted: buildFormattedAddress(data),
    })
    closeModal()
  }

  const handleEnterKey: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') handleSubmit(onSubmit)
  }

  const handleLocationChange = (location: Location) => {
    setValue('street', location.street || '', { shouldValidate: true })
    setValue('streetNumber', location.streetNumber || '', { shouldValidate: true })
    setValue('state', location.state, { shouldValidate: true })
    setValue('city', location.city || '', { shouldValidate: true })
    setValue('country', location.country, { shouldValidate: true })
    setValue('formatted', location.formatted, { shouldValidate: true })
    setValue('zipCode', location.zipCode || '', { shouldValidate: true })
    setValue('other', location.other || '', { shouldValidate: true })
    setGoogleInput(location.formatted.split(',')[0])
  }

  return (
    <Modal
      title='Address'
      isOpen={modal === 'address'}
      onRequestClose={closeModal}
      size='small'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Cancel',
          onClick: handleCancel,
          variant: 'tertiary',
        },
        {
          text: 'Done',
          variant: 'primary',
          onClick: handleSubmit(onSubmit),
        },
      ]}
    >
      <Form>
        <GoogleAddressAutoComplete
          name='location'
          label='Address'
          handleChange={handleLocationChange}
          value={googleInput}
          setValue={setGoogleInput}
          onKeyDown={handleEnterKey}
          invalid={errors.streetNumber?.message || errors.street?.message}
          width={null}
          autoFocus
        />
        <ControlledInput
          control={control}
          name='other'
          label='PO Box, Apartment, Unit, Suite, or Floor'
          autoComplete='off'
          invalid={errors.other?.message}
          onKeyDown={handleEnterKey}
          width='100%'
        />
        <FlexRow>
          <ControlledInput
            control={control}
            name='zipCode'
            label='Postal Code'
            autoComplete='off'
            invalid={errors.zipCode?.message}
            onKeyDown={handleEnterKey}
            style={{ marginRight: '8px' }}
          />
          <ControlledInput
            control={control}
            name='city'
            label='City'
            autoComplete='off'
            invalid={errors.city?.message}
            onKeyDown={handleEnterKey}
          />
        </FlexRow>
        <FlexRow>
          <ControlledInput
            control={control}
            name='state'
            label='State'
            autoComplete='off'
            invalid={errors.state?.message}
            onKeyDown={handleEnterKey}
            style={{ marginRight: '8px' }}
          />
          <ControlledInput
            control={control}
            name='country'
            label='Country'
            autoComplete='off'
            invalid={errors.country?.message}
            onKeyDown={handleEnterKey}
          />
        </FlexRow>
      </Form>
    </Modal>
  )
}

export default AddressForm

export const Form = styled.form`
  font-family: Lato;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div,
  section {
    margin-bottom: 20px;
  }
`
