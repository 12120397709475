import { AsyncFuncState } from 'shared'
import type { ClientManagementState } from './types'

export const state: ClientManagementState = {
  actionStatus: AsyncFuncState.NULL,
  currentClient: {
    id: '',
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    taxExempt: false,
    taxCertificates: [],
  },
  currentQuote: null,
  deleting: [],
  emailPreview: null,
  quotes: [],
  status: AsyncFuncState.NULL,
}
