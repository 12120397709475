import { FlexRow } from 'components'
import styled from 'styled-components'
import { Text } from '@ubnt/ui-components'
import { useOverState } from 'store'
import { Card } from './Card'
import config from 'config'
import { FileTitle } from '../Form/styles'
import { extractFileName } from '../Form/helpers'

interface Props {
  label: string
}

export const WifiBubble = ({ label }: Props) => {
  const { projects } = useOverState()
  const { data } = projects.current
  const isIndoor = label === 'Indoor'
  const text = isIndoor ? 'Floor' : 'Area'
  const area = isIndoor ? data?.wifiIndoor : data?.wifiOutdoor

  return (
    <>
      <FlexRow>
        <Text size='body'>{`${label} ${text}s`}</Text>
        <Text size='body'>{area?.length}</Text>
      </FlexRow>
      {area?.map((floor) => {
        return (
          <BubbleContainer key={floor.id}>
            <FlexRow className={floor.note ? 'add--margin' : ''}>
              <FlexContainer>
                <Text size='body'>{isIndoor ? `${text} ${floor.name}` : floor.name},</Text>
                <Text size='body'>
                  {floor.area} sq.ft{isIndoor ? ',' : ''}
                </Text>
                {isIndoor && (
                  <Text size='body'>
                    {floor.roomQty > 1 ? `${floor.roomQty} Rooms` : `${floor.roomQty} Room`}
                  </Text>
                )}
              </FlexContainer>
              {floor.plans.map(({ plan }) =>
                plan.toLowerCase().endsWith('pdf') ? (
                  <PlanWrapper
                    key={plan}
                    href={`${config.API_URL}/api/projects/${plan}`}
                    className={floor.note ? 'image--relative' : ''}
                  >
                    <FileTitle size='label'>{extractFileName(plan)}</FileTitle>
                  </PlanWrapper>
                ) : (
                  <PlanWrapper
                    key={plan}
                    href={`${config.API_URL}/api/projects/${plan}`}
                    className={floor.note ? 'image--relative' : ''}
                  >
                    <PlanImage src={`${config.API_URL}/api/projects/${plan}`} alt={text} />
                  </PlanWrapper>
                ),
              )}
            </FlexRow>
            {floor.note && <Card label='Note' value={floor.note} section='note-wifi' />}
          </BubbleContainer>
        )
      })}
    </>
  )
}

const BubbleContainer = styled.section`
  background: ${({ theme }) => theme.colors.Light.color.neutral.n1};
  border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n3};
  border-radius: 4px;
  max-height: 100px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;

  .image--relative {
    position: relative;
    top: 12px;
  }

  .add--margin {
    margin-bottom: 6px;
    height: 22px;
  }
`
const FlexContainer = styled.article`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
`
const PlanImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
  font-size: 10px;
  cursor: pointer;
`
const PlanWrapper = styled.a`
  height: 36px;
  width: 36px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n8};
  background: ${({ theme }) => theme.colors.Light.color.neutral.n3};
  font-size: 10px;
  cursor: pointer;
  text-decoration: none;
`
