import { prop } from 'ramda'
import styled, { keyframes } from 'styled-components'
import type { InnerProps, OuterProps, SliderPosition } from './types'

const slideInFrames = (side: SliderPosition) => keyframes`
  100% { ${side}: 0px; }
`

const slideOutFrames = (side: SliderPosition, width: number) => keyframes`
  100% { ${side}: -${width}px; }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(33, 33, 36, 0.4);
  z-index: 1000;
`

export const Outer = styled.div<OuterProps>`
  position: absolute;
  ${prop('position')}: 0px;
  top: var(--app-header-height);
  height: calc(100% - var(--app-header-height));
  width: ${({ width }) => width}px;
  overflow-x: hidden;
`

export const SlideIn = styled.div.attrs<InnerProps>((props) => ({
  duration: props.duration / 1_000,
}))<InnerProps>`
  position: relative;
  ${prop('position')}: -${({ width }) => width}px;
  width: ${({ width }) => width}px;
  height: 100%;

  -webkit-animation: ${({ position }) => slideInFrames(position)} ${({ duration }) => duration}s
    forwards;
  animation: ${({ position }) => slideInFrames(position)} ${({ duration }) => duration}s forwards;
`

export const SlideOut = styled.div.attrs<InnerProps>((props) => ({
  duration: props.duration / 1_000,
}))<InnerProps>`
  position: relative;
  ${prop('position')}: 0px;
  width: ${({ width }) => width}px;
  height: 100%;

  -webkit-animation: ${({ position, width }) => slideOutFrames(position, width)}
    ${({ duration }) => duration}s forwards;
  animation: ${({ position, width }) => slideOutFrames(position, width)}
    ${({ duration }) => duration}s forwards;
`
