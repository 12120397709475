import { http } from 'helpers'
import type { Company, UserInvitation } from 'app/onboarding/types'
import type { Professional, User } from 'types'
import type { UploadResult } from './types'

interface ExtendedCompany extends Company {
  adminId: string
  email: string
}

export const api = {
  createProfessional: (company: ExtendedCompany): Promise<Professional> =>
    http.post('professionals', company),
  createUser: (user: Partial<User>): Promise<User> => http.post('users', user),
  inviteUsers: (userInvitations: Omit<UserInvitation, 'key'>[], inviteeType: string): any =>
    http.post(`users/invite/pro/${inviteeType}`, { userInvitations }),
  uploadLogo: (body: FormData): UploadResult => http.post('professionals/upload-logo', body),
}
