import React, { FC } from 'react'
import styled from 'styled-components'
import { Input as UiInput, InputProps } from '@ubnt/ui-components'

interface Props extends InputProps {
  inputCurrency?: '€' | '$' | '£'
}

export const CurrencyInput: FC<Props> = ({ inputCurrency = '$', ...props }) => {
  return (
    <Wrapper>
      <StyledLeft>{inputCurrency}</StyledLeft>
      <UiInput {...props} className='hide-icons' inputClassName='input' min='0' />
      {/* <StyledP>/ Month</StyledP> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  position: relative;

  .hide-icons div:last-child {
    display: none;
  }

  .input {
    text-indent: 0.85rem;
  }
`

// const StyledP = styled.p`
//   position: absolute;
//   right: 10px;
//   bottom: 7px;
//   font-size: 14px;
// `

const StyledLeft = styled.p`
  position: absolute;
  left: 2px;
  bottom: 7px;
  font-size: 13px;
  font-weight: bold;
`
