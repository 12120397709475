import { SidePanel } from '@ubnt/ui-components/SidePanel'
import { FlexRow, NumberTag } from 'components'
import { renderClient, renderPayoutBadge, renderSpinner } from 'helpers'
import { useSlider } from 'hooks'
import { FC } from 'react'
import { useActions, useOverState } from 'store'
import type { ModalType, TalkCommissionSidebar } from 'store/modal/types'
import { Row } from '../components'
import { makeClientAvatar } from '../PaymentDetails/helpers'
import { Container } from '../PaymentDetails/styles'
import { CommissionAmount } from './CommissionAmount'

export const TalkCommissionDetails: FC = () => {
  const { sidebarProps } = useOverState().modal as ModalType<any, TalkCommissionSidebar>
  const { status } = useOverState().payments
  const actions = useActions()

  const { invoice } = sidebarProps

  const handleClose = () => {
    sidebarProps.onClose()
    actions.modal.closeSidebar()
  }
  const { Slider, closeSlider } = useSlider(handleClose)

  const myIncome = {
    badge: renderPayoutBadge(invoice.paidOut),
    label: 'My Income',
    number: invoice.commission,
    renderPlaceholder: () => renderSpinner(status),
  }

  return (
    <Slider>
      <SidePanel header='Payment Details' in onClose={closeSlider}>
        <Container>
          <FlexRow alignItems='flex-start' justify='space-between' flexWrap='wrap'>
            <NumberTag
              badge={myIncome.badge}
              label={myIncome.label}
              number={myIncome.number}
              numberSize='header-s'
              renderPlaceholder={myIncome.renderPlaceholder}
            />
          </FlexRow>
          <Row
            label='Commission'
            status={status}
            value={
              <CommissionAmount
                amount={invoice.commission}
                paidOut={invoice.paidOut}
                professionalId={invoice.professionalId}
                invoiceId={invoice.id}
              />
            }
          />

          <Row
            label='Customer'
            placeholder={renderClient(
              [invoice.clientFirstName, invoice.clientLastName],
              invoice.clientLogo,
            )}
            value={makeClientAvatar({
              name: invoice.clientName,
              firstName: invoice.clientFirstName,
              lastName: invoice.clientLastName,
              logo: invoice.clientLogo,
            })}
            status={status}
          />
        </Container>
      </SidePanel>
    </Slider>
  )
}
