import { http } from 'helpers'
import { WithQuoteId } from 'store/clientmanagement/types'

import type { PaymentInvoice } from 'store/payments/types'
import type { CommissionUpdate, Fulfillments, PaymentDetails } from './types'

const getFulfillments = (quoteId: string) =>
  http.get<Fulfillments>(`quotes/${quoteId}/fulfillments`)

const getPaymentDetails = ({ quoteId }: WithQuoteId) => {
  return http.get<PaymentDetails>(`quotes/${quoteId}/payment`)
}

const rejectInvoice = (invoiceId: string) =>
  http.put<Pick<PaymentInvoice, 'id' | 'paidOut'>>(`invoices/${invoiceId}/reject`)

const updateCommissionRate = (commissionRate: number, invoiceId: string, quoteId: string) =>
  http.put<CommissionUpdate>(`quotes/${quoteId}/commission-rate`, { invoiceId, commissionRate })

const updateCommissionAmount = (commissionAmount: number, invoiceId: string) =>
  http.put<PaymentInvoice>(`invoices/${invoiceId}/commission`, {
    invoiceId,
    commissionAmount,
  })

export const api = {
  getFulfillments,
  getPaymentDetails,
  rejectInvoice,
  updateCommissionRate,
  updateCommissionAmount,
}
