import { Text, UploadButton as UIUploadButton } from '@ubnt/ui-components'
import type { ChangeEvent } from 'react'
import styled from 'styled-components'
import { UploadText } from './styles'

interface Props {
  name: string
  onLoad: (file: File, dataUrl?: string) => void
}

export const UploaderButton = ({ onLoad, name }: Props) => {
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    return Array.from(target?.files ?? []).forEach((file) =>
      file.type.startsWith('image') || file.type.endsWith('pdf')
        ? readFile(onLoad, file)
        : onLoad(file),
    )
  }

  const label = name === 'wifiIndoor' ? 'Upload Floor Plan' : 'Upload Area Plan'

  return (
    <UploadButton accept='image/*,.pdf' onChange={handleChange}>
      <UploadText size='body'>
        {label}
        <Text color='tertiary'>(Optional)</Text>
      </UploadText>
    </UploadButton>
  )
}

const readFile = (onLoad: (file: File, dataUrl?: string) => void, file: File) => {
  const reader = new FileReader()
  const handleLoad = ({ target }: ProgressEvent<FileReader>) => {
    if (!target) return
    onLoad(file, target.result as string)
    reader.removeEventListener('load', handleLoad)
  }
  reader.addEventListener('load', handleLoad)
  reader.readAsDataURL(file)
}

const UploadButton = styled(UIUploadButton)`
  margin-top: 16px;

  > div > span {
    padding: 0;
  }
`
