import { indexById } from 'helpers'
import { filter } from 'overmind'
import { assocPath, map, omit, propEq, when } from 'ramda'
import type { Context } from 'store'
import { WithProfessionalId } from 'store/types'
import { Professional, User } from 'types'

export const professionalListIsEmpy = () =>
  filter(({ state }) => state.professionals.professionals.length === 0)

export const getAllProfessionals = ({ state, effects }: Context) =>
  effects.professionals.api
    .getAllProfessionals() //
    .then((data) => {
      state.professionals.professionalsHash = indexById(data)
    })

export const getProfessional = ({ state, effects, actions }: Context) =>
  effects.professionals.api
    .getProfessional(state.app.user?.professionalId) //
    .then((data) => {
      if (data.clients) state.clients.clients.hash = indexById(data.clients)
      actions.professionals.setProfessional(omit(['clients', 'users'], data))
    })

export const refreshBackorderQuota = ({ effects, state }: Context, professionalId: string) =>
  effects.professionals.api
    .refreshBackorderQuota(professionalId) //
    .then(({ boRefreshedAt, count }) => {
      if (state.admin?.backorders[professionalId]) {
        state.admin.backorders[professionalId] = count
      }
      state.users.list = map(
        when(
          propEq(professionalId, 'professionalId'),
          assocPath(['professional', 'boRefreshedAt'], boRefreshedAt),
        ),
        state.users.list,
      ) as User[]
    })

export interface WithProfessional {
  professional: Pick<Professional, 'avatar' | 'name' | 'location' | 'email' | 'phone'>
}

export const updateProfessional = <A extends WithProfessional & WithProfessionalId>(
  { effects }: Context,
  { professionalId, professional }: A,
) => effects.professionals.api.updateProfessional(professionalId, professional)
