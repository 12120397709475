import { FormFooter as UIFormFooter } from '@ubnt/ui-components/FormFooter'
import { Input as UIInput } from '@ubnt/ui-components/Input'
import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const ProjectBody = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 20px;

  > label {
    margin-bottom: 10px;
  }
`

export const Wrapper = styled.div`
  margin: 16px 0;
`

export const Input = styled(UIInput)`
  margin-bottom: 15px;
`

export const FormFooter = styled(UIFormFooter)`
  margin-top: auto;
`
