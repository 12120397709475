import { TALK_SUBS_COMMISSION } from 'shared'

const texts = {
  backorder: {
    bubble: 'Backorder items may take longer to fulfill depending on when stock is replenished.',
  },
  tooltip: {
    subscription:
      'This subscription fee will not be included in the quote total. Your client will only be charged for this service after they activate their subscription.',
    commission: `You will receive a ${TALK_SUBS_COMMISSION} monthly commission for each activated UniFi Talk subscription you sell with a Talk phone. The sum of these commission(s) will be your monthly payout.`,
  },
}

export default texts
