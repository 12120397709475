import styled from 'styled-components'
import { Text } from '@ubnt/ui-components/Text'

import { FlexRow } from 'components'
import { dateOrEmpty } from 'helpers'
import { Line } from '../PaymentDetails/styles'

import type { FC } from 'react'
import type { EventRowProps } from '../PaymentDetails/types'

export const EventRow: FC<EventRowProps> = ({ actor, date, event, icon, line = false }) => (
  <>
    <FlexRow>
      {icon}
      {actor ? (
        <FlexRow grow={1} justify='flex-start'>
          <Text size='caption' weight='bold'>
            {actor}
          </Text>
          &nbsp;
          <Text size='caption'>{event}</Text>
        </FlexRow>
      ) : (
        <Text className='flex-grow-1' size='caption'>
          {event}
        </Text>
      )}
      <Date size='caption'>{dateOrEmpty(date, 'MMM d, HH:mm')}</Date>
    </FlexRow>
    {line && <Line />}
  </>
)

const Date = styled(Text)`
  width: 110px;
  text-align: right;
  white-space: nowrap;
`
