import { AsyncFuncState } from 'shared'

export interface Onboarding {
  view: OnboardingView
  submitted: boolean
  status: AsyncFuncState
}

export enum OnboardingView {
  CREATE_ACCOUNT = 'Create Account',
  COMPANY_PROFILE = 'Company Profile',
  OWNER_PROFILE = 'Owner Profile',
  INVITE_TEAM = 'Invite Team',
}

export type UploadResult = Promise<{ key: string }>
