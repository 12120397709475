import { isNotNil } from 'ramda'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@ubnt/ui-components'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import { FlexRow } from 'components'
import { ControlledInputWithUnit } from 'components/ControlledInputWithUnit'
import { useSysAdmin } from 'components/Roles'
import { useActions } from 'store'
import { PayoutStatus } from 'store/payments/types'

interface Props {
  amount?: number
  paidOut?: PayoutStatus
  professionalId: string
  invoiceId: string
}

type FormFields = {
  commissionAmount: string
}

const errorMessage = 'Invalid value'

const schema = yup.object().shape({
  commissionAmount: yup.number().typeError(errorMessage).min(0, errorMessage).required(),
})

export const CommissionAmount: FC<Props> = ({ amount, paidOut, professionalId, invoiceId }) => {
  const isSysAdmin = useSysAdmin()
  const actions = useActions()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (amount) {
      reset({
        commissionAmount: amount.toString(),
      })
    }
  }, [amount])

  const onSubmit = ({ commissionAmount }: FormFields) => {
    if (commissionAmount && invoiceId) {
      actions.paymentDetails.updateCommissionAmount({
        commissionAmount: Number(commissionAmount),
        invoiceId,
        professionalId,
      })
    }
  }

  const form = () => (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ControlledInputWithUnit
        control={control}
        name='commissionAmount'
        iconBefore={<>$</>}
        width='70px'
        invalid={errors.commissionAmount?.message}
      />

      <FlexRow style={{ marginLeft: 6 }} justify='space-between'>
        <Button
          variant='primary'
          disabled={isNotNil(errors.commissionAmount?.message)}
          onClick={handleSubmit(onSubmit)}
          size='small'
          style={{ fontSize: 11, marginRight: 6 }}
        >
          Save
        </Button>
        <Button
          variant='primary'
          color='danger'
          onClick={() =>
            invoiceId && actions.paymentDetails.rejectInvoice({ invoiceId, professionalId })
          }
          size='small'
          style={{ fontSize: 11 }}
        >
          Reject
        </Button>
      </FlexRow>
    </Form>
  )

  return <>{isSysAdmin && paidOut === PayoutStatus.PROCESSING ? form() : `${amount}`}</>
}

const Form = styled.form`
  display: flex;
`
