import { FlexColumn } from 'components'
import type { FC } from 'react'
import { EventRow } from './EventRow'
import { EventData } from '../PaymentDetails/types'

interface Props {
  events: EventData[]
}

export const Events: FC<Props> = ({ events }) => {
  const lastEventIdx = events.length - 1

  return (
    <FlexColumn verticalSpacing={8}>
      {events.map((x, i) => (
        <EventRow
          key={`${x.event}-${x.date}`}
          actor={x.actor}
          date={x.date}
          event={x.event}
          icon={<x.Icon size='original' />}
          line={i < lastEventIdx}
        />
      ))}
    </FlexColumn>
  )
}
