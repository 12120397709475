import { http, parseInvoiceType } from 'helpers'

import type { PayoutManyOptions, PayoutOneOptions } from './types'

export const api = {
  check: (invoices: string[]) => http.post<number>('invoices/payout/check', { invoices }),
  execute: ({ invoices, type, ...rest }: PayoutManyOptions | PayoutOneOptions) => {
    const professionalPath = ('professionalId' in rest && rest.professionalId) || ''
    return http.post<number>(`invoices/payout/${professionalPath}`, {
      invoices,
      type: parseInvoiceType(type),
    })
  },
}
