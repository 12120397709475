import React, { FC, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { memoizeWith, omit } from 'ramda'
import { useForm, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { Modal } from '@ubnt/ui-components/Modal'
import { FlexRow } from 'components'
import { ControlledDropdown } from 'components/ControlledDropdown'
import { ControlledInput } from 'components/ControlledInput'
import { isEmptyObj } from 'helpers'
import { AsyncFuncState, emailRegex, UserRole, USER_ROLE_OPTIONS } from 'shared'
import { useActions, useEffects, useOverState } from 'store'

type FormFields = {
  email: string
  role: Exclude<UserRole, UserRole.SYS_ADMIN>
}

const AddUser: FC = () => {
  const { app } = useEffects()
  const { modal, modalProps } = useOverState().modal
  const { closeModal } = useActions().modal
  const { inviteUsers } = useActions().onboarding
  const { status: invitationStatus } = useOverState().onboarding

  const [emailErrMessage, setErrMessage] = useState('')

  const inviteeType: string = modalProps.inviteeType

  const validateEmail = memoizeWith(toString, (email?: string) => {
    if (!email || !emailRegex.test(email.toLowerCase())) return false
    return app.api
      .validateEmail({ email, client: true, pro: true, user: true })
      .then(({ isInvalid, message }) => {
        setErrMessage(message)
        return !isInvalid
      })
  })

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email('Must be valid email address')
          .required()
          .test('valid-email', '', validateEmail),
        role: yup.mixed().oneOf(Object.values(omit([UserRole.SYS_ADMIN], UserRole))),
      }),
    [validateEmail],
  )

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      role: UserRole.MANAGER,
    },
  })

  const [email, role] = useWatch({ control, name: ['email', 'role'] })

  useEffect(() => {
    if (modalProps.role) {
      reset({
        role: modalProps.role,
      })
    }
  }, [modalProps.role])

  const parseInvitation = ({
    email,
    role,
  }: FormFields): {
    email: string
    role: UserRole.AGENT | UserRole.MANAGER | UserRole.ADMIN
    professionalId: string
  }[] => [{ email, role, professionalId: modalProps.professionalId }]

  const onSubmit = (data: FormFields) =>
    inviteUsers({ userInvitations: parseInvitation(data), inviteeType })

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit(onSubmit)()
  }

  const isButtonDisabled = (errObj: any) => !isEmptyObj(errObj) || !email

  return (
    <Modal
      title={modalProps.name}
      isOpen={modal === 'add_user'}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      size='small'
      height='small'
      actions={[
        {
          text: 'Cancel',
          onClick: closeModal,
          variant: 'tertiary',
        },
        {
          text: 'Send Invitation',
          variant: 'primary',
          onClick: handleSubmit(onSubmit),
          disabled: isButtonDisabled(errors) || invitationStatus === AsyncFuncState.LOADING,
        },
      ]}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FlexRow gap={8}>
          <ControlledInput
            control={control}
            name='email'
            label='Email'
            autoComplete='chrome-off'
            width='210px'
            onKeyDown={handleEnterKey}
            invalid={emailErrMessage || errors.email?.message}
          />
          <ControlledDropdown
            label='Role'
            name='role'
            control={control}
            placeholder={role}
            width='150px'
            maxHeight={200}
            options={USER_ROLE_OPTIONS.filter(({ label }) =>
              [UserRole.MANAGER, UserRole.ADMIN].includes(label),
            )}
            disabled={modalProps.disabled || false}
          />
        </FlexRow>
      </Form>
    </Modal>
  )
}

export default AddUser

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`
