import styled, { css } from 'styled-components'
import { Button, Tab, Tabs, Text } from '@ubnt/ui-components'
import { TrashIconL as UITrashIcon } from '@ubnt/icons'

import { ReactComponent as HardwareIcon } from 'images/icons/Hardware.svg'
import { ReactComponent as ServiceIcon } from 'images/icons/Service.svg'
import { TextContainer } from 'app/marketplace/proCenter/Card'

import type { FC } from 'react'
import type { ProHardware, ProHardwareStates, ProService, ProServiceStates } from 'types'

interface Props {
  tabs: Tab[]
  tab: Tab
  type: string
  items: ProServiceStates[] | ProHardwareStates[]
  selectedService: string
  selectedHardware: string
  isDisabledAsideNav: boolean
  addItem: () => void
  deleteItem: (id: string) => (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  handleTabSelection: (selectedTab: Tab) => void
  handleSelectedItem: (item: ProService | ProHardware, type: 'services' | 'hardware') => void
}

export const AsideNav: FC<Props> = ({
  tabs,
  tab,
  type,
  items,
  selectedService,
  selectedHardware,
  addItem,
  deleteItem,
  handleTabSelection,
  isDisabledAsideNav,
  handleSelectedItem,
}) => {
  return (
    <Container>
      <TabsHolder className='test' disabled={isDisabledAsideNav}>
        <Tabs
          id='remove-margin'
          tabClassName='tabs'
          selectedTab={tab.id}
          tabs={tabs}
          onSelectTab={handleTabSelection}
        />
      </TabsHolder>

      <Button variant='secondary' onClick={addItem} disabled={isDisabledAsideNav}>
        {type === 'services' ? '+ Add Service' : '+ Add Hardware'}
      </Button>

      <List>
        {type === 'services' &&
          (items as ProService[]).map((item) => (
            <Item
              key={item.id}
              icon={<ServiceIcon style={{ margin: '0 8px' }} />}
              name={item.name}
              subText=''
              isSelected={item.id === selectedService}
              handleSelect={() => handleSelectedItem(item, 'services')}
              handleDelete={deleteItem(item.id)}
              disabled={isDisabledAsideNav}
              type={type}
            />
          ))}
        {type === 'hardware' &&
          (items as ProHardware[]).map((item) => (
            <Item
              key={item.id}
              icon={<HardwareIcon style={{ margin: '0 8px' }} />}
              name={item.name}
              subText={item.manufacturer}
              isSelected={item.id === selectedHardware}
              handleSelect={() => handleSelectedItem(item, 'hardware')}
              handleDelete={deleteItem(item.id)}
              disabled={isDisabledAsideNav}
              type={type}
            />
          ))}
      </List>
    </Container>
  )
}

const itemTextWidth = '170px'

interface ItemProps {
  icon: JSX.Element
  name: string
  subText: string
  isSelected: boolean
  handleSelect: () => void
  handleDelete: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  disabled: boolean
  type: 'hardware' | 'services'
}

const Item: FC<ItemProps> = ({
  icon,
  name,
  subText,
  isSelected,
  handleSelect,
  handleDelete,
  disabled,
  type,
}) => {
  return (
    <ItemContainer isSelected={isSelected} disabled={disabled} onClick={() => handleSelect()}>
      {icon}

      <TextContainer>
        <Text weight='bold' truncate style={{ width: itemTextWidth, lineHeight: '20px' }}>
          {name || 'Untitled'}
        </Text>
        <Text truncate style={{ width: itemTextWidth }}>
          {type === 'hardware' ? subText || 'Manufacturer' : subText}
        </Text>
      </TextContainer>

      <TrashIcon onClick={handleDelete} />
    </ItemContainer>
  )
}

const disabledAndOverlayStyle = css`
  pointer-events: none;

  position: relative;
  &:after {
    content: ' ';
    z-index: 10;
    display: block;
    position: absolute;
    height: 110%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.6);
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 0;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  border-right: 1px solid ${({ theme }) => theme.borderColor};
`

const TabsHolder = styled.div<{ disabled: boolean }>`
  width: 100%;
  margin-bottom: 10px;
  height: var(--top-bar-height);

  #remove-margin {
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
  }

  .tabs:focus {
    outline: none;
  }

  ${({ disabled }) => (disabled ? disabledAndOverlayStyle : '')}
`

const List = styled.ul`
  display: grid;
  grid-auto-rows: 72px;
  grid-row-gap: 5px;
  align-content: flex-start;
  padding: 6px 0;
  overflow-y: auto;
  height: 345px;

  ::-webkit-scrollbar {
    display: none;
  }
`

const TrashIcon = styled(UITrashIcon)`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 16px;
  padding: 5px;
  display: none;
`

const ItemContainer = styled.li<{ isSelected: boolean; disabled: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  padding-left: 0px;
  border-radius: 4px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? `${theme.colors.Light.color.blue.b1}` : 'none'};
  position: relative;

  ${({ disabled, isSelected }) => (disabled && !isSelected ? disabledAndOverlayStyle : '')}

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};

    svg:first-child {
      border: 1px solid ${({ theme }) => theme.colors.Light.color.blue.b6};
      border-radius: 8px;
    }

    ${TrashIcon} {
      display: block;
      background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
    }
  }
`
