import { indexById } from 'helpers'
import { derived } from 'overmind'
import { flatten, pipe, prop, values } from 'ramda'
import { AsyncFuncState } from 'shared'
import { StorefrontCategory, StoreProduct } from 'types'
import { HardwareState } from './types'

const toList = pipe<
  [any],
  Record<StorefrontCategory, StoreProduct[]>,
  StoreProduct[][],
  StoreProduct[]
>(prop('categoryHash'), values, flatten)
const toIdHash = pipe(toList, indexById)

export const state: HardwareState = {
  status: AsyncFuncState.NULL,

  proHardware: {
    hash: {},
    list: derived(({ hash }: HardwareState['proHardware']) => Object.values(hash)),
  },
  products: {
    list: derived(toList),
    idHash: derived(toIdHash),
    categoryHash: {} as Record<StorefrontCategory, StoreProduct[]>,
  },
  productsManagement: [],
  quantityLimit: null,
  loadingSection: null,
}
