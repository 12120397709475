import { toLocaleCurrency } from 'helpers'

import type { Context } from 'store'
import type { WithDateRange, WithToast } from 'store/types'
import type { ScoreLoadingSection, WithAmount } from './types'

export interface UpdateScoreData {
  commissionLevel: number
}

export interface UpdateOneScore extends UpdateScoreData {
  professionalId: string
}

export const getPeriodCommission = ({ state, effects }: Context, dates: WithDateRange) =>
  effects.scores.api.getPeriodCommission(dates).then(({ periodCommission }) => {
    state.scores.periodCommission = periodCommission
  })

export const getScores = ({ state, effects }: Context, dates: WithDateRange) =>
  effects.scores.api
    .getScores(dates) //
    .then((data) => {
      state.scores.data = data
    })

export const setLoadingSection =
  (value: ScoreLoadingSection) =>
  ({ state }: Context) => {
    state.scores.loadingSection = value
  }

export const topUp = <A extends WithAmount>(
  { effects }: Context,
  value: A,
): Promise<A & WithToast> =>
  effects.scores.api
    .topUp(value) //
    .then(({ id, amount }) => ({
      ...value,
      toast: {
        title: 'Top-up successful',
        message: [`id: ${id}`, `amount: ${toLocaleCurrency(amount)}`],
      },
    }))

export const updateScoreData = ({ effects }: Context, { commissionLevel }: UpdateScoreData) =>
  effects.scores.api.updateAllScores(commissionLevel)

export const updateOneScore = (
  { effects }: Context,
  { professionalId, commissionLevel }: UpdateOneScore,
) => effects.scores.api.updateOneScore(professionalId, commissionLevel)
