import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Input, Text } from '@ubnt/ui-components/'
import { BubbleContainer, ConditionalTooltip, FlexRow } from 'components'
import { assocPath } from 'ramda'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useOverState } from 'store'
import { Form, FormFooter, Wrapper } from './EditQuote/styles'
import texts from './EditQuote/texts.json'
import type { QuoteState } from './EditQuote/types'
import { isValidForm } from './EditQuoteOrTaxOrClient/helpers'

interface Props {
  state: QuoteState
  setState: React.Dispatch<React.SetStateAction<QuoteState>>
  onSubmit: () => void | Promise<undefined>
  validations: {
    name: boolean
    taxRates: boolean
  }
  schema: any
  handleCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const EditTax: FC<Props> = ({
  state,
  setState,
  onSubmit,
  validations,
  schema,
  handleCancel,
}) => {
  const { modalProps } = useOverState().modal

  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<{
    serviceTax: string
    hardwareTax: string
  }>({
    resolver: yupResolver(schema),
    mode: 'all',
  })

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <Text size='caption'>{texts.tax.subheader}</Text>
        <FlexRow gap={8} verticalSpacing={8} alignItems='flex-start'>
          <Controller
            control={control}
            defaultValue={String(state.taxData.proService || '')}
            name='serviceTax'
            render={({ field: { onChange, value } }) => (
              <Input
                label='Service Tax Rate'
                onChange={(_, value) => {
                  onChange(value)
                  setState(assocPath(['taxData', 'proService'], value))
                }}
                iconAfter={<>%</>}
                value={value}
                width={186}
                onKeyDown={handleEnterKey}
                placeholder='Enter Tax rate here'
                invalid={errors.serviceTax?.message}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={String(state.taxData.proHardware || '')}
            name='hardwareTax'
            render={({ field: { onChange, value } }) => (
              <Input
                label='Hardware Tax Rate'
                onChange={(_, value) => {
                  onChange(value)
                  setState(assocPath(['taxData', 'proHardware'], value))
                }}
                iconAfter={<>%</>}
                value={value}
                width={186}
                onKeyDown={handleEnterKey}
                placeholder='Enter Tax rate here'
                invalid={errors.hardwareTax?.message}
              />
            )}
          />
        </FlexRow>
        <BubbleContainer icon radius='small' type='info' variant='info' padding={16}>
          <Text size='label'>{texts.tax.note}</Text>
        </BubbleContainer>
      </Wrapper>

      <FormFooter>
        <Button variant='tertiary' onClick={handleCancel}>
          Cancel
        </Button>
        <ConditionalTooltip
          description={!validations.taxRates ? texts.button.taxTooltip : texts.button.nameTooltip}
          position='topEnd'
          show={!isValidForm(validations)}
          width={160}
        >
          <Button
            variant='primary'
            disabled={!isValidForm(validations)}
            type='submit'
            onClick={handleSubmit(onSubmit)}
          >
            {!modalProps.quote ? 'Create' : 'Save'}
          </Button>
        </ConditionalTooltip>
      </FormFooter>
    </Form>
  )
}
