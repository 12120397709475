import { useOverState } from 'store'

import { Card } from './Card'
import { SectionContainer } from './Wifi'

export const Door = () => {
  const { projects } = useOverState()
  const { data } = projects.current

  return (
    <SectionContainer>
      <Card label='Entry/Exit Points' value={data?.doors.qty} />
      <Card label='Note' value={data?.doors.note} />
    </SectionContainer>
  )
}
