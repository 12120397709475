import { isEmpty } from 'ramda'
import { FC, useEffect } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { InfoIcon, SettingsIcon } from '@ubnt/icons'
import { Button, Input, Modal, Text, TextArea, Tooltip } from '@ubnt/ui-components'

import { ConditionalTooltip, FlexRow } from 'components'
import { makeTooltipText } from 'components/ConditionalTooltip/helpers'
import { ControlledInputWithUnit } from 'components/ControlledInputWithUnit'
import { AddItemNotification } from 'components/Notifications/AddItem'
import { displayDecimal, formatNumber, isAcceptableNumber, toLocaleCurrency } from 'helpers'
import { checkProItemsEditable } from 'helpers/quote'
import { ReactComponent as _HardwareIcon } from 'images/icons/Hardware.svg'
import { useActions, useOverState } from 'store'
import { getItemPrice, getItemQty, getItemTax } from './helpers'
import {
  ButtonWrapper,
  Form,
  FormFooter,
  HorizontalDivider,
  NotificationWrapper,
  RowContainer,
} from './styles'
import { TOOLTIP_TEXT } from './constants'

type FormFields = {
  price: string
  qty: number
  tax: string
  note: string
}

const priceErrorMessage = 'Price must be greater than or equal to 0.01'
const qtyErrorMessage = 'Number of Units must be greater than or equal to 1'
const taxErrorMessage = 'Tax Rate must be a number'

const schema = yup.object().shape({
  price: yup
    .number()
    .typeError(priceErrorMessage)
    .min(0.01, priceErrorMessage)
    .required()
    .test('acceptable-number', 'Must be to no more than 2 decimal places', (x) =>
      x ? isAcceptableNumber(x) : false,
    ),
  qty: yup.number().typeError(qtyErrorMessage).min(1, qtyErrorMessage).required(qtyErrorMessage),
  tax: yup.number().typeError(taxErrorMessage).min(0).max(100).required(),
  note: yup.string(),
})

export const AddProHardware: FC = () => {
  const {
    modal,
    modalProps: { item: hardware, resetSearch },
  } = useOverState().modal
  const { closeModal, setModal } = useActions().modal
  const { addProHardware } = useActions().clientManagement
  const { currentQuote } = useOverState().clientManagement

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      price: '0',
      qty: 1,
      tax: '0',
      note: '',
    },
  })

  const priceData = useWatch({ control, name: ['price', 'qty', 'tax'] })
  const price = Number(priceData[0]) || 0
  const qty = Number(priceData[1]) || 0
  const tax = Number(priceData[2]) || 0
  const subTotal = price * qty
  const taxTotal = (subTotal * tax) / 100
  const total = subTotal + taxTotal

  useEffect(() => {
    if (hardware && currentQuote) {
      reset({
        price: getItemPrice(currentQuote, 'proHardware', hardware).toString(),
        qty:
          Number(hardware.adjusted?.qty ?? hardware.qty) || getItemQty(currentQuote, hardware.id),
        tax:
          hardware.adjusted?.tax ??
          hardware.tax ??
          getItemTax(currentQuote, 'proHardware', hardware.id),
        note: hardware.note || hardware.description || '',
      })
    }
  }, [reset])

  const handleClose = () => {
    closeModal()
    if (resetSearch) resetSearch()
  }

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit)()
    }
  }

  if (modal === null) return null

  const handleChange = (value: string | number): [boolean, any] => {
    return typeof value === 'string' ? [true, formatNumber(value)] : [false, value]
  }

  const onSubmit = (data: FormFields) => {
    if (resetSearch) resetSearch()

    if (currentQuote && data.qty)
      addProHardware({
        quoteId: currentQuote.id,
        id: hardware.id,
        qty: Number(data.qty),
        price: Number(data.price),
        tax: Number(data.tax),
        note: data.note,
      })
  }

  const isDisabled = !isEmpty(errors) || !currentQuote || !checkProItemsEditable(currentQuote)

  const renderFooter = () => (
    <FormFooter>
      <FlexRow justify='flex-end' alignItems='flex-end'>
        <Button variant='tertiary' onClick={handleClose}>
          Cancel
        </Button>
        <ConditionalTooltip
          show={isDisabled}
          description={
            makeTooltipText({ quote: currentQuote, item: 'proHardware' }) || 'Please add quantity'
          }
          position='topEnd'
          width='200px'
        >
          <ButtonWrapper>
            <Button variant='primary' onClick={handleSubmit(onSubmit)} disabled={isDisabled}>
              Add to Quote
            </Button>
          </ButtonWrapper>
        </ConditionalTooltip>
      </FlexRow>

      <NotificationWrapper>
        <AddItemNotification item='proHardware' />
      </NotificationWrapper>
    </FormFooter>
  )

  return (
    <Modal
      title='Add Hardware to Quote'
      isOpen={modal === 'add_pro_hardware_to_quote'}
      onRequestClose={handleClose}
      size='small'
      shouldCloseOnOverlayClick={false}
      footer={renderFooter()}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <HardwareIcon />

        <RowContainer>
          <Text
            style={{ lineHeight: '24px' }}
          >{`${hardware.manufacturer} \u00A0 · \u00A0 ${hardware.category}`}</Text>
          <Text>{}</Text>
        </RowContainer>
        <RowContainer>
          <Text weight='bold' size='body'>
            {hardware.name || ''}
          </Text>
          <Button
            Icon={<SettingsIcon variant='fill' />}
            onClick={() =>
              setModal({
                modal: 'add_edit_pro_items',
                props: { editItem: true, hardwareItem: hardware },
              })
            }
          >
            Edit Item
          </Button>
        </RowContainer>
        <HorizontalDivider />

        <Text weight='bold' style={{ marginBottom: '12px' }}>
          Order Details
        </Text>
        <ControlledInputWithUnit
          control={control}
          name='price'
          label='Estimated Cost'
          iconBefore={<>$</>}
          invalid={errors.price?.message}
          width='100%'
          onKeyDown={handleEnterKey}
          disabled={!currentQuote}
          handleChange={handleChange}
        />
        <FlexRow justify='space-between' alignItems='center' gap={8} style={{ margin: '10px 0' }}>
          <Controller
            control={control}
            name='qty'
            render={({ field: { onChange, value } }) => (
              <Input
                type='number'
                min={0}
                label='Number of Units'
                placeholder='Please enter quantity'
                invalid={errors.qty?.message}
                value={value}
                onChange={(_e, v) => onChange(Number(v))}
                autoFocus
                width='100%'
                onKeyDown={handleEnterKey}
                disabled={!currentQuote}
              />
            )}
          />

          <ControlledInputWithUnit
            control={control}
            name='tax'
            label='Tax Rate'
            iconAfter={<>%</>}
            invalid={errors.tax?.message}
            width='100%'
            autoFocus
            onKeyDown={handleEnterKey}
            disabled={!currentQuote}
            handleChange={handleChange}
            formatValue={(val: number | string) => displayDecimal(val).toString()}
          />
        </FlexRow>
        <Controller
          control={control}
          name='note'
          render={({ field }) => (
            <TextArea
              {...field}
              label='Notes'
              size='body'
              placeholder='Please provide a description of the hardware to your customer.'
              height={75}
              width='100%'
              style={{ margin: '10px 0' }}
              disabled={!currentQuote}
              inputClassName='no-resize-x'
            />
          )}
        />
        <RowContainer>
          <Tooltip description={TOOLTIP_TEXT} position='right' width={150}>
            <Text size='body'>
              Estimated Subtotal <InfoIcon isActive />
            </Text>
          </Tooltip>
          <Text size='body'>{`${toLocaleCurrency(subTotal)}`}</Text>
        </RowContainer>
        <RowContainer>
          <Text size='body'>Tax</Text>
          <Text size='body'>{`${toLocaleCurrency(taxTotal)}`}</Text>
        </RowContainer>
        <RowContainer>
          <Text size='body'>Total</Text>
          <Text size='body' weight='bold'>{`${toLocaleCurrency(total)}`}</Text>
        </RowContainer>
      </Form>
    </Modal>
  )
}

const HardwareIcon = styled(_HardwareIcon)`
  margin: 16px 0;
`
