import { DropdownOption } from '@ubnt/ui-components'
import type { ReactElement } from 'react'
import { BuildingType, ProjectSize, Size } from 'store/projects/types'
import { sizeToBuildingSize, sizeToUserQty } from './helpers'

export interface DropdownInput {
  label: string
  name: 'buildingType' | 'userQty' | 'sizeUnit' | 'buildingSize'
  options: DropdownOption[]
  extra?: ReactElement
}

export const dropdowns: DropdownInput[] = [
  {
    label: 'Building Type',
    name: 'buildingType',
    options: [
      {
        label: 'Home',
        value: BuildingType.HOME,
      },
      {
        label: 'Office',
        value: BuildingType.OFFICE,
      },
      {
        label: 'School',
        value: BuildingType.SCHOOL,
      },
      {
        label: 'Hotel',
        value: BuildingType.HOTEL,
      },
      {
        label: 'Stadium',
        value: BuildingType.STADIUM,
      },
      {
        label: 'Other',
        value: BuildingType.OTHER,
      },
    ],
  },
  {
    label: 'Users',
    name: 'userQty',
    options: [
      {
        label: sizeToUserQty(Size.XSMALL),
        value: Size.XSMALL,
      },
      {
        label: sizeToUserQty(Size.SMALL),
        value: Size.SMALL,
      },
      {
        label: sizeToUserQty(Size.MEDIUM),
        value: Size.MEDIUM,
      },
      {
        label: sizeToUserQty(Size.LARGE),
        value: Size.LARGE,
      },
      {
        label: sizeToUserQty(Size.XLARGE),
        value: Size.XLARGE,
      },
    ],
  },
  {
    label: 'Building Size',
    name: 'sizeUnit',
    options: [
      {
        label: 'Feet',
        value: 'sqft',
      },
    ],
  },
  {
    label: '',
    name: 'buildingSize',
    options: [
      {
        label: sizeToBuildingSize(ProjectSize.SMALL),
        value: ProjectSize.SMALL,
      },
      {
        label: sizeToBuildingSize(ProjectSize.MEDIUM),
        value: ProjectSize.MEDIUM,
      },
      {
        label: sizeToBuildingSize(ProjectSize.LARGE),
        value: ProjectSize.LARGE,
      },
      {
        label: sizeToBuildingSize(ProjectSize.XLARGE),
        value: ProjectSize.XLARGE,
      },
    ],
  },
]
