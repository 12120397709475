import { http } from 'helpers'
import { News } from './types'

const listNews = () => http.get<News[]>('news/me')
const readNews = (newsIds: string[]) => http.post<void>('news/read', { newsIds })

export const api = {
  list: listNews,
  read: readNews,
}
