import { UserRole } from 'shared'
import { Context } from 'store'

export interface InviteUsersOptions {
  userInvitations: {
    email: string
    role: Exclude<UserRole, UserRole.SYS_ADMIN>
    professionalId?: string
  }[]
  inviteeType: string
}

export const inviteUsers = <A extends InviteUsersOptions>(
  { effects }: Context,
  { userInvitations, inviteeType }: A,
) => {
  const { api } = effects.onboarding

  return api.inviteUsers(userInvitations, inviteeType)
}
