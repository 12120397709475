import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

interface Props {
  placeholder: string
  value?: string
  setValue: (value: string) => void
  icon?: JSX.Element
}

export const Input = ({ placeholder, value, setValue, icon }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <Wrapper>
      <StyledInput
        type='text'
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        padding={icon ? 1 : 0}
      />
      {icon ? <IconWrapper>{icon}</IconWrapper> : null}
    </Wrapper>
  )
}

// Styles

const Wrapper = styled.div`
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.background};
  width: 100%;
`

const StyledInput = styled.input<{ padding: number }>`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.Light.color.neutral.n10};
  font-size: 12px;
  transition: 0.2s ease;
  border: 1px solid ${({ theme }) => theme.colors.Light.color.neutral.n3};
  border-radius: 3px;
  background: none;
  padding: 0 0 0 ${({ padding }) => (padding ? 30 : 5)}px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.Light.color.neutral.n6};
  }

  :focus {
    outline: none;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  left: 7px;
  pointer-events: none;
  display: flex;
  align-items: center;
`
