import { Button } from '@ubnt/ui-components'
import { useActions } from 'store'
import { Project } from 'store/projects/types'

export const Manage = (project: Project) => {
  const {
    projects: { deleteProject },
    modal: { setModal, closeModal, closeSidebar },
    app: { toast },
  } = useActions()

  const onSuccess = () => {
    closeSidebar()
    closeModal()
    toast({ title: 'Project removed', message: 'The project has been successfully removed' })
  }

  const handleDeleteProject = () => {
    deleteProject({ id: project.id, onSuccess })
  }

  return (
    <Button
      variant='inline'
      color='danger'
      size='medium'
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        setModal({
          modal: 'remove_project',
          props: {
            onRemove: handleDeleteProject,
          },
        })
      }}
    >
      Remove
    </Button>
  )
}
