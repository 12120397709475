import * as Sentry from '@sentry/react'
import { VFC, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { ToastProvider } from '@ubnt/ui-components/Toast'

import { useOverState } from 'store'
import PreSite from './pages/PreSite'
import { theme } from 'styles'
import RootModal from 'app/root-modal-holder'
import { SocketListener } from 'components'

const App: VFC = () => {
  const { user } = useOverState().app

  useEffect(() => {
    const opts = user ? { id: user.ssoId } : { ip_address: '{{auto}}' }
    Sentry.setUser(opts)
  }, [user])

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <RootModal />
        <PreSite />
        <SocketListener />
      </ToastProvider>
    </ThemeProvider>
  )
}

export default App
