import { http } from 'helpers'
import type { PaginatedResponse } from 'store/types'
import { GetInvitesOptions, Invite } from './types'

export const api = {
  getInvites: ({
    page = 0,
    type = 'pro',
    account,
    sortingColumn = 'email',
    order = 'asc',
    search,
    role,
  }: GetInvitesOptions) =>
    http.get<PaginatedResponse<Invite[]>>(`invitations`, {
      params: {
        search,
        type,
        account,
        role: role !== 'all' ? role : null,
        sortingColumn,
        order,
        page,
      },
    }),
  deleteInvite: (id: string) => http.delete<void>(`invitations/${id}`),
}
