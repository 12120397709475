import { Button, Text } from '@ubnt/ui-components'
import { VFC, useEffect } from 'react'
import styled from 'styled-components'

import { AsyncFuncState } from 'shared'
import { MEDIA } from 'styles'
import { useActions, useOverState } from 'store'
import { useLoader, useQuery } from 'hooks'

import Hero from 'images/Hero.png'
import { ReactComponent as SVGUbiquitiLogo } from 'svgs/UbiquitiLogo_blue.svg'
import { ReactComponent as SVGUnifiLogo } from 'svgs/UniFiLogo.svg'

const errorTexts: Record<string, string> = {
  uninvited: 'The UI SSO Account you are using is not authorized to access.',
  expired: 'Your session has expired. Please, log in again.',
  default:
    'It looks like you are using an UI SSO account that does not have access to this portal. Click above to switch accounts',
}

export const Login: VFC = () => {
  const { error, status } = useOverState().app
  const { app: actions } = useActions()
  const { error: queryError } = useQuery(['error'])
  const [loader] = useLoader(status)

  useEffect(() => actions.resetError, [])

  const showError =
    (status === AsyncFuncState.ERROR && error?.message === 'Wrong user type') || queryError
  const isWrongUser = showError && queryError !== 'expired'
  const buttonText = isWrongUser ? 'Switch UI SSO account' : 'Sign In with Ubiquiti Account'
  const errorText = errorTexts[queryError || 'default'] || errorTexts.default

  const handleLogin = isWrongUser ? () => actions.logout({ redirect: true }) : actions.login

  return (
    <Container>
      <UniFiLogo />

      <ButtonContainer>
        <Button
          full
          loader={loader({ showResponse: false })}
          onClick={handleLogin}
          size='large'
          variant='primary'
        >
          {buttonText}
        </Button>
      </ButtonContainer>

      {showError && (
        <ErrorWrapper>
          <Text size='body' color='danger'>
            {errorText}
          </Text>
        </ErrorWrapper>
      )}

      <LinksContainer>
        <StyledA href='https://www.ui.com/legal/termsofservice/' target='_blank' rel='noopener'>
          Terms Of Service
        </StyledA>
        <StyledA href='https://www.ui.com/legal/privacypolicy/' target='_blank' rel='noopener'>
          Privacy Policy
        </StyledA>
      </LinksContainer>

      <UbiquitiLogo />

      <ImageWrapper>
        <StyledImg src={Hero} alt='Hero Image' />
      </ImageWrapper>
    </Container>
  )
}

const ButtonContainer = styled.div`
  grid-area: centerLeft;
  align-self: center;

  padding: 0 32px;

  @media (${MEDIA.MOBILE}) {
    grid-column: topLeft / topRight;
    grid-row: centerLeft;
    align-self: end;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 400px) 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    'topLeft topRight'
    'centerLeft centerRight'
    'bottomLeft bottomRight';
  height: 100vh;
`

const ErrorWrapper = styled.div`
  grid-area: centerLeft;
  align-self: end;
  padding: 0 32px;
  margin-bottom: 5vh;

  @media (${MEDIA.MOBILE}) {
    grid-column: topLeft / topRight;
    grid-row: bottomLeft;
    align-self: start;
    margin-top: 20px;
  }
`

const ImageWrapper = styled.section`
  grid-column: topRight;
  grid-row: topRight / bottomRight;
  height: 100vh;

  display: flex;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n3};

  @media (${MEDIA.MOBILE}) {
    grid-column: topLeft / topRight;
    grid-row: topLeft / bottomLeft;
    height: 50%;
  }
`

const LinksContainer = styled.div`
  grid-area: bottomLeft;
  align-self: end;

  display: flex;
  justify-content: center;
  gap: 32px;
  padding-bottom: 32px;
`

const StyledA = styled.a`
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textColor.black.b65};

  :hover {
    color: ${({ theme }) => theme.colors.textColor.black.b100};
  }
`

const StyledImg = styled.img`
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
`

const UbiquitiLogo = styled(SVGUbiquitiLogo)`
  position: absolute;
  right: 32px;
  top: 32px;
  height: 64px;
  width: 64px;

  @media (${MEDIA.MOBILE}) {
    display: none;
  }
`

const UniFiLogo = styled(SVGUnifiLogo)`
  grid-area: centerLeft;
  align-self: start;
  justify-self: center;

  margin-top: 5vh;

  @media (${MEDIA.MOBILE}) {
    grid-column: topLeft / topRight;
    grid-row: topLeft;
    z-index: 100;
    margin-top: 8vh;
  }
`
