import { Text } from '@ubnt/ui-components'

import { DetailRow, DetailRowCol } from '../PaymentDetails/styles'
import { Loader } from 'components'

import type { RowProps } from '../PaymentDetails/types'
import type { VFC } from 'react'

export const Row: VFC<RowProps> = ({
  column = false,
  label = null,
  placeholder,
  value,
  status,
}) => {
  const Container = column ? DetailRowCol : DetailRow

  return (
    <Container>
      {label && (
        <Text size='body' weight='bold' color='primary'>
          {label}
        </Text>
      )}
      <Loader status={status} cond={!placeholder} otherwise={placeholder} type='small'>
        {typeof value === 'string' ? <Text size='body'>{value}</Text> : value}
      </Loader>
    </Container>
  )
}
