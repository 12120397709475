import { dateOnly, http } from 'helpers'
import type { WithDateRange, WithId } from 'store/types'
import type { Professional } from 'types'
import type { PeriodCommission, ProfessionalScore, WithAmount } from './types'

const getPeriodCommission = ({ start, end }: WithDateRange): Promise<PeriodCommission> =>
  http.get(`invoices/total?start=${dateOnly(start)}&end=${dateOnly(end)}`)

const getScores = ({ start, end }: WithDateRange): Promise<ProfessionalScore[]> =>
  http.get(`professionals/scores?start=${dateOnly(start)}&end=${dateOnly(end)}`)

const topUp = (body: WithAmount): Promise<WithId & WithAmount> => http.post(`stripe/topup`, body)

const updateAllScores = (commissionLevel: number) =>
  http.put<void>('professionals/scores', { commissionLevel })

const updateOneScore = (professionalId: string, commissionLevel: number) =>
  http.put<Professional>(`professionals/scores/${professionalId}`, { commissionLevel })

export const api = {
  getPeriodCommission,
  getScores,
  topUp,
  updateAllScores,
  updateOneScore,
}
