import { UserRole } from 'shared'

export const filteredAndParsedInvitations = (
  invitations: { id: string; email: string; role: Exclude<UserRole, UserRole.SYS_ADMIN> }[],
  professionalId: string,
) => {
  return invitations
    .filter((x) => x.email)
    .map((x) => ({ email: x.email, role: x.role, professionalId }))
}
