import { Button, PanelFooter } from '@ubnt/ui-components'
import { useLoader } from 'hooks'
import { FC, useContext } from 'react'
import { useOverState } from 'store'
import { FormSection } from '.'
import { ProjectFormContext } from '../ProjectContext'
import { NavigationFunction } from './types'

interface FooterProps {
  nextDisabled?: boolean
  nextLabel?: string
  onNext: NavigationFunction
  onPrev?: NavigationFunction
  prevLabel?: string
}

const nextLabelDefaults = {
  form: 'Next',
  sidebar: 'Save',
}

const prevLabelDefaults = {
  form: 'Back',
  sidebar: 'Cancel',
}

export const Footer: FC<FooterProps> = ({ nextDisabled, onPrev, onNext }) => {
  const { status } = useOverState().projects
  const formContext = useContext(ProjectFormContext)
  const [loader, isLoading] = useLoader(status)

  const next = formContext.buttons.next || nextLabelDefaults[formContext.location]
  const prev = formContext.buttons.prev || prevLabelDefaults[formContext.location]

  return (
    <PanelFooter>
      <FormSection type='footer' border={formContext.location === 'form'}>
        <Button
          variant='primary'
          disabled={nextDisabled}
          type='submit'
          onClick={onNext}
          loader={loader()}
        >
          {next}
        </Button>
        <Button
          variant='tertiary'
          disabled={nextDisabled || isLoading}
          onClick={onPrev}
          type='button'
        >
          {prev}
        </Button>
      </FormSection>
    </PanelFooter>
  )
}
