import { parallel } from 'overmind'
import { handleAsyncAction } from 'store/operators'
import * as op from './operators'
import { GetStatsOptions } from './types'

const handleDashboardAction = handleAsyncAction('dashboard')

export const getClientStats = handleDashboardAction<GetStatsOptions, void>(op.getClientStats)

export const getCommissionStats = handleDashboardAction<
  { professionalId?: string; year?: string },
  void
>(op.getCommissionStats)

export const getTopClients = handleDashboardAction<GetStatsOptions, void>(op.getTopClients)

export const getProRatings = handleDashboardAction<GetStatsOptions, void>(op.getProRatings)

export const getDashboardStats = handleDashboardAction<GetStatsOptions, void>(
  parallel(
    op.getQuoteStats,
    op.getClientStats,
    op.getCommissionStats,
    op.getTopClients,
    op.getProRatings,
  ),
)

export const getDateBasedStats = handleDashboardAction<GetStatsOptions, void>(
  parallel(op.getQuoteStats, op.getClientStats),
)
