import styled from 'styled-components'
import { Text } from '@ubnt/ui-components'

export const Amount = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.Light.color.orange.o6};
`

export const Container = styled.div`
  position: absolute;
  top: 24px;
  right: 28px;
  width: 184px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 18px;
  padding: 8px 16px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`
