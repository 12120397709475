import type { Company } from 'app/onboarding/types'
import { pipe } from 'overmind'
import config from 'config'
import { AsyncFuncState, UserRole } from 'shared'
import { Context } from 'store'
import toast, { handleError } from 'store/helpers'
import * as modalActions from 'store/modal/actions'
import { handleAsyncAction, toast as opToast } from 'store/operators'
import { filteredAndParsedInvitations } from './helpers'
import * as op from './operators'
import type { OnboardingView } from './types'

export interface Onboarding {
  company: Company
  userInvitations: { id: string; email: string; role: Exclude<UserRole, UserRole.SYS_ADMIN> }[]
}

export const setView = ({ state }: Context, view: OnboardingView) => {
  state.onboarding.view = view
}

export const onboard = (
  { state, effects, actions }: Context,
  { company, userInvitations }: Onboarding,
) => {
  state.onboarding.status = AsyncFuncState.LOADING
  const { api } = effects.onboarding
  const { user } = state.app

  if (!user) return Promise.resolve()

  state.onboarding.submitted = true

  return api
    .createProfessional({ ...company, adminId: user.ssoId, email: user.email }) //
    .then((professional) => {
      actions.professionals.setProfessional(professional)
      return professional.id
    })
    .then((professionalId) =>
      api
        .inviteUsers(filteredAndParsedInvitations(userInvitations, professionalId), 'member')
        .catch((error: any) => {
          state.onboarding.status = AsyncFuncState.ERROR
          toast(state, 'Send email(s) error', handleError(error), 'danger')
        }),
    )
    .then(() => {
      state.onboarding.status = AsyncFuncState.SUCCESS
      toast(state, 'Congratulations', 'Onboarding successful.', 'success')
      return new Promise((resolve) => setTimeout(resolve, 1500))
    })
    .then(() => {
      window.location.href = `${config.BASE_URL}/marketplace/hardware`
    })
    .catch((error) => {
      state.onboarding.submitted = false
      state.onboarding.status = AsyncFuncState.ERROR
      toast(state, 'Onboarding error', handleError(error), 'danger')
    })
}

const handleOnboardingAction = handleAsyncAction('onboarding')

export const uploadLogo = handleOnboardingAction<
  { data: FormData; onSuccess: (key: string) => void },
  void
>({
  action: ({ effects }: Context, { data, onSuccess }) =>
    effects.onboarding.api
      .uploadLogo(data) //
      .then(({ key }) => onSuccess(key)),
  errorTitle: 'Error uploading logo',
})

export const inviteUsers = handleOnboardingAction<op.InviteUsersOptions, void>({
  action: pipe(
    op.inviteUsers,
    opToast('User Added', 'Invitation(s) successfully sent'),
    modalActions.closeModal,
  ),
  errorTitle: 'Send user invitation(s) error',
})
