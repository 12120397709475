import { PencilIconL as UIPencilIcon, TrashIconL as UITrashIcon } from '@ubnt/icons'
import styled from 'styled-components'

export const List = styled.ul`
  margin-top: 20px;
  width: 100%;

  .list_item:first-child .trash {
    display: none;
  }
`

export const Row = styled.li`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
`

export const AvatarWrapper = styled.div`
  margin: 0 20px 0 0;
`

export const PencilIcon = styled(UIPencilIcon)`
  margin: 0 10px 0 15px;
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.colors.Light.color.neutral.n9};

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Light.color.blue.b6};
  }

  path {
    pointer-events: none;
  }
`

export const TrashIcon = styled(UITrashIcon)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.colors.Light.color.neutral.n9};

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Light.color.blue.b6};
  }

  path,
  rect {
    pointer-events: none;
  }
`
