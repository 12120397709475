import { enumSchema, locationSchema, nameSchema } from 'shared'
import { BuildingType, ProjectSize, Size } from 'store/projects/types'
import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReactNode } from 'react'
import { NewProject } from './Form/types'
import { CheckedDeployment } from './ProjectContext'

type SchemaKeys =
  | 'name'
  | 'location'
  | 'buildingType'
  | 'buildingSize'
  | 'userQty'
  | 'wifiIndoor'
  | 'wifiOutdoor'
  | 'doors'
  | 'cameras'
  | 'voIP'

export interface Schema extends Pick<NewProject, SchemaKeys> {
  checked: CheckedDeployment
  sizeUnit: 'sqft'
}

const negativeNumberMessage = 'Must be a positive number'
const requiredErrorMessage = 'Field cannot be empty'
const numberErrorMessage = 'Must be a number'
const fileSizeErrorMessage = 'File size must not exceed 20 MB'
const maxNameErrorMessage = 'Must not exceed 30 characters'

const wifiIndoorSchema = yup.mixed().when('checked', {
  is: (checked: CheckedDeployment) => checked.wifi,
  then: () =>
    yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.number().typeError(numberErrorMessage).required(requiredErrorMessage),
        area: yup
          .number()
          .typeError(negativeNumberMessage)
          .min(1, negativeNumberMessage)
          .required(requiredErrorMessage),
        roomQty: yup
          .number()
          .typeError(negativeNumberMessage)
          .min(1, negativeNumberMessage)
          .required(requiredErrorMessage),
        note: yup.string(),
        size: yup.number().max(20_000_000, fileSizeErrorMessage),
      }),
    ),
})

const wifiOutdoorSchema = yup.array().when('checked', {
  is: (checked: CheckedDeployment) => checked.wifi,
  then: () =>
    yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.string().max(30, maxNameErrorMessage).required(requiredErrorMessage),
        area: yup
          .number()
          .typeError(negativeNumberMessage)
          .min(1, negativeNumberMessage)
          .required(requiredErrorMessage),
        note: yup.string(),
      }),
    ),
})

const keySchema = {
  qty: yup
    .number()
    .typeError(numberErrorMessage)
    .min(0, negativeNumberMessage)
    .required(requiredErrorMessage),
  note: yup.string(),
}

const doorSchema = yup.mixed().when('checked', {
  is: (checked: CheckedDeployment) => checked.doorAccess,
  then: () => yup.object().shape(keySchema),
})

const cameraSchema = yup.mixed().when('checked', {
  is: (checked: CheckedDeployment) => checked.camera,
  then: () => yup.object().shape(keySchema),
})

const voIPSchema = yup.mixed().when('checked', {
  is: (checked: CheckedDeployment) => checked.phoneSystem,
  then: () => yup.object().shape(keySchema),
})

export const SCHEMA = yup.object().shape({
  checked: yup.object().shape({
    wifi: yup.boolean(),
    camera: yup.boolean(),
    doorAccess: yup.boolean(),
    phoneSystem: yup.boolean(),
  }),
  name: nameSchema(),
  location: locationSchema,
  buildingType: enumSchema(BuildingType),
  buildingSize: enumSchema(ProjectSize),
  sizeUnit: yup.string(),
  userQty: enumSchema(Size),
  wifiIndoor: wifiIndoorSchema,
  wifiOutdoor: wifiOutdoorSchema,
  doors: doorSchema,
  cameras: cameraSchema,
  voIP: voIPSchema,
})

interface Props {
  children: ReactNode
}

export const ProjectFormProvider = ({ children }: Props) => {
  const methods = useForm<Schema>({
    mode: 'onSubmit',
    resolver: yupResolver(SCHEMA),
    defaultValues: {
      checked: {
        wifi: false,
        camera: false,
        doorAccess: false,
        phoneSystem: false,
      },
      name: '',
      doors: {
        qty: 0,
        note: '',
      },
      cameras: {
        qty: 0,
        note: '',
      },
      sizeUnit: 'sqft',
      voIP: {
        qty: 0,
        note: '',
      },
      wifiIndoor: [],
      wifiOutdoor: [],
    },
  })

  return <FormProvider {...methods}>{children}</FormProvider>
}
