import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '@ubnt/ui-components'
import { FC, MouseEventHandler, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { isNotEmpty } from 'helpers'
import { useActions, useOverState } from 'store'
import { EMPTY_CLIENT } from './constants'
import { parseClient } from './helpers'
import { addClientSchema } from './schemas'
import { ClientForm, ContactsForm, ProjectForm } from './sharedComponents'
import { useDebounceValidation } from './useDebounceValidation'

import type { FormFields } from './types'

export const AddClient: FC = () => {
  const {
    modal,
    modalProps: { modalName },
  } = useOverState().modal
  const { closeModal, setModalName } = useActions().modal
  const { addClient } = useActions().clients

  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState(0)
  const [currentClientIndex, setCurrentClientIndex] = useState<number | null>(0)
  const [emailError, setEmailError] = useState('')
  const [isEditMode, setIsEditMode] = useState(false)

  const debounceValidate = useDebounceValidation()

  const validateEmail = (email?: string) => {
    return debounceValidate(email) //
      .then(({ isInvalid, message }) => {
        setEmailError(message)
        return !isInvalid
      })
  }

  const methods = useForm<FormFields>({
    resolver: yupResolver(addClientSchema(validateEmail)),
    mode: 'onChange',
    defaultValues: {
      clients: [EMPTY_CLIENT],
      tax: {
        exempt: false,
        file: [],
      },
      projectSelection: 'pro',
    },
  })

  const handleClose: MouseEventHandler = (event) => {
    event.preventDefault()
    closeModal()
  }

  const handleAddClient = () => {
    addClient({
      client: parseClient(methods.getValues()),
      email: methods.getValues().projectSelection === 'client',
      historyFn: navigate,
      fetchAllClients: false,
      uploadedTaxFile: isNotEmpty(methods.getValues().tax.file),
    })
  }

  return (
    <Modal
      title={modalName}
      isOpen={modal === 'add_client'}
      onRequestClose={closeModal}
      size='small'
      height='medium'
      shouldCloseOnOverlayClick={false}
    >
      <FormProvider {...methods}>
        {currentStep === 0 && currentClientIndex !== null && (
          <ClientForm
            clientIndex={currentClientIndex}
            emailError={emailError}
            close={handleClose}
            onSubmit={() => {
              setCurrentStep((prev) => prev + 1)
              setModalName({ name: 'Contacts ' })
              setCurrentClientIndex(null)
              setIsEditMode(false)
            }}
            isEditMode={isEditMode}
          />
        )}

        {currentStep === 1 && (
          <ContactsForm
            add={(index: number) => {
              setCurrentStep(0)
              setModalName({ name: 'Add Additional Contact' })
              setCurrentClientIndex(index)
            }}
            edit={(index: number) => {
              setCurrentStep(0)
              setModalName({ name: 'Edit Client' })
              setCurrentClientIndex(index)
              setIsEditMode(true)
            }}
            back={(event: React.MouseEvent<Element, MouseEvent>) => {
              event.preventDefault()
              setCurrentStep((prev) => prev - 1)
              setModalName({ name: 'Add Client' })
              setCurrentClientIndex(0)
            }}
            onSubmit={() => {
              setCurrentStep((prev) => prev + 1)
              setModalName({ name: 'Add a Project Information' })
            }}
          />
        )}

        {currentStep === 2 && (
          <ProjectForm
            back={(event: React.MouseEvent<Element, MouseEvent>) => {
              event.preventDefault()
              setCurrentStep((prev) => prev - 1)
              setModalName({ name: 'Contacts' })
            }}
            onSubmit={handleAddClient}
          />
        )}
      </FormProvider>
    </Modal>
  )
}
