import { Link } from 'react-router-dom'
import { Button, Text } from '@ubnt/ui-components'
import styled from 'styled-components'

import type { News } from 'store/news/types'

interface Props {
  items: News[]
}

export const ArticleGroup = ({ items }: Props) => {
  if (items.length === 1) return <Article hideTitle item={items[0]} />
  return (
    <>
      {items.map((item) => (
        <Article key={item.id} item={item} />
      ))}
    </>
  )
}

interface ArticleProps {
  hideTitle?: boolean
  item: News
}

const Article = ({ hideTitle = false, item }: ArticleProps) => {
  return (
    <Container>
      {hideTitle || (
        <Text size='body' weight='bold'>
          {item.title}
        </Text>
      )}
      <Text size='body'>
        {item.content}
        {item.more && (
          <>
            &nbsp;
            <Button<typeof Link> component={Link} to={item.more} variant='inline'>
              Learn More
            </Button>
          </>
        )}
      </Text>
    </Container>
  )
}

const Container = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 4px;
  padding: 0 24px;
`
