import type { ChangeEvent, FC } from 'react'
import styled from 'styled-components'

interface FileUploaderProps {
  accept?: string
  className?: string
  disabled?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const FileUploader: FC<FileUploaderProps> = ({
  accept = 'image/jpeg, image/png, application/pdf',
  className,
  disabled,
  children,
  onChange,
}) => (
  <Label className={className}>
    {children}
    <input
      accept={accept}
      className='file-uploader__input'
      disabled={disabled}
      name='file'
      onChange={onChange}
      type='file'
    />
  </Label>
)

const Label = styled.label`
  display: inline-block;
  cursor: pointer;

  > .file-uploader__input {
    display: none;
  }
`
