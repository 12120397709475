import { CheckboxRow as UICheckboxRow, PanelCard } from '@ubnt/ui-components'
import { AccessElementsIconL, WifiIconL, ProtectElementIconL, DevicesTalkIconL } from '@ubnt/icons'
import styled from 'styled-components'
import { FieldPath, useFormContext, useWatch } from 'react-hook-form'

import { VoIPForm } from './VoIPForm'
import { DoorForm } from './DoorForm'
import { CameraForm } from './CameraForm'
import { WiFiForm } from './WiFiForm'

import type { Schema } from '../ProjectFormProvider'

export const Deployment = () => {
  const { setValue } = useFormContext<Schema>()
  const checked = useWatch({ name: 'checked' })

  const items = [
    {
      id: 'wifi',
      label: 'WiFi',
      renderContent: () => <WiFiForm />,
      openByDefault: true,
    },
    {
      id: 'camera',
      label: 'Camera',
      renderContent: () => <CameraForm />,
      openByDefault: true,
    },
    {
      id: 'doorAccess',
      label: 'Door Access',
      renderContent: () => <DoorForm />,
      openByDefault: true,
    },
    {
      id: 'phoneSystem',
      label: 'Phone System',
      renderContent: () => <VoIPForm />,
      openByDefault: true,
    },
  ].filter(({ id }) => checked[id as keyof Schema['checked']])

  return (
    <>
      <CheckboxRow
        caption=''
        label=''
        checkboxes={[
          {
            checked: checked.wifi,
            id: 'wifi',
            label: 'WiFi',
            labelClassName: 'deployCheckbox',
            labelProps: {
              icon: <WifiIconL size='large' color='#838691' />,
            },
            variant: 'boxed',
          },
          {
            checked: checked.camera,
            id: 'camera',
            label: 'Camera',
            labelClassName: 'deployCheckbox',
            labelProps: { icon: <ProtectElementIconL size='large' color='#838691' /> },
            variant: 'boxed',
          },
          {
            checked: checked.doorAccess,
            id: 'doorAccess',
            label: 'Door Access',
            labelClassName: 'deployCheckbox',
            labelProps: { icon: <AccessElementsIconL size='large' color='#838691' /> },
            variant: 'boxed',
          },
          {
            checked: checked.phoneSystem,
            id: 'phoneSystem',
            label: 'Phone System',
            labelClassName: 'deployCheckbox',
            labelProps: { icon: <DevicesTalkIconL size='large' color='#838691' /> },
            variant: 'boxed',
          },
        ]}
        onChange={(_e, name, value) => {
          setValue(`checked.${name}` as FieldPath<Schema>, value)
        }}
      />
      <PanelContainer>
        {items.map((item) => (
          <PanelCard header={item.label} defaultIsOpen key={item.id} className='subsection__card'>
            {item.renderContent()}
          </PanelCard>
        ))}
      </PanelContainer>
    </>
  )
}

const PanelContainer = styled.div`
  .subsection__card {
    button {
      padding: 12px 0px;
      div {
        color: ${({ theme }) => theme.colors.textColor.black.b65};
        font-size: 14px;
      }
      svg {
        width: 15px;
      }
    }

    > div {
      ul li {
        display: flex;
        justify-content: center;
        padding: 0 18px;
      }
    }
  }
`

const CheckboxRow = styled(UICheckboxRow)`
  display: flex;

  div {
    display: flex;
    justify-content: space-between;
    font-size: 9px;
    width: 100%;
    padding: 0px;
  }

  > div > div {
    display: flex;
    gap: 8px;

    :first-child {
      display: none;
    }
  }

  div label:nth-child(n) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    min-width: 80px;
    height: 80px;
  }

  .deployCheckbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`
