import { branch, filter, noop, OperatorContextFunction, when } from 'overmind'
import { all, assoc, isNil, isNotNil, mapObjIndexed, omit, pick, values, __ } from 'ramda'

import { checkProItemsEditable } from 'helpers/quote'
import { isEmptyObj } from 'helpers'
import { QuoteStatus } from 'shared'
import { getQuoteEvent } from 'store/helpers'
import { SetModal } from 'store/modal/actions'
import { parseTaxData } from './helpers'

import type { Context } from 'store'
import type { WithError, WithOnSuccess } from 'store/types'
import type { ProTaxData, Quote } from 'types'
import type {
  AddProHardwareOpts,
  AddProServiceOpts,
  AdjustOptions,
  CloneQuoteOptions,
  CreateDraftOptions,
  DeleteProItemOpts,
  GetQuotes,
  HardwareQuote,
  ProItemType,
  ProductQuote,
  TaxDataState,
  UpdateDraftOptions,
  WithQuote,
  WithQuoteId,
  WithResend,
  WithTaxRate,
} from './types'

const _updateDefaultTax = (
  { effects }: Context,
  value: CreateDraftOptions | UpdateDraftOptions,
): Promise<Omit<CreateDraftOptions | UpdateDraftOptions, 'taxData'> & { taxData: ProTaxData }> => {
  const data = parseTaxData(value)
  return effects.clientManagement.api
    .updateDefaultTax(data)
    .then((taxData) => ({ ...value, taxData }))
}

const _updateUsersTax = <A extends { taxData: ProTaxData }>({ state }: Context, { taxData }: A) => {
  if (state.app.user) state.app.user.professional.taxData = taxData
}

export const addProHardware = ({ effects }: Context, value: AddProHardwareOpts) =>
  effects.clientManagement.api.addProItem('prohardware', value)

export const addProService = ({ effects }: Context, value: AddProServiceOpts) =>
  effects.clientManagement.api.addProItem('proservices', value)

export const adjust = <A extends AdjustOptions>({ effects }: Context, value: A) =>
  effects.clientManagement.api
    .changeAdjustingState(value.quote.id, value.action)
    .then(assoc('quote', __, value))

export const bulkAddHardware = <A extends { quoteId: string; data: HardwareQuote[] }>(
  { effects }: Context,
  { quoteId, data }: A,
) => effects.clientManagement.api.bulkAddHardware(quoteId, data)

export const deleteProService =
  <A extends DeleteProItemOpts>(type: ProItemType) =>
  ({ effects }: Context, value: A) =>
    effects.clientManagement.api.deleteProItem(type, value)

export const deleteHardware = <A extends { quoteId: string; productVariantId: string }>(
  { effects, state }: Context,
  { quoteId, productVariantId }: A,
) => {
  if (state.clientManagement.currentQuote) {
    state.clientManagement.currentQuote.productVariantQuotes =
      state.clientManagement.currentQuote.productVariantQuotes.filter(
        (p) => p.productVariantId !== productVariantId,
      )
  }
  return effects.clientManagement.api.deleteHardware(quoteId, productVariantId)
}

export const cloneQuote = <A extends CloneQuoteOptions>(
  { effects }: Context,
  { id, quoteName }: A,
) => effects.clientManagement.api.cloneQuote(id, { quoteName })

export const createQuote = <A extends CreateDraftOptions>(
  { effects }: Context,
  value: A,
): Promise<A & WithQuote> => {
  const { getQuote, postQuote } = effects.clientManagement.api

  return postQuote(value)
    .then((data) => ('data' in data ? getQuote(data.data.id) : getQuote(data.id)))
    .then((quoteData) => ('data' in quoteData ? quoteData.data : quoteData))
    .then((x) => ({ ...value, quote: x }))
}

export const completeQuote = <A extends WithQuoteId>(
  { effects }: Context,
  value: A,
): Promise<A & WithQuote> =>
  effects.clientManagement.api.completeQuote(value.quoteId).then((quote) => ({ ...value, quote }))

export const ifAdjustable = filter<any>(({ state }: Context) => {
  const { currentQuote } = state.clientManagement

  return !isNil(currentQuote) && checkProItemsEditable(currentQuote)
})

export const ifDraftIsNotEmpty = filter<any>(
  ({ state }: Context) => !isEmptyObj(state.clientManagement.currentQuote),
)

export const ifStatusIs = <A extends WithQuote>(status: QuoteStatus | QuoteStatus[]) =>
  filter<A>((_: Context, { quote }: any) =>
    Array.isArray(status) ? status.includes(quote.status) : quote.status === status,
  )

export const getQuoteById = <
  A extends WithQuoteId & WithTaxRate & WithOnSuccess & { refetchOnSuccess?: boolean },
>(
  { effects }: Context,
  value: A,
): Promise<Omit<A, 'taxRate'> & WithQuote & WithError & WithOnSuccess> => {
  return effects.clientManagement.api //
    .getQuote(value.quoteId, value.taxRate)
    .then((quoteData) => {
      if ('data' in quoteData)
        return {
          ...omit(['taxRate'], value),
          quote: quoteData.data,
          error: quoteData.metaData.error,
        }
      return { ...omit(['taxRate'], value), quote: quoteData }
    })
}

export const getQuoteForModal = <A extends SetModal & WithQuoteId>(
  { effects }: Context,
  value: A,
): Promise<A & { props: WithQuote }> =>
  effects.clientManagement.api
    .getQuote(value.quoteId)
    .then((quoteData) => ('data' in quoteData ? quoteData.data : quoteData))
    .then((x) => ({ ...value, props: { ...value.props, quote: x } }))

export const deleteQuote = <A extends WithQuoteId>({ effects }: Context, { quoteId }: A) =>
  effects.clientManagement.api.deleteQuote(quoteId)

export const setCurrentQuoteForModal = <A extends { props: { quote: Quote } }>(
  { state }: Context,
  { props: { quote } }: A,
) => {
  state.clientManagement.currentQuote = quote
}

export const getQuotes = <A extends GetQuotes>(
  { effects, state }: Context,
  { clientId, projectId }: A,
) =>
  effects.clientManagement.api
    .getQuotes(clientId, projectId) //
    .then((data) => void (state.clientManagement.quotes = data))

export const setQuotePending = <A extends WithQuoteId>(
  { effects }: Context,
  value: A,
): Promise<A & WithQuote> =>
  effects.clientManagement.api
    .setQuotePending(value.quoteId) //
    .then((quote) => ({ ...value, quote }))

export const updateQuote = <A extends UpdateDraftOptions>(
  { state, effects }: Context,
  { data, quoteId }: A,
) => {
  const body = {
    ...data,
    taxData: data.taxData
      ? mapObjIndexed((x) => (isNil(x) ? x : Number(x)), data.taxData)
      : undefined,
  }

  return effects.clientManagement.api
    .putQuote(quoteId, body) //
    .then((data) => void (state.clientManagement.currentQuote = data))
}

export const addProduct = <A extends { quoteId: string; data: ProductQuote }>(
  { effects }: Context,
  { quoteId, data }: A,
) => effects.clientManagement.api.addProduct(quoteId, data)

export const saveQuoteVersion = <A extends UpdateDraftOptions & WithResend>(
  { state, effects }: Context,
  { data, reSend }: A,
) => {
  const { id: quoteId, history, status } = state.clientManagement.currentQuote as Quote

  const event = getQuoteEvent(status)

  const send = () =>
    reSend
      ? effects.clientManagement.api.reSendQuote(quoteId, pick(['recipients', 'message'], data))
      : effects.clientManagement.api.sendQuote(quoteId)

  return send().then(() =>
    effects.clientManagement.api.putQuoteHistory(quoteId, { event, quoteVersionId: history?.id }),
  )
}

export const whenHasTaxData = <A extends CreateDraftOptions | UpdateDraftOptions, B = A>(paths: {
  true: OperatorContextFunction<A, B>
  false: OperatorContextFunction<A, B>
}) =>
  when((_, value: CreateDraftOptions | UpdateDraftOptions) => {
    const taxData = 'taxData' in value ? value.taxData : (value.data.taxData as TaxDataState)
    return all(isNotNil, values(taxData))
  }, paths)

export const updateDefaultTax = <A extends CreateDraftOptions | UpdateDraftOptions>() =>
  whenHasTaxData<A>({
    true: branch(_updateDefaultTax, _updateUsersTax) as unknown as OperatorContextFunction<A, A>,
    false: noop() as OperatorContextFunction<A, A>,
  })

export const showDispute = ({ actions }: Context, value: WithQuote) => {
  const dispute = value.quote.disputes[0]

  actions.modal.setModal({
    modal: 'dispute_notice',
    props: {
      dispute: dispute,
    },
  })
}

export const getEmailPreview = <A extends WithQuoteId>(
  { effects, state }: Context,
  { quoteId }: A,
) =>
  effects.clientManagement.api
    .getEmailPreview(quoteId)
    .then((data) => (state.clientManagement.emailPreview = data))
