import { yupResolver } from '@hookform/resolvers/yup'
import { Input, Text, TextArea } from '@ubnt/ui-components'
import { CreatedOption, Dropdown, DropdownOption, NEW_ITEM } from '@ubnt/ui-components/Dropdown'
import { displayDecimal, formatNumber, isAcceptableNumber } from 'helpers'
import { lensPath, set } from 'ramda'
import React, { ChangeEvent, FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { ProHardwareStates } from 'types/hardware.type'
import * as yup from 'yup'
import { ConditionalOverlay, Form } from './styles'

const REPLACEABLE = /^Create\s"(.+)"$/

type FormFields = {
  name: string
  manufacturer: string
  category: string
  price: string
}

const schema = yup.object().shape({
  name: yup.string().min(2).required(),
  manufacturer: yup.string().required(),
  category: yup.string().required(),
  price: yup.number().min(0.01).required(),
})

interface Props {
  setState: React.Dispatch<React.SetStateAction<ProHardwareStates>>
  state: ProHardwareStates
  onSubmit: (state: any) => void
  setIsValidForm: React.Dispatch<React.SetStateAction<boolean>>
  setHardware: React.Dispatch<React.SetStateAction<Record<string, ProHardwareStates>>>
  setEditedHardware: React.Dispatch<React.SetStateAction<Record<string, ProHardwareStates>>>
  isDisabledFields: boolean
  manufacturerOptions: DropdownOption[]
  setManufacturerOptions: React.Dispatch<React.SetStateAction<DropdownOption[]>>
  hardwareCategories: DropdownOption[]
  setHardwareCategories: React.Dispatch<React.SetStateAction<DropdownOption[]>>
}

export const HardwareForm: FC<Props> = ({
  state,
  setState,
  onSubmit,
  setIsValidForm,
  setHardware,
  setEditedHardware,
  isDisabledFields,
  manufacturerOptions,
  setManufacturerOptions,
  hardwareCategories,
  setHardwareCategories,
}) => {
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (state.id) schema.isValid(state).then((bool) => setIsValidForm(bool))
  }, [state])

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit(onSubmit)()
  }

  const appendToDropdownList = (label: string, key: string) => {
    if (key === 'manufacturer') setManufacturerOptions((prev) => [...prev, { label, value: label }])
    if (key === 'category') setHardwareCategories((prev) => [...prev, { label, value: label }])
  }

  const handleDropDownChange =
    (key: string, onChange: (...event: any[]) => void) =>
    ({ label, value }: CreatedOption | DropdownOption) => {
      const parsedLabel = value === NEW_ITEM ? label.replace(REPLACEABLE, '$1') : label

      setState((prev) => ({ ...prev, [key]: parsedLabel }))
      setHardware(set(lensPath([state.id, key]), parsedLabel))
      setEditedHardware(set(lensPath([state.id, key]), parsedLabel))
      onChange(parsedLabel)

      if (value === NEW_ITEM) appendToDropdownList(parsedLabel, key)
    }

  const handleChange =
    (key: string, onChange?: (...event: any[]) => void) =>
    (_e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | number) => {
      const isNumber = key === 'price'
      if (isNumber && !isAcceptableNumber(value)) return

      const _value = isNumber && typeof value === 'string' ? formatNumber(value) : value

      setState((prev) => ({ ...prev, [key]: _value }))
      setHardware(set(lensPath([state.id, key]), _value))
      setEditedHardware(set(lensPath([state.id, key]), _value))
      if (onChange) onChange(_value)
    }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ConditionalOverlay show={isDisabledFields}>
        <Text size='header-xs' weight='boldest'>
          Select a Service or Hardware to edit
        </Text>
      </ConditionalOverlay>

      <Controller
        control={control}
        defaultValue={state.name}
        name='name'
        render={({ field: { onChange } }) => (
          <Input
            label='Product Name'
            name='name'
            autoFocus
            autoComplete='chrome-off'
            invalid={errors.name?.message}
            width='100%'
            value={state.name}
            onChange={handleChange('name', onChange)}
            onKeyDown={handleEnterKey}
            disabled={isDisabledFields}
          />
        )}
      />

      <Controller
        control={control}
        defaultValue={state.manufacturer}
        name='manufacturer'
        render={({ field: { onChange } }) => (
          <Dropdown
            value={state.manufacturer}
            label='Manufacturer'
            width='100%'
            maxHeight={200}
            options={manufacturerOptions}
            searchable
            onChange={handleDropDownChange('manufacturer', onChange)}
            creatable
            disabled={isDisabledFields}
            invalid={!!errors.manufacturer}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={state.category}
        name='category'
        render={({ field: { onChange } }) => (
          <Dropdown
            value={state.category}
            label='Category'
            width='100%'
            maxHeight={200}
            options={hardwareCategories}
            onChange={handleDropDownChange('category', onChange)}
            creatable
            searchable
            disabled={isDisabledFields}
            invalid={!!errors.category}
          />
        )}
      />

      <TextArea
        value={state.description}
        size='body'
        label='Notes'
        full
        inputClassName='no-resize-x'
        labelClassName='label'
        minLength={5}
        height={110}
        onChange={handleChange('description')}
        disabled={isDisabledFields}
        placeholder='Please describe this hardware (e.g. the techincal specifications, functions etc.).'
      />

      <Controller
        control={control}
        defaultValue={String(state.price)}
        name='price'
        render={({ field: { onChange } }) => (
          <Input
            label='Price'
            iconBefore={<>$</>}
            value={displayDecimal(state.price ?? 0).toString()}
            invalid={errors.price?.message}
            width='100%'
            onChange={handleChange('price', onChange)}
            onKeyDown={handleEnterKey}
          />
        )}
      />
    </Form>
  )
}
