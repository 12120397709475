import { Button, Text } from '@ubnt/ui-components'
import { GoogleAddressAutoComplete } from 'components'
import { useContext, useState, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useActions } from 'store'
import type { Location } from 'types'
import { ProjectFormContext } from '../ProjectContext'
import { Schema } from '../ProjectFormProvider'
import { ButtonWrapper, Input } from './styles'

export const LocationDropdown = () => {
  const formContext = useContext(ProjectFormContext)
  const isReadOnly = !formContext.editable
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<Schema>()
  const {
    modal: { setModal },
  } = useActions()

  const location = useWatch({ control, name: 'location' })
  const [googleInput, setGoogleInput] = useState('')

  useEffect(() => {
    if (location) setGoogleInput(location.formatted)
  }, [location])

  const handleLocationChange = (location: Location) => {
    if (formContext.editable) {
      setValue('location', location, { shouldValidate: true })
      setGoogleInput(location.formatted)
    }
  }

  const handleEditAddress = () => {
    setModal({
      modal: 'address',
      props: {
        location: location,
        updateLocation: (l: Location) => handleLocationChange(l),
      },
    })
  }

  if (!formContext.editable)
    <Input
      variant='secondary'
      label='Project Location'
      readOnly={isReadOnly}
      value={location.formatted}
      size='body'
    />

  return (
    <>
      <GoogleAddressAutoComplete
        variant='secondary'
        name='location'
        label='Address'
        handleChange={handleLocationChange}
        value={googleInput}
        setValue={setGoogleInput}
        width='100%'
      />
      {errors.location && (
        <Text color='danger'>Address incomplete. Click &quot;Edit&quot; below to update</Text>
      )}
      {errors.location && (
        <ButtonWrapper>
          <Button type='button' size='inherit' variant='inline' onClick={handleEditAddress}>
            Edit
          </Button>
        </ButtonWrapper>
      )}
    </>
  )
}
