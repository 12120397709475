import React, { FC } from 'react'
import styled from 'styled-components'
import { Input, InputProps } from '@ubnt/ui-components'

export const PercentInput: FC<InputProps> = ({ ...props }) => {
  return (
    <Wrapper>
      <Input {...props} className='hide-icons' min='0' max='100' />
      <StyledP>%</StyledP>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 15px;
`

const StyledP = styled.p`
  position: absolute;
  right: 25px;
  bottom: 9px;
  font-size: 14px;
`
