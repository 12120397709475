import styled from 'styled-components'

import { always, cond, equals } from 'ramda'

export const Status = styled.div<{ status: string }>`
  position: absolute;
  padding: 2px 9px;
  font-size: 10px;
  line-height: 1.2;
  border-radius: 4px;
  font-weight: 700;

  background: ${({ theme, status }) =>
    cond<any, string>([
      [equals('available'), always(theme.colors.Light.color.green.g5)],
      [equals('backorder'), always(theme.colors.Light.color.orange.o1)],
      [equals('comingsoon'), always(theme.colors.Light.color.orange.o2)],
      [equals('soldout'), always(theme.colors.Light.color.red.r5)],
    ])(status)};

  color: ${({ theme, status }) =>
    cond<any, string>([
      [equals('available'), always(theme.colors.textColor.white.w100)],
      [equals('backorder'), always(theme.colors.Light.color.orange.o6)],
      [equals('comingsoon'), always(theme.colors.Light.color.orange.o8)],
      [equals('soldout'), always(theme.colors.Light.color.neutral.n0)],
    ])(status)};
`
