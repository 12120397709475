import { pipe, branch } from 'overmind'
import { Context } from 'store'
import { handleAsyncAction, toast as opToast } from 'store/operators'
import { Professional } from 'types'
import * as op from './operators'
import * as modalActions from 'store/modal/actions'

const handleProfessionalsAction = handleAsyncAction('professionals')

export const getAllProfessionals = handleProfessionalsAction(op.getAllProfessionals)

export const getProfessional = handleProfessionalsAction(op.getProfessional)

export const refreshBackorderQuota = handleProfessionalsAction(op.refreshBackorderQuota)

type UpdateProf = Pick<Professional, 'avatar' | 'name' | 'location' | 'email' | 'phone'>

export const setProfessional = ({ state }: Context, professional: Partial<Professional>) => {
  if (state.app.user)
    state.app.user.professional = { ...state.app.user.professional, ...professional }
}

export const updateProfessional = handleProfessionalsAction<UpdateProf, void>({
  errorTitle: 'Edit Company error',
  action: pipe(
    branch(
      ({ state }: Context, professional) => ({
        professional,
        professionalId: state.app.user?.professionalId || '',
      }),
      op.updateProfessional,
      setProfessional,
      modalActions.closeModal,
      opToast('Done', 'Professional successfully updated'),
    ),
  ),
})

// export const updateProfessional2 = async (
//   { state, effects, actions }: Context,
//   professional: UpdateProf,
// ) => {
//   try {
//     state.professionals.status = AsyncFuncState.LOADING
//     const professionalId = state.app.user?.professionalId
//     console.log(professional)

//     effects.app.http.defaults.withCredentials = true

//     const { data } = await effects.app.http.put<UpdateProf>(
//       `${config.API_URL}/api/professionals/${professionalId}`,
//       professional,
//     )

//     actions.professionals.setProfessional(data)

//     toast(state, 'Done', 'Successfully updated company.', 'success')
//     state.professionals.status = AsyncFuncState.SUCCESS
//     actions.modal.closeModal()
//   } catch (error) {
//     toast(state, 'Update company error', handleError(error), 'danger')
//     state.professionals.status = AsyncFuncState.ERROR
//   }
// }
