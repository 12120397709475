import { FC, useEffect } from 'react'

import { ProjectSidebar } from 'app/marketplace/ProjectSidebar'
import { PaymentDetails } from 'app/payments/PaymentDetails'
import { DeleteModal } from 'components'
import AddUser from 'app/root-modal-holder/AddUser'
import { TermsAndConditionsModal } from 'components/Modals'
import { NewContactFound } from 'components/Modals/NewContactFound'
import { useActions, useOverState } from 'store'
import { AddEditProItems } from './AddEditProItems'
import { AddProHardware, AddProService } from './AddProItemToQuote'
import { CompleteConfirmation } from './FinalizeBillOrQuote'
import ImportHardwareModal from './ImportHardware'
import SendToClient from './SendToClient'
import { EditUserRole } from './EditUserRole'
import EditCompanyForm from './EditCompanyForm'
import { AddClient, AddContact, EditClient } from './ClientForm'
import { EditQuoteOrTaxOrClient } from './EditQuoteOrTaxOrClient'
import { DisputeNotice } from './DisputeNotice'
import { ProItemsQuoteDetails } from 'app/payments/ProItemsQuoteDetails'
import { TalkCommissionDetails } from 'app/payments/TalkCommissionDetails'
import AddressForm from './AddressModal'
import { News } from './News'
import { useQuery } from 'hooks'
import { RemoveProjectModal } from 'app/marketplace/ProjectSidebar/Form/RemoveProjectModal'
import { ProductModal } from './ProductModal'

const RootModal: FC = () => {
  const { modal, sidebar } = useOverState().modal
  return (
    <>
      {modal === 'add_client' && <AddClient />}
      {modal === 'add_contact' && <AddContact />}
      {modal === 'add_edit_pro_items' && <AddEditProItems />}
      {modal === 'add_pro_service_to_quote' && <AddProService />}
      {modal === 'add_pro_hardware_to_quote' && <AddProHardware />}
      {modal === 'add_user' && <AddUser />}
      {modal === 'address' && <AddressForm />}
      {modal === 'company_profile' && <EditCompanyForm />}
      {modal === 'confirm_complete_quote' && <CompleteConfirmation modal={modal} />}
      {modal === 'confirm_final_bill' && <CompleteConfirmation modal={modal} />}
      {modal === 'delete' && <DeleteModal />}
      {modal === 'dispute_notice' && <DisputeNotice />}
      {modal === 'edit_client' && <EditClient />}
      {modal === 'edit_quote' && <EditQuoteOrTaxOrClient />}
      {modal === 'edit_role' && <EditUserRole />}
      {modal === 'edit_tax' && <EditQuoteOrTaxOrClient />}
      {modal === 'import_hardware' && <ImportHardwareModal />}
      {modal === 'new_contact_found' && <NewContactFound />}
      {modal === 'news' && <News />}
      {modal === 'product' && <ProductModal />}
      {modal === 'remove_project' && <RemoveProjectModal />}
      {modal === 'terms_and_conditions' && <TermsAndConditionsModal />}

      {sidebar === 'payment_details' && <PaymentDetails />}
      {sidebar === 'pro_item_quote' && <ProItemsQuoteDetails />}
      {sidebar === 'project_details' && <ProjectSidebar />}
      {sidebar === 'send_to_client' && <SendToClient />}
      {sidebar === 'talk_comm_details' && <TalkCommissionDetails />}

      <ModalListener />
    </>
  )
}

export default RootModal

const ModalListener = () => {
  const { modal } = useActions()
  const { m } = useQuery(['m'])

  useEffect(() => {
    if (!m) return modal.closeModal()
    if (m === 'program-details') modal.setModal({ modal: 'terms_and_conditions', props: null })
  }, [m])

  return null
}
