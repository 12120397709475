import { pipe, values, map as Rmap, splitEvery, omit, evolve, when, includes, always } from 'ramda'
import type { Context } from 'store'
import { indexById } from 'helpers'
import type { ProHardware, StoreProduct } from 'types/hardware.type'
import type { EditedHardware, ProductLoadingSection, QuantityLimitOptions } from './types'
import type { WithId } from 'store/types'

export const getProducts = ({ effects, state }: Context) =>
  effects.hardware.api //
    .getProducts()
    .then((products) => {
      state.hardware.products.categoryHash = products
    })

export const getProductsManagement = ({ effects, state }: Context) =>
  effects.hardware.api //
    .getProductsManagement()
    .then((products) => {
      state.hardware.productsManagement = products
    })

export const getQuantityLimit = ({ effects, state }: Context, values: QuantityLimitOptions) =>
  effects.hardware.api //
    .getQuantityLimit(values)
    .then((data) => {
      state.hardware.quantityLimit = typeof data === 'string' ? null : Object.values(data)[0]
    })

export const updateProduct = <A extends Partial<StoreProduct> & Pick<StoreProduct, 'id'>>(
  { effects, state }: Context,
  product: A,
) =>
  effects.hardware.api.putProduct(product.id, product).then((products) => {
    state.hardware.productsManagement = products
  })

export const getProHardware = ({ effects, state }: Context) =>
  effects.hardware.api.getProHardware().then((hardware) => {
    state.hardware.proHardware.hash = indexById(hardware)
  })

export const deleteProHardware = <A extends WithId>({ effects, state }: Context, { id }: A) =>
  effects.hardware.api
    .deleteProHardware(id)
    .then(() => (state.hardware.proHardware.hash = omit([id], state.hardware.proHardware.hash)))

export const parseHardware = <A extends EditedHardware>(
  _: Context,
  value: A,
): Omit<A, 'hardware'> & { hardware: ProHardware[] } =>
  evolve(
    {
      hardware: pipe<any, any, any>(
        values,
        Rmap(
          evolve({
            id: when(includes('temp'), always(undefined)),
            price: Number,
          }) as any,
        ),
      ),
    },
    value,
  )

export const bulkUpdateProHardware = <A extends { hardware: ProHardware[] }>(
  { effects }: Context,
  { hardware }: A,
) => {
  const updateChunks = splitEvery(50, hardware)

  return Promise.all(updateChunks.map(effects.hardware.api.bulkUpdateProHardware))
}

export const setLoadingSection =
  (value: ProductLoadingSection) =>
  ({ state }: Context) => {
    state.hardware.loadingSection = value
  }
