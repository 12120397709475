import { DropdownOption } from '@ubnt/ui-components'

export interface WifiDropdownInput {
  label: string
  options: DropdownOption[]
}

export const wifiDropdown = {
  label: 'WiFi Coverage',
  options: [
    {
      label: 'Indoor',
      value: 'indoor',
    },
    {
      label: 'Indoor & Outdoor',
      value: 'both',
    },
  ],
}
