import { AsyncFuncState } from 'shared'
import { DashboardState } from './types'

export const state: DashboardState = {
  quoteStats: null,
  clientStats: null,
  commission: { stats: null, years: null },
  ratings: null,
  topClients: null,
  status: AsyncFuncState.NULL,
}
