import { useEffect, useState } from 'react'
import { Dropdown } from '@ubnt/ui-components'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

import { wifiDropdown } from './wifiFields'
import { Schema } from '../ProjectFormProvider'
import { FormSection } from './FormSection'
import { AreaGroup } from './AreaGroup'
import { SubSection } from './styles'
import { ButtonsContainer } from 'components'

const EMPTY_AREA = {
  wifiIndoor: {
    area: 0,
    roomQty: 0,
    note: '',
    plans: [],
  },
  wifiOutdoor: {
    area: 0,
    note: '',
    plans: [],
  },
}
type Area = 'indoor' | 'both'
export type WifiArea = 'wifiIndoor' | 'wifiOutdoor'

export const WiFiForm = () => {
  const [areas, setAreas] = useState<Area>()

  const { control } = useFormContext<Schema>()

  const indoor = useFieldArray({ control, name: 'wifiIndoor' })
  const outdoor = useFieldArray({ control, name: 'wifiOutdoor' })

  const indoorSize = indoor.fields.length
  const outdoorSize = outdoor.fields.length

  useEffect(() => {
    if (indoor.fields.length > 0 && outdoor.fields.length > 0) {
      return setAreas('both')
    }
    if (indoor.fields.length > 0) {
      return setAreas('indoor')
    }
    return setAreas(undefined)
  }, [])

  const addArea = ({ fields, append }: typeof indoor | typeof outdoor, area: WifiArea) => {
    const next = {
      ...EMPTY_AREA.wifiIndoor,
      name: area === 'wifiIndoor' ? `${fields.length + 1}` : '',
      id: uuid(),
    }
    return append(next, { shouldFocus: false })
  }

  const removeArea = ({ fields, remove }: typeof indoor | typeof outdoor) =>
    remove(fields.length - 1)

  useEffect(() => {
    if (!areas) return
    if (indoorSize === 0) addArea(indoor, 'wifiIndoor')
    if (areas === 'both' && outdoorSize === 0) addArea(outdoor, 'wifiOutdoor')
  }, [areas])

  return (
    <FormSection main>
      <SubSection>
        <Dropdown
          label='WiFi Coverage'
          onChange={({ value }) => setAreas(value as Area)}
          options={wifiDropdown.options}
          width={336}
          variant='secondary'
          value={areas}
          inputContainerClassName='dropdown__wifi'
        />
        {(areas === 'indoor' || areas === 'both') && (
          <ButtonsContainer
            label='Indoor Floors'
            min={0}
            onIncrease={() => addArea(indoor, 'wifiIndoor')}
            onDecrease={() => removeArea(indoor)}
            value={indoorSize}
          />
        )}
        <AreaGroup fields={indoor.fields} name='wifiIndoor' update={indoor.update} />
      </SubSection>
      {areas === 'both' && (
        <SubSection>
          <ButtonsContainer
            label='Outdoor Areas'
            min={0}
            onIncrease={() => addArea(outdoor, 'wifiOutdoor')}
            onDecrease={() => removeArea(outdoor)}
            value={outdoorSize}
          />
          <AreaGroup fields={outdoor.fields} name='wifiOutdoor' update={outdoor.update} />
        </SubSection>
      )}
    </FormSection>
  )
}
