import { OperatorContextFunction, pipe } from 'overmind'
import { isNil, isNotNil, omit, zip } from 'ramda'

import { indexById } from 'helpers'
import { whenPropIs } from 'store/operators'
import { NewWifi, Plan } from 'app/marketplace/ProjectSidebar/Form/types'

import type { Context } from 'store'
import type {
  CreateProjectOptions,
  Files,
  FilesName,
  UpdateProjectOptions,
  WithNewProject,
  WithProjectId,
} from './types'
import type { WithClientId } from 'store/types'

export type GetProjectOptions = WithClientId & WithProjectId

export interface SetCurrent extends WithClientId {
  currentId?: string | null
  setCurrent?: boolean
}

export interface SetCurrentOptions extends WithProjectId {
  currentId?: string | null
}

export const createProject = <A extends WithNewProject>(
  { effects }: Context,
  value: A,
): Promise<A & WithProjectId> =>
  effects.projects.api
    .create(value.clientId, value.project)
    .then(({ id }) => ({ ...value, projectId: id }))

export const setCreating = ({ state }: Context, { projectId }: WithProjectId) => {
  state.projects.creating = projectId
}

export const updateProject = ({ effects, state }: Context, opts: UpdateProjectOptions) =>
  effects.projects.api
    .update(opts) //
    .then((data) => {
      state.projects.current.data = data
    })

export const createOrUpdateProject = whenPropIs<CreateProjectOptions | UpdateProjectOptions, void>(
  'projectId',
  isNil,
  {
    true: pipe(createProject, setCreating),
    false: pipe(updateProject, () => undefined) as OperatorContextFunction<
      CreateProjectOptions | UpdateProjectOptions,
      void
    >,
  },
)

export interface ProjectID {
  id: string
}

export const deleteProject = ({ effects, state }: Context, { id }: ProjectID) =>
  effects.projects.api
    .delete(id) //
    .then(() => {
      state.projects.hash = omit([id], state.projects.hash)
    })

export const getProject = (
  { state, effects }: Context,
  { clientId, projectId }: GetProjectOptions,
) =>
  effects.projects.api
    .getProject(clientId, projectId as string) //
    .then((data) => {
      state.projects.current.data = data
    })

export const getProjectsByClient = ({ state, effects }: Context, { clientId }: WithClientId) =>
  effects.projects.api
    .getProjects(clientId) //
    .then((data) => {
      state.projects.hash = indexById(data)
    })

export const passLastProject = <A>({ state }: Context, value: A): A & WithProjectId => {
  const projectId =
    state.projects.list.length === 0
      ? null
      : state.projects.list.reduce((a, b) => (a.createdAt >= b.createdAt ? a : b)).id

  return { ...value, projectId }
}

const extractFile = (item: NewWifi) => item.plan ?? []

export const extractFiles = (_: Context, payload: CreateProjectOptions) => {
  const indoor = payload.project.wifiIndoor.flatMap(extractFile)
  const outdoor = payload.project.wifiOutdoor.flatMap(extractFile)
  return { payload, indoor, outdoor }
}

interface UploadFilesInput {
  payload: CreateProjectOptions
  indoor: Plan[]
  outdoor: Plan[]
}

const doUploadFiles =
  (
    getUrls: (arr: Plan[]) => Promise<Files>,
    upload: (data: [Plan, FilesName]) => Promise<string>,
  ) =>
  (plans: Plan[], wifis: NewWifi[]) => {
    if (plans.length === 0) return wifis
    return getUrls(plans)
      .then(({ data }) => zip(plans, data))
      .then((data) => Promise.all(data.map(upload)))
      .then((data) =>
        wifis.map((item) => {
          if (!item.plan) return item
          return { ...item, plan: { ...item.plan, name: data.shift() as string } }
        }),
      )
  }

export const uploadFiles = (
  { effects }: Context,
  { payload, indoor, outdoor }: UploadFilesInput,
): Promise<CreateProjectOptions> => {
  const fun = doUploadFiles(
    effects.projects.api.getUploadUrls(payload.clientId),
    effects.projects.api.uploadFile,
  )
  return Promise.all([
    fun(indoor, payload.project.wifiIndoor),
    fun(outdoor, payload.project.wifiOutdoor),
  ]).then(([wifiIndoor, wifiOutdoor]) => ({
    ...payload,
    project: { ...payload.project, wifiIndoor, wifiOutdoor },
  }))
}

export const whenCurrentProjectIsSet = <A extends GetProjectOptions, B = A>(paths: {
  true: OperatorContextFunction<A, B>
  false: OperatorContextFunction<A, B>
}) => whenPropIs('projectId', isNotNil, paths)
