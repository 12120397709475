import {
  always,
  cond,
  equals,
  identity,
  ifElse,
  isNil,
  pipe,
  prop,
  propSatisfies,
  reject,
  sort,
  T,
} from 'ramda'

import { capitalise, renderClient } from 'helpers'
import { AdjustIcon, CheckIcon, PaymentIcon, QuoteIcon, SendIcon } from './styles'
import texts from './texts.json'
import { FulfillmentStatus } from 'store/paymentDetails/constants'

import type { Order, PaymentDetails } from 'store/paymentDetails/types'
import type { EventData } from './types'

const findEvents = (details: PaymentDetails): EventData[] => [
  {
    date: details.invoice?.paidOutAt,
    event: texts.events.commission,
    Icon: CheckIcon,
  },
  {
    date: details.invoice?.createdAt,
    event: texts.events.ordered,
    Icon: PaymentIcon,
  },
  {
    date: details.lastAdjusted,
    event: texts.events.adjusted,
    Icon: AdjustIcon,
  },
  {
    date: details.sentAt,
    event: texts.events.sent,
    Icon: SendIcon,
  },
  {
    actor: details.createdBy.name,
    date: details.createdAt,
    event: texts.events.created,
    Icon: QuoteIcon,
  },
]

export const getEvents: (x: PaymentDetails) => EventData[] = pipe(
  findEvents,
  reject(propSatisfies(isNil, 'date')),
  sort((a, b) => ((a.date as string) <= (b.date as string) ? 1 : -1)),
)

export const getShippingStatus: (order?: Order | null) => string | undefined = ifElse(
  isNil,
  always(undefined),
  pipe(
    prop('fulfillmentStatus'),
    cond<any, string>([
      [equals(FulfillmentStatus.PENDING_PROCESSING), always('unfulfilled')],
      [equals(FulfillmentStatus.SHIPPED), always('fulfilled')],
      [equals(FulfillmentStatus.PARTIALLY_SHIPPED), always('partial')],
      [T, identity],
    ]),
    capitalise,
  ),
)

export const makeClientAvatar = (client?: PaymentDetails['client']) => {
  if (isNil(client)) return undefined
  return renderClient(
    client.name ? [client.name] : [client.firstName, client.lastName],
    client.logo,
    { asBodyText: true },
  )
}
