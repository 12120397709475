import { http } from 'helpers'
import { PaginatedResponse } from 'store/types'
import type { User } from 'types/user.type'
import { GetUserOptions } from './types'

export const api = {
  getCompanyMembers: () => http.get<User[]>('users/company-members'),
  listUsers: ({
    page = 0,
    type = 'pro',
    sortingColumn = 'email',
    order = 'asc',
    search,
    role,
  }: GetUserOptions) =>
    http.get<PaginatedResponse<User[]>>('users/list', {
      params: {
        search,
        type,
        role: role !== 'all' ? role : null,
        sortingColumn,
        order,
        page,
      },
    }),
  putUser: (user: Pick<User, 'ssoId'>) => http.put<User>(`users/${user.ssoId}`, user),
  removeUserFromCompany: (userId: string) => http.put<User>(`users/${userId}/remove-pro`),
  deleteUser: (userId: string) => http.delete<User>(`users/${userId}`),
}
