import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { overmind } from 'store'
import config from 'config'
import { rejectNilValues } from 'helpers'

const isOwn = (url: string) => !url.startsWith('http')

const makeOptions = (url: string, params: Record<string, any> = {}): AxiosRequestConfig => {
  const own = isOwn(url)
  return {
    headers: own ? { 'X-App-Build': config.BUILD } : undefined,
    withCredentials: own,
    params: rejectNilValues(params),
  }
}

export const makeUrl = (url: string) =>
  url.startsWith('http') ? url : `${config.API_URL}/api/${url}`

const parseBuild =
  (url: string) =>
  ({ data, headers }: AxiosResponse) => {
    if (isOwn(url) && headers['x-build'] === 'outdated') overmind.actions.app.setBuild('outdated')
    return data
  }

interface HttpOptions {
  data?: any
  params?: Record<string, any>
}

export const http = {
  get: <A>(url: string, { params }: HttpOptions = {}): Promise<A> =>
    axios.get(makeUrl(url), makeOptions(url, params)).then(parseBuild(url)),
  post: <A>(url: string, data?: any): Promise<A> =>
    axios.post(makeUrl(url), data, makeOptions(url)).then(parseBuild(url)),
  put: <A>(url: string, data?: any): Promise<A> =>
    axios.put(makeUrl(url), data, makeOptions(url)).then(parseBuild(url)),
  delete: <A>(url: string): Promise<A> =>
    axios.delete(makeUrl(url), makeOptions(url)).then(parseBuild(url)),
}

export const makeAwsUrl = (key: string) => `${config.AWS_URL}/${key}`

type CSV = 'clients' | 'contracts' | 'feedback' | 'projects' | 'quotes'
export const makeCsvUrl = (key: CSV) =>
  key === 'contracts' ? `${config.API_URL}/api/quotes/csv/old` : `${config.API_URL}/api/${key}/csv`

export const makeStripeUrl = (url: string) =>
  `${config.API_URL}/api/professionals/stripe_onboard?redirectUrl=${config.BASE_URL}${url}`
