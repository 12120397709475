import { InputWithUnitProps, InputWithUnit } from 'components'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'

type Props<A extends FieldValues = FieldValues> = UseControllerProps<A> &
  InputWithUnitProps & {
    handleChange?: <B extends string | number>(value: B) => [boolean, B]
    formatValue?: (s: string | number) => string | number
  }

export const ControlledInputWithUnit = <A extends FieldValues = FieldValues>({
  control,
  name,
  handleChange,
  formatValue,
  ...restProps
}: Props<A>) => {
  const {
    field: { onChange, value, ...inputProps },
    //fieldState: { invalid, isTouched, isDirty },
    //formState: { touchedFields, dirtyFields },
  } = useController<A>({
    name,
    control,
  })

  return (
    <InputWithUnit
      {...restProps}
      {...inputProps}
      value={formatValue ? formatValue(value) : value}
      onChange={(event) => {
        if (!handleChange) return onChange(event)
        const [change, val] = handleChange(event.target.value)
        if (change) return onChange({ ...event, target: { ...event.target, value: val } })
      }}
    />
  )
}
