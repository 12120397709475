import { toLocaleCurrency } from 'helpers'
import { EarnedInfo, EarnedText } from './styles'

import type { FC } from 'react'
import type { ProgressEarnedProps } from './types'

export const Earned: FC<ProgressEarnedProps> = ({ earned }) => (
  <>
    <EarnedText size='header-s' weight='bold'>
      {toLocaleCurrency(earned, { decimals: 0 })}
    </EarnedText>
    <EarnedInfo size='caption'>Total Earned</EarnedInfo>
  </>
)
