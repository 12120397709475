import { useOverState } from 'store'
import { Card } from './Card'
import { SectionContainer } from './Wifi'

export const Camera = () => {
  const { projects } = useOverState()
  const { data } = projects.current
  return (
    <SectionContainer>
      <Card label='Cameras' value={data?.cameras.qty} />
      <Card label='Note' value={data?.cameras.note} />
    </SectionContainer>
  )
}
