import { Adjustment } from './quote.type'

export interface Hardware {
  id: string
  name: string
  displayName: string
  coreItemCode: string
  lifecycle: 'RAMPDOWN' | 'EARLYRELEASE' | 'SUSTAINING'
  group: string
  variants: Variant[]
  isFeatured: boolean
  isFrozen: boolean
  isLegacy: boolean
  isNewTechnology: boolean
  category: string
  subCategory: string
  subCatOrder: number
  sku: string
  description: string
  thumbnail: string
  shopifyData: ShopifyData[]
  displayProduct: boolean
  displayCategory: string
  displayCategoryOrder: number
  inStock: boolean
  limit: number
  continueSelling: boolean
}

export type Variant = 'GLOBAL' | 'INTL' | 'US'

export interface ShopifyData {
  id: string
  coreItemCode: string
  description: string | null
  specs: string
  images: string[] | null
  publicUrl: string
  price: string
  currencyCode: string
  key: 'us'
  variantId: string
}

export enum ProductType {
  ACCESS = 'Access',
  ACCESSORIES = 'Accessories',
  APPAREL = 'Apparel',
  CONNECT = 'Connect',
  LED = 'LED',
  ROUTING = 'Routing',
  SERVER = 'Server',
  SURVEILLANCE = 'Surveillance',
  UNIFI_OS_GATEWAYS = 'UniFiOSGateways',
  VOIP = 'VoIP',
  WIRELESS = 'Wireless',
  // MULTI_POINT = 'MultiPoint',
  // PTP_BRIDGING = 'PtPBridging',
  // ROUTERS_AND_MESH = 'RoutersAndMesh',
  // SOLAR = 'Solar',
  // WIRED_TECHNOLOGIES = 'WiredTechnologies',
}

export enum ProductDisplayStatus {
  AVAILABLE = 'Available',
  BACKORDER = 'Backorder',
  COMING_SOON = 'ComingSoon',
  SOLD_OUT = 'SoldOut',
}

export enum StorefrontCategory {
  UNIFI_OS_CONSOLES = 'UniFi OS Consoles',
  WIFI = 'WiFi',
  SWITCHING = 'Switching',
  CAMERA_SECURITY = 'Camera Security',
  SMART_BUILDING = 'Smart Building',
  ACCESSORY_TECH = 'Accessory Tech',
}

export interface StoreProduct {
  id: string
  productId: string

  category: StorefrontCategory
  currency: string
  description: string
  discontinued: boolean | null
  displayProduct: boolean
  limit: number
  price: number
  shortDescription: string
  sku: string
  status: ProductDisplayStatus | null
  title: string
  thumbnail: string
  type: ProductType | null
  url: string
  variantTitle: string

  isEarlyAccess: true
  isVisibleInStore: boolean
}

interface ProHardwareBase {
  id: string
  name: string
  manufacturer: string
  category: string
  description: string
}

interface UncompletedProHardware extends ProHardwareBase {
  price: string
}

export interface ProHardware extends ProHardwareBase {
  price: number
}

export type ProHardwareStates = UncompletedProHardware | ProHardware

export interface CustomerProductVariantQuote {
  id: string
  adjusted: Pick<Adjustment, 'qty' | 'type'>
  backorderable: boolean
  backordered: number
  displayProduct: boolean
  inStock: boolean
  name: string
  price: number
  quantity: number
  shortDescription: string
  talkSubsQty: number
  thumbnail: string
  type: ProductType | null
  title: string
  url: string
}
