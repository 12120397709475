import { FC, createContext, Dispatch, SetStateAction, useState } from 'react'
import { toDisplayUnits, toServerUnits } from 'helpers'

import type { Unit, UnitFunction } from 'types'

interface ContextButtons {
  next?: string
  prev?: string
}

export interface CheckedDeployment {
  wifi: boolean
  camera: boolean
  doorAccess: boolean
  phoneSystem: boolean
}

interface Context {
  buttons: ContextButtons
  checked: CheckedDeployment
  editable: boolean
  isSmallScreen: boolean
  location: 'form' | 'sidebar'
  toDisplay: UnitFunction
  toServer: UnitFunction
  unit: Unit
  updateUnit: (unit: Unit) => void
  setChecked: Dispatch<SetStateAction<CheckedDeployment>>
}

export const ProjectFormContext = createContext<Context>({
  buttons: {},
  checked: {} as any,
  editable: false,
  isSmallScreen: false,
  location: 'sidebar',
  toDisplay: () => 1,
  toServer: () => 1,
  unit: 'sqft',
  updateUnit: () => undefined,
  setChecked: () => undefined,
})

interface ProjectContextProviderProps {
  buttons?: ContextButtons
  editable?: boolean
  location: 'form' | 'sidebar'
}

export const ProjectContextProvider: FC<ProjectContextProviderProps> = ({
  buttons = {},
  children,
  editable = false,
  location,
}) => {
  const [unit, setUnit] = useState<Unit>('sqft')
  const [checked, setChecked] = useState({
    wifi: false,
    camera: false,
    doorAccess: false,
    phoneSystem: false,
  })

  const toDisplay = (value: number) => toDisplayUnits(value, { precision: 2 })
  const toServer = (value: number) => toServerUnits(value)

  const value: Context = {
    buttons,
    checked,
    editable,
    isSmallScreen: window.screen.width <= 768,
    location,
    toDisplay,
    toServer,
    unit,
    updateUnit: setUnit,
    setChecked,
  }

  return <ProjectFormContext.Provider value={value}>{children}</ProjectFormContext.Provider>
}
