import { Button } from './Button'
import { Label } from './Label'
import { Container } from './styles'

import type { FC } from 'react'
import type { PaginatorProps } from './types'

/**
 * Paginator
 * @property align string An option for justify-content.
 * @property className? string
 * @property current number The current page.
 * @property label? object A label describing the type of document. Required to show something like "1-25 of 65 clients".
 * @property last number The last page.
 * @property onFirst? function Function to load the first page. If present, shows a double-arrow button.
 * @property onLast? function Function to load the last page. If present, shows a double-arrow button.
 * @property onNext? function Function to load the next page. If present, shows an arrow button.
 * @property onPrev? function Function to load the previous page. If present, shows an arrow button.
 * @returns JSX Element
 */
export const Paginator: FC<PaginatorProps> = ({
  align = 'flex-end',
  children,
  className,
  current,
  label,
  last,
  onFirst,
  onLast,
  onNext,
  onPrev,
}) => {
  return (
    <Container align={align} className={className}>
      {align === 'flex-end' && children}
      {label && <Label {...label} current={current} />}
      <Button type='first' disabled={current <= 0} onClick={onFirst} />
      <Button type='prev' disabled={current <= 0} onClick={onPrev} />
      <Button type='next' disabled={current >= last} onClick={onNext} />
      <Button type='last' disabled={current >= last} onClick={onLast} />
      {align !== 'flex-end' && children}
    </Container>
  )
}
