import { VFC, useEffect, useContext, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { BasicToast, ToastContext } from '@ubnt/ui-components'
import { v4 as uuid } from 'uuid'

import { useOverState, useActions } from '../store'
import { FullscreenLoader, Loader, PrivateRoute, PublicRoute, ToastMessage } from 'components'
import { Login } from './Login'

import type { ToastType } from 'types'

const Onboarding = lazy(() => import('app/onboarding'))
const Site = lazy(() => import('./Site'))

const PreSite: VFC = () => {
  const { loadingSection, status, toast } = useOverState().app
  const { resetToast } = useActions().app

  useEffect(() => {
    if (toast.message) {
      renderToast(toast.message, toast.title, toast.type, toast.duration)
      resetToast()
    }
  }, [toast.message])

  const { createNotification } = useContext(ToastContext)
  const renderToast = (
    message: string | string[],
    toastTitle: string,
    type: ToastType,
    duration: number | null,
  ) => {
    const details = Array.isArray(message) ? message : new Array(message)

    createNotification(
      <BasicToast
        id={uuid()}
        details={<ToastMessage messages={details} />}
        duration={duration ? duration : type === 'danger' ? 6000 : 3000}
        stateIndicator={type}
        title={toastTitle}
      />,
    )
  }

  return (
    <Loader status={status} cond={loadingSection !== 'login'}>
      <Suspense fallback={<FullscreenLoader />}>
        <Routes>
          <Route
            path='/login'
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path='/onboarding'
            element={
              <PrivateRoute>
                <Onboarding />
              </PrivateRoute>
            }
          />
          <Route
            path='*'
            element={
              <PrivateRoute>
                <Site />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </Loader>
  )
}

export default PreSite
