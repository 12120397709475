import { useCallback, useLayoutEffect, useRef } from 'react'

import type { UseOutsideClickOptions } from './types'

export const useOutsideClick = <A extends HTMLElement>(
  fn: () => void,
  { checkToast = true, ignoreIds = [] }: UseOutsideClickOptions = {},
) => {
  const ref = useRef<A>(null)

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node

      const modalPortal = document.querySelector('.ReactModalPortal')
      const ignored = ignoreIds
        .concat(checkToast ? 'toast-container' : [])
        .some((id) => document.getElementById(id)?.contains(target) ?? false)

      if (
        ref.current &&
        !ref.current.contains(target) &&
        !modalPortal?.contains(target) &&
        !ignored
      ) {
        fn()
      }
    },
    [ref.current, fn],
  )

  useLayoutEffect(() => {
    document.addEventListener('click', handleOutsideClick, true)

    return () => {
      document.removeEventListener('click', handleOutsideClick, true)
    }
  }, [ref])

  return ref
}
