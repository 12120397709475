import { DateTime } from 'luxon'
import {
  HomeIcon,
  OfficeIconL,
  IOsMenuIconL,
  StadiumIconL,
  HotelIconL,
  SchoolIconL,
} from '@ubnt/icons'

import { useOverState } from 'store'
import { sizeToBuildingSize, sizeToUserQty } from '../Form/helpers'
import { Card } from './Card'
import { Customer } from './Customer'
import { SectionContainer } from './Wifi'
import { BuildingType } from 'store/projects/types'

export const General = () => {
  const { projects } = useOverState()
  const { data } = projects.current
  const date = data && DateTime.fromISO(data.createdAt).toFormat('MMMM dd, yyyy')

  const buildingTypes = {
    [BuildingType.HOME]: <HomeIcon size='large' className='card__icon' />,
    [BuildingType.OFFICE]: <OfficeIconL size='large' className='card__icon' />,
    [BuildingType.STADIUM]: <StadiumIconL size='large' className='card__icon' />,
    [BuildingType.HOTEL]: <HotelIconL size='large' className='card__icon' />,
    [BuildingType.SCHOOL]: <SchoolIconL size='large' className='card__icon' />,
    [BuildingType.OTHER]: <IOsMenuIconL size='large' className='card__icon' />,
  }

  if (!data) return null
  return (
    <SectionContainer>
      <Card label='Project Name' value={data.name} />
      <Customer label='Customer' value={data.client} />
      <Card label='Address' value={data.location.formatted} />
      <Card
        label='Building Type'
        value={data.buildingType}
        icon={buildingTypes[data.buildingType]}
      />
      <Card
        label='Building Size'
        value={data.buildingSize && sizeToBuildingSize(data.buildingSize)}
      />
      <Card label='Users' value={data.userQty && sizeToUserQty(data.userQty)} />
      {date && <Card label='Created on' value={date} />}
    </SectionContainer>
  )
}
