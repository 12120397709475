import { http } from 'helpers'
import type { Professional } from 'types'
import { WithProfessional } from './operators'
import type { ExternalAccount, ProfResponse } from './types'

export const api = {
  getExternalAccount: () => http.get<ExternalAccount>('professionals/external-account'),
  getAllProfessionals: () => http.get<Professional[]>('professionals'),
  getProfessional: (professionalId: string | undefined) =>
    http.get<ProfResponse>(`professionals/${professionalId}`),
  refreshBackorderQuota: (professionalId: string) =>
    http.put<{ boRefreshedAt: string; count: number }>(
      `professionals/${professionalId}/refresh-bo`,
    ),
  uploadLogo: (body: FormData) => http.post<{ key: string }>('professionals/upload-logo', body),
  updateProfessional: (
    professionalId: string,
    professional: WithProfessional['professional'],
  ): Promise<Professional> => http.put(`professionals/${professionalId}`, professional),
}
