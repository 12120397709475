import { toLocaleCurrency } from 'helpers'
import { debounce, pipe, when } from 'overmind'
import { Context } from 'store'
import { toast, ToastOptions } from 'store/app/actions'
import { handleAsyncAction, userIsSysAdmin } from 'store/operators'
import { PayoutManyOptions, PayoutOneOptions } from './types'

const REQUEST_DEBOUNCE = 400

// Sync Actions

export const handleAfterTrigger = ({ state }: Context) => {
  state.payouts.amount = 0
  state.payouts.triggeredAt = Date.now()
}

export const setAmount = ({ state }: Context, amount: number) => {
  state.payouts.amount = amount
}

// Async Actions

const handlePayoutsAction = handleAsyncAction('payouts')

export const check = handlePayoutsAction<{ invoices: string[] }, void>({
  action: pipe(
    when((_: Context, { invoices }) => invoices.length > 0, {
      true: ({ effects }: Context, { invoices }) => effects.payouts.api.check(invoices),
      false: () => Promise.resolve(0),
    }),
    setAmount,
  ),
  before: pipe(userIsSysAdmin(), debounce(REQUEST_DEBOUNCE)),
})

export const executeMany = handlePayoutsAction<PayoutManyOptions, void>({
  action: pipe(
    ({ effects }: Context, payload) => effects.payouts.api.execute(payload),
    (_: Context, value): ToastOptions => ({
      duration: 10e3,
      message: [
        `Processing ${toLocaleCurrency(value)} in payouts.`,
        'You will be notified when it is done.',
      ],
      title: 'Processing Payouts',
      type: 'info',
    }),
    toast,
  ),
  before: userIsSysAdmin(),
  errorTitle: 'Error handling payouts',
})

export const executeOne = handlePayoutsAction<PayoutOneOptions, void>({
  action: pipe(
    ({ effects }: Context, payload) => effects.payouts.api.execute(payload),
    (_: Context, value) => ({
      duration: 15e3,
      message: `Paid ${toLocaleCurrency(value)}`,
      title: 'Payout Triggered!',
    }),
    toast,
    handleAfterTrigger,
  ),
  before: userIsSysAdmin(),
  errorTitle: 'Error handling payout',
})
