import { FlexRow, NumberTag } from 'components'
import { NumberTagProps } from 'components/NumberTag/types'
import type { FC } from 'react'
import styled from 'styled-components'
import { EventData } from '../PaymentDetails/types'
import { Events } from './Events'

interface Props {
  numberTags: NumberTagProps[]
  events: EventData[]
  infoBubble?: JSX.Element
}

export const Header: FC<Props> = ({ numberTags, events, infoBubble }) => {
  return (
    <DetailHeader>
      <FlexRow alignItems='flex-start' justify='space-between' flexWrap='wrap'>
        {numberTags.map((n) => (
          <NumberTag
            key={n.label}
            badge={n.badge}
            label={n.label}
            number={n.number}
            numberSize={n.numberSize}
            renderPlaceholder={n.renderPlaceholder}
          />
        ))}
      </FlexRow>
      <Events events={events} />
      {infoBubble && infoBubble}
    </DetailHeader>
  )
}

const DetailHeader = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  margin: 0px 0px 16px;
  padding-bottom: 16px;
`
