import React, { FC } from 'react'
import styled from 'styled-components'
import { Text, Modal } from '@ubnt/ui-components'
import { useActions, useOverState } from 'store'

export const DeleteModal: FC = () => {
  const { closeModal } = useActions().modal
  const { modal, modalProps } = useOverState().modal
  const { deleteClient } = useActions().clients
  const { removeUserFromCompany, deleteUser } = useActions().users
  const { clients } = useOverState().clients

  const { modalName, id, deleteName, deleteProUser, getAllProUsers, removeFromCompany, page } =
    modalProps

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    closeModal()
  }

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    event.preventDefault()

    // TODO: Call action to delete client, but should wire up with UID first.
    // Question is how they handle delete requests.
    if (removeFromCompany) return removeUserFromCompany({ id })
    if (deleteProUser) return deleteUser({ id, page, getAllProUsers })
    deleteClient({ clientId: id, page: clients.page.current })
  }

  return (
    <Modal
      title={modalName}
      isOpen={modal === 'delete'}
      onRequestClose={closeModal}
      size='small'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Cancel',
          onClick: handleCancel,
          variant: 'tertiary',
        },
        {
          text: 'Confirm',
          variant: 'primary',
          onClick: handleDelete,
        },
      ]}
    >
      <Container>
        <Text>
          Permanently delete <Text weight='bold'>{deleteName}</Text>?
        </Text>
      </Container>
    </Modal>
  )
}

const Container = styled.aside``
