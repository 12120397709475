import { indexById } from 'helpers'
import { filter, pipe } from 'overmind'
import { omit } from 'ramda'
import type { Context } from 'store'
import { userIsSysAdmin } from 'store/operators'
import {
  getAllProfessionals as _getAllProfessionals,
  professionalListIsEmpy,
} from 'store/professionals/operators'
import type { WithOrder, WithProfessionalId } from 'store/types'
import { parseQuotesForTable, pickFilters } from './helpers'
import type {
  Filters,
  GetOtherPaymentDataOptions,
  GetPaymentDataOptions,
  GetQuotesWithProItemsOptions,
  ListQuoteOptions,
  ListRewardInvoicesOptions,
  WithPaginatedData,
  WithPaymentData,
} from './types'

export const getAllProfessionals = pipe(
  userIsSysAdmin(),
  professionalListIsEmpy(),
  _getAllProfessionals,
)

export const getQuotes = <A extends ListQuoteOptions>({ state, effects }: Context, value: A) =>
  effects.payments.api
    .listQuotes(value)
    .then(parseQuotesForTable)
    .then(({ data, ...page }) => {
      state.payments.quotes.hash = data
      state.payments.quotes.page = page
    })

export const getQuotesWithProItems = (
  { state, effects }: Context,
  value: GetQuotesWithProItemsOptions,
) =>
  effects.payments.api
    .listQuotesWithProItems(value)
    .then(parseQuotesForTable)
    .then(({ data, ...page }) => {
      state.payments.quotes.hash = data
      state.payments.quotes.page = page
    })

export const getMethodData = ({ state, effects }: Context) =>
  effects.professionals.api
    .getExternalAccount() //
    .then((data) => {
      state.payments.method = data
    })

export const getProfessionalPayout = <A extends Partial<WithProfessionalId>>(
  { state, effects }: Context,
  { professionalId }: A,
) =>
  effects.payments.api
    .getProfessionalPayout(professionalId) //
    .then((data) => {
      state.payments.professional = {
        ...state.payments.professional,
        ...data,
      }
    })

export const getProfessionalRewards = <A extends Partial<WithProfessionalId>>(
  { effects, state }: Context,
  { professionalId }: A,
) =>
  effects.payments.api
    .getProfessionalRewards(professionalId)
    .then(({ id, paidOut: paid, rewards: total }) => {
      state.payments.professional.id = id
      state.payments.professional.rewards = {
        paid,
        total,
      }
    })

export const getOtherPaymentData = <A extends GetOtherPaymentDataOptions>(
  { effects }: Context,
  value: A,
): Promise<A & WithPaginatedData> =>
  effects.payments.api
    .listOtherPaymentInvoices(value) //
    .then((data) => ({ ...value, data }))

export const getPaymentData = <A extends GetPaymentDataOptions>(
  { effects }: Context,
  value: A,
): Promise<A & WithPaginatedData> =>
  effects.payments.api
    .listPaymentInvoices(value) //
    .then((data) => ({ ...value, data }))

export const getRewardInvoices = ({ effects, state }: Context, value: ListRewardInvoicesOptions) =>
  effects.payments.api
    .listRewardInvoices(value) //
    .then(({ data, ...rest }) => {
      state.payments.rewards.hash = indexById(data)
      state.payments.rewards.page = rest
    })

export const ifFiltersAreDifferent = <A extends Filters>() =>
  filter<any>(({ state }: Context, value: A) => {
    const prev = state.payments.quotes.filters
    const next = { ...prev, ...pickFilters(value) }
    return JSON.stringify(prev) !== JSON.stringify(next)
  })

export const parsePaymentData = <A extends WithPaginatedData>(
  _: Context,
  value: A,
): Omit<A, 'data'> & WithPaymentData => ({
  ...value,
  data: {
    hash: indexById(value.data.data),
    page: omit(['data'], value.data),
  },
})

export const setFilters = <A extends Filters>({ state }: Context, value: A) => {
  const prev = state.payments.quotes.filters
  const next = pickFilters(value)
  state.payments.quotes.filters = { ...prev, ...next }
}

export const setOrder = <A extends WithOrder>({ state }: Context, { order }: A) => {
  state.payments.quotes.order = order
}

export const setPaymentData = <A extends WithPaymentData>({ state }: Context, { data }: A) => {
  state.payments.payment.hash = data.hash
  state.payments.payment.page = data.page
}
