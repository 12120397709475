import styled from 'styled-components'
import { Text } from '@ubnt/ui-components/Text'

import { FlexRow } from '../Flex'

export const BarContainer = styled(FlexRow)`
  grid-area: bar;
  margin-top: 8px;
`

export const Container = styled.article`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'title earned-1'
    'info earned-2'
    'bar bar';

  align-items: center;
`

export const CurrentNumber = styled.span`
  color: ${({ theme }) => theme.colors.Light.color.blue.b6};
`

export const EarnedText = styled(Text)`
  grid-area: earned-1;
  justify-self: end;
`

export const EarnedInfo = styled(Text)`
  grid-area: earned-2;
  justify-self: end;
  color: ${({ theme }) => theme.colors.textColor.black.b45};
`

export const InfoWrapper = styled.div`
  grid-area: info;
`

export const ProgressBar = styled.progress`
  grid-area: bar;
  flex-grow: 1;

  appearance: none;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n3};
  color: ${({ theme }) => theme.colors.Light.color.blue.b6};
  border: none;
  border-radius: 2px;

  height: 8px;
  margin-right: 12px;

  &::-moz-progress-bar {
    background-color: ${({ theme }) => theme.colors.Light.color.blue.b6};
    border-radius: 2px;
  }

  &::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.colors.Light.color.neutral.n3};
    border-radius: 2px;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.Light.color.blue.b6};
    border-radius: 2px;
  }
`

export const TitleRow = styled(FlexRow)`
  grid-area: title;
`
