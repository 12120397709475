import { debounce, pipe, branch } from 'overmind'
import { handleAsyncAction, toast as opToast, whenPropIs } from 'store/operators'
import * as modalActions from 'store/modal/actions'
import * as op from './operators'

import type { WithClientId, WithPage, WithProfessionalId } from 'store/types'
import type { ClientTableType } from 'app/client/constants'
import type { Context } from 'store'

export const updateRefetchTs = ({ state }: Context) => {
  state.clients.clients.refetchTs = Date.now()
}

const handleClientAction = handleAsyncAction('clients')

export const changeProfessional = handleClientAction<
  WithClientId & WithProfessionalId & WithPage<ClientTableType>,
  void
>(
  pipe(
    branch(op.changeProfessional, opToast('Done', 'Professional successfully changed')),
    op.getClients,
  ),
)

export const getClientsIfEmpty = handleClientAction<WithPage<ClientTableType>, void>({
  before: op.clientsIsEmpty(),
  action: op.getClients,
})

export const getClients = handleClientAction<WithPage<ClientTableType>, void>(op.getClients)

export const getDebouncedClients = handleClientAction<WithPage<ClientTableType>, void>({
  before: debounce(600),
  action: op.getClients,
})

interface AddClientOptions extends op.AddClientOptions {
  historyFn: (x: string) => void
}

export const addClient = handleClientAction<AddClientOptions & WithPage<ClientTableType>, void>({
  action: pipe(
    op.removeEmptyFields,
    op.addClient(),
    branch(modalActions.closeModal),
    branch(updateRefetchTs),
    whenPropIs('email', false, {
      true: (_, { clientId, historyFn }) => {
        historyFn(`/clients/${clientId}/add-project`)
      },
      false: opToast('Done', 'New client successfully added'),
    }),
  ),
  errorTitle: 'Add Client Error',
})

export const updateClient = handleClientAction<
  op.UpdateClientOptions & WithPage<ClientTableType>,
  void
>({
  errorTitle: 'Edit client error',
  action: pipe(
    branch(
      op.removeEmptyFields,
      op.updateClient,
      modalActions.closeModal,
      opToast('Done', 'Client successfully updated'),
    ),
    updateRefetchTs,
  ),
})

export const deleteClient = handleClientAction<WithClientId & WithPage<ClientTableType>, void>(
  pipe(
    branch(
      op.deleteClient,
      modalActions.closeModal,
      opToast('Done', 'Client successfully deleted'),
    ),
    updateRefetchTs,
  ),
)

export const addContact = handleClientAction<Omit<op.UpdateClientOptions, 'uploadedTaxFile'>, void>(
  {
    action: pipe(op.addContact, modalActions.closeModal),
    errorTitle: 'Add Contact Error',
  },
)
