import React, { FC } from 'react'
import styled from 'styled-components'
import handleAvatar from 'components/Avatar/helpers'

interface Props {
  className?: string
  name: string[]
  logoUrl: string | null
  imgSize?: number
  fontSize?: number
  fontWeight?: string
  borderRadius?: string
}

export const Avatar: FC<Props> = ({
  className,
  name,
  logoUrl,
  imgSize = 35,
  fontSize = 12,
  fontWeight = 'normal',
  borderRadius = '50%',
}) => {
  const styling = {
    width: `${imgSize}px`,
    height: `${imgSize}px`,
    fontSize: `${fontSize}px`,
    fontWeight: `${fontWeight}` as any,
    borderRadius,
  }

  return (
    <Wrapper className={className} style={styling}>
      {handleAvatar(name, logoUrl)}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  color: ${({ theme }) => theme.colors.Light.color.neutral.n8};

  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
`
