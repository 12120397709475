import { branch, debounce, pipe } from 'overmind'
import { handleAsyncAction, toast as opToast } from 'store/operators'
import { WithPage } from 'store/types'
import * as op from './operators'
import { GetInvitesOptions, Invite, WithId } from './types'

const handleInvitationAction = handleAsyncAction('dashboard')

export const getInvites = handleInvitationAction<GetInvitesOptions, void>(op.getInvites)

export const getSearchedinvites = handleInvitationAction<GetInvitesOptions, void>({
  before: debounce(600),
  action: op.getInvites,
})

export const deleteInvite = handleInvitationAction<WithId & WithPage<Invite>, void>({
  action: pipe(
    branch(op.deleteInvite, opToast('Done', 'Invite successfully deleted')),
    branch(op.getInvites),
  ),
})
