import { FC, Fragment, useCallback, useState } from 'react'
import { useOutsideClick } from '../useOutsideClick'
import { Outer, Overlay, SlideIn, SlideOut } from './styles'
import type { InnerProps, UseSlider } from './types'

/**
 * useSlider is a hook to handle slide-in and slide-out animation
 * It takes a function to be executed after it has slid out
 * and optionally an options object
 *
 * It returns a react functional component, an open function and a close function
 * The component is the wrapper for whatever needs to be animated
 * The functions are the actions to trigger the slide-in and slide-out
 *
 * Use Example:
 *
 * const App = () => {
 *    const { Slider, closeSlider, openSlider } = useSlider(
 *      () => console.log('It\'s closed!'),
 *      { initiallyOpen: false }
 *    )
 *
 *    return (
 *      <>
 *        <Slider>
 *          <aside>Your JSX goes here</aside>
 *        </Slider>
 *        <main>
 *          <p>This is outside of the slider</p>
 *          <button onClick={openSlider}>Click me!</button>
 *      </main>
 *      </>
 *    )
 * }
 */

export const useSlider: UseSlider = (
  onClose,
  { duration = 500, initiallyOpen = true, overlay = false, position = 'right' } = {},
) => {
  const [isIn, setIsIn] = useState(initiallyOpen)

  const closeSlider = useCallback(() => {
    setIsIn(false)
    return setTimeout(onClose, duration)
  }, [onClose])

  const ref = useOutsideClick<HTMLDivElement>(closeSlider)

  const openSlider = useCallback(() => setIsIn(true), [])

  const Slider: FC<Partial<InnerProps>> = useCallback(
    ({ children, width = 400 }) => {
      const Inner = isIn ? SlideIn : SlideOut

      const Comp = overlay ? Overlay : Fragment

      return (
        <Comp>
          <Outer ref={ref} position={position} width={width}>
            <Inner duration={duration} position={position} width={width}>
              {children}
            </Inner>
          </Outer>
        </Comp>
      )
    },
    [duration, isIn, overlay],
  )

  return {
    Slider,
    closeSlider,
    openSlider,
  }
}
