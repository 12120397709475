import styled from 'styled-components'
import { Button } from '@ubnt/ui-components/Button'
import { DateRangePicker as UIDateRangePicker } from '@ubnt/ui-components/DatePicker'

export const DateRangePicker = styled(UIDateRangePicker)`
  > div > div:last-child {
    overflow-y: auto;
  }

  ul [class*='active'] {
    background-color: ${({ theme }) => theme.colors.Light.color.blue.b6} !important;
  }
`

export const Label = styled.span`
  margin-right: 8px;
`

export interface LabelWrapProps {
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between'
}

export const LabelWrap = styled(Button).attrs<LabelWrapProps>((props) => ({
  justify: props.justify || 'center',
}))`
  padding: 0px;
  font-size: 14px;
  justify-content: ${({ justify }) => justify};

  &:hover {
    background: transparent;
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`

export const Toggle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${({ theme }) => theme.colors.Light.color.blue.b6};

  svg {
    margin: 0px 5px;
  }
`
