import { branch, OperatorContextFunction, parallel, pipe } from 'overmind'
import { isNil } from 'ramda'

import { handleAsyncAction, runOnSuccess, whenPropIs } from 'store/operators'
import * as draftOps from '../clientmanagement/operators'
import * as op from './operators'

import type { CreateProjectOptions, WithProjectId } from './types'
import type { Context } from 'store'
import type { WithClientId, WithOnSuccess } from 'store/types'

type GetProjectOptions = WithClientId & WithProjectId

const handleProjectsAction = handleAsyncAction('projects')

export const createOrUpdateProject = handleProjectsAction<CreateProjectOptions, void>(
  pipe(branch(op.extractFiles, op.uploadFiles, op.createOrUpdateProject), runOnSuccess()),
)

export const deleteProject = handleProjectsAction<op.ProjectID & WithOnSuccess>(
  pipe(branch(op.deleteProject), runOnSuccess()),
)

export const resetCurrentProject = ({ state }: Context) => {
  state.projects.current.id = null
  state.projects.current.data = null
}

export const resetProjects = ({ state }: Context) => {
  state.projects.hash = {}
}

export const setCurrentProjectAndFetch = handleProjectsAction<GetProjectOptions & WithOnSuccess>(
  pipe(
    branch<GetProjectOptions & WithOnSuccess, void>(op.getProjectsByClient),
    branch<GetProjectOptions & WithOnSuccess, void>(
      whenPropIs<GetProjectOptions & WithOnSuccess, void>('projectId', isNil, {
        true: () => undefined,
        false: parallel(draftOps.getQuotes, op.getProject) as OperatorContextFunction<
          GetProjectOptions,
          void
        >,
      }),
    ),
    runOnSuccess(),
  ),
)

export type GetProjectOptsAndHistory = GetProjectOptions & { history: (s: string) => void }

type GetProjectsAndContracts = Omit<GetProjectOptsAndHistory, 'projectId'> & Partial<WithProjectId>
export const getProjectsAndContracts = handleProjectsAction<GetProjectsAndContracts, void>(
  pipe(
    branch<GetProjectsAndContracts, void>(op.getProjectsByClient),
    op.passLastProject,
    whenPropIs<GetProjectsAndContracts, void>('projectId', isNil, {
      true: () => undefined,
      false: pipe(
        branch<any, void>(parallel(draftOps.getQuotes, op.getProject)),
        (_, { projectId, history }) => history(projectId as string),
      ),
    }),
  ),
)

export const getProjects = handleProjectsAction<GetProjectOptions, void>(op.getProjectsByClient)

export const resetCreating = ({ state }: Context) => {
  state.projects.creating = null
}
