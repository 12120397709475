import { useOverState } from 'store'
import { Card } from './Card'
import { SectionContainer } from './Wifi'

export const VoIP = () => {
  const { projects } = useOverState()
  const { data } = projects.current
  return (
    <SectionContainer>
      <Card label='Phone Devices' value={data?.voIP.qty} />
      <Card label='Note' value={data?.voIP.note} />
    </SectionContainer>
  )
}
