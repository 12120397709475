import { Text } from '@ubnt/ui-components/Text'
import { FC } from 'react'
import styled from 'styled-components'
import { FulfillmentItem } from 'types'
import { TrackingLink } from './TrackingLink'

interface Props {
  items: FulfillmentItem | FulfillmentItem[]
}
export const FulfillmentList: FC<Props> = ({ items }) => {
  const _items = Array.isArray(items) ? items : [items]
  const { trackingCompany, trackingNumber, trackingUrl } = _items[0]

  return (
    <Container>
      <TrackingLink company={trackingCompany} number={trackingNumber} url={trackingUrl} />

      <List>
        {_items.map((x) => (
          <Item key={x.productVariantId}>
            <Left>
              <Thumbnail src={x.thumbnail} alt='Line item thumbnail' />

              <TextContainer>
                <Text size='body'>{x.sku}</Text>
                {x.isBackorder && (
                  <Text color='warning' style={{ marginTop: '-2px' }}>
                    Extended Lead Time
                  </Text>
                )}
              </TextContainer>
            </Left>

            <Quantity>{`x ${x.qty}`}</Quantity>
          </Item>
        ))}
      </List>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n1};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 12px 22px 4px 16px;
  margin-bottom: 8px;
`

const List = styled.ul``

const Item = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const Quantity = styled(Text)`
  width: 35px;
  text-align: right;
`

const Thumbnail = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 12px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
