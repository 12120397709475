import { Controller, useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import { omit } from 'ramda'

import { FormSection } from './FormSection'
import { NoteText } from './NoteText'
import { Schema } from '../ProjectFormProvider'
import { ErrorMessage } from './styles'
import { ButtonsContainer } from 'components'

export const VoIPForm = () => {
  const { control, setValue, formState, getFieldState, getValues } = useFormContext<Schema>()
  const { error } = getFieldState('voIP.qty', formState)

  const handleChange = (value: number) => setValue('voIP.qty', value)

  useEffect(() => {
    const { voIP } = getValues()
    if (voIP.qty === 0) setValue('voIP.qty', 1)
  }, [])

  return (
    <FormSection main>
      <Controller
        control={control}
        name='voIP.qty'
        render={({ field }) => (
          <ButtonsContainer
            label='Phone Devices'
            min={0}
            onIncrease={handleChange}
            onDecrease={handleChange}
            value={field.value}
          />
        )}
      />
      {error?.message && <ErrorMessage color='danger'>{error?.message}</ErrorMessage>}
      <Controller
        control={control}
        name='voIP.note'
        render={({ field }) => (
          <NoteText
            {...omit(['ref'], field)}
            setValue={(_, value) => field.onChange(value)}
            note={field.value}
          />
        )}
      />
    </FormSection>
  )
}
