import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserRole } from 'shared'

export const useDynamicForm = (schema: any, name: 'invitations') => {
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      invitations: [{ email: '', role: UserRole.MANAGER }],
      // invitations: [{ email: '', role: UserRole.AGENT }], // Change back to AGENT when support agent feature is added.
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })

  return { control, handleSubmit, errors, fields, append, remove }
}
