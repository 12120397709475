import { SearchIcon } from '@ubnt/icons'
import { Checkbox, Text } from '@ubnt/ui-components'
import { FC, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Input } from 'components'
import { escapeRegExp } from 'helpers'
import { ProjectsEntity } from 'store/hardware/types'

interface Props {
  projects: ProjectsEntity[]
  projectId: string
  setProjectId: (projectId: string) => void
}

const Projects: FC<Props> = ({ projects, setProjectId, projectId }) => {
  const [search, setSearch] = useState<string>('')

  const filteredProjects = useMemo(() => {
    if (search.length) {
      return projects.filter((p) => new RegExp(escapeRegExp(search), 'i').test(p.title))
    }
    return projects
  }, [search, projects])

  return (
    <Container>
      <HeaderWrapper>
        <Text style={{ paddingRight: '5px' }} weight='bold' size='body'>
          All Projects
        </Text>
        <SearchWrapper>
          <Input
            placeholder='Type to search'
            setValue={setSearch}
            value={search}
            icon={<SearchIcon />}
          />
        </SearchWrapper>
      </HeaderWrapper>
      <GridContainer>
        {filteredProjects.map((project) => (
          <ProjectCard key={project.id} onClick={() => setProjectId(project.id)}>
            <Checkbox
              id={project.id}
              checked={project.id === projectId}
              onChange={() => (projectId ? setProjectId('') : setProjectId)}
            >
              <Text size='header-xs'>{project.title}</Text>
            </Checkbox>
          </ProjectCard>
        ))}
      </GridContainer>
    </Container>
  )
}

export default Projects

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  > div {
    max-width: 80%;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  height: 100%;
  padding: 4px 16px 4px 0;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
`

const GridContainer = styled.div`
  display: grid;
  grid-auto-rows: 40px;
  grid-row-gap: 5px;
  align-content: flex-start;
  height: 365px;
  padding: 6px 0 6px 0;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

const ProjectCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.Light.color.blue.b1};
  }
`

export const SearchWrapper = styled.div`
  width: 150px;
`
