import { useEffect, useRef } from 'react'

/**
 * Custom hook for scrolling controlled RHF element (using \<Controller />) into view.
 * @param condition Condition that needs to be fulfilled for the scrolling
 * @param dependencies Dependency array that triggers the scrolling whenever any of the dependencies are updated
 * @returns A useRef hook that needs to be assigned to the element which will be scrolled into the view
 */
export const useScrollIntoView = <T extends HTMLElement>(
  condition: boolean,
  dependencies: any[],
) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    if (condition) ref.current?.scrollIntoView()
  }, dependencies)

  return ref
}
