import styled from 'styled-components'
import { Text } from '@ubnt/ui-components/Text'

import { useOverState } from 'store'
import { capitalise } from 'helpers'
import { FlexColumn } from 'components'

import type { FC } from 'react'

export const ShippingAddress: FC = () => {
  const {
    paymentDetails: { data },
  } = useOverState()

  if (!data || !data.order || !data.order.shipping) return <>-</>

  const { address, firstName, lastName, city, provinceCode, postalCode, country } =
    data.order.shipping

  return (
    <Flex>
      <Text size='body'>{`${capitalise(firstName)} ${capitalise(lastName)}`}</Text>
      <Text size='body'>{address.toUpperCase()}</Text>
      <Text size='body'>
        {`${city.toUpperCase()}, ${provinceCode} ${postalCode}`.toUpperCase()}
      </Text>
      <Text size='body'>{country.toUpperCase()}</Text>
    </Flex>
  )
}

const Flex = styled(FlexColumn)`
  margin-top: 8px;
`
