import { emailSchema, nameSchema, phoneSchema } from 'shared'
import * as yup from 'yup'

const clientSchema = (validateEmail: (email?: string | undefined) => boolean | Promise<boolean>) =>
  yup.object({
    name: yup.string().optional(),
    firstName: nameSchema(),
    lastName: nameSchema(),
    email: emailSchema(validateEmail),
    phone: phoneSchema(true),
  })

const taxFileSchema = yup.object({
  key: yup.string(),
  string: yup.string(),
})

export const addClientSchema = (
  validateEmail: (email?: string | undefined) => boolean | Promise<boolean>,
) =>
  yup.object({
    clients: yup.array().of(clientSchema(validateEmail)),
    tax: yup.object({
      exempt: yup.boolean(),
      file: yup.array().of(taxFileSchema),
    }),
    projectSelection: yup.mixed().oneOf(['pro', 'client']),
  })

export const editClientSchema = (
  validateEmail: (email?: string | undefined) => boolean | Promise<boolean>,
) =>
  yup.object({
    clients: yup.array().of(clientSchema(validateEmail)),
    tax: yup.object({
      exempt: yup.boolean(),
      file: yup.array().of(taxFileSchema),
    }),
  })

export const addContactSchema = (
  validateEmail: (email?: string | undefined) => boolean | Promise<boolean>,
) =>
  yup.object({
    clients: yup.array().of(clientSchema(validateEmail)),
  })
