import { Button, Text } from '@ubnt/ui-components'
import { Link } from 'react-router-dom'

import { ErrorLeft, ErrorWrapper } from './styles'
import config from 'config'

import ErrorImg from 'images/404.png'

import type { VFC } from 'react'

interface ErrorProps {
  componentStack: string | null
  error: Error
  inRouter?: boolean
  resetError: () => void
}

export const Error: VFC<ErrorProps> = ({ componentStack, error, inRouter = false, resetError }) => {
  return (
    <ErrorWrapper>
      <ErrorLeft>
        <Text size='header-xl' weight='bold'>
          Oops, something went wrong!
        </Text>

        <Text size='body'>
          Don&apos;t worry, our team has been informed and will look into it as soon as possible.
        </Text>
        <Text size='body'>Meanwhile, you can go back to the main page.</Text>

        {inRouter && (
          <Button<typeof Link> component={Link} to='/' onClick={resetError}>
            Back to main page
          </Button>
        )}
        {inRouter || (
          <Button<'a'>
            component='a'
            variant='primary'
            href='/'
            onClick={resetError}
            className='text-decor'
          >
            Back to main page
          </Button>
        )}

        {(config.ENV === 'local' || config.ENV === 'proto') && (
          <>
            <Text size='header-s'>Error</Text>
            <Text size='body'>{error.toString()}</Text>
            <Text size='header-s'>Component Stack</Text>
            {componentStack?.split(/\s/).map((x) => (
              <Text key={x} size='body'>
                {x}
              </Text>
            ))}
          </>
        )}
      </ErrorLeft>
      <img src={ErrorImg} alt='' className='error-image' />
    </ErrorWrapper>
  )
}
