import { AddSecondaryIcon, MinusSecondaryIcon } from '@ubnt/icons'
import { Button, Text } from '@ubnt/ui-components'
import { isNotNil } from 'ramda'

import { ButtonContainer, ButtonGroup } from './style'

interface Props {
  label: string
  onIncrease: (newValue: number) => void
  onDecrease: (newValue: number) => void
  value: number
  max?: number
  min?: number
}

export const ButtonsContainer = ({ label, onIncrease, onDecrease, value, max, min }: Props) => {
  const isMax = isNotNil(max) && value >= max
  const isMin = isNotNil(min) && value <= min

  return (
    <ButtonContainer>
      <Text color='tertiary'>{label}</Text>
      <ButtonGroup variant='comfy'>
        <Button
          variant='tertiary'
          onClick={() => onDecrease(value - 1)}
          className='btn'
          disabled={isMin}
        >
          <MinusSecondaryIcon size='original' />
        </Button>
        <Button variant='tertiary' disabled className='btn btn--value'>
          <Text color={isMin || isMax ? 'disabled' : 'secondary'}>{value}</Text>
        </Button>
        <Button
          variant='tertiary'
          onClick={() => onIncrease(value + 1)}
          className='btn'
          disabled={isMax}
        >
          <AddSecondaryIcon size='original' />
        </Button>
      </ButtonGroup>
    </ButtonContainer>
  )
}
