import { isEmpty } from 'ramda'
import { FC, useEffect } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { InfoIcon, SettingsIcon } from '@ubnt/icons'
import { Button, Modal, Text, TextArea, Tooltip } from '@ubnt/ui-components'

import { ConditionalTooltip, FlexRow } from 'components'
import { makeTooltipText } from 'components/ConditionalTooltip/helpers'
import { ControlledInputWithUnit } from 'components/ControlledInputWithUnit'
import { AddItemNotification } from 'components/Notifications/AddItem'
import { displayDecimal, formatNumber, isAcceptableNumber, toLocaleCurrency } from 'helpers'
import { checkProItemsEditable } from 'helpers/quote'
import { ReactComponent as _ServiceIcon } from 'images/icons/Service.svg'
import { useActions, useOverState } from 'store'
import { getItemPrice, getItemTax } from './helpers'
import {
  ButtonWrapper,
  Form,
  FormFooter,
  HorizontalDivider,
  NotificationWrapper,
  RowContainer,
} from './styles'
import { TOOLTIP_TEXT } from './constants'

type FormFields = {
  price: string
  tax: string
  note: string
}

const priceErrorMessage = 'Estimated Cost must be greater than or equal to 0.01'
const taxErrorMessage = 'Tax Rate must be a number'

const schema = yup.object().shape({
  price: yup
    .number()
    .typeError(priceErrorMessage)
    .min(0.01, priceErrorMessage)
    .required()
    .test('acceptable-number', 'Must be to no more than 2 decimal places', (x) =>
      x ? isAcceptableNumber(x) : false,
    ),
  tax: yup.number().typeError(taxErrorMessage).min(0).max(100).required(),
  note: yup.string(),
})

export const AddProService: FC = () => {
  const {
    modal,
    modalProps: { item: service, resetSearch },
  } = useOverState().modal
  const { closeModal, setModal } = useActions().modal
  const { addProService } = useActions().clientManagement
  const { currentQuote } = useOverState().clientManagement

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      price: '0',
      tax: '0',
      note: '',
    },
  })

  const priceData = useWatch({ control, name: ['price', 'tax'] })
  const price = Number(priceData[0]) || 0
  const tax = Number(priceData[1]) || 0
  const taxTotal = (price * tax) / 100
  const total = price + taxTotal

  useEffect(() => {
    if (service && currentQuote) {
      reset({
        price: String(getItemPrice(currentQuote, 'proServices', service)),
        tax:
          service.adjusted?.tax ??
          service.tax ??
          getItemTax(currentQuote, 'proServices', service.id),
        note: service.note || service.description || '',
      })
    }
  }, [reset])

  const handleClose = () => {
    closeModal()
    if (resetSearch) resetSearch()
  }

  if (modal === null) return null

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit(onSubmit)()
  }

  const handleChange = (value: string | number): [boolean, any] => {
    return typeof value === 'string' ? [true, formatNumber(value)] : [false, value]
  }

  const onSubmit = (data: FormFields) => {
    if (resetSearch) resetSearch()

    if (currentQuote)
      addProService({
        quoteId: currentQuote.id,
        id: service.id,
        price: Number(data.price),
        tax: Number(data.tax),
        note: data.note,
      })
  }

  const isDisabled = !isEmpty(errors) || !currentQuote || !checkProItemsEditable(currentQuote)

  const renderFooter = () => (
    <FormFooter>
      <FlexRow justify='flex-end' alignItems='flex-end'>
        <Button variant='tertiary' onClick={handleClose}>
          Cancel
        </Button>
        <ConditionalTooltip
          show={isDisabled}
          message={makeTooltipText({ quote: currentQuote, item: 'proService' })}
          position='topRight'
          width='200px'
        >
          <ButtonWrapper>
            <Button variant='primary' onClick={handleSubmit(onSubmit)} disabled={isDisabled}>
              Add to Quote
            </Button>
          </ButtonWrapper>
        </ConditionalTooltip>
      </FlexRow>

      <NotificationWrapper>
        <AddItemNotification item='proService' />
      </NotificationWrapper>
    </FormFooter>
  )

  return (
    <Modal
      title='Add Service to Quote'
      isOpen={modal === 'add_pro_service_to_quote'}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      size='small'
      footer={renderFooter()}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ServiceIcon />
        <RowContainer>
          <Text weight='bold' size='body'>
            {service.name || ''}
          </Text>
          <Button
            Icon={<SettingsIcon variant='fill' />}
            onClick={() =>
              setModal({
                modal: 'add_edit_pro_items',
                props: { editItem: true, serviceItem: service, tab: 'services' },
              })
            }
          >
            Edit Item
          </Button>
        </RowContainer>

        <HorizontalDivider />

        <Text weight='bold' style={{ marginBottom: '12px' }}>
          Order Details
        </Text>
        <RowContainer
          style={{
            gap: '8px',
            paddingBottom: `${errors.price || errors.tax ? '30px' : '0'}`,
          }}
        >
          <ControlledInputWithUnit
            control={control}
            name='price'
            label='Estimated Cost'
            iconBefore={<>$</>}
            invalid={errors.price?.message}
            width='100%'
            onKeyDown={handleEnterKey}
            disabled={!currentQuote}
            handleChange={handleChange}
            // formatValue={(val: number | string) => displayDecimal(val).toString()}
          />

          <ControlledInputWithUnit
            control={control}
            name='tax'
            label='Tax Rate'
            iconAfter={<>%</>}
            invalid={errors.tax?.message}
            width='100%'
            autoFocus
            onKeyDown={handleEnterKey}
            disabled={!currentQuote}
            handleChange={handleChange}
            formatValue={(val: number | string) => displayDecimal(val).toString()}
          />
        </RowContainer>
        <Controller
          control={control}
          name='note'
          render={({ field }) => (
            <TextArea
              {...field}
              label='Notes'
              size='body'
              placeholder='Please provide additional service details that your customer should know.'
              height={75}
              width='100%'
              style={{ margin: '10px 0' }}
              disabled={!currentQuote}
              inputClassName='no-resize-x'
            />
          )}
        />
        <RowContainer>
          <Tooltip description={TOOLTIP_TEXT} position='right' width={150}>
            <Text size='body'>
              Estimated Subtotal <InfoIcon isActive />
            </Text>
          </Tooltip>
          <Text size='body'>{`${toLocaleCurrency(price)}`}</Text>
        </RowContainer>
        <RowContainer>
          <Text size='body'>Tax</Text>
          <Text size='body'>{`${toLocaleCurrency(taxTotal)}`}</Text>
        </RowContainer>
        <RowContainer>
          <Text size='body'>Total</Text>
          <Text size='body' weight='bold'>{`${toLocaleCurrency(total)}`}</Text>
        </RowContainer>
      </Form>
    </Modal>
  )
}

const ServiceIcon = styled(_ServiceIcon)`
  margin: 16px 0;
`
