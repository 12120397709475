import { branch, parallel, pipe } from 'overmind'
import { assoc } from 'ramda'

import { handleAsyncAction, whenPropIs, toast } from 'store/operators'
import * as op from './operators'
import { getMethodData, getProfessionalPayout } from 'store/payments/operators'

import {
  GetPaymentDetails,
  RejectInvoiceOptions,
  UpdateCommissionAmount,
  UpdateCommissionRate,
} from './types'
import type { Context } from 'store'

const handleAction = handleAsyncAction('paymentDetails')

export const get = handleAction<GetPaymentDetails, void>(
  parallel(
    whenPropIs('externalAccount', true, {
      true: getMethodData,
      false: () => undefined,
    }),
    op.getPaymentDetails,
    op.getFulfillments,
  ),
)

export const rejectInvoice = handleAction<RejectInvoiceOptions, void>({
  action: pipe(
    branch<RejectInvoiceOptions, any, void>(
      op.rejectInvocie,
      ({ state }: Context, { id, paidOut }) => {
        if (state.paymentDetails.data?.invoice) {
          state.paymentDetails.data.invoice.paidOut = paidOut
        }
        if (state.payments.payment.hash[id]) {
          state.payments.payment.hash[id] = assoc(
            'paidOut',
            paidOut,
            state.payments.payment.hash[id],
          )
        }
      },
    ),
    getProfessionalPayout,
    toast('Success', 'Invoice Rejected', 'success'),
  ),
})

export const reset = ({ state }: Context) => {
  state.paymentDetails.data = null
  state.paymentDetails.fulfillments = {
    fulfilled: [],
    unfulfilled: [],
  }
}

export const updateCommissionRate = handleAction<UpdateCommissionRate, void>(
  pipe(
    branch(
      op.updateCommissionRate,
      ({ state }: Context, { commission, commissionRate, invoiceId }) => {
        if (state.paymentDetails.data?.invoice) {
          state.paymentDetails.data.invoice.commission = commission
          state.paymentDetails.data.commissionRate = commissionRate
        }
        if (state.payments.payment.hash[invoiceId]) {
          state.payments.payment.hash[invoiceId] = assoc(
            'commission',
            commission,
            state.payments.payment.hash[invoiceId],
          )
        }
      },
    ),
    getProfessionalPayout,
    toast('Success', 'Commission Rate updated', 'success'),
  ),
)

export const updateCommissionAmount = handleAction<UpdateCommissionAmount, void>(
  pipe(
    branch(op.updateCommissionAmount, getProfessionalPayout),
    ({ state }: Context, { invoiceId, commissionAmount }) => {
      if (state.payments.payment.hash[invoiceId]) {
        state.payments.payment.hash[invoiceId] = assoc(
          'commission',
          commissionAmount,
          state.payments.payment.hash[invoiceId],
        )
      }
    },
    toast('Success', 'Commission Amount updated', 'success'),
  ),
)
