import { Loader, Text, Tooltip } from '@ubnt/ui-components'

import { useOverState } from 'store'
import { Amount } from './styles'
import { InfoIcon } from '../InfoIcon'
import { AsyncFuncState, MAX_BACKORDERS } from 'shared'
import texts from './texts.json'

import type { FC } from 'react'

export const Content: FC = () => {
  const { backorders } = useOverState()

  if (backorders.status === AsyncFuncState.LOADING) return <Loader size='tiny' />
  if (backorders.error) return <Text size='body'>{texts.error}</Text>
  return (
    <>
      <Text size='caption' weight='bold'>
        {texts.label}
      </Text>
      <Tooltip
        description={texts.tooltip}
        portal
        position='bottom'
        width={200}
        style={{ display: 'flex' }}
      >
        <InfoIcon />
      </Tooltip>
      <Amount size='caption'>
        {backorders.count ?? 0}/{MAX_BACKORDERS}
      </Amount>
    </>
  )
}
