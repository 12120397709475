import { pipe, branch } from 'overmind'
import { handleAsyncAction, ifPropIs, runOnSuccess, toast } from 'store/operators'
import * as op from './operators'
import type { WithId, WithOnSuccess } from 'store/types'
import type { EditedServices } from './types'
import { isNotEmpty } from 'helpers'

const handleServiceAsyncAction = handleAsyncAction('services')

export const getProServices = handleServiceAsyncAction(op.getProServices)

export const deleteProService = handleServiceAsyncAction<WithId & WithOnSuccess>({
  action: pipe(branch(op.deleteProService), runOnSuccess()),
  errorTitle: 'Delete Service Error',
})

export const bulkUpdateProServices = handleServiceAsyncAction<EditedServices & WithOnSuccess, void>(
  {
    before: ifPropIs('services', isNotEmpty),
    action: pipe(
      op.parseServices,
      op.bulkUpdateProServices,
      runOnSuccess(),
      toast('', 'Saved service update'),
      op.getProServices,
    ),
    errorTitle: 'Submit Services Error',
  },
)
