import { useEffect } from 'react'
import { PanelCard } from '@ubnt/ui-components'
import { Footer, GeneralForm } from './Form'
import { FormStepProps } from './Form/types'
import { Deployment } from './Form/Deployment'
import styled from 'styled-components'
import { Manage } from './Form/Manage'
import { useFormContext } from 'react-hook-form'
import { Schema } from './ProjectFormProvider'

export const Settings = ({ handleNext, handlePrev, project }: FormStepProps) => {
  const { handleSubmit, reset } = useFormContext<Schema>()

  useEffect(() => {
    if (project) {
      reset({
        ...project,
        checked: {
          doorAccess: project?.doors.qty > 0,
          phoneSystem: project?.voIP.qty > 0,
          wifi: project.wifiIndoor.length > 0 || project.wifiIndoor.length > 0,
          camera: project.cameras.qty > 0,
        },
      })
    }
  }, [project])

  const items = [
    {
      id: 'general',
      label: 'General',
      renderContent: () => <GeneralForm />,
      openByDefault: true,
    },
    {
      id: 'deployment',
      label: 'Deployment',
      renderContent: () => <Deployment />,
      openByDefault: true,
    },
    {
      id: 'manage',
      label: 'Manage',
      renderContent: () => <Manage {...project} />,
      openByDefault: true,
    },
  ]

  const filtered = project.id ? items : items.filter((item) => item.id !== 'manage')
  return (
    <PanelContainer className={project.id ? 'card--container' : 'card--container new'}>
      {filtered.map((item) => (
        <PanelCard header={item.label} defaultIsOpen width='100%' key={item.id}>
          {item.renderContent()}
        </PanelCard>
      ))}
      <Footer onNext={handleSubmit(handleNext)} onPrev={handlePrev} />
    </PanelContainer>
  )
}

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 368px;
`
