import { derived } from 'overmind'
import { AsyncFuncState } from 'shared'
import { resetPage } from 'store/helpers'
import { InviteState } from './types'

export const state: InviteState = {
  list: derived(({ hash }: InviteState['hash']) => Object.values(hash)),
  hash: {},
  page: resetPage(),
  status: AsyncFuncState.NULL,
}
