import styled from 'styled-components'
import { Button } from '@ubnt/ui-components/Button'

export const NavWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const NavItem = styled(Button).attrs<{ selected?: boolean }>((props) => ({
  selected: props.selected || false,
  background: props.selected ? `background-color: ${props.theme.colors.Light.color.blue.b1};` : '',
  weight: props.selected ? 'bold' : 'normal',
}))`
  justify-content: flex-start;
  padding: 0px 24px;

  color: ${({ theme }) => theme.colors.textColor.black.b65};
  font-size: 14px;
  font-weight: ${({ weight }) => weight};
  ${({ background }) => background}
  height: 40px;
  border-radius: 0px;

  :active,
  :focus-within,
  :focus {
    box-shadow: none !important;
    border: 0 !important;
    outline: 0 !important;
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`
