import { createOvermind, IContext } from 'overmind'
import { lazy, merge, namespaced } from 'overmind/config'
import {
  createActionsHook,
  createEffectsHook,
  createReactionHook,
  createStateHook,
} from 'overmind-react'

import { app } from './app'
import { backorders } from './backorders'
import { clients } from './clients'
import { clientManagement } from './clientmanagement'
import { hardware } from './hardware'
import { modal } from './modal'
import { news } from './news'
import { paymentDetails } from './paymentDetails'
import { payments } from './payments'
import { payouts } from './payouts'
import { professionals } from './professionals'
import { projects } from './projects'
import { rewards } from './rewards'
import { scores } from './scores'
import { services } from './services'
import { users } from './users'
import { onboarding } from './onboarding'
import { dashboard } from './dashboard'
import { invites } from './invites'
import appConfig from 'config'

import type { Concrete } from 'types'

export const config = merge(
  namespaced({
    app,
    backorders,
    clients,
    clientManagement,
    hardware,
    modal,
    news,
    paymentDetails,
    payments,
    payouts,
    professionals,
    projects,
    rewards,
    scores,
    services,
    users,
    onboarding,
    dashboard,
    invites,
  }),
  lazy({
    admin: () => import('./admin'),
  }),
)

export type Context = IContext<{
  state: Concrete<typeof config.state>
  actions: typeof config.actions
  effects: typeof config.effects
}>

export const overmind = createOvermind(config, {
  devtools: appConfig.ENV === 'local',
})

export const useOverState = createStateHook<Context>()
export const useActions = createActionsHook<Context>()
export const useEffects = createEffectsHook<Context>()
export const useReaction = createReactionHook<Context>()
