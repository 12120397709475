import React, { FC } from 'react'

import { useRole, Roles } from './Roles'
import { UserRole } from 'shared'

const roles = [UserRole.ADMIN, UserRole.SYS_ADMIN]

export const RolesAdmin: FC = ({ children }) => <Roles roles={roles}>{children}</Roles>

export const useAdmin = () => useRole(roles)
