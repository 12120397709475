import { fromThisMonth } from 'helpers'

import { AsyncFuncState } from 'shared'
import type { ScoreState } from './types'

export const state: ScoreState = {
  data: [],
  dates: {
    start: fromThisMonth({}),
    end: new Date(),
  },
  loadingSection: null,
  periodCommission: 0,
  status: AsyncFuncState.SUCCESS,
}
