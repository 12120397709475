import { isNil } from 'ramda'
import { Container, Label, Number } from './styles'
import { displayDecimal, toLocaleCurrency } from 'helpers'

import type { FC } from 'react'
import type { NumberTagProps } from './types'

export const NumberTag: FC<NumberTagProps> = ({
  badge,
  className,
  currency = true,
  label,
  number,
  numberSize = 'body',
  renderPlaceholder,
}) => {
  const numberFn = currency ? toLocaleCurrency : displayDecimal
  const placeholderFn = renderPlaceholder ?? (() => null)

  return (
    <Container className={className}>
      <Label size='body'>{label}</Label>
      {isNil(number) ? (
        placeholderFn()
      ) : (
        <Number size={numberSize} weight='bold'>
          {numberFn(number)}
        </Number>
      )}
      {badge}
    </Container>
  )
}
