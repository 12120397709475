import { AddWithCircleIconL, CloseWithCircleIconL } from '@ubnt/icons'
import { Button, Input, SidePanel, Text, TextArea } from '@ubnt/ui-components'
import { memoizeWith, omit, uniq } from 'ramda'
import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'

import { useSlider } from 'hooks'
import { AsyncFuncState, emailRegex, QuoteStatus } from 'shared'
import { useActions, useEffects, useOverState } from 'store'

import type { ModalType, SendQuoteSidebar } from 'store/modal/types'

const SendToClient: FC = () => {
  const { sendToClient, updateMessage, updateQuote } = useActions().clientManagement
  const { status } = useOverState().clientManagement
  const { clientManagement, app } = useEffects()
  const { closeSidebar, setModal } = useActions().modal
  const { sidebarProps } = useOverState().modal as ModalType<any, SendQuoteSidebar>

  const { currentQuote } = sidebarProps

  const [message, setMessage] = useState(currentQuote.message || '')
  const [emailArray, setEmailArray] = useState<string[]>(currentQuote.recipients)
  const [email, setEmail] = useState('')
  const [invitees, setInvitees] = useState<string[]>([])
  const [emailErrMessage, setErrMessage] = useState('')

  const { Slider, closeSlider } = useSlider(closeSidebar)

  useEffect(() => {
    if (currentQuote.status !== QuoteStatus.COMPLETED)
      clientManagement.api
        .getClientContacts(currentQuote.clientId, { emailOnly: true })
        .then((recipients) => {
          setInvitees(recipients)

          const _recipients = currentQuote.status === QuoteStatus.DRAFT ? recipients : []
          setEmailArray(uniq([..._recipients, ...currentQuote.recipients]))
        })
        .catch(() => null)
  }, [])

  useEffect(() => {
    if (currentQuote.status === 'Draft')
      updateQuote({
        quoteId: currentQuote.id,
        data: {
          clientId: currentQuote.clientId,
          projectId: currentQuote.projectId,
          recipients: emailArray,
        },
      })
  }, [emailArray])

  const validateEmail = memoizeWith(toString, (email?: string) => {
    if (email === '') return true
    if (!email || !emailRegex.test(email.toLowerCase())) {
      setErrMessage('email must be valid email')
      return false
    }
    if (invitees.includes(email)) return true
    return app.api
      .validateEmail({ email, client: true, pro: true, user: true })
      .then(({ isInvalid, message }) => {
        setErrMessage(message)
        return !isInvalid
      })
  })

  const schema = yup.string().email().test('valid-email', '', validateEmail)

  const handleAddEmail = () => {
    if (emailArray.includes(email)) return
    if (!invitees.includes(email)) {
      setModal({
        modal: 'new_contact_found',
        props: {
          modalName: 'New contact is found',
          email,
          resetMail: () => setEmail(''),
          setEmail: (string: string) => setEmail(string),
          setEmailArray: (email: string) => setEmailArray(emailArray.concat(email)),
        },
      })
      return
    }

    setEmailArray(emailArray.concat(email))
    setEmail('')
  }

  const removeEmail = (event: any) =>
    setEmailArray(emailArray.filter((e) => e !== event.target.getAttribute('data-email')))

  const handleMessage = (message: string) => {
    setMessage(message)
    updateMessage({
      quoteId: currentQuote.id,
      data: { clientId: currentQuote.clientId, projectId: currentQuote.projectId, message },
    })
  }

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // if (emailErrMessage || errors.email?.message) return
    if (emailErrMessage) return
    if (event.key === 'Enter' && email) {
      event.preventDefault()
      handleAddEmail()
    }
  }

  const parsedData = useMemo(
    () => ({
      data: {
        ...omit(['hardware', 'proHardware', 'proServices'], currentQuote),
        recipients: emailArray,
        message,
      },
      reSend: currentQuote?.status !== QuoteStatus.DRAFT,
      clientId: currentQuote.clientId,
      quoteId: currentQuote.id,
      onSuccess: () => closeSlider(),
    }),
    [currentQuote, emailArray, message],
  )

  const handleDone = () => {
    sendToClient(parsedData)
  }

  const handleChange = (email: string) => {
    schema.validate(email).catch((x) => x)
    setEmail(email)
  }
  const disabled = !emailArray.length || currentQuote.status === QuoteStatus.COMPLETED

  return (
    <Slider>
      <SidePanel header='Send to Customer' in onClose={closeSlider}>
        <ContentWrapper>
          <EmailsWrapper isLoading={status === AsyncFuncState.LOADING}>
            <Input
              name='email'
              label='Addressee'
              autoComplete='chrome-off'
              autoFocus
              width='100%'
              placeholder='name@email.com'
              value={email}
              invalid={emailErrMessage}
              onChange={(e) => handleChange(e.target.value)}
              onKeyDown={handleEnterKey}
            />
            <IconWrapper disabled={email === ''} isLoading={status === AsyncFuncState.LOADING}>
              <StyledIcon onClick={handleAddEmail} />
            </IconWrapper>
          </EmailsWrapper>
          <EmailList>
            {emailArray.map((email) => (
              <EmailCard key={email}>
                <Text>{email}</Text>
                <StyledCloseWithCircleIconL
                  transparent
                  actionable
                  motif='dark'
                  data-email={email}
                  onClick={removeEmail}
                />
              </EmailCard>
            ))}
          </EmailList>
          <TextArea
            autoFocus={!!emailArray}
            size='body'
            label='Message'
            full
            labelClassName='label'
            placeholder='Leave a message for your customer'
            minLength={5}
            height={150}
            value={message}
            onChange={(e) => handleMessage(e.target.value)}
            inputClassName='no-resize-x'
          />
          <ButtonWrapper>
            <Button variant='tertiary' onClick={closeSlider}>
              Cancel
            </Button>
            <Button
              variant='primary'
              disabled={disabled}
              onClick={handleDone}
              loader={status === AsyncFuncState.LOADING ? 'loading' : undefined}
            >
              Send
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
      </SidePanel>
    </Slider>
  )
}

export default SendToClient

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > * {
    margin-bottom: 8px;
  }
`

const EmailsWrapper = styled.div<{ isLoading: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  ${({ isLoading }) => (isLoading ? 'opacity: 0.5;' : '')}
`

const IconWrapper = styled.div<{ disabled: boolean; isLoading: boolean }>`
  ${({ disabled, isLoading }) =>
    disabled || isLoading ? 'pointer-events: none; opacity: 0.3' : ''}
`

const StyledIcon = styled(AddWithCircleIconL)`
  width: 34px;
  height: 34px;
  background-color: white;
  color: ${({ theme }) => `${theme.colors.Light.color.blue.b6}`};
  :hover {
    color: ${({ theme }) => `${theme.colors.Light.color.blue.b4}`};
    cursor: pointer;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-self: end;
  margin-top: 8px;

  > div {
    margin-left: 10px;
  }
`

const EmailList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;

  > * {
    margin: 0 5px 10px 0;
  }
`

const EmailCard = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 6px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 25px;
`

const StyledCloseWithCircleIconL = styled(CloseWithCircleIconL)`
  margin-left: 3px;

  path,
  circle {
    pointer-events: none;
  }
`
