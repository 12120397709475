import { FC, useContext } from 'react'

import { ProjectFormContext } from '../ProjectContext'
import { FormSection as StyledFormSection, FormSectionProps } from './styles'

export const FormSection: FC<FormSectionProps> = ({ children, ...rest }) => {
  const context = useContext(ProjectFormContext)

  return (
    <StyledFormSection {...rest} renderContext={context.location}>
      {children}
    </StyledFormSection>
  )
}
