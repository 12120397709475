import { state } from './state'
import * as actions from './actions'
import * as effects from './effects'
import * as operators from './operators'

export const invites = {
  state,
  actions,
  operators,
  effects,
}
