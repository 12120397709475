import type { AsyncFuncState, QuoteStatus } from 'shared'
import type { ExternalAccount } from 'store/professionals/types'
import type { PaginatedResponse, PaginationDetails } from 'store/types'
import type {
  Base,
  CommissionTab,
  CumulativeCommission,
  Order,
  QuoteTaxData,
  RewardInvoice,
  SectionPrice,
  User,
} from 'types'

export interface PaymentsState {
  method: ExternalAccount
  payment: PaymentData & {
    list: PaymentInvoice[]
  }
  professional: PaymentProfessional
  professionalId: string
  quotes: QuotesData & {
    list: QuoteTableItem[]
  }
  rewards: RewardsData & {
    list: RewardInvoice[]
  }
  status: AsyncFuncState
  view: Views
}

export interface Invoice {
  id: string
  amount: number
  paymentPeriod: string
  due: number
  number: string
  status: string
}

export interface PaymentData {
  hash: Record<string, PaymentInvoice>
  page: PaginationDetails
}

export interface QuotesData {
  filters: {
    clientId: string | null
    status: QuoteStatus[]
    contains: QuoteItemKey[]
  }
  hash: Record<string, QuoteTableItem>
  order: Order
  page: PaginationDetails
}

export interface RewardsData {
  hash: Record<string, RewardInvoice>
  page: PaginationDetails
}

export type QuoteItemKey = 'hardware' | 'proHardware' | 'proServices'

export interface PaymentProfessional {
  id: string
  cumulativeCommission: CumulativeCommission
  createdAt: string
  rewards: {
    paid: number
    total: number
  }
  upcomingCommissionTalk: number
  upcomingCommissionUnifi: number
}

export interface PaymentInvoice extends Base {
  clientId: string
  clientName: string
  clientFirstName: string
  clientLastName: string
  clientLogo: string
  number: string
  revenue: number
  commission: number
  quoteId: string
  paidOut: PayoutStatus
  paidAt: string
  date: string
  professionalId: string
  status: QuoteStatus
  orderId: string | null
}

export enum PayoutStatus {
  FAILED = 'failed',
  PAID = 'paid',
  PROCESSING = 'processing',
  REFUND_REQUESTED = 'refund requested',
  REJECTED = 'rejected',
}

export enum Views {
  PAYMENT = 'payment',
  CONTRACT_LIST = 'quotes',
  COMMISSION = 'commission-receipt-method',
}

export interface GetPaymentDataOptions {
  [x: string]: any
  start?: string
  end?: string
  page?: number
  professionalId?: string
  type: CommissionTab
}

export interface GetOtherPaymentDataOptions extends GetPaymentDataOptions {
  professionalId: string
}

export interface GetQuotesWithProItemsOptions {
  end?: string
  order?: Order
  page?: number
  professionalId?: string
  start?: string
}

export interface IncomingQuoteTableItem {
  id: string
  quoteName: string
  status: QuoteStatus
  createdAt: string
  updatedAt: string
  client: {
    name: string
    firstName: string
    lastName: string
    logo: string
  }
  handlePayment: boolean
  priceBreakdown: SectionPrice & {
    productVariants: SectionPrice
    proHardware: SectionPrice
    proServices: SectionPrice
  }
  projectId: string
  clientId: string
  professionalUser: User
  taxData: QuoteTaxData
  backorder: boolean
  commissionRate: number
}

export interface ListQuoteOptions {
  clientId?: string | null
  contains?: QuoteItemKey[]
  order?: Order
  page?: number
  status?: QuoteStatus[]
}

export interface ListRewardInvoicesOptions {
  end?: string
  page?: number
  professionalId?: string
  start?: string
}

export type PaginatedIncomingQuoteTableData = PaginatedResponse<IncomingQuoteTableItem[]>
export type PaginatedPaymentData = PaginatedResponse<PaymentInvoice[]>
export type PaginatedQuoteTableData = PaginatedResponse<Record<string, QuoteTableItem>>
export type PaginatedRewardData = PaginatedResponse<RewardInvoice[]>

export interface QuoteTableItem extends Omit<IncomingQuoteTableItem, 'client'> {
  clientName: string[]
  clientLogo: string
}

export interface WithPaginatedData {
  data: PaginatedPaymentData
}

export interface WithPaymentData {
  data: PaymentData
}

export interface WithPayoutData {
  payout: PaymentProfessional
}

export interface WithProjectId {
  projectId?: string | null
}

export type Filters = Partial<QuotesData['filters']>

export interface GetContracts {
  clientId: string
}

export interface SetFilterOptions {
  invoice: PayoutStatus
}

export interface ProItemSalesTotals {
  quotedAmount: number
  amountDue: number
  paymentsReceived: number
}
