import { SidePanel } from '@ubnt/ui-components/SidePanel'
import { Text } from '@ubnt/ui-components/Text'
import { FlexRow, NumberTag } from 'components'
import { NumberTagProps } from 'components/NumberTag/types'
import { renderClient, renderQuoteBadge, renderSpinner } from 'helpers'
import { useSlider } from 'hooks'
import { dropLast } from 'ramda'
import { FC } from 'react'
import { useActions, useOverState } from 'store'
import type { ModalType, ProItemsQuoteDetailsSidebar } from 'store/modal/types'
import { Header, Row } from '../components'
import { QuoteName } from '../components/QuoteName'
import { makeClientAvatar } from '../PaymentDetails/helpers'
import { Container } from '../PaymentDetails/styles'
import { getEvents } from './helpers'

export const ProItemsQuoteDetails: FC = () => {
  const { sidebarProps } = useOverState().modal as ModalType<any, ProItemsQuoteDetailsSidebar>
  const { status } = useOverState().payments
  const actions = useActions()

  const { quote } = sidebarProps

  const handleClose = () => {
    sidebarProps.onClose()
    actions.modal.closeSidebar()
  }
  const { Slider, closeSlider } = useSlider(handleClose)

  const initialNumberTags: NumberTagProps[] = [
    {
      badge: renderQuoteBadge(quote.status),
      label: 'Additional Hardware',
      number: quote.priceBreakdown.proHardware.base,
      numberSize: 'header-xs',
      renderPlaceholder: () => renderSpinner(status),
    },
    {
      badge: renderQuoteBadge(quote.status),
      label: 'Services',
      number: quote.priceBreakdown.proServices.base,
      numberSize: 'header-xs',
      renderPlaceholder: () => renderSpinner(status),
    },
    {
      badge: renderQuoteBadge(quote.status),
      label: 'Tax',
      number: quote.priceBreakdown.tax,
      numberSize: 'header-xs',
      renderPlaceholder: () => renderSpinner(status),
    },

    {
      badge: renderQuoteBadge('Deducted'),
      label: 'Stripe Processing Fees',
      number: quote.priceBreakdown.total * 0.029,
      numberSize: 'header-xs',
      renderPlaceholder: () => renderSpinner(status),
    },
  ]

  const numberTags = dropLast(Number(!quote.handlePayment), initialNumberTags)

  const myIncome = {
    badge: renderQuoteBadge(quote.status),
    label: 'My Income',
    number: quote.priceBreakdown.total,
    renderPlaceholder: () => renderSpinner(status),
  }

  const events = getEvents(quote)

  return (
    <Slider>
      <SidePanel header='Payment Details' in onClose={closeSlider}>
        <Container>
          <FlexRow alignItems='flex-start' justify='space-between' flexWrap='wrap'>
            <NumberTag
              badge={myIncome.badge}
              label={myIncome.label}
              number={myIncome.number}
              numberSize='header-s'
              renderPlaceholder={myIncome.renderPlaceholder}
            />
          </FlexRow>
          <Header numberTags={numberTags} events={events} />
          <Row
            label='Tax Rate'
            value={
              <Text>
                <Text size='body' style={{ marginRight: '10px' }}>{`Hardware: ${String(
                  quote.taxData.proHardware || 0,
                )}%`}</Text>
                <Text size='body'>{`Services: ${String(quote.taxData.proService || 0)}%`}</Text>
              </Text>
            }
            status={status}
          />
          {quote.handlePayment && (
            <Row label='Stripe Processing Fees' value='2.9%' status={status} />
          )}
          <Row
            label='Customer'
            placeholder={renderClient(quote.clientName, quote.clientLogo)}
            value={makeClientAvatar({
              name: quote.clientName.join(' '),
              logo: quote.clientLogo,
              firstName: quote.clientName[0],
              lastName: quote.clientName[1],
            })}
            status={status}
          />
          <Row
            label='Quote'
            placeholder={<QuoteName id={sidebarProps.id} name={quote.quoteName} />}
            status={status}
            value={
              <QuoteName
                id={sidebarProps.id}
                clientId={quote.clientId}
                name={quote.quoteName}
                projectId={quote.projectId}
              />
            }
          />
        </Container>
      </SidePanel>
    </Slider>
  )
}
