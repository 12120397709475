import styled from 'styled-components'
import { ButtonGroup as UIButtonGroup } from '@ubnt/ui-components'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonGroup = styled(UIButtonGroup)`
  background: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  width: 96px;
  border-radius: 4px;
  .btn {
    height: 32px;
    width: 32px;
    > span > svg {
      width: 100%;
      height: 100%;
    }
  }
  .btn--value {
    color: ${({ theme }) => theme.colors.textColor.black.b85};
  }
`
