import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import Options from '@ubnt/ui-components/Dropdown/Options'

import { Search } from './styles'

import { CreatedOption, DropdownOption } from '@ubnt/ui-components/Dropdown'

export interface SearchBarProps {
  initialValue?: string
  onSelect: (value?: string) => void
  options: DropdownOption[]
  className?: string
  width?: number
}

export const SearchBar: FC<SearchBarProps> = ({
  initialValue = '',
  onSelect,
  options,
  className = '',
  width,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [label, setLabel] = useState(options.find((x) => x.value === initialValue)?.label || '')

  const value = useMemo(() => {
    const check = label.toLowerCase()
    return options.find((x) => x.label.toLowerCase() === check)?.value || ''
  }, [label, options])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLabel(event.target.value)
      if (!isOpen) setIsOpen(true)
    },
    [isOpen, setLabel, setIsOpen],
  )

  const handleClear = useCallback(() => {
    setIsOpen(false)
    setLabel('')
    onSelect()
  }, [setIsOpen])

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const handleSelect = useCallback(
    ({ value, label }: DropdownOption | CreatedOption) => {
      setLabel(label)
      setIsOpen(false)
      onSelect(value as string)
    },
    [setIsOpen, setLabel],
  )

  const handleSubmit = useCallback(() => {
    onSelect(value as string)
  }, [value])

  const optionsToRender = useMemo(() => {
    const check = label.toLowerCase()
    return options.filter((x) => x.label.toLowerCase().includes(check))
  }, [label, options])

  return (
    <Search
      className={className}
      width={width}
      onChange={handleChange}
      onClear={handleClear}
      onFocus={handleOpen}
      onSubmit={handleSubmit}
      value={label}
    >
      {/* ts-ignored because TS thinks all the props in Options are required */}
      {/* @ts-ignore */}
      <Options
        isOpen={isOpen}
        onSelect={handleSelect}
        options={optionsToRender}
        value={[value]}
        variant='popout'
        optionContainerClassName='option-container'
      />
    </Search>
  )
}
