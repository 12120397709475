import { useOverState } from 'store'

import type { FC } from 'react'
import type { UserRole } from 'shared'

interface Props {
  roles: UserRole[]
}

export const useRole = (roles: UserRole[]) => {
  const role = useOverState().app.user?.role

  if (!role) return false
  return roles.includes(role)
}

export const Roles: FC<Props> = ({ roles, children }) => {
  const hasAccess = useRole(roles)

  if (!hasAccess) return null
  return <>{children}</>
}
