import { when, OperatorContextFunction } from 'overmind'
import isNil from 'ramda/es/isNil'

import { handleError } from 'store/helpers'
import { AsyncError } from 'store/types'

import type { Context } from 'store'
import type { User } from 'types'
import type { LoadingSection, WithUser } from './types'

export const getUser = ({ state, effects }: Context) =>
  effects.app.api
    .getUser() //
    .then((data) => {
      state.app.user = data
    })

export const login = <A>({ effects }: Context, value: A): Promise<A & WithUser> =>
  effects.app.api
    .login() //
    .then((user) => ({
      ...value,
      user,
    }))

export const parseError = ({ state }: Context, { error }: AsyncError) => {
  const errorMesage = handleError(error)

  state.app.error = {
    code: error?.response?.status || 0,
    message: Array.isArray(errorMesage) ? errorMesage.join(', ') : errorMesage,
  }
}

export const redirectTo =
  (path: string, search?: string) =>
  ({ effects }: Context) =>
    effects.app.location.goTo(path, search)

export const refresh =
  (search?: string) =>
  ({ effects }: Context) =>
    effects.app.location.goTo(window.location.pathname, search)

export const setLoadingSection =
  (section: LoadingSection) =>
  ({ state }: Context) => {
    state.app.loadingSection = section
  }

export const setUser = ({ state }: Context, { user }: WithUser) => {
  state.app.user = user
}

export const setUserTo =
  (value: User | null) =>
  ({ state }: Context) => {
    state.app.user = value
  }

export const whenUserIsLoggedIn = <A>(paths: {
  true: OperatorContextFunction<A, A>
  false: OperatorContextFunction<A, A>
}) => when(({ state }: Context) => !isNil(state.app.user), paths)
