const cssVariables = {
  // Host styles varies according to theme.
  Light: {
    //color palette
    color: {
      blue: {
        b1: '#E5F1FF',
        b2: '#CCE2FF',
        b3: '#99C5FF',
        b4: '#66A9FF',
        b5: '#338CFF',
        b6: '#006FFF',
        b7: '#0059CC',
        b8: '#0048A6',
        b9: '#003880',
        b10: '#002759',
      },
      navy: {
        n1: '#E5EBF5',
        n2: '#CCD8EC',
        n3: '#99B1D8',
        n4: '#668AC5',
        n5: '#3363B1',
        n6: '#003C9E',
        n7: '#00307E',
        n8: '#002767',
        n9: '#001E4F',
        n10: '#001537',
      },
      aqua: {
        a1: '#ECF9FF',
        a2: '#DAF4FF',
        a3: '#B5E9FE',
        a4: '#90DDFE',
        a5: '#6BD2FD',
        a6: '#46C7FD',
        a7: '#389FCA',
        a8: '#2E81A4',
        a9: '#23637F',
        a10: '#194659',
      },
      green: {
        g1: '#EBFAEF',
        g2: '#D7F5E0',
        g3: '#B0EBC1',
        g4: '#88E0A2',
        g5: '#61D683',
        g6: '#39CC64',
        g7: '#2EA350',
        g8: '#258541',
        g9: '#1C6632',
        g10: '#144723',
      },
      lime: {
        l1: '#F9FCEC',
        l2: '#F2F9D9',
        l3: '#E5F3B3',
        l4: '#D9EC8D',
        l5: '#CCE667',
        l6: '#BFE041',
        l7: '#99B334',
        l8: '#7C922A',
        l9: '#607020',
        l10: '#434E17',
      },
      yellow: {
        y1: '#FFFBE8',
        y2: '#FFF8D1',
        y3: '#FFF1A3',
        y4: '#FFE976',
        y5: '#FFE248',
        y6: '#FFDB1A',
        y7: '#CCAF15',
        y8: '#A68E11',
        y9: '#806E0D',
        y10: '#594D09',
      },
      orange: {
        o1: '#FEF6E9',
        o2: '#FDEDD3',
        o3: '#FBDBA7',
        o4: '#F9CA7B',
        o5: '#F7B84F',
        o6: '#F5A623',
        o7: '#C4851C',
        o8: '#9F6C17',
        o9: '#7A5312',
        o10: '#563A0C',
      },
      red: {
        r1: '#FDEBEC',
        r2: '#FCD8D8',
        r3: '#F9B0B2',
        r4: '#F6898B',
        r5: '#F36165',
        r6: '#F03A3E',
        r7: '#C02E32',
        r8: '#9C2628',
        r9: '#781D1F',
        r10: '#541416',
      },
      purple: {
        p1: '#F6EEFC',
        p2: '#ECDCF9',
        p3: '#DABAF4',
        p4: '#C797EE',
        p5: '#B575E9',
        p6: '#A252E3',
        p7: '#8242B6',
        p8: '#693594',
        p9: '#512971',
        p10: '#391D4F',
      },
      neutral: {
        n0: '#FFFFFF',
        n1: '#FAFBFB',
        n2: '#F6F6F8',
        n3: '#EDEDF0',
        n4: '#DBDCE1',
        n5: '#C8CAD3',
        n6: '#B6B9C4',
        n7: '#A4A7B5',
        n8: '#838691',
        n9: '#6B6D76',
        n10: '#52535A',
        n11: '#393A3F',
        n12: '#212124',
      },
    },
    //Shadow Style Effects
    shadowEffects: {
      high: {
        boxShadow: '0px 12px 48px rgba(0, 0, 0, 0.15)',
      },
      low: {
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
      },
    },
  },
  Dark: {
    //color palette
    color: {
      blue: {
        b1: '#CED9E6',
        b2: '#B8CBE6',
        b3: '#8AB1E6',
        b4: '#5C98E6',
        b5: '#2E7EE6',
        b6: '#005ED9',
        b7: '#004CAD',
        b8: '#003D8D',
        b9: '#00306D',
        b10: '#00214C',
      },
      navy: {
        n1: '#CED4DC',
        n2: '#B8C2D4',
        n3: '#8A9FC2',
        n4: '#5C7CB1',
        n5: '#2E599F',
        n6: '#003386',
        n7: '#00296B',
        n8: '#002158',
        n9: '#001A43',
        n10: '#00122F',
      },
      aqua: {
        a1: '#D4E0E6',
        a2: '#C4DCE6',
        a3: '#A3D2E5',
        a4: '#82C7E5',
        a5: '#60BDE4',
        a6: '#3CA9D7',
        a7: '#3087AC',
        a8: '#276E8B',
        a9: '#1E546C',
        a10: '#153C4C',
      },
      green: {
        g1: '#D4E1D7',
        g2: '#C1DCCA',
        g3: '#ADD7B9',
        g4: '#90CFA3',
        g5: '#74C88C',
        g6: '#30AD55',
        g7: '#278B44',
        g8: '#1F7137',
        g9: '#18572B',
        g10: '#113C1E',
      },
      lime: {
        l1: '#E0E3D4',
        l2: '#DAE0C3',
        l3: '#CEDBA1',
        l4: '#C3D47F',
        l5: '#B8CF5D',
        l6: '#A2BE37',
        l7: '#82982C',
        l8: '#697C24',
        l9: '#525F1B',
        l10: '#394214',
      },
      yellow: {
        y1: '#E6E2D1',
        y2: '#E6DFBC',
        y3: '#E6D993',
        y4: '#E6D26A',
        y5: '#E6CB41',
        y6: '#D9BA16',
        y7: '#AD9512',
        y8: '#8D790E',
        y9: '#6D5E0B',
        y10: '#4C4108',
      },
      orange: {
        o1: '#E5DDD2',
        o2: '#E4D5BE',
        o3: '#E2C596',
        o4: '#E0B66F',
        o5: '#DEA647',
        o6: '#D08D1E',
        o7: '#A77118',
        o8: '#875C14',
        o9: '#68470F',
        o10: '#49310A',
      },
      red: {
        r1: '#E4D4D4',
        r2: '#E3C2C2',
        r3: '#E09EA0',
        r4: '#DD7B7D',
        r5: '#DB575B',
        r6: '#CC3135',
        r7: '#A3272B',
        r8: '#852022',
        r9: '#66191A',
        r10: '#471113',
      },
      purple: {
        p1: '#DDD6E3',
        p2: '#D4C6E0',
        p3: '#C4A7DC',
        p4: '#B388D6',
        p5: '#A369D2',
        p6: '#8A46C1',
        p7: '#6F389B',
        p8: '#592D7E',
        p9: '#452360',
        p10: '#301943',
      },
      neutral: {
        n0: '#0E0E0E',
        n1: '#171719',
        n2: '#29292D',
        n3: '#404147',
        n4: '#5C5E66',
        n5: '#7B7D88',
        n6: '#9B9DA7',
        n7: '#AAACB3',
        n8: '#BABBBF',
        n9: '#C9C9CC',
        n10: '#D1D1D3',
        n11: '#E1E2E2',
        n12: '#F2F2F2',
      },
    },
    //Shadow Style Effects
    shadowEffects: {
      high: {
        boxShadow: ' 0px 12px 48px #000000',
      },
      low: {
        boxShadow: '0px 4px 12px #000000',
      },
    },
  },
  // General
  textColor: {
    black: {
      b100: '#000000',
      b85: 'rgba(0, 0, 0, 0.85)',
      b65: 'rgba(0, 0, 0, 0.65)',
      b45: 'rgba(0, 0, 0, 0.45)',
      b20: 'rgba(0, 0, 0, 0.2)',
    },
    white: {
      w100: '#FFFFFF',
      w85: 'rgba(255, 255, 255, 0.85)',
      w65: 'rgba(255, 255, 255, 0.65)',
      w45: 'rgba(255, 255, 255, 0.45)',
      w20: 'rgba(255, 255, 255, 0.2)',
    },
  },

  borderRadius: {
    16: '16px',
    8: '8px',
    4: '4px',
  },
}
export default cssVariables
