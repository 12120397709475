import React, { FC, useState, useEffect } from 'react'
import { Dropdown } from '@ubnt/ui-components/Dropdown'
import Modal from '@ubnt/ui-components/Modal/Modal'
import styled from 'styled-components'
import { useActions, useOverState } from 'store'
import { UserRole, USER_ROLE_OPTIONS } from 'shared/constants'
import { useSysAdmin } from 'components/Roles'

export const EditUserRole: FC = () => {
  const { closeModal } = useActions().modal
  const { modal, modalProps } = useOverState().modal
  const { user, modalName, page } = modalProps

  const [role, setRole] = useState(user.role)
  const { updateUser } = useActions().users
  const isSysAdmin = useSysAdmin()

  useEffect(() => {
    setRole(user.role)
  }, [user])

  const handleSubmit = (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    e.preventDefault()
    updateUser({
      user: { ...user, role },
      ...page,
    })
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <Modal
      title={modalName}
      isOpen={modal === 'edit_role'}
      onRequestClose={closeModal}
      size='small'
      allowOverflow
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Cancel',
          onClick: handleCancel,
          variant: 'tertiary',
        },
        {
          text: 'Update',
          variant: 'primary',
          onClick: handleSubmit,
        },
      ]}
    >
      <Form onSubmit={handleSubmit}>
        <Dropdown
          label='Role'
          options={
            isSysAdmin
              ? USER_ROLE_OPTIONS
              : USER_ROLE_OPTIONS.filter((obj) => obj.label !== UserRole.SYS_ADMIN)
          }
          value={role}
          onChange={(selection) => setRole(selection.value)}
          width={150}
          optionContainerClassName='role_list'
        />
      </Form>
    </Modal>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`
