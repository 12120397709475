const newItem = {
  hardware: 'hardware',
  proHardware: 'hardware',
  proService: 'service',
}
const text = {
  client: 'select a client',
  project: 'create a project and then create a quote',
  quote: 'create or select a quote',
}

export const makeAlertText = (
  item: 'hardware' | 'proHardware' | 'proService',
  type: 'client' | 'project' | 'quote',
) => `In order to add ${newItem[item]}, you must first ${text[type]}`
