import { http } from 'helpers'
import type { Client } from 'types'
import type { PaginatedResponse, WithProfessionalId } from 'store/types'
import type { WithClient } from './operators'
import type { ClientData, TaxCertificate } from 'types/client.type'

export const api = {
  changeProfessional: (clientId: string, { professionalId }: WithProfessionalId) =>
    http.put<Client>(`clients/${clientId}/professional`, { professionalId }),
  getClients: (
    page = 0,
    all = false,
    search?: string,
    sortingColumn = 'username',
    order = 'asc',
  ) => {
    return http.get<PaginatedResponse<Client[]> | Client[]>(`clients`, {
      params: { search, sortingColumn, order, page: !all ? page : null },
    })
  },
  getOneClient: (clientId: string) => http.get<Client>(`clients/${clientId}`),
  getContacts: (clientId: string) => http.get<ClientData[]>(`clients/${clientId}/contacts`),
  addClient: (client: WithClient['client'], email: boolean, uploadedTaxFile: boolean) =>
    http.post<Pick<Client, 'id'>>(`clients?email=${email}&taxEmail=${uploadedTaxFile}`, client),
  addContact: (clientId: string, client: Partial<Client>) =>
    http.post<void>(`users/invite/customer`, { contacts: [{ clientId, ...client }] }),
  updateClient: (clientId: string, client: WithClient['client'], uploadedTaxFile: boolean) =>
    http.put<Client>(`clients/${clientId}?taxEmail=${uploadedTaxFile}`, client),
  uploadTaxCertificate: (data: FormData) =>
    http.post<TaxCertificate>(`clients/upload-tax-certificate`, data),
  deleteClient: (clientId: string) => http.delete<void>(`clients/${clientId}`),
}
