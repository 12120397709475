import { head, isNil, mapObjIndexed, paths, pipe, reject } from 'ramda'

import type { CreateDraftOptions, UpdateDraftOptions, TaxDataState } from './types'
import type { ProTaxData } from 'types'

export const parseTaxData = pipe<
  [CreateDraftOptions | UpdateDraftOptions],
  (TaxDataState | undefined)[],
  any[],
  TaxDataState,
  ProTaxData
>(
  paths([['taxData'], ['data', 'taxData']]),
  reject(isNil),
  head,
  mapObjIndexed((x) => (isNil(x) ? x : Number(x))),
)
