import { isNil, pipe, propSatisfies, reject, sort } from 'ramda'
import { QuoteTableItem } from 'store/payments/types'
import { AdjustIcon, QuoteIcon, SendIcon } from '../PaymentDetails/styles'
import texts from '../PaymentDetails/texts.json'
import type { EventData } from '../PaymentDetails/types'

const findEvents = (quote: QuoteTableItem): EventData[] => [
  {
    date: quote.updatedAt,
    event: texts.events.adjusted,
    Icon: AdjustIcon,
  },
  {
    date: quote.createdAt,
    event: texts.events.sent,
    Icon: SendIcon,
  },
  {
    actor: `${quote.professionalUser.firstName} ${quote.professionalUser.lastName}`,
    date: quote.createdAt,
    event: texts.events.created,
    Icon: QuoteIcon,
  },
]

export const getEvents: (x: QuoteTableItem) => EventData[] = pipe(
  findEvents,
  reject(propSatisfies(isNil, 'date')),
  sort((a, b) => ((a.date as string) <= (b.date as string) ? 1 : -1)),
)
