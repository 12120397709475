import { Button, Input, Text, Tooltip } from '@ubnt/ui-components'
import { InfoIcon, Radio24GIcon } from '@ubnt/icons'
import styled from 'styled-components'

import { FlexColumn, FlexRow } from 'components'
import { talkSubsPrice, TALK_SUBS_COMMISSION_MULTIPLIER } from 'shared'
import texts from './texts'
import { toLocaleCurrency } from 'helpers'

import type { VFC } from 'react'

interface Props {
  talkSubsQty: number
  setTalkSubsQty: React.Dispatch<React.SetStateAction<number>>
  disabled: boolean
}

export const TalkSub: VFC<Props> = ({ talkSubsQty, setTalkSubsQty, disabled }) => {
  return (
    <Container>
      <RowWrapper justify='space-between' alignItems='center'>
        <FlexRow justify='flex-start' alignItems='center' gap={6}>
          <Text weight='bold'>Subscriptions</Text>
          <Tooltip
            className='subscription__tooltip'
            description={texts.tooltip.subscription}
            position='bottom'
            width={240}
          >
            <InfoIcon size='original' isActive />
          </Tooltip>
        </FlexRow>
        <Button
          component='a'
          variant='inline'
          size='small'
          href='https://help.ui.com/hc/en-us/articles/360058776614'
          target='_blank'
        >
          Learn more
        </Button>
      </RowWrapper>
      <StyledFlexColumn>
        <FlexRow>
          <FlexRow justify='flex-start' gap={6}>
            <Radio24GIcon isActive />
            <Text>Subscription</Text>
          </FlexRow>
          <Text color='primary' weight='bold'>{`${toLocaleCurrency(talkSubsPrice)}/month`}</Text>
        </FlexRow>
        <FlexColumn verticalSpacing={10}>
          <Text color={disabled ? 'disabled' : 'tertiary'}>Number of Lines</Text>
          <Input
            disabled={disabled}
            type='number'
            variant='secondary'
            min={0}
            width={188}
            arrowSize='small'
            value={talkSubsQty}
            onChange={(_, value) => setTalkSubsQty(Number(value))}
          />
        </FlexColumn>
        <FlexRow>
          <Text color='primary' weight='bold'>
            Total
          </Text>
          <Text color='primary' weight='bold'>
            {`${toLocaleCurrency(talkSubsQty * talkSubsPrice)}/month`}
          </Text>
        </FlexRow>
      </StyledFlexColumn>
      {talkSubsQty !== 0 && (
        <FlexRow justify='space-between' alignItems='center' style={{ marginTop: '4px' }}>
          <FlexRow width='auto' justify='flex-start' gap={6}>
            <Text>Est Monthly Commission</Text>
            <Tooltip description={texts.tooltip.commission} position='bottomEnd' width={240}>
              <InfoIcon size='original' isActive />
            </Tooltip>
          </FlexRow>
          <Text>
            {toLocaleCurrency(talkSubsQty * talkSubsPrice * TALK_SUBS_COMMISSION_MULTIPLIER)}
          </Text>
        </FlexRow>
      )}
    </Container>
  )
}

const Container = styled(FlexColumn)`
  margin-top: 16px;
`

const RowWrapper = styled(FlexRow)`
  border-top: ${({ theme }) => ` 1px solid ${theme.colors.Light.color.neutral.n3}`};
  padding: 16px 0 4px;

  .subscription__tooltip {
    display: flex;
  }
`

const StyledFlexColumn = styled(FlexColumn)`
  border: ${({ theme }) => ` 1px solid ${theme.colors.Light.color.neutral.n3}`};
  border-radius: 8px;
  padding: 12px;
`
