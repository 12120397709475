import { omit } from 'ramda'
import { useState } from 'react'
import { FieldArrayWithId, useFormContext } from 'react-hook-form'
import { Schema } from '../ProjectFormProvider'
import { BubbleContainer } from './styles'
import { NewWifi } from './types'
import { WifiBubbleFields } from './WifiBubbleFields'

interface Props<A extends 'wifiIndoor' | 'wifiOutdoor' = 'wifiIndoor' | 'wifiOutdoor'> {
  fields: FieldArrayWithId<Schema, A, 'id'>[]
  name: A
  update: (index: number, object: FieldArrayWithId<Schema, A, 'id'>) => void
}
export interface DataUrl {
  url: string
  name: string
}

export const AreaGroup = ({ fields, name, update }: Props) => {
  const [urls, setUrls] = useState<(DataUrl | undefined)[]>([])
  const { clearErrors } = useFormContext<Schema>()

  const handleLoadPlan = (index: number, previous: NewWifi) => (file: File, dataUrl?: string) => {
    update(index, { ...previous, plan: { name: file.name, file: file }, size: file.size })
    if (dataUrl) {
      setUrls((prev) => {
        const next = [...prev]
        next[index] = { url: dataUrl, name: file.name }
        return next
      })
    }
  }

  const handleRemovePlan = (index: number, previous: NewWifi) => {
    clearErrors(`wifiIndoor.${index}.size`)
    if (previous.plans.length > 0) {
      return update(index, { ...previous, plans: [], size: 0 })
    }
    update(index, omit(['plan'], previous))
    setUrls((prev) => {
      const next = [...prev]
      next[index] = undefined
      return next
    })
  }

  if (fields.length === 0) return null

  return (
    <BubbleContainer headerTitle='' headerContentClassName='hidden'>
      {fields.map((field, index) => {
        return (
          <WifiBubbleFields
            key={field.id}
            index={index}
            name={name}
            field={field}
            handleLoadPlan={handleLoadPlan}
            handleRemovePlan={handleRemovePlan}
            urls={urls}
          />
        )
      })}
    </BubbleContainer>
  )
}
