import { derived } from 'overmind'
import { AsyncFuncState } from 'shared'
import type { ProjectsState } from './types'

export const state: ProjectsState = {
  creating: null,
  current: {
    id: null,
    data: null,
  },
  hash: {},
  list: derived(({ hash }: ProjectsState) => Object.values(hash)),
  status: AsyncFuncState.SUCCESS,
}
