import styled, { css } from 'styled-components'
import {
  CheckmarkIconL,
  MailsCloseIconL,
  NotificationIconL as UINotificationIconL,
  PaymentMethodIconL,
  QuoteIconL,
  SkipBackwardIconL,
} from '@ubnt/icons'

import { FlexRow } from 'components'

const detailField = css`
  padding: 16px 0px;
`

const iconMargin = css`
  margin-right: 14px;
`

export const AdjustIcon = styled(SkipBackwardIconL)`
  ${iconMargin}
  color: ${({ theme }) => theme.colors.Light.color.blue.b6};
`

export const NotificationIconL = styled(UINotificationIconL)`
  margin: 12px 12px 12px 0px;
`

export const CheckIcon = styled(CheckmarkIconL)`
  ${iconMargin}
  color: ${({ theme }) => theme.colors.Light.color.green.g6};
`

export const Container = styled.section`
  padding: 24px 28px;
`

export const DetailLink = styled.a`
  color: ${({ theme }) => theme.pallette.blue.main};
  text-decoration: none;
  margin-right: 4px;
`

export const DetailRow = styled(FlexRow)`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  ${detailField}
`

export const DetailRowCol = styled.section`
  display: flex;
  flex-direction: column;
  ${detailField}
`

export const HorizontalLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  margin-bottom: -16px;
`

export const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n4};
  margin-left: 9px;
  height: 15px;
  width: 1px;
`

export const PaymentIcon = styled(PaymentMethodIconL)`
  ${iconMargin}
  color: ${({ theme }) => theme.colors.Light.color.green.g6};
`

export const QuoteIcon = styled(QuoteIconL)`
  ${iconMargin}
  color: ${({ theme }) => theme.colors.Light.color.neutral.n7};
`

export const SendIcon = styled(MailsCloseIconL)`
  ${iconMargin}
  color: ${({ theme }) => theme.colors.Light.color.neutral.n7};
`
