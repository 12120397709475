import { Text } from '@ubnt/ui-components/Text'

import { BarContainer, CurrentNumber, ProgressBar } from './styles'

import type { FC } from 'react'
import type { ProgressBarProps } from './types'

export const Bar: FC<ProgressBarProps> = ({ current, max }) => (
  <BarContainer>
    <ProgressBar max={max} value={current} />
    <Text size='label' weight='bold' style={{ letterSpacing: 1 }}>
      <CurrentNumber>{current}</CurrentNumber>
      {`/${max}`}
    </Text>
  </BarContainer>
)
