import { pipe } from 'overmind'

import { handleAsyncAction } from 'store/operators'

import type { Context } from 'store'
import type { News } from './types'

// Sync actions

export const setNews = ({ state }: Context, news: News[]) => {
  state.news.list = news
}

export const reset = ({ state }: Context) => {
  state.news.list = []
}

// Async actions

const handleAction = handleAsyncAction('news')

export const list = handleAction<void>({
  action: pipe(({ effects }: Context) => effects.news.api.list(), setNews),
  toastOnError: false,
})

export const read = handleAction<void>({
  action: pipe(
    ({ state }: Context) => state.news.list.map(({ id }) => id),
    ({ effects }: Context, ids) => effects.news.api.read(ids),
  ),
  toastOnError: false,
})
