import { Camera } from './Overview/Camera'
import { Door } from './Overview/Door'
import { General } from './Overview/General'
import { VoIP } from './Overview/VoIP'
import { Wifi } from './Overview/Wifi'

export const items = [
  {
    id: 'wifi',
    label: 'WiFi',
    renderContent: () => <Wifi />,
    openByDefault: true,
  },
  {
    id: 'camera',
    label: 'Camera',
    renderContent: () => <Camera />,
    openByDefault: true,
  },
  {
    id: 'doorAccess',
    label: 'Door Access',
    renderContent: () => <Door />,
    openByDefault: true,
  },
  {
    id: 'phoneSystem',
    label: 'Phone System',
    renderContent: () => <VoIP />,
    openByDefault: true,
  },
  {
    id: 'general',
    label: 'General',
    renderContent: () => <General />,
    openByDefault: true,
  },
]
