import { compose, curry, lensIndex, lensProp, pipe, replace, times, where } from 'ramda'
import { v4 as uuid } from 'uuid'

import { Badge, BadgeProps } from 'components/Badge'
import { isInEmum, isNotEmpty, truncateStart } from 'helpers'

import { BuildingType, BuildingWiFi, ProjectSize, Size } from 'store/projects/types'
import type { NewProject, NewWifi } from './types'

export const truncatePlanName = pipe<string[], string, string>(
  replace(/^.+\//, ''),
  truncateStart(12),
)

export const extractFileName = (path: string) => path.match(/[ \w-]+?(?=\.)/)

const BUILDING_SIZE_DICT: Record<ProjectSize, string> = {
  [ProjectSize.SMALL]: '<2,000 Sq.Ft',
  [ProjectSize.MEDIUM]: '2,000-5,000 Sq.Ft',
  [ProjectSize.LARGE]: '5,000-10,000 Sq.Ft',
  [ProjectSize.XLARGE]: '>10,000 Sq.Ft',
}

const USER_DICT: Record<Size, string> = {
  [Size.XSMALL]: '< 25',
  [Size.SMALL]: '25-50',
  [Size.MEDIUM]: '50-100',
  [Size.LARGE]: '100-250',
  [Size.XLARGE]: '> 250',
}

const makeNew = (index: number): NewWifi => {
  const name = index + 1

  return {
    id: uuid(),
    name,
    area: 0,
    roomQty: 0,
    note: '',
    plans: [],
    plan: undefined,
  }
}

interface RenderBadgeOptions {
  type: BadgeProps['type']
  size?: BadgeProps['size']
}

export const renderBadge = curry(
  ({ type, size = 'medium' }: RenderBadgeOptions, value: string | number) => (
    <Badge type={type} size={size}>
      {value}
    </Badge>
  ),
)

export const sizeToBuildingSize = (size: ProjectSize) => BUILDING_SIZE_DICT[size]

export const sizeToUserQty = (size: Size) => USER_DICT[size]

export const updateWifiArray =
  (length: number) =>
  (list: BuildingWiFi[]): BuildingWiFi[] =>
    times((idx) => list[idx] ?? makeNew(idx), length)

type ValidateProject = (project: NewProject) => boolean
export const validateProject: ValidateProject = where({
  name: isNotEmpty,
  location: isNotEmpty,
  buildingSize: isInEmum(ProjectSize),
  buildingType: isInEmum(BuildingType),
  userQty: isInEmum(Size),
})

interface WiFiLens {
  wifiIndoor: BuildingWiFi[]
  wifiOutdoor: BuildingWiFi[]
}

export const wifiLens = <
  A extends WiFiLens,
  B extends keyof WiFiLens,
  C extends 'id' | 'name' | 'area' | 'roomQty' | 'plans' | 'note',
>(
  type: B,
  index: number,
  prop: C,
) => compose(lensProp<A, B>(type), lensIndex<BuildingWiFi>(index), lensProp<BuildingWiFi, C>(prop))
