import { derived } from 'overmind'

import { ServicesState } from './types'
import { AsyncFuncState } from 'shared'

export const state: ServicesState = {
  services: {
    hash: {},
    list: derived(({ hash }: ServicesState['services']) => Object.values(hash)),
  },

  status: AsyncFuncState.NULL,
}
