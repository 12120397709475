import { fromThisMonth } from 'helpers'

const now = new Date()

export const presets = [
  {
    label: 'This Month',
    range: {
      startDate: fromThisMonth(0),
      endDate: fromThisMonth({ months: 1, days: -1 }),
    },
  },
  {
    label: 'Last Month',
    range: {
      startDate: fromThisMonth({ months: -1 }),
      endDate: fromThisMonth({ days: -1 }),
    },
  },
  {
    label: 'Last 3 Months',
    range: {
      startDate: fromThisMonth({ months: -2 }),
      endDate: now,
    },
  },
  {
    label: 'All Time',
    range: {
      startDate: new Date(0),
      endDate: fromThisMonth({ months: 3 }),
    },
  },
]
