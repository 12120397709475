import { FlexColumn as FlexColumnComp, FlexRow } from 'components'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
  isNote?: boolean
}

export const CardContainer = ({ children, isNote }: Props) => {
  if (isNote) return <FlexColumn>{children}</FlexColumn>
  return <FlexRow>{children}</FlexRow>
}

const FlexColumn = styled(FlexColumnComp)`
  .card__tooltip {
    word-break: break-all;
  }
`
