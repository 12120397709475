import { isNil } from 'ramda'

import { Bar } from './Bar'
import { Earned } from './Earned'
import { Title } from './Title'
import { Container, InfoWrapper } from './styles'

import type { FC } from 'react'
import type { ProgressProps } from './types'

export const Progress: FC<ProgressProps> = ({
  className,
  current,
  earned,
  max,
  renderEarned,
  renderIcon,
  renderInfo,
  showBar = true,
  title,
  tooltip,
}) => {
  const renderEarnedSection = renderEarned
    ? renderEarned
    : isNil(earned)
    ? () => null
    : () => <Earned earned={earned} />

  return (
    <Container className={className}>
      <Title renderIcon={renderIcon} title={title} tooltip={tooltip} />
      {renderInfo && <InfoWrapper>{renderInfo()}</InfoWrapper>}
      {renderEarnedSection()}
      {showBar && <Bar current={current} max={max} />}
    </Container>
  )
}
