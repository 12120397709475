import { Text } from '@ubnt/ui-components/Text'
import { FileUploader } from '../FileUploader'
import { ChangeEvent, FC } from 'react'
import { Container, UploadText, Wrapper } from './styles'
import { Thumbnail } from './Thumbnail'
import { makeAwsUrl } from 'helpers/http'

interface Props {
  label?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  text: string
  files: { key: string; thumb?: string }[]
  onClose?: (x: string) => void
  readOnly?: boolean
  makeUrl?: (x: string) => string
  accept?: string
}

export const File: FC<Props> = ({
  label = false,
  onChange,
  text,
  files,
  onClose,
  readOnly = false,
  makeUrl = makeAwsUrl,
  accept,
}) => {
  return (
    <Wrapper className='bubble-field'>
      {label && <Text size='label'>Upload</Text>}
      {files.length > 0 && (
        <Container>
          {files.map(({ key, thumb }) => (
            <Thumbnail
              key={key}
              url={key}
              thumb={thumb}
              onClose={onClose ? () => onClose(key) : undefined}
              makeUrl={makeUrl}
            />
          ))}
        </Container>
      )}
      {!readOnly && files.length === 0 && (
        <FileUploader className='bubble-field' onChange={onChange} accept={accept}>
          <UploadText size='body'>{text}</UploadText>
        </FileUploader>
      )}
    </Wrapper>
  )
}
