import { Text } from '@ubnt/ui-components'

import { FlexColumn, RouterLink } from 'components'

import type { QuoteNameProps } from '../PaymentDetails/types'
import type { VFC } from 'react'

export const QuoteName: VFC<QuoteNameProps> = ({ id, clientId, name, projectId }) => {
  if (!id || !clientId || !projectId) return null
  return (
    <FlexColumn alignItems='flex-end'>
      <Text size='body' truncate style={{ width: '200px', textAlign: 'right' }}>
        {name}
      </Text>
      <Text size='caption'>
        <RouterLink
          to={`/marketplace/hardware/client/${clientId}/project/${projectId}/quote/${id}`}
        >
          View
        </RouterLink>
      </Text>
    </FlexColumn>
  )
}
