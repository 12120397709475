import { SettingsIconL, OverviewIcon } from '@ubnt/icons'

import { emptyLocation } from 'shared'
import { Settings } from './Settings'
import { TabItem, TabText } from './styles'
import { Overview } from './Overview'

import type { FC } from 'react'
import type { FormStepProps, NewProject } from './Form/types'

export const formMap: Record<string, FC<FormStepProps>> = {
  overview: Overview,
  settings: Settings,
}

export const makeNewProject = (): NewProject => ({
  name: '',
  location: emptyLocation,
  buildingSize: undefined,
  buildingType: undefined,
  userQty: undefined,
  wifiIndoor: [],
  wifiOutdoor: [],
  cameras: {
    qty: 0,
    note: '',
  },
  voIP: {
    qty: 0,
    note: '',
  },
  doors: {
    qty: 0,
    note: '',
  },
})

export const tabs = [
  {
    value: 'overview',
    label: (
      <TabItem>
        <OverviewIcon size='large' />
        <TabText size='label'>Overview</TabText>
      </TabItem>
    ),
  },
  {
    value: 'settings',
    label: (
      <TabItem>
        <SettingsIconL size='large' />
        <TabText size='label'>Settings</TabText>
      </TabItem>
    ),
  },
]
