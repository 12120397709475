import { Tab } from '@ubnt/ui-components'

export const emptyHardware = {
  id: '',
  name: '',
  manufacturer: '',
  category: '',
  description: '',
  price: '0',
}
export const emptyService = {
  id: '',
  name: '',
  description: '',
  price: '0',
}

export const tabs: Tab[] = [
  { id: 'hardware', label: 'Hardware' },
  { id: 'services', label: 'Services' },
]

export const HW_CATEGORIES = [
  'Computer - Desktop',
  'Alarm System',
  'Battery',
  'Cables',
  'Camera',
  'Communication Technology',
  'Computer - Laptop',
  'Door Lock',
  'Doorbell',
  'Firewall',
  'Home Theater',
  'LED',
  'Modem',
  'Multimedia Device',
  'Network Video Recorder',
  'NAS',
  'PoE',
  'Power Strip',
  'Printer',
  'Projector',
  'Router',
  'Security System',
  'Server',
  'Smart Lighting',
  'Smart Speaker',
  'Smart TV',
  'Smartphone',
  'Software Application',
  'Soundbar',
  'Storage',
  'Switch',
  'Tablet',
  'Thermotstat',
  'VoIP Phone',
  'Wireless Access Point',
]

export const SERVICES = ['Installation']
