import { AsyncFuncState } from 'shared'
import type { PaymentDetailsState } from './types'

export const state: PaymentDetailsState = {
  data: null,
  status: AsyncFuncState.SUCCESS,
  fulfillments: {
    fulfilled: [],
    unfulfilled: [],
  },
}
