import styled from 'styled-components'
import { Text } from '@ubnt/ui-components/Text'

export const TabItem = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
`

export const TabText = styled(Text)`
  color: inherit;
`

export const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  .sidebar-header {
    margin-bottom: 20px;
  }
  .new {
    > div:first-child {
      margin-top: 14px;
    }
  }
`
