import { indexById } from 'helpers'
import { omit } from 'ramda'
import { Context } from 'store'
import { GetInvitesOptions, WithId } from './types'

export const getInvites = ({ state, effects }: Context, opts: GetInvitesOptions) =>
  effects.invites.api.getInvites(opts).then((data) => {
    state.invites.hash = indexById(data.data)
    state.invites.page = omit(['data'], data)
  })

export const deleteInvite = <A extends WithId>({ effects }: Context, { id }: A) =>
  effects.invites.api.deleteInvite(id)
