import { SidePanel } from '@ubnt/ui-components/SidePanel'
import { NumberTagProps } from 'components/NumberTag/types'
import {
  dateOrDefault,
  dateOrEmpty,
  isNotEmpty,
  renderClient,
  renderPayoutBadge,
  renderQuoteBadge,
  renderSpinner,
} from 'helpers'
import { useSlider } from 'hooks'
import { isNil } from 'ramda'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useActions, useOverState } from 'store'
import type { ModalType, PaymentDetailsSidebar } from 'store/modal/types'
import { Header } from '../components/Header'
import { InfoBubble } from '../components/InfoBubble'
import { QuoteName } from '../components/QuoteName'
import { Row } from '../components/Row'
import { CommissionRate } from './CommissionRate'
import { Fulfillments } from './Fulfillments'
import { getEvents, getShippingStatus, makeClientAvatar } from './helpers'
import { ShippingAddress } from './ShippingAddress'
import { Container, HorizontalLine } from './styles'

export const PaymentDetails: FC = () => {
  const { sidebarProps } = useOverState().modal as ModalType<any, PaymentDetailsSidebar>
  const { app, paymentDetails, payments } = useOverState()
  const actions = useActions()

  const handleClose = () => {
    sidebarProps.onClose()
    actions.modal.closeSidebar()
  }
  const { Slider, closeSlider } = useSlider(handleClose)

  useEffect(() => {
    actions.paymentDetails.get({
      externalAccount: app.user?.professional.isStripeEnabled ?? false,
      quoteId: sidebarProps.id,
    })
    return actions.paymentDetails.reset
  }, [])

  const { placeholders } = sidebarProps

  if (isNil(paymentDetails.data)) return null

  const numberTags: NumberTagProps[] = [
    {
      badge: paymentDetails.data?.invoice
        ? renderPayoutBadge(paymentDetails.data.invoice.paidOut)
        : undefined,
      label: 'My Commission',
      number: paymentDetails.data?.invoice?.commission || 0.0,
      numberSize: 'header-s',
      renderPlaceholder: () => renderSpinner(paymentDetails.status),
    },
    {
      badge: paymentDetails.data ? renderQuoteBadge(paymentDetails.data.status) : undefined,
      label: 'Quote Total',
      number: paymentDetails.data?.total,
      numberSize: 'header-xs',
      renderPlaceholder: () => renderSpinner(paymentDetails.status),
    },
  ]

  const events = getEvents(paymentDetails.data)

  const { fulfilled, unfulfilled } = paymentDetails.fulfillments

  return (
    <Slider>
      <SidePanel header='Payment Details' in onClose={closeSlider}>
        <Container>
          <Header infoBubble={<InfoBubble />} numberTags={numberTags} events={events} />
          <Row
            label='Commission Rate'
            status={paymentDetails.status}
            value={
              <CommissionRate
                rate={paymentDetails.data?.commissionRate}
                paidOut={paymentDetails.data?.invoice?.paidOut}
                professionalId={sidebarProps.professionalId}
                quoteId={sidebarProps.id}
              />
            }
          />
          <Row
            label='Date Created'
            placeholder={dateOrEmpty(placeholders?.createdAt)}
            value={dateOrDefault(paymentDetails.data?.createdAt)}
            status={paymentDetails.status}
          />
          <Row
            label='Customer'
            placeholder={
              placeholders?.clientName && placeholders.clientLogo
                ? renderClient(placeholders.clientName, placeholders.clientLogo)
                : null
            }
            value={makeClientAvatar(paymentDetails.data?.client)}
            status={paymentDetails.status}
          />
          <Row
            label='Quote'
            placeholder={<QuoteName id={sidebarProps.id} name={placeholders?.quoteName} />}
            status={paymentDetails.status}
            value={
              <QuoteName
                id={sidebarProps.id}
                clientId={paymentDetails.data?.clientId}
                name={paymentDetails.data?.quoteName}
                projectId={paymentDetails.data?.projectId}
              />
            }
          />
          <Row label='Payment' value={payments.method.number} status={paymentDetails.status} />
          <Row
            label='Shipment Status'
            value={getShippingStatus(paymentDetails.data?.order)}
            status={paymentDetails.status}
          />
          <Row
            column
            label='Shipping Address'
            value={<ShippingAddress />}
            status={paymentDetails.status}
          />

          <HorizontalLine />
          <Row
            column
            value={
              <>
                {(isNotEmpty(fulfilled) || isNotEmpty(unfulfilled)) && (
                  <Fulfillments fulfilled={fulfilled} unfulfilled={unfulfilled} />
                )}
              </>
            }
            status={paymentDetails.status}
          />
        </Container>
      </SidePanel>
    </Slider>
  )
}
