import * as op from './operators'
import { handleAsyncAction } from 'store/operators'
import { handleError } from 'store/helpers'

import type { Context } from 'store'
import type { AsyncError, Count } from 'store/types'

const handleAction = handleAsyncAction('backorders')

const setError = ({ state }: Context, { error }: AsyncError) => {
  state.backorders.error = handleError(error)
}

export const count = handleAction<void>({
  action: op.getCount,
  onError: setError,
  toastOnError: false,
})

export const updateCount = ({ state }: Context, { count }: Count) => {
  state.backorders.count = count
  state.backorders.error = null
}
