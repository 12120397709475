import { branch, OperatorContextFunction, parallel, pipe } from 'overmind'

import * as op from './operators'
import { handleAsyncAction, toastFromProps } from 'store/operators'

import { AsyncFuncState } from 'shared'
import type { ProfessionalScore, WithAmount } from './types'
import type { AsyncError, WithDateRange } from 'store/types'
import type { Context } from 'store'

const handleAsyncScoreAction = handleAsyncAction('scores')

export const getScores = handleAsyncScoreAction<WithDateRange, void>(
  parallel(op.getScores, op.getPeriodCommission),
)

export const topUp = handleAsyncScoreAction<WithAmount, void>(pipe(op.topUp, toastFromProps))

export const updateScoreDataAndRefetch = handleAsyncScoreAction<
  op.UpdateScoreData & WithDateRange,
  void
>({
  before: branch(op.setLoadingSection('button')),
  after: branch(op.setLoadingSection(null)) as OperatorContextFunction<
    AsyncError | void,
    AsyncError | void
  >,
  action: pipe(branch<op.UpdateScoreData & WithDateRange, void>(op.updateScoreData), op.getScores),
})

export const updateOneScoreAndRefetch = handleAsyncScoreAction<
  op.UpdateOneScore & WithDateRange,
  void
>(pipe(branch<op.UpdateOneScore & WithDateRange, void>(op.updateOneScore), op.getScores))

export const resetData = ({ state }: Context) => {
  state.scores.data = []
  state.scores.periodCommission = 0
}

export const setData = ({ state }: Context, value: ProfessionalScore[]) => {
  state.scores.data = value
}

export const setDates = ({ state }: Context, value: WithDateRange) => {
  state.scores.dates = value
}

export const setStatus = ({ state }: Context, status: AsyncFuncState) => {
  state.scores.status = status
}
