import { Input as UIInput } from '@ubnt/ui-components/Input'
import styled from 'styled-components/macro'

export const Form = styled.form`
  font-family: Lato;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  > div {
    margin-bottom: 15px;
  }
`

export const Input = styled(UIInput)`
  height: 34px;
  width: 100%;
  font-weight: normal;
  font-size: 13px;
  color: #a4a7b5;
  margin-bottom: 40px !important;
  > input {
    margin: 0;
  }
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const RadioContainer = styled.div`
  height: 34px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
`

export const FormFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  > div > div:first-child {
    margin-right: 10px;
  }
`

export const TextWrapper = styled.div`
  margin-left: 23px;
`
