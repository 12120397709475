import { Dropdown, DropdownBaseProps } from '@ubnt/ui-components'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'

type Props<A extends FieldValues = FieldValues> = UseControllerProps<A> &
  Omit<DropdownBaseProps, 'onChange'>

export const ControlledDropdown = <A extends FieldValues = FieldValues>({
  control,
  name,
  ...restProps
}: Props<A>) => {
  const {
    field: { onChange, ...inputProps },
    //fieldState: { invalid, isTouched, isDirty },
    //formState: { touchedFields, dirtyFields },
  } = useController<A>({
    name,
    control,
  })

  return <Dropdown {...inputProps} {...restProps} onChange={(val) => onChange(val.value)} />
}
