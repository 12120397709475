import { http } from 'helpers'
import type {
  AddProHardwareOpts,
  AddProServiceOpts,
  ClientContactOptions,
  CreateDraftOptions,
  DeleteProItemOpts,
  DraftBody,
  HardwareQuote,
  ProItemType,
  ProductQuote,
  UpdateDraftOptions,
} from './types'
import type { AdjustAction, Quote, QuoteListItem, ProTaxData } from 'types'
import type { ResponseWithMetaData } from 'store/types'

const addProduct = (quoteId: string, product: ProductQuote) =>
  http.put<void>(`quotes/${quoteId}/product-variants/${product.id}`, product)

type AddProItem = {
  (type: 'prohardware', opts: AddProHardwareOpts): Promise<void>
  (type: 'proservices', opts: AddProServiceOpts): Promise<void>
}
const addProItem: AddProItem = (type: ProItemType, { id, quoteId, ...rest }: AddProServiceOpts) =>
  http.put<void>(`quotes/${quoteId}/${type}/${id}`, rest)

const bulkAddHardware = (quoteId: string, hardware: HardwareQuote[]) =>
  http.put<void>(`quotes/${quoteId}/hardware/import`, { hardware })

const changeAdjustingState = (quoteId: string, action: AdjustAction) =>
  http.put<Quote>(`quotes/${quoteId}/adjust`, { action })

const cloneQuote = (quoteId: string, body: Pick<DraftBody, 'quoteName'>) =>
  http.post<Quote>(`quotes/${quoteId}/clone`, body)

const completeQuote = (quoteId: string) => http.put<Quote>(`quotes/${quoteId}/complete`)

const deleteHardware = (quoteId: string, productVariantId: string) =>
  http.delete<void>(`quotes/${quoteId}/product-variants/${productVariantId}`)

const deleteProItem = (type: ProItemType, { quoteId, id }: DeleteProItemOpts) =>
  http.delete<void>(`quotes/${quoteId}/${type}/${id}`)

const deleteQuote = (id: string) => http.delete<void>(`quotes/${id}`)

const getClientContacts = (clientId: string, { emailOnly = false }: ClientContactOptions) =>
  http.get<string[]>(`clients/${clientId}/contacts?emailOnly=${emailOnly}`)

const getEmailPreview = (quoteId: string) => http.get<string>(`quotes/${quoteId}/email-preview`)

const getQuote = (id: string, taxRate = false) =>
  http.get<Quote | ResponseWithMetaData<Quote>>(`quotes/${id}?taxRate=${taxRate}`)

const getQuotes = (clientId: string, projectId: string | null | undefined) => {
  const project = projectId ? `&projectId=${projectId}` : ''
  return http.get<QuoteListItem[]>(`quotes?clientId=${clientId}${project}`)
}

const postQuote = (body: CreateDraftOptions) =>
  http.post<QuoteListItem | ResponseWithMetaData<QuoteListItem>>('quotes', body)

const putQuote = (quoteId: string, body: UpdateDraftOptions['data']) =>
  http.put<Quote>(`quotes/${quoteId}`, body)

const putQuoteHistory = (quoteId: string, body: { event: string; quoteVersionId?: number }) =>
  http.put(`quotes/${quoteId}/history`, body)

const reSendQuote = (quoteId: string, update: Pick<DraftBody, 'recipients' | 'message'>) =>
  http.post<Quote>(`quotes/${quoteId}/resend`, update)

const sendQuote = (quoteId: string) => http.post<Quote>(`quotes/${quoteId}/send`)

const setQuotePending = (quoteId: string) => http.put<Quote>(`quotes/${quoteId}/pending`)

const updateDefaultTax = (body: ProTaxData) => http.put<ProTaxData>('professionals/tax', body)

export const api = {
  addProduct,
  addProItem,
  bulkAddHardware,
  changeAdjustingState,
  cloneQuote,
  completeQuote,
  deleteHardware,
  deleteProItem,
  deleteQuote,
  getClientContacts,
  getEmailPreview,
  getQuote,
  getQuotes,
  postQuote,
  putQuote,
  putQuoteHistory,
  reSendQuote,
  sendQuote,
  setQuotePending,
  updateDefaultTax,
}
