import { default as axios } from 'axios'
import config from 'config'
import { http } from 'helpers'
import { SocketEvents } from 'shared/sockets/constants'
import io, { Socket } from 'socket.io-client'
import type { Count } from 'store/types'
import type { User } from 'types'
import type { ValidateName, ValidateEmail, SocketHandler } from './types'

export { default as http } from 'axios'

const regex = {
  finalSlash: /\/$/,
  startSlash: /^\//,
}

interface PayoutError {
  professional: string
  reason: string
}

export interface PayoutErrorInput {
  failed?: PayoutError[]
  reason?: string
}

export const api = {
  initialize: () => {
    axios.defaults.baseURL = config.BASE_URL + '/api'
  },
  getUser: () => http.get<User>('users/me'),
  login: () => http.post<User | null>('oauth/login'),
  logout: () => http.post<void>('oauth/logout', {}),
  validateEmail: ({
    email,
    client = false,
    pro = false,
    user = false,
    proInvites = false,
    clientInvites = false,
  }: ValidateEmail) =>
    http.get<{ isInvalid: boolean; message: string }>(
      `validations/email/${email}?client=${client}&pro=${pro}&user=${user}&pro-invites=${proInvites}&client-invites=${clientInvites}`,
    ),
  validateName: ({ name, client = false, pro = false }: ValidateName) =>
    http.get<boolean>(`validations/duplicate-name/${name}?client=${client}&pro=${pro}`),
}

export const location = {
  goTo: (path: string, search = '') => {
    const origin = window.location.origin.replace(regex.finalSlash, '')
    const _path = path.replace(regex.startSlash, '')
    window.location.href = `${origin}/${_path}?${search}`
  },
  goToLogin: (redirectPath = '') => {
    window.location.href = `${config.API_URL}/api/oauth/login?redirect_path=${redirectPath}`
  },
}

export interface SocketOptions {
  bouncedEmail: (...args: any[]) => void
  payoutComplete?: (amount: number) => void
  payoutFailed?: ({ failed, reason }: PayoutErrorInput) => void
  updateBackorderCount: (x: Count) => void
}

export const socket = (() => {
  let socket: Socket | null = null

  return {
    connect({ bouncedEmail, payoutComplete, payoutFailed, updateBackorderCount }: SocketOptions) {
      socket = io(config.API_URL, { withCredentials: true })
      socket.on(SocketEvents.BACKORDER_COUNT, updateBackorderCount)
      socket.on(SocketEvents.BOUNCED_EMAIL, bouncedEmail)
      payoutComplete && socket.on(SocketEvents.PAYOUT_COMPLETE, payoutComplete)
      payoutFailed && socket.on(SocketEvents.PAYOUT_FAILED, payoutFailed)
    },
    disconnect() {
      if (!socket) return
      socket.disconnect()
      socket = null
    },
    send(event: SocketEvents, data: any) {
      if (socket) socket.emit(event, data)
    },
    subscribe(event: SocketEvents, fn: SocketHandler) {
      if (socket) socket.on(event, fn)
    },
    unsubscribe(event: string, fn?: SocketHandler) {
      if (socket) socket.off(event, fn)
    },
  }
})()
