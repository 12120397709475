import { CloseWithCircleIconL } from '@ubnt/icons'
import { Text as UIText } from '@ubnt/ui-components'
import styled from 'styled-components'

export const CloseIcon = styled(CloseWithCircleIconL)`
  position: absolute;
  top: -4px;
  right: -4px;

  cursor: pointer;
`
CloseIcon.defaultProps = {
  isActive: true,
}

export const Img = styled.img`
  max-height: 40px;
  max-width: 50px;
  height: auto;
  width: auto;
`

export const ImgContainer = styled.div`
  position: relative;
  margin-right: 5px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-right: 5px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  margin: 5px 0px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const UploadText = styled(UIText)`
  color: ${({ theme }) => theme.colors.Light.color.blue.b6};
  font-weight: bold;
  text-align: center;
  width: 100%;
`
