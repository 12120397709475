import { evolve, map, pick, pipe } from 'ramda'
import { indexById } from 'helpers'
import type {
  IncomingQuoteTableItem,
  PaginatedIncomingQuoteTableData,
  PaginatedQuoteTableData,
  QuoteTableItem,
} from './types'

const parseQuoteForTable = ({ client, ...rest }: IncomingQuoteTableItem): QuoteTableItem => ({
  ...rest,
  clientName: client.name ? [client.name] : [client.firstName, client.lastName],
  clientLogo: client.logo,
})

export const parseQuotesForTable: (x: PaginatedIncomingQuoteTableData) => PaginatedQuoteTableData =
  evolve({ data: pipe(map(parseQuoteForTable), indexById) })

export const pickFilters = pick(['clientId', 'contains', 'status'])
