import { isNotNil } from 'ramda'
import styled from 'styled-components'
import { Text } from '@ubnt/ui-components'

import { Avatar } from 'components/Avatar'
import { Client } from 'store/projects/types'
import { CardContainer } from './CardContainer'

interface Props {
  label: string
  value: Client
}

export const Customer = ({ label, value }: Props) => {
  return (
    <CardContainer>
      <Text color='secondary' size='body'>
        {label}
      </Text>
      <TextContainer>
        {isNotNil(value) && (
          <Avatar logoUrl={value?.logo ?? null} name={[value?.firstName]} imgSize={24} />
        )}
      </TextContainer>
    </CardContainer>
  )
}

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 100%;

  .avatar__text {
    max-width: 230px;
  }
`
