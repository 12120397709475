import { Button } from '@ubnt/ui-components/Button'
import { Radio } from '@ubnt/ui-components/Radio'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, FormFooter, ProjectBody } from '../styles'
import type { FormFields } from '../types'

interface Props {
  back: (event: React.MouseEvent<Element, MouseEvent>) => void
  onSubmit: () => void
}

export const ProjectForm: FC<Props> = ({ back, onSubmit }) => {
  const { control, handleSubmit } = useFormContext<FormFields>()

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name='projectSelection'
        render={({ field: { onChange, value } }) => (
          <ProjectBody>
            <Radio
              id='pro'
              value='pro'
              variant='boxed'
              checked={value === 'pro'}
              onChange={() => onChange('pro')}
              labelProps={{
                caption:
                  "Input your customer's project information (and get to quote-building sooner)",
              }}
            >
              Enter project information directly
            </Radio>
            <Radio
              id='client'
              value='client'
              variant='boxed'
              checked={value === 'client'}
              onChange={() => onChange('client')}
              labelProps={{
                caption:
                  'We will send an email to your customer asking them to input basic project information',
              }}
            >
              Send project form to customer
            </Radio>
          </ProjectBody>
        )}
      />

      <FormFooter padding='none'>
        <Button variant='tertiary' onClick={back}>
          Back
        </Button>
        <Button type='submit' variant='primary'>
          Done
        </Button>
      </FormFooter>
    </Form>
  )
}
