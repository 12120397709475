import { yupResolver } from '@hookform/resolvers/yup'
import { InfoIcon } from '@ubnt/icons'
import { Button, Dropdown, Tooltip, Text } from '@ubnt/ui-components/'
import { assoc } from 'ramda'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { ConditionalTooltip, FlexRow } from 'components'
import { AsyncFuncState } from 'shared'
import { useOverState } from 'store'
import { Checkbox } from './components'
import { Form, FormFooter, Input, TextWrapper, Wrapper } from './styles'
import texts from './texts.json'

import type { EditContractProps } from './types'

export const EditQuote: FC<EditContractProps> = ({
  state,
  setState,
  onSubmit,
  validations,
  schema,
  handleCancel,
}) => {
  const { app, clientManagement, projects } = useOverState()
  const { modalProps } = useOverState().modal

  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<{
    name: string
  }>({
    resolver: yupResolver(schema),
    mode: 'all',
  })

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const handleChangeProject = ({ value }: { value: string }) => {
    setState((prev) => ({
      ...prev,
      projectId: value,
    }))
  }

  const handleChangeCheckbox = (key: 'handlePayment' | 'shipToPro') => () =>
    setState((prev) => ({ ...prev, [key]: !prev[key] }))

  const projectOptions = projects.list.map((x) => ({ label: x.name, value: x.id }))

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <Controller
          control={control}
          defaultValue={state.quoteName}
          name='name'
          render={({ field: { onChange, value } }) => (
            <Input
              invalid={errors.name?.message}
              width='100%'
              height='52px'
              label='Quote Name'
              value={value}
              onChange={(_, value) => {
                onChange(value)
                setState(assoc('quoteName', String(value)))
              }}
              onKeyDown={handleEnterKey}
              placeholder='Enter Quote name here'
              autoFocus
            />
          )}
        />
        <Dropdown
          label='Choose a Project'
          onChange={handleChangeProject}
          options={projectOptions}
          value={state.projectId ?? undefined}
          width='100%'
        />

        <Checkbox
          checked={state.handlePayment}
          disabled={!app.user?.professional.isStripeEnabled}
          onChange={handleChangeCheckbox('handlePayment')}
          text={texts.handlePayment.text}
        />
        <TextWrapper>
          <FlexRow justify='flex-start'>
            <Text>{texts.handlePayment.info}</Text>
            <Tooltip description={texts.handlePayment.tooltip} position='bottomEnd' width={250}>
              <InfoIcon style={{ marginLeft: '2px' }} isActive />
            </Tooltip>
          </FlexRow>
          {state.handlePayment && (
            <Text color='danger' style={{ marginTop: '8px' }}>
              {texts.handlePayment.checked}
            </Text>
          )}
        </TextWrapper>
        <Checkbox
          checked={state.shipToPro}
          label={texts.shipping.label}
          onChange={handleChangeCheckbox('shipToPro')}
          text={texts.shipping.text}
        />
      </Wrapper>

      <FormFooter>
        <Button variant='tertiary' onClick={handleCancel}>
          Cancel
        </Button>
        <ConditionalTooltip
          description={texts.button.nameTooltip}
          position='topEnd'
          show={!validations.name}
          width={160}
        >
          <Button
            variant='primary'
            disabled={!validations.name}
            type='submit'
            onClick={handleSubmit(onSubmit)}
            loader={clientManagement.status === AsyncFuncState.LOADING ? 'loading' : undefined}
          >
            {!modalProps.quote ? 'Create' : 'Save'}
          </Button>
        </ConditionalTooltip>
      </FormFooter>
    </Form>
  )
}
