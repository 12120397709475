import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  padding-left: 0px;
  white-space: pre-wrap;
  border: ${({ theme }) => ` 1px solid ${theme.borderColor}`};
  border-radius: 8px;

  :hover {
    cursor: pointer;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }
`
