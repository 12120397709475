import { QuoteEvent, QuoteStatus } from 'shared'

import type { AppState } from 'store/app/types'
import type { ToastType } from 'types'
import type { PaginationDetails } from './types'

export const toast = (
  state: { app: AppState },
  title: string,
  message: string | string[],
  type: ToastType,
  duration: number | null = null,
) => {
  state.app.toast.title = title
  state.app.toast.message = message
  state.app.toast.type = type
  state.app.toast.duration = duration
}

export const handleError = (error: any) => {
  if (error.response) return error.response.data.message || new Array(error.response.data)
  if (error.message) return error.message
  if (error.raw?.message) return error.raw.message
  if (typeof error === 'string' || Array.isArray(error)) return error
  return String(error)
}

export const getQuoteEvent = (status: QuoteStatus) => {
  switch (status) {
    case QuoteStatus.DRAFT:
      return QuoteEvent.SENT
    case QuoteStatus.SENT:
      return QuoteEvent.RE_SENT
    default:
      return 'Undefined quote event...'
  }
}

export const resetPage = (): PaginationDetails => ({
  count: 0,
  current: 0,
  first: 0,
  last: 0,
  next: null,
  perPage: 0,
  prev: null,
})

export default toast
