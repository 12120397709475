import { useEffect } from 'react'

import { useActions } from 'store'

export const Reader = () => {
  const actions = useActions()

  useEffect(() => {
    actions.news.read()
  }, [])

  return null
}
