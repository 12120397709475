import { Button } from '@ubnt/ui-components'
import { always, cond, propEq, T } from 'ramda'
import styled, { css } from 'styled-components'

export interface BadgeProps {
  type: 'blue' | 'navy' | 'yellow' | 'red' | 'green' | 'grey' | 'purple' | 'selected' | 'unselected'
  size: 'medium' | 'small' | 'xsmall'
}

const typeEq = (type: string) => propEq(type, 'type')

export const badgeStyles = css<BadgeProps>`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: ${(props) => (props.size === 'medium' ? '12px' : '10px')};
  font-weight: 700;
  padding: ${cond<[BadgeProps], string>([
    [propEq('medium', 'size'), always('2px 16px')],
    [propEq('xsmall', 'size'), always('0px 5px')],
    [T, always('0px 9px')],
  ])};

  background-color: ${(props) =>
    cond<[typeof props], string>([
      [typeEq('blue'), ({ theme }) => theme.colors.Light.color.blue.b1],
      [typeEq('navy'), ({ theme }) => theme.colors.Light.color.navy.n1],
      [typeEq('yellow'), ({ theme }) => theme.colors.Light.color.orange.o1],
      [typeEq('red'), ({ theme }) => theme.colors.Light.color.red.r1],
      [typeEq('green'), ({ theme }) => theme.colors.Light.color.green.g1],
      [typeEq('grey'), ({ theme }) => theme.colors.Light.color.neutral.n2],
      [typeEq('purple'), ({ theme }) => theme.colors.Light.color.purple.p6],
      [typeEq('selected'), ({ theme }) => theme.colors.Light.color.neutral.n1],
      [typeEq('unselected'), ({ theme }) => theme.colors.Light.color.neutral.n0],
    ])(props)};
  color: ${(props) =>
    cond<[typeof props], string>([
      [typeEq('blue'), ({ theme }) => theme.colors.Light.color.blue.b6],
      [typeEq('navy'), ({ theme }) => theme.colors.Light.color.navy.n6],
      [typeEq('yellow'), ({ theme }) => theme.colors.Light.color.orange.o6],
      [typeEq('red'), ({ theme }) => theme.colors.Light.color.red.r6],
      [typeEq('green'), ({ theme }) => theme.colors.Light.color.green.g8],
      [typeEq('grey'), ({ theme }) => theme.colors.Light.color.neutral.n8],
      [typeEq('purple'), ({ theme }) => theme.colors.Light.color.neutral.n0],
      [typeEq('selected'), ({ theme }) => theme.colors.textColor.black.b65],
      [typeEq('unselected'), ({ theme }) => theme.colors.textColor.black.b45],
    ])(props)};
`

export const Badge = styled.div<BadgeProps>`
  ${badgeStyles}
`

export const BadgeButton = styled(Button)<BadgeProps>`
  ${badgeStyles}
`
