import { Button, Text } from '@ubnt/ui-components'
import { StatusBadge as BaseStatusBadge } from 'components'
import styled from 'styled-components'

import { addVariantTitle } from 'helpers'

import type { FC } from 'react'
import { ProductType, StoreProduct } from 'types'

interface Props {
  product: StoreProduct
}

const ProductInfo: FC<Props> = ({ product }) => {
  return (
    <LeftContainer height={product.type === ProductType.VOIP ? '470px' : '242px'}>
      <Wrapper>
        {/* TODO: Await Store to add backorderable field to storefrontProducts endpoint */}
        {/* {isBackorderItem(product) && <BadgeWrapper>{renderStockBadge('Backorder')}</BadgeWrapper>} */}
        {product.status && <StatusBadge status={product.status} />}
        <StyledImg src={product.thumbnail} alt='product' />
        <DescriptionWrapper>
          <ShortDescription color='secondary' size='caption'>
            {addVariantTitle(product, 'shortDescription')}
          </ShortDescription>
          <Button
            component='a'
            href={product.url}
            weight='normal'
            size='small'
            target='_blank'
            variant='inline'
          >
            Learn more
          </Button>
        </DescriptionWrapper>
      </Wrapper>
    </LeftContainer>
  )
}

export default ProductInfo

const StatusBadge = styled(BaseStatusBadge)`
  position: absolute;
  top: 0;
  left: 0;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const ShortDescription = styled(Text)`
  margin-bottom: 4px;
  line-height: 20px;
  align-self: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const LeftContainer = styled.div<{ height: string }>`
  display: flex;
  height: ${({ height }) => height};
  overflow-y: auto;
  scrollbar-width: none;
  position: relative;
`

const StyledImg = styled.img`
  width: 50%;
`
