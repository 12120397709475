import { TALK_SUBS_COMMISSION } from 'shared'

const texts = {
  onboarding: {
    header: 'Onboarding',
    text: 'Professionals will be required to sign a contract agreeing to the terms and conditions of the program. Additionally, Professionals will be required to submit a W-9. Ubiquiti will use this information to prepare a 1099 form for Professionals at the end of each year.',
  },
  commissions: {
    header: 'Commissions',
    text: "Subject to the Professionals' compliance with Ubiquiti's terms and policies, UniFi Professionals will receive a commision for products and services sold through the Professionals program in accordance with the Commission Table below. Professionals are not allowed to make a purchase on behalf of their customers. Professionals will not receive commissions from Ubiquiti for purchases made by customers directly in the Ubiquiti webstore or from another seller. Additionally, Professionals are not permitted to change the price of Ubiquiti hardware and services or add any markup or additional fees to the Ubiquiti hardware and services; customers will pay the same price as webstore customers. Ubiquiti has the right to withhold commission payouts if any suspicious activity is suspected.",
  },
  commissionTable: {
    header: 'Commission Table',
    text: {
      hardware: '10% on UniFi hardware sales (excluding taxes and fees)',
      talk: `${TALK_SUBS_COMMISSION} of the Talk Subscription fee (excluding taxes and other fees)  on the UniFi Talk devices (“Talk Phones”). See additional instructions below.`,
    },
  },
  unifiTalkSubs: {
    header: 'Additional Instructions for UniFi Talk Subscriptions',
    text: {
      first: 'In order to qualify for the commission, the Professional must do the following:',
      second:
        'Professionals may choose to purchase more Talk Subscription phone numbers than Talk Phones. However, commission payouts for a Talk Subscription will be limited to the number of Talk Phones purchased (e.g., if a Professional sells 3 Talk Phones and 5 Talk Subscription phone numbers, a commission will only be paid on the Talk Subscription fee for 3 phone numbers).',
    },
    bullet: {
      first: '- Sell Talk Phones through the Professionals program',
      second:
        "- Ensure that the customer's UI Account that manages the Talk Subscription uses the same email as one of the client's contacts in the Professionals portal",
      third:
        '- Ensure that the customer uses their own credit card to pay for the Talk Subscription',
    },
  },
  sellProItems: {
    header: 'Selling Third-Party Hardware and Services ',
    text: "Professionals may add third-party hardware or services manually to a customer's quote. Professionals will be able to set the description, category, manufacturer and price of each item, where applicable, in the Professional Center section of the portal. You are solely responsible for the third-party hardware and services and you are solely responsible for determining, setting, collecting and reporting the applicable taxes for any non-Ubiquiti hardware or services. Please consult your own tax advisor. You agree to defend, indemnify, and hold harmless Ubiquiti and its affiliates for all liabilities relating to the third-party hardware and services. The portion of the quote that contains third-party hardware and services will be editable until the job is marked as complete.",
  },
  payProItems: {
    header: 'Payment for Third-Party Hardware and Services',
    text: {
      first:
        'Professionals have the option to accept payment for third-party hardware and services through the portal. The option will be presented upon the creation of each quote, and can be modified until the quote is marked as complete.',
      second: [
        "Once the customer submits the payment, the payment will be automatically transferred to the Professional's connected Stripe account. Unless otherwise stated, Professionals who choose to use this payment processing method will be charged with Stripe's processing fee of 2.9% per transaction, which will be automatically deducted from the payment from the customer.",
        'If Professionals decline this payment processing method, Professionals will have to arrange their own payment method from their customers for the non-Ubiquiti hardware and services.',
      ],
    },
  },
  stripeAccount: {
    header: 'Stripe Account',
    text: 'Professionals are required to create a (free) Stripe account and link it to the Professionals Portal in order to receive commission payments from Ubiquiti or payment for additional hardware or services provided. Ubiquiti does not collect payment information from Professionals. Professionals will not be charged transaction fees on commissions related to Ubiquiti hardware sales or Talk Subscriptions. The name on the Stripe account should match the entity or person on the W-9 submitted.',
  },
  paymentTiming: {
    header: 'Payment Timing',
    text: 'Professionals will receive the commission on sales made during a month or on active subscriptions within 10 days following the end of that same month in their Stripe accounts. Ubiquiti has the ability to change the timing or cadence of payments to Professionals without notice.',
  },
  inviteTeamMembers: {
    header: 'Inviting Team Members',
    text: 'Professionals will be allowed to invite other members of their company to the Professionals program. A company will share the same UniFi Professional account but each team member will have an individual login. Professionals will only be allowed to add team members if they are employed by the legal entity identified on the W-9. Additionally, the commissions for all individuals under the same UniFi Professional account will be paid out to the same Stripe account.',
  },
  returns: {
    header: 'Returns',
    text: 'Professionals will not receive commissions for items that are returned by customers. If the Professional was already paid commission on any product that was subsequently returned, Ubiquiti will deduct that commission amount from future commission payments. Ubiquiti has the right to withhold commission payouts if any suspicious activity is suspected.',
  },
  rewards: {
    header: 'Rewards',
    text: 'Professionals may be eligible to receive certain rewards (the “<strong>Rewards</strong>”) by meeting the applicable requirements of our reward program (the “<strong>Reward Program</strong>”). For more details, see the section “My Rewards” on the dashboard. All rewards shall be finally determined by Ubiquiti in its sole discretion. The Reward Program may be withdrawn, suspended, terminated or modified by Ubiquiti at any time for any reason without prior notice.  Ubiquiti reserves the right to substitute any Reward with other prizes in its sole discretion. All taxes imposed on the Rewards are the sole responsibility of the Professionals. The Reward Program is subject to all applicable federal, state and local laws, and is void where prohibited by applicable law. ',
  },
  rulesOfConduct: {
    header: 'Rules of Conduct',
    text: "Professionals may not engage in any detrimental activity in connection with the Professional Program. Detrimental activities would include, but are not limited to: making any misrepresentation about Ubiquiti, Ubiquiti products or the Professional Program; re-selling Ubiquiti products after the initial sale through the program; conducting any fraudulent, unethical or other questionable activities; offering a customer a part of the commission; or falsifying any customer or Professional information. If the Professional has any questions regarding whether any sales method or business practice constitutes detrimental activities, please reach out to Ubiquiti for approval beforehand. Ubiquiti has the right to remove any individual Professional or company from the Professionals program at Ubiquiti's sole discretion and without prior warning. Additionally, Ubiquiti can choose to withhold commissions if questionable activity is suspected.",
  },
  ppts: {
    header: 'Privacy Policy and Terms of Service',
    text: "Ubiquiti's terms of service and privacy policy can be viewed here.",
  },
  revisions: {
    header: 'Revisions',
    text: "Ubiquiti may, from time to time and at its sole discretion, update and modify any of the terms and conditions herein. Unless otherwise stated by Ubiquiti, any changes to the terms and conditions will be effective immediately upon the revision, so Professionals are encouraged to review the terms and conditions regularly for updates. Professionals' continued participation in this program will constitute acceptance of the revised terms and conditions by Professionals.",
  },
}

export default texts
