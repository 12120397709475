import { branch, pipe } from 'overmind'

import * as modalActions from 'store/modal/actions'
import * as op from './operators'
import { handleAsyncAction, ifPropIs, runOnSuccess, toast } from 'store/operators'
import { isNotEmpty } from 'helpers'

import type { Context } from 'store'
import type { EditedHardware, QuantityLimitOptions } from './types'
import type { StoreProduct } from 'types'
import type { WithId, WithOnSuccess } from 'store/types'

const handleHardwareAsyncAction = handleAsyncAction('hardware')

export const getProducts = handleHardwareAsyncAction<void>(op.getProducts)

export const getProductsManagement = handleHardwareAsyncAction<void, void>(op.getProductsManagement)

export const getQuantityLimit = handleHardwareAsyncAction<QuantityLimitOptions, void>({
  before: branch(op.setLoadingSection('productModal')),
  after: branch(op.setLoadingSection(null)),
  action: op.getQuantityLimit,
})

export const updateProduct = handleHardwareAsyncAction<
  Partial<StoreProduct> & Pick<StoreProduct, 'id'>,
  void
>({
  action: pipe(branch(op.updateProduct, toast('', 'Updated Product')), modalActions.closeModal),
})

export const resetProducts = ({ state }: Context) => {
  state.hardware.products.categoryHash = {} as any
}

export const resetQuantityLimit = ({ state }: Context) => {
  state.hardware.quantityLimit = null
}

export const getProHardware = handleHardwareAsyncAction(op.getProHardware)

export const deleteProHardware = handleHardwareAsyncAction<WithId & WithOnSuccess, void>({
  action: pipe(op.deleteProHardware, runOnSuccess()),
  errorTitle: 'Delete Hardware Error',
})

export const bulkUpdateProHardware = handleHardwareAsyncAction<
  EditedHardware & WithOnSuccess,
  void
>({
  before: ifPropIs('hardware', isNotEmpty),
  action: pipe(
    branch(op.parseHardware, op.bulkUpdateProHardware),
    runOnSuccess(),
    toast('', 'Saved hardware update'),
    op.getProHardware,
  ),
  errorTitle: 'Submit Hardware Error',
})
