import { FC, useEffect, useState } from 'react'
import { useOverState } from 'store'

import { ProjectContextProvider } from './ProjectContext'
import { makeNewProject } from './data'
import { Sidebar } from './Sidebar'

import type { ModalType, ProjectSidebar as ProjectsSidebarProps } from 'store/modal/types'
import type { Project } from 'store/projects/types'

const buttonLabels = {
  next: 'Add',
  prev: 'Cancel',
}

export const ProjectSidebar: FC = () => {
  const { sidebarProps } = useOverState().modal as ModalType<any, ProjectsSidebarProps>
  const { projects } = useOverState()

  const [project, setProject] = useState(
    sidebarProps.newProject ? makeNewProject() : (projects.current.data as Project),
  )

  useEffect(() => {
    if (!sidebarProps.newProject && projects.current.data) setProject(projects.current.data)
  }, [projects.current.data])

  const buttons = sidebarProps.newProject ? buttonLabels : undefined

  return (
    <ProjectContextProvider buttons={buttons} editable location='sidebar'>
      <Sidebar initProject={project} />
    </ProjectContextProvider>
  )
}
