import { branch, parallel, pipe } from 'overmind'
import { isNil } from 'ramda'

import * as op from './operators'
import { AsyncFuncState } from 'shared'
import { SocketEvents } from 'shared/sockets/constants'
import { handleAsyncAction, whenPropIs } from 'store/operators'
import { toLocaleCurrency } from 'helpers'

import type { ClientViewState, WithRedirect } from './types'
import type { PayoutErrorInput } from './effects'
import type { Context } from 'store'
import type { ToastType } from 'types'

interface SocketSubscription {
  event: SocketEvents
  listener: (...args: any[]) => void
}

const handleAppAction = handleAsyncAction('app')

export const goToLogin = ({ effects }: Context) => effects.app.location.goToLogin()

export const login = handleAppAction({
  action: pipe(
    op.login,
    whenPropIs('user', isNil, {
      true: goToLogin,
      false: op.setUser,
    }),
  ),
  after: op.setLoadingSection(null),
  before: op.setLoadingSection('login'),
  onError: goToLogin,
  toastOnError: false,
})

export const logout = handleAppAction<WithRedirect, void>(
  pipe(
    branch(
      ({ effects }: Context) => effects.app.api.logout(),
      ({ state }: Context) => {
        state.app.user = null
      },
    ),
    whenPropIs('redirect', true, {
      true: goToLogin,
      false: () => undefined,
    }),
  ),
)

export const resetError = ({ state }: Context) => {
  state.app.status = AsyncFuncState.SUCCESS
  state.app.error = null
}

export const setBuild = ({ state }: Context, build: string) => {
  state.app.build = build
}

export const setPage = ({ state }: Context, page: string) => {
  state.app.page = page
}

export const verifyUser = handleAppAction({
  action: op.getUser,
  onError: parallel(op.setUserTo(null), op.parseError),
  toastOnError: false,
})

export const changeClientView = ({ state }: Context, viewState: ClientViewState) => {
  state.app.clientView = viewState
  // Persist view state upon page refresh.
  localStorage.setItem('client_view_state', viewState)
}

export const connectSocket = ({ actions, effects, state }: Context) => {
  const user = state.app.user ?? undefined
  effects.app.socket.connect({
    bouncedEmail: (message: string) => {
      actions.app.toast({
        title: 'Failed email delivery',
        message,
        type: 'warning',
        duration: 30_000,
      })
    },
    payoutComplete:
      user &&
      ((amount: number) =>
        actions.app.toast({
          duration: 15e3,
          message: `Paid ${toLocaleCurrency(amount)}`,
          title: 'Payout Complete',
        })),
    payoutFailed:
      user &&
      (({ failed = [], reason = 'Could not deliver the following payouts:' }: PayoutErrorInput) => {
        const failures = failed.map(({ professional, reason }) => `${professional}: ${reason}`)
        actions.app.toast({
          duration: 25e3,
          message: [reason, ...failures],
          title: 'Payout Failure',
          type: 'danger',
        })
      }),
    updateBackorderCount: actions.backorders.updateCount,
  })
}

export const disconnectSocket = ({ effects }: Context) => {
  effects.app.socket.disconnect()
}

export const resetToast = ({ state }: Context) => {
  state.app.toast.message = ''
  state.app.toast.title = ''
  state.app.toast.type = 'info'
}

export interface ToastOptions {
  title: string
  message?: string | string[]
  type?: ToastType
  duration?: number | null
}

export const subscribe = ({ effects }: Context, { event, listener }: SocketSubscription) => {
  effects.app.socket.subscribe(event, listener)
}

export const toast = (
  { state }: Context,
  { title, message, type = 'success', duration = null }: ToastOptions,
) => {
  state.app.toast.title = title
  state.app.toast.message = message || ' '
  state.app.toast.type = type
  state.app.toast.duration = duration
}

export const unsubscribe = ({ effects }: Context, { event, listener }: SocketSubscription) => {
  effects.app.socket.unsubscribe(event, listener)
}

export const onInitializeOvermind = pipe(({ actions }: Context) => {
  if (new URLSearchParams(window.location.search).get('show') === 'reloaded') {
    actions.app.toast({
      message: [
        'We had an issue processing your activity.',
        'Portal refreshed to use the latest version.',
        'Please try again.',
      ],
      title: 'Updated App Version',
      type: 'warning',
    })
  }
}, verifyUser)
