import { Navigate } from 'react-router-dom'

import { useOverState } from 'store'

import type { FC } from 'react'

export const PublicRoute: FC = ({ children }) => {
  const { user } = useOverState().app

  if (user) return <Navigate replace to='/' />
  return <>{children}</>
}
