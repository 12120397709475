import { AsyncFuncState } from 'shared'
import type { AppState } from './types'

export const state: AppState = {
  build: 'current',
  clientView: 'ITEMS',
  error: null,
  loadingSection: null,
  page: 'Sales Pros',
  status: AsyncFuncState.NULL,
  toast: {
    message: '',
    title: '',
    type: 'info',
    duration: null,
  },
  user: null,
}
