import React from 'react'
import { TableColumnConfig, Table } from '@ubnt/ui-components'
import ContentLoader from 'react-content-loader'

interface Props {
  columns: TableColumnConfig<any>[]
  rowCount?: number
  initialSortBy?: string
}

export const TableLoadingState = ({ columns, rowCount = 5, initialSortBy }: Props) => {
  const items = Array.from({ length: rowCount }, (_x, i) => i).map((key) => ({ id: key }))
  const loadingColumns = columns.map((col) => ({
    ...col,
    renderCell: () => (
      <ContentLoader width='50' height='16'>
        <rect x='0' y='0' rx='5' ry='5' width='50' height='16' />
      </ContentLoader>
    ),
  }))

  return (
    <Table
      columns={loadingColumns as any}
      items={items}
      initialSortBy={initialSortBy as any}
      initiallySortAscending
      disableColumnFilters
      disableSelection
    />
  )
}
