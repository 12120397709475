import { DateTime, DurationLike } from 'luxon'

const now = new Date()
const thisMonth = () => {
  const date = new Date()
  date.setDate(1)
  return date
}
const yearStart = () => {
  const date = new Date()
  date.setMonth(0)
  date.setDate(1)
  return date
}

export const changeDate = (opts: DurationLike) => (date: Date) =>
  DateTime.fromJSDate(date).plus(opts).toJSDate()

export const dateOr =
  (defaultValue = 'MM/DD/YYYY') =>
  (date: any, format = 'MM/dd/yyyy') => {
    const parsedDate = DateTime.fromISO(date)
    return parsedDate.invalidReason === null ? parsedDate.toFormat(format) : defaultValue
  }

export const dateOrDefault = dateOr()
export const dateOrEmpty = dateOr('')

export const daysFromNow = (str: string) => {
  const date = new Date(str)
  const difference = date.getTime() - Date.now()
  const days = difference / (24 * 60 * 60 * 1_000)
  return Math.floor(days)
}

export const dateOnly = (date?: Date) => dateOr('')(date?.toISOString(), 'yyyy-MM-dd')

export const fromDate = (date: Date) => (opts: DurationLike) => changeDate(opts)(date)

export const fromToday = fromDate(now)
export const fromThisMonth = fromDate(thisMonth())
export const fromYearStart = fromDate(yearStart())

export const isValidDate = (input: any) => new Date(input).toString() !== 'Invalid Date'
