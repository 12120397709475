import { Text } from '@ubnt/ui-components/Text'
import { Modal } from '@ubnt/ui-components/Modal'
import { FC } from 'react'
import { useActions, useOverState } from 'store'
import styled from 'styled-components'

export const DisputeNotice: FC = () => {
  const { modal, modalProps } = useOverState().modal
  const { closeModal } = useActions().modal

  const {
    dispute,
    user: { firstName, lastName },
    client: { name: clientCompany },
  } = modalProps.dispute

  return (
    <Modal
      title='Notice of Dispute'
      isOpen={modal === 'dispute_notice'}
      onRequestClose={closeModal}
      size='small'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Continue',
          variant: 'primary',
          onClick: closeModal,
        },
      ]}
    >
      <TextContainer>
        <Text size='body'>
          <em>
            {`${firstName} ${lastName}`}
            {clientCompany ? ` (${clientCompany}) ` : ' '}
          </em>
          has provided the following details about their dispute. Please review and make
          adjustments, if needed.
        </Text>
      </TextContainer>
      <Dispute>
        <Text size='body'>{dispute}</Text>
      </Dispute>
    </Modal>
  )
}

const TextContainer = styled.div`
  padding: 14px 0 20px;
`

const Dispute = styled.article`
  background-color: ${({ theme }) => theme.colors.Light.color.neutral.n2};
  border-radius: 16px;
  padding: 16px 20px;
`
