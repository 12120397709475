import { always, find, ifElse, isNotNil, pipe, prop, propEq } from 'ramda'

import type { Quote, QuoteProHardware, QuoteProService } from 'types'

const _getItemPrice = (
  key: 'proHardware' | 'proServices',
  item: QuoteProHardware | QuoteProService,
) =>
  pipe<
    [Quote],
    QuoteProHardware[] | QuoteProService[],
    QuoteProHardware | QuoteProService | undefined,
    number
  >(
    prop(key),
    find<QuoteProHardware | QuoteProService>(propEq(item.id, 'id')),
    ifElse(isNotNil, (x) => x.adjusted?.price ?? x.price, always(item.price)),
  )

const _getItemQty = (id: string) =>
  pipe<[Quote], QuoteProHardware[], QuoteProHardware | undefined, number>(
    prop('proHardware'),
    find<QuoteProHardware>(propEq(id, 'id')),
    ifElse(isNotNil, (x) => x.adjusted?.qty ?? x.qty, always(1)),
  )

const _getItemTax = (key: 'proHardware' | 'proServices', id: string, defValue: number | null = 0) =>
  pipe<
    [Quote],
    QuoteProHardware[] | QuoteProService[],
    QuoteProHardware | QuoteProService | undefined,
    number | null
  >(
    prop(key),
    find<QuoteProHardware | QuoteProService>(propEq(id, 'id')),
    ifElse(isNotNil, (x) => x.tax, always(defValue)),
  )

export const getItemPrice = (
  quote: Quote | null,
  key: 'proHardware' | 'proServices',
  item: QuoteProHardware | QuoteProService,
) => {
  if (!quote) return 0
  return _getItemPrice(key, item)(quote)
}

export const getItemQty = (quote: Quote | null, id: string) => {
  if (!quote) return 0
  return _getItemQty(id)(quote)
}

export const getItemTax = (quote: Quote | null, key: 'proHardware' | 'proServices', id: string) => {
  if (!quote) return 0
  const defValue = key === 'proHardware' ? quote.taxData.proHardware : quote.taxData.proService
  return _getItemTax(key, id, defValue)(quote)
}
