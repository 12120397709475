import styled from 'styled-components'
import { ExclamationMarkIconL } from '@ubnt/icons'

export const Icon = styled(ExclamationMarkIconL)`
  margin-right: 10px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  padding: 8px 18px;

  min-height: 50px;
`
