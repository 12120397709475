import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const HorizontalDivider = styled.div`
  margin: 16px 0;
  height: 1px;
  background-color: ${({ theme }) => `${theme.colors.Light.color.neutral.n3}`};
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`

export const ButtonWrapper = styled.div`
  margin-left: 16px;
`

export const NotificationWrapper = styled.div`
  margin: 20px 0 0;
`

export const FormFooter = styled.section`
  height: fit-content;
  padding: 36px 32px 28px 32px;
`
