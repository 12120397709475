import { useEffect } from 'react'
import { useActions, useOverState } from 'store'
import { ClientTableType } from 'app/client/constants/clientColumns'
import { useFirstRender } from './useFirstRender'

export const useFetchSortedAndSearchedClients = (
  sortingColumn: keyof ClientTableType,
  order: 'asc' | 'desc',
  search: string,
) => {
  const { getClients, getDebouncedClients } = useActions().clients
  const { page, refetchTs } = useOverState().clients.clients

  const isFirstRender = useFirstRender()

  const opts = {
    search: search || undefined,
    sortingColumn,
    order,
  }

  useEffect(() => {
    if (isFirstRender) return

    getDebouncedClients({
      page: search ? 0 : page.current,
      ...opts,
    })
  }, [search])

  useEffect(() => {
    getClients({
      page: page.current,
      ...opts,
    })
  }, [sortingColumn, order, refetchTs])
}
