import { always, curry, equals, pipe, takeLast, when } from 'ramda'
import { countDecimals } from 'helpers'
// import { PriceOptions } from 'types'

const regEx = {
  escape: /[.*+?^${}()|[\]\\]/g,
  number: /^\d+(?:\.\d*)?$/,
  startZeroes: /^0+(\d)/,
}

const _capitalise = (str: string) => str.replace(/^./, (x) => x.toUpperCase())
const _capitaliseAll = (str: string) => str.split(/\s|-/).map(_capitalise).join(' ')

export const capitalise = (str: string, opts: { every?: boolean } = {}) =>
  opts.every ? _capitaliseAll(str) : _capitalise(str)

export const escapeRegExp = (str: string) => {
  return str.replace(regEx.escape, '\\$&') // $& means the whole matched string
}

export const length = (str: string) => str.length

export const pluralise =
  (unit: string, plural = 's') =>
  (num: number) =>
    `${num} ${unit}${Math.abs(num) === 1 ? '' : plural}`

export const isValidNumber = (x: string | number) => typeof x === 'number' || regEx.number.test(x)

export const removeStartZeroes = (x: string) => x.replace(regEx.startZeroes, '$1')

// TODO: Mock type
// export const setPriceModelLabel = (pricingModel: PriceOptions) => {
export const setPriceModelLabel = (pricingModel: any) => {
  return `Number of ${pricingModel.replace('Per ', '')}(s)`
}

const _truncateStart = (length: number, str: string) =>
  str.length <= length ? str : `...${takeLast(length, str)}`

export const truncateStart = curry(_truncateStart)

export const formatNumber = pipe(removeStartZeroes, when(equals(''), always('0')))

export const isAcceptableNumber = (x: number | string) =>
  x >= 0 && countDecimals(x) <= 2 && !Number.isNaN(Number(x)) && (isValidNumber(x) || x === '')
