import { Text } from '@ubnt/ui-components'

import { useOverState } from 'store'
import { BubbleContainer } from 'components'
import { NotificationIconL } from '../PaymentDetails/styles'
import texts from '../PaymentDetails/texts.json'

import type { VFC } from 'react'

export const InfoBubble: VFC = () => {
  const { app } = useOverState()

  if (app.user?.professional.isStripeEnabled) return null
  return (
    <BubbleContainer padding='2px 12px' radius='small' type='info' variant='info'>
      <NotificationIconL isActive variant='fill' />
      <Text size='caption'>{texts.info.noStripe}</Text>
    </BubbleContainer>
  )
}
