import { Button, Modal } from '@ubnt/ui-components'
import styled from 'styled-components'

import { useActions, useOverState } from 'store'
import { ArticleGroup } from './ArticleGroup'
import { Reader } from './Reader'

export const News = () => {
  const { modal, news } = useOverState()
  const actions = useActions()

  const isOpen = modal.modal === 'news'
  const title = news.list.length === 1 ? news.list[0].title : "What's New"

  return (
    <Modal
      footer={<Footer />}
      isOpen={isOpen}
      onRequestClose={actions.modal.closeModal}
      shouldCloseOnOverlayClick={false}
      size='small'
      title={title}
    >
      <ArticleGroup items={news.list} />
      {isOpen && <Reader />}
    </Modal>
  )
}

const Footer = () => {
  const { modal } = useActions()

  return (
    <FooterWrapper>
      <Button onClick={modal.closeModal} variant='primary'>
        Got it
      </Button>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  padding: 0 20px 20px;
`
