import { FC } from 'react'
import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'
import type { ProService, ProHardware } from 'types'
import { ReactComponent as HardwareIcon } from 'images/icons/Hardware.svg'
import { ReactComponent as ServiceIcon } from 'images/icons/Service.svg'
import { isTaxValid, toLocaleCurrency } from 'helpers'
import { useActions, useOverState } from 'store'
import { CardContainer } from './styles'

interface Props {
  type: 'services' | 'hardware'
  item: ProService | ProHardware
}

const Card: FC<Props> = ({ type, item }) => {
  const { currentClient, currentQuote } = useOverState().clientManagement
  const { setModal } = useActions().modal

  const handleAddToQuote = () =>
    currentQuote && !isTaxValid(currentQuote)
      ? setModal({
          modal: 'edit_tax',
          props: {
            modalName: 'Quote Details',
            client: currentClient,
            displayTabs: true,
            quote: currentQuote,
          },
        })
      : setModal({
          modal: type === 'services' ? 'add_pro_service_to_quote' : 'add_pro_hardware_to_quote',
          props: {
            item,
          },
        })

  const price = item.price ? item.price : 0

  return (
    <CardContainer onClick={handleAddToQuote}>
      {type === 'services' && (
        <CardContent icon={<ServiceIcon style={{ margin: '0 16px' }} />} name={item.name} />
      )}

      {type === 'hardware' && (
        <CardContent
          icon={<HardwareIcon style={{ margin: '0 16px' }} />}
          header={`${(item as ProHardware).category} \u00A0 · \u00A0 ${
            (item as ProHardware).manufacturer
          }`}
          name={item.name}
          price={price}
        />
      )}
    </CardContainer>
  )
}

export default Card

const textWidth = '140px'

interface CardContentProps {
  icon: JSX.Element
  header?: string
  name: string
  price?: number
}

const CardContent: FC<CardContentProps> = ({ icon, header, name, price }) => (
  <>
    {icon}

    <TextContainer>
      <Text truncate style={{ width: textWidth, lineHeight: '16px' }}>
        {header}
      </Text>
      <Text weight='bold' size='body' truncate style={{ width: textWidth, lineHeight: '24px' }}>
        {name}
      </Text>
      <Text size='body' truncate style={{ width: textWidth, lineHeight: '24px' }}>
        {price && `${toLocaleCurrency(price)}`}
      </Text>
    </TextContainer>
  </>
)

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
