import React, { FC } from 'react'

import { useRole, Roles } from './Roles'
import { UserRole } from 'shared'

const roles = [UserRole.SYS_ADMIN]

export const RolesSysAdmin: FC = ({ children }) => <Roles roles={roles}>{children}</Roles>

export const useSysAdmin = () => useRole(roles)
