import styled from 'styled-components'

import { Alert } from '@ubnt/ui-components'
import texts from './texts'

import type { FC } from 'react'
import type { StoreProduct } from 'types'

export interface Props {
  product: StoreProduct
}

export const Info: FC<Props> = ({ product: _ }) => {
  return null // TODO: Once Store has added backorderable field to storefrontProducts endpoint -> Remove line & uncomment next line
  // if (product.status !== ProductDisplayStatus.SOLD_OUT ||!product.backorderable) return null

  return (
    <Wrapper>
      <Alert type='warning' text={texts.backorder.bubble} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 16px;
  > div > div:first-child {
    display: none;
  }
`
