import { derived } from 'overmind'
import { AsyncFuncState } from 'shared'
import { resetPage } from 'store/helpers'
import { ClientState } from './types'

export const state: ClientState = {
  clients: {
    list: derived(({ hash }: ClientState['clients']) => Object.values(hash)),
    hash: {},
    page: resetPage(),
    refetchTs: null,
  },
  status: AsyncFuncState.NULL,
  limited: {
    hash: {},
    list: derived(({ hash }: ClientState['clients']) => Object.values(hash)),
  },
  // allClients: derived(({ allClientsHash }: ClientState) => Object.values(allClientsHash)),
  // allClientsHash: {},
}
