import addressHelpers from 'helpers/address'
import { useEffect, useState } from 'react'

export function usePlacesAutoComplete(text = '', debounceTimeout = 400) {
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([])

  useEffect(() => {
    const handleDebounce = setTimeout(() => {
      if (!text) return
      addressHelpers
        .googlePlacesAutoComplete(text)
        .then((data) => setPredictions(data ?? []))
        .catch(console.error)
    }, debounceTimeout)

    return () => clearTimeout(handleDebounce)
  }, [text, debounceTimeout])

  return predictions
}
