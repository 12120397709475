import { Context } from 'store'
import { GetStatsOptions } from './types'

export const getQuoteStats = <A extends GetStatsOptions>({ effects, state }: Context, opts: A) =>
  effects.dashboard.api.getQuoteStats(opts).then((data) => {
    state.dashboard.quoteStats = data
  })

export const getClientStats = <A extends GetStatsOptions>({ effects, state }: Context, opts: A) =>
  effects.dashboard.api.getClientStats(opts).then((data) => {
    state.dashboard.clientStats = data
  })

export const getTopClients = <A extends { professionalId?: string }>(
  { effects, state }: Context,
  opts: A,
) =>
  effects.dashboard.api.getTopClients(opts.professionalId).then((data) => {
    state.dashboard.topClients = data
  })

export const getCommissionStats = <A extends { professionalId?: string; year?: string }>(
  { effects, state }: Context,
  { professionalId, year }: A,
) =>
  effects.dashboard.api.getCommissionStats(professionalId, year).then((data) => {
    state.dashboard.commission = data
  })

export const getProRatings = <A extends { professionalId?: string }>(
  { effects, state }: Context,
  opts: A,
) =>
  effects.dashboard.api.getProRatings(opts.professionalId).then((data) => {
    state.dashboard.ratings = data
  })
