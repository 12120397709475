import { FormFooter as UIFormFooter, Upload as UIUpload } from '@ubnt/ui-components'
import { Avatar as ReusableAvatar, FlexRow } from 'components'
import styled from 'styled-components/macro'
import { ReactComponent as SVGUbiquitiLogo } from 'svgs/UbiquitiLogo_blue.svg'

export const Form = styled.form`
  font-family: Lato;
  font-style: normal;
  display: flex;
  align-items: center;
  gap: 5%;
`

export const FlexColumn = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 20%;
  > div {
    margin-bottom: 15px;
  }
`

export const FlexColumn2 = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  width: 75%;
  > div {
    margin-bottom: 15px;
  }
`

export const Row = styled(FlexRow)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const LogoSection = styled(UIUpload)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ChangeLogo = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #006fff;

  :hover {
    cursor: pointer;
    color: #5ea4ff;
  }
`

export const Header = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
`

export const FormFooter = styled(UIFormFooter)`
  margin-top: 78px;

  > div {
    padding: 0 !important;
  }
`

export const UbiquitiLogo = styled(SVGUbiquitiLogo)`
  width: 112px;
  height: 112px;
  border-radius: 15px;

  :hover {
    cursor: pointer;
  }
`

export const Avatar = styled(ReusableAvatar)`
  :hover {
    cursor: pointer;
  }
`
