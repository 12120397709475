import { derived } from 'overmind'
import { AsyncFuncState } from 'shared'
import { resetPage } from 'store/helpers'
import { PaymentsState, Views } from './types'

export const state: PaymentsState = {
  method: {
    account: '',
    info: '',
    name: '',
    number: '',
  },
  payment: {
    hash: {},
    list: derived(({ hash }: PaymentsState['payment']) => Object.values(hash)),
    page: resetPage(),
  },
  professional: {
    id: '',
    cumulativeCommission: { talk: 0, unifi: 0 },
    createdAt: '',
    rewards: {
      paid: 0,
      total: 0,
    },
    upcomingCommissionTalk: 0,
    upcomingCommissionUnifi: 0,
  },
  professionalId: '',
  quotes: {
    filters: {
      clientId: null,
      status: [],
      contains: [],
    },
    hash: {},
    list: derived(({ hash }: PaymentsState['quotes']) => Object.values(hash)),
    order: 'desc',
    page: resetPage(),
  },
  rewards: {
    hash: {},
    list: derived(({ hash }: PaymentsState['rewards']) => Object.values(hash)),
    page: resetPage(),
  },
  status: AsyncFuncState.SUCCESS,
  view: Views.PAYMENT,
}
