import { branch, Overmind, parallel, pipe } from 'overmind'

import { handleAsyncAction, userIsSysAdmin } from 'store/operators'
import * as op from './operators'
import { AsyncFuncState } from 'shared'
import { resetPage } from 'store/helpers'

import type {
  Filters,
  GetOtherPaymentDataOptions,
  GetPaymentDataOptions,
  GetQuotesWithProItemsOptions,
  ListQuoteOptions,
  ListRewardInvoicesOptions,
  Views,
} from './types'
import type { WithOrder } from 'store/types'
import type { Context } from 'store'

const handlePaymentsAction = handleAsyncAction('payments')

export const getQuotes = handlePaymentsAction<ListQuoteOptions, void>(op.getQuotes)

export const getQuotesWithProItems = handlePaymentsAction<GetQuotesWithProItemsOptions, void>(
  parallel(op.getQuotesWithProItems, op.getAllProfessionals),
)

export const getMethodData = handlePaymentsAction(op.getMethodData)

export const getOtherPaymentData = handlePaymentsAction<GetOtherPaymentDataOptions, void>({
  before: userIsSysAdmin(),
  action: parallel<GetOtherPaymentDataOptions, void, void, void>(
    pipe(op.getOtherPaymentData, op.parsePaymentData, op.setPaymentData),
    op.getAllProfessionals,
    op.getProfessionalPayout,
  ),
})

export const getPaymentData = handlePaymentsAction<GetPaymentDataOptions, void>(
  parallel<GetPaymentDataOptions, void, void>(
    pipe(op.getPaymentData, op.parsePaymentData, op.setPaymentData),
    op.getProfessionalPayout,
  ),
)

export const getRewardInvoices = handlePaymentsAction<ListRewardInvoicesOptions, void>({
  action: parallel(op.getRewardInvoices, op.getProfessionalRewards, op.getAllProfessionals),
})

export const resetFilters = ({ state }: Context) => {
  state.payments.quotes.filters = {
    clientId: null,
    status: [],
    contains: [],
  }
}

export const resetFiltersAndRefetch = handlePaymentsAction<ListQuoteOptions, void>(
  pipe(branch(resetFilters), op.getQuotes),
)

export const resetMethodData = ({ state }: Context) => {
  state.payments.method = {
    account: '',
    info: '',
    name: '',
    number: '',
  }
}

export const resetPaymentData = ({ state }: Context) => {
  state.payments.payment.hash = {}
  state.payments.payment.page = resetPage()
  state.payments.professional = {
    id: '',
    cumulativeCommission: { talk: 0, unifi: 0 },
    createdAt: '',
    rewards: {
      paid: 0,
      total: 0,
    },
    upcomingCommissionTalk: 0,
    upcomingCommissionUnifi: 0,
  }
}

export const resetQuoteData = ({ state }: Context) => {
  state.payments.quotes.hash = {}
  state.payments.quotes.page = resetPage()
}

export const resetRewardData = ({ state }: Context) => {
  state.payments.rewards.hash = {}
  state.payments.rewards.page = resetPage()
}

export const setFilters = handlePaymentsAction<Filters & ListQuoteOptions, void>({
  before: op.ifFiltersAreDifferent(),
  action: pipe(branch(op.setFilters), op.getQuotes),
})

export const setOrderAndFetch = handlePaymentsAction<WithOrder & ListQuoteOptions, void>(
  pipe(branch(op.setOrder), op.getQuotes),
)

export const setStatus = ({ state }: Context, status: AsyncFuncState) => {
  state.payments.status = status
}

export const setView = ({ state }: Context, view: Views) => {
  state.payments.view = view
}

export const setProfessionalId = ({ state }: Context, { value }: { value: string }) => {
  state.payments.professionalId = value
}

export const onInitializeOvermind = (context: Context, instance: Overmind<Context>) => {
  instance.reaction(
    (state) => state.app.user?.professionalId,
    (value) => value && context.actions.payments.setProfessionalId({ value }),
    { immediate: true },
  )
}
