import { http } from 'helpers'
import {
  QuoteStats,
  ClientStats,
  CommissionStats,
  TopClients,
  Rating,
  GetStatsOptions,
} from './types'

const getQuoteStats = ({ professionalId, start, end }: GetStatsOptions) =>
  http.get<QuoteStats[]>(`quotes/stats`, { params: { professionalId, start, end } })

const getClientStats = ({ professionalId, start, end }: GetStatsOptions) =>
  http.get<ClientStats[]>(`clients/stats`, { params: { professionalId, start, end } })

const getTopClients = (professionalId?: string) =>
  http.get<TopClients[]>(`clients/top`, { params: { professionalId } })

const getCommissionStats = (professionalId?: string, year?: string) =>
  http.get<CommissionStats>(`invoices/stats`, { params: { professionalId, year } })

const getProRatings = (professionalId?: string) =>
  http.get<Rating[]>(`feedback/ratings`, { params: { professionalId } })

export const api = {
  getQuoteStats,
  getClientStats,
  getCommissionStats,
  getTopClients,
  getProRatings,
}
