import cssVariables from '@ubnt/ui-components/styles/variables'

import config from 'config'

export const NAVIGATION = {
  HEIGHT: 50,
  WIDTH: 50,
}

export const BOX_SHADOW = ` 0px 5px 31px -5px ${cssVariables['white-7']}`

export const ANIMATION_SPEED = 400
export const ANIMATION_STYLE = 'ease-in-out'

export const CONTAINER = {
  MOBILE: 460,
  TABLET: 768,
  PC: 1180,
  TV: 1400,
} as const

export const CONTAINER_PADDING = {
  MOBILE: 12,
  TABLET: 20,
  PC: 20,
  TV: 20,
} as const

export const MEDIA = {
  MOBILE: `(max-width: ${CONTAINER.MOBILE}px)`,
  TABLET: `(max-width: ${CONTAINER.TABLET}px)`,
  PC: `(max-width: ${CONTAINER.PC}px)`,
  TV: `(max-width: ${CONTAINER.TV}px)`,
}

export const QUESTION_RESOLVED_GREEN = '#11A653'

export const DEBUG_COLOR = {
  local: cssVariables['red-0'],
  proto: cssVariables['orange-0'],
  qa: cssVariables['green-0'],
  prod: undefined,
}[config.ENV]
