import { FC } from 'react'
import { isNil } from 'ramda'
import { useActions, useOverState } from 'store'
import { Loader, Paginator } from 'components'
import type { Client } from 'types'
import type { ClientTableType } from 'app/client/constants/clientColumns'

interface Props {
  clients: Partial<Client>[]
  sortingColumn?: keyof ClientTableType
  order?: 'asc' | 'desc'
  search?: string
}

export const ClientPaginator: FC<Props> = ({ clients, sortingColumn, order, search }) => {
  const {
    clients: {
      page: { current, last, count, perPage, next, prev },
    },
    status,
  } = useOverState().clients
  const { clients: actions } = useActions()

  const handleClick = (page: number | null) => () =>
    isNil(page) ? undefined : actions.getClients({ page, sortingColumn, order, search })

  return (
    <Paginator
      current={current}
      last={last}
      label={{ count, perPage, text: 'clients' }}
      onNext={handleClick(next)}
      onPrev={handleClick(prev)}
    >
      <Loader status={status} cond={clients.length > 0} type='small' />
    </Paginator>
  )
}
