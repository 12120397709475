import { ClosePrimaryIconL, UniFiLogo } from '@ubnt/icons'
import { Button, Modal, NoContent, Text } from '@ubnt/ui-components'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { FullscreenLoader } from 'components'
import { AsyncFuncState } from 'shared'
import { useActions, useEffects, useOverState } from 'store'
import { ProjectsEntity } from 'store/hardware/types'
import Hardware from './Hardware'
import Projects from './Projects'

import type { ProductItem } from './types'

const ImportHardwareModal: FC = () => {
  const [projectId, setProjectId] = useState('')
  const [selectedHardware, setSelectedHardware] = useState<ProductItem[]>([])
  const { bulkAddHardware } = useActions().clientManagement
  const { currentQuote, actionStatus } = useOverState().clientManagement
  const { modal } = useOverState().modal
  const { closeModal } = useActions().modal
  const { api } = useEffects().hardware
  const [projects, setProjects] = useState<ProjectsEntity[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (modal === 'import_hardware') {
      setLoading(true)
      api
        .getDcProjects()
        .then(({ projects }) => setProjects(projects))
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false))
    }
  }, [])

  const handleSubmit = () => {
    const mappedHw = selectedHardware.map((hw) => ({
      hardwareId: hw.id,
      price: hw.price,
      qty: hw.qty,
    }))
    if (currentQuote) bulkAddHardware({ quoteId: currentQuote.id, data: mappedHw })
  }

  const isDisabled =
    !projectId ||
    !selectedHardware.length ||
    !currentQuote ||
    actionStatus === AsyncFuncState.LOADING

  return (
    <Modal
      title='Import UniFi Design Center Project'
      isOpen={modal === 'import_hardware'}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      size='large'
      height='medium'
      header={<ModalHeader handleClose={closeModal} />}
      footer={
        <ModalFooter handleClose={closeModal} isDisabled={isDisabled} handleSubmit={handleSubmit} />
      }
    >
      <Container>
        {loading ? (
          <FullscreenLoader />
        ) : error ? (
          <Text>{error}</Text>
        ) : !projects.length ? (
          <NoContent
            size='small'
            icon='noFloorplans'
            title='There are no projects in your Design Center'
          />
        ) : (
          <>
            <ContentContainer>
              <Projects projects={projects} projectId={projectId} setProjectId={setProjectId} />
              <Hardware projectId={projectId} setSelectedHardware={setSelectedHardware} />
            </ContentContainer>
          </>
        )}
      </Container>
    </Modal>
  )
}

export default ImportHardwareModal

interface HeaderProps {
  handleClose: () => void
}

const ModalHeader: FC<HeaderProps> = ({ handleClose }) => (
  <HeaderWrapper>
    <Text weight='bold' size='header-s'>
      Import UniFi Design Center Project
    </Text>
    <ClosePrimaryIconL className='icon' actionable onClick={handleClose} />
  </HeaderWrapper>
)

interface FooterProps {
  isDisabled: boolean
  handleSubmit: () => void
  handleClose: () => void
}

const ModalFooter: FC<FooterProps> = ({ isDisabled, handleSubmit, handleClose }) => (
  <FooterWrapper>
    <Title>
      <UniFiLogo size='logo' variant='neutral' />
      Design Center
    </Title>
    <ButtonWrapper>
      <Button variant='secondary' onClick={handleClose}>
        Cancel
      </Button>
      <Button variant='primary' disabled={isDisabled} onClick={handleSubmit}>
        Add to Client
      </Button>
    </ButtonWrapper>
  </FooterWrapper>
)

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 28px 28px;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
`
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  margin-top: 10px;

  .icon {
    top: 20px;
    right: 20px;
    position: absolute;
  }
`
const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > div {
    margin-right: 10px;
  }
`
const Title = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.Light.color.neutral.n8};
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 165px;
`
