import { http, isNotEmpty, parseInvoiceType } from 'helpers'

import type { TotalRewards } from 'types'
import type {
  GetOtherPaymentDataOptions,
  GetPaymentDataOptions,
  GetQuotesWithProItemsOptions,
  ListQuoteOptions,
  ListRewardInvoicesOptions,
  PaginatedIncomingQuoteTableData,
  PaginatedPaymentData,
  PaginatedRewardData,
  PaymentProfessional,
  ProItemSalesTotals,
} from './types'

const getProfessionalPayout = (professionalId = 'self') =>
  http.get<Omit<PaymentProfessional, 'rewards'>>(`professionals/${professionalId}/payment`)

const getProfessionalRewards = (professionalId = 'self') =>
  http.get<TotalRewards>(`professionals/${professionalId}/payment?type=reward`)

const listQuotes = ({
  clientId,
  contains = [],
  status = [],
  order = 'desc',
  page = 0,
}: ListQuoteOptions) => {
  const clientQs = clientId ? `&clientId=${clientId}` : ''
  const containsQs = isNotEmpty(contains) ? `&contains=${contains.join(',')}` : ''
  const statusQs = isNotEmpty(status) ? `&status=${status.join(',')}` : ''

  return http.get<PaginatedIncomingQuoteTableData>(
    `quotes/prices?page=${page}${clientQs}${containsQs}${statusQs}&order=${order}&includeui=true`,
  )
}

const listQuotesWithProItems = ({
  end = '',
  order = 'desc',
  page = 0,
  professionalId = '',
  start = '',
}: GetQuotesWithProItemsOptions) =>
  http.get<PaginatedIncomingQuoteTableData>(
    `quotes/prices/pro-items/${professionalId}?page=${page}&order=${order}&start=${start}&end=${end}`,
  )

const listPaymentInvoices = ({ start = '', end = '', page = 0, type }: GetPaymentDataOptions) =>
  http.get<PaginatedPaymentData>(
    `invoices?start=${start}&end=${end}&page=${page}&type=${parseInvoiceType(type)}`,
  )

const listOtherPaymentInvoices = ({
  professionalId,
  start = '',
  end = '',
  page = 0,
  type,
}: GetOtherPaymentDataOptions) => {
  return http.get<PaginatedPaymentData>(
    `invoices/${professionalId}/?start=${start}&end=${end}&page=${page}&type=${parseInvoiceType(
      type,
    )}`,
  )
}

const listRewardInvoices = ({ end, page, professionalId, start }: ListRewardInvoicesOptions) =>
  http.get<PaginatedRewardData>(
    `invoices/rewards/${professionalId ?? ''}?start=${start}&end=${end}&page=${page}`,
  )

const getProItemSalesTotals = (professionalId = '') =>
  http.get<ProItemSalesTotals>(`quotes/totals/${professionalId}`)

export const api = {
  getProfessionalPayout,
  getProfessionalRewards,
  getProItemSalesTotals,
  listOtherPaymentInvoices,
  listPaymentInvoices,
  listQuotes,
  listQuotesWithProItems,
  listRewardInvoices,
}
