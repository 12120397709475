import { Modal, Tabs } from '@ubnt/ui-components'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { useNavigate, useLocation } from 'react-router-dom'

import { useActions, useOverState } from 'store'
import { EditClient } from '../ClientForm'
import { EditQuote } from '../EditQuote'
import { QuoteState } from '../EditQuote/types'
import { EditTax } from '../EditTax'
import texts from '../EditQuote/texts.json'

const quoteSchema = yup.object().shape({
  name: yup.string().min(3).required(),
})

const taxSchema = yup.object().shape({
  serviceTax: yup.number().typeError(texts.tax.error).min(0).max(100).required(),
  hardwareTax: yup.number().typeError(texts.tax.error).min(0).max(100).required(),
})

const tabs = [
  { id: 'contract_info', label: 'Quote Info' },
  { id: 'tax_rates', label: 'Tax Rates' },
  { id: 'contact', label: 'Contact' },
]

const createQuoteTabs = tabs.filter((x) => x.id !== 'contact')

export const EditQuoteOrTaxOrClient = () => {
  const {
    clientManagement: { editDraftDetails, createDraft, getQuotesByProject },
    modal: { closeModal },
  } = useActions()
  const {
    app: { user },
    clientManagement: { currentClient },
    modal: { modalProps, modal },
  } = useOverState()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [tab, setTab] = useState(
    modal === 'edit_client' ? tabs[2] : modal === 'edit_tax' ? tabs[1] : tabs[0],
  )
  const [quoteState, setQuoteState] = useState<QuoteState>({
    quoteName: modalProps.quote?.quoteName ?? '',
    handlePayment: modalProps.quote?.handlePayment ?? false,
    shipToPro: modalProps.quote?.shipTo.recommendation === 'pro' ?? false,
    taxData: modalProps.quote?.taxData ??
      user?.professional.taxData ?? { proHardware: null, proService: null },
    projectId: modalProps.quote?.projectId ?? modalProps.projectId,
    clientId: modalProps.quote?.clientId ?? modalProps.clientId,
  })
  const [validations, setValidations] = useState({
    name: false,
    taxRates: false,
  })

  useEffect(() => {
    setValidations({
      name: quoteSchema.isValidSync({ name: quoteState.quoteName }),
      taxRates: taxSchema.isValidSync({
        serviceTax: quoteState.taxData.proService,
        hardwareTax: quoteState.taxData.proHardware,
      }),
    })
  }, [quoteState])

  const handleTabSelection = (selectedTab: any) => setTab(selectedTab)

  const handleCancel = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    event.preventDefault()
    closeModal()
  }

  const onSubmit = () => {
    if (modalProps.quote) {
      return editDraftDetails({
        quoteId: modalProps.quote.id,
        data: quoteState,
        clientId: quoteState.clientId,
        projectId: quoteState.projectId,
        onSuccess: () =>
          getQuotesByProject({ clientId: quoteState.clientId, projectId: quoteState.projectId }),
      })
    }

    return createDraft({
      ...quoteState,
      taxData: {
        ...quoteState.taxData,
        proHardware: Number(quoteState.taxData.proHardware),
        proService: Number(quoteState.taxData.proService),
      },
      history: (draftId: string) =>
        navigate(`${pathname.replace(/(?:\/new-quote)/, '')}/quote/${draftId}`),
    })
  }

  const renderTabs = () => (
    <TabsHolder>
      <Tabs
        className='tab'
        variant='tertiary'
        selectedTab={tab.id}
        tabs={modalProps.modalName === 'Create a Quote' ? createQuoteTabs : tabs}
        onSelectTab={handleTabSelection}
      />
    </TabsHolder>
  )

  return (
    <Modal
      title={modalProps.modalName}
      isOpen
      onRequestClose={handleCancel}
      shouldCloseOnOverlayClick={false}
      size='small'
      height='medium'
    >
      {modalProps.displayTabs && renderTabs()}
      {tab === tabs[0] && (
        <EditQuote
          state={quoteState}
          setState={setQuoteState}
          onSubmit={onSubmit}
          validations={validations}
          schema={quoteSchema}
          handleCancel={handleCancel}
        />
      )}
      {tab === tabs[1] && (
        <EditTax
          state={quoteState}
          setState={setQuoteState}
          onSubmit={onSubmit}
          validations={validations}
          schema={taxSchema}
          handleCancel={handleCancel}
        />
      )}
      {tab === tabs[2] && <EditClient client={currentClient} />}
    </Modal>
  )
}

export const TabsHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .tab {
    display: flex;
    justify-content: center;
  }

  .tab > div {
    display: flex;
    justify-content: space-around;
    margin: 0;
    width: 70%;
  }

  .tab button {
    &:focus {
      outline: none;
    }
  }
`
