import styled from 'styled-components'
import { Controller, FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form'
import { isNotNil, omit } from 'ramda'

import config from 'config'
import { Input, Text } from '@ubnt/ui-components'
import { Schema } from '../ProjectFormProvider'
import { NoteText } from './NoteText'
import {
  BubbleRow,
  CloseIcon,
  FloorImg,
  FloorImgContainer,
  FloorItem,
  InputNumber,
  FileWrapper,
  FileTitle,
} from './styles'
import { UploaderButton } from './UploaderButton'
import { NewWifi } from './types'
import { extractFileName } from './helpers'
import { useScrollIntoView } from 'hooks'

import type { DataUrl } from './AreaGroup'

interface Props {
  index: number
  name: 'wifiIndoor' | 'wifiOutdoor'
  field: FieldArrayWithId<Schema, 'wifiIndoor' | 'wifiOutdoor', 'id'>
  handleLoadPlan: (
    index: number,
    prev: NewWifi,
  ) => (file: File, dataUrl?: string | undefined) => void
  handleRemovePlan: (index: number, previous: NewWifi) => void
  urls: (DataUrl | undefined)[]
}
export const WifiBubbleFields = ({
  index,
  name,
  field,
  handleLoadPlan,
  handleRemovePlan,
  urls,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<Schema>()

  const labelText = name === 'wifiIndoor' ? 'Floor' : 'Area Name'
  const className = 'bubble-field'
  const areaWidth = name === 'wifiIndoor' ? 96 : 148
  const wifi = useWatch({ control, name: `${name}.${index}` })
  const errorList = errors[name] ?? []

  // Workaround for scrolling upload error into view upon submitting incomplete form.
  const hasErrorsAboveUpload =
    'buildingSize' in errors ||
    'buildingType' in errors ||
    'location' in errors ||
    'name' in errors ||
    'userQty' in errors ||
    (errors.wifiIndoor &&
      errors.wifiIndoor[index] &&
      // @ts-expect-error. TS thinks object is possibly null
      ('area' in errors.wifiIndoor[index] || 'roomQty' in errors.wifiIndoor[index]))
  const condition = isNotNil(errorList[index]?.size?.message) && !hasErrorsAboveUpload
  const uploaderRef = useScrollIntoView<HTMLDivElement>(condition, [errorList])

  return (
    <BubbleRow key={field.id}>
      <Controller
        control={control}
        name={`${name}.${index}.name`}
        render={({ field }) => (
          <Input
            {...omit(['ref'], field)}
            onChange={(_, value) => field.onChange(value)}
            className={className}
            invalid={errorList[index]?.name?.message}
            label={labelText}
            type={labelText === 'Floor' ? 'number' : 'text'}
            arrowSize='smaller'
            variant='secondary'
            width={areaWidth}
          />
        )}
      />
      <Controller
        control={control}
        name={`${name}.${index}.area`}
        render={({ field }) => (
          <Input
            {...field}
            contentAfter='sq.ft'
            className={className}
            invalid={errorList[index]?.area?.message}
            inputContainerClassName={field.value === 0 ? 'input--color--disabled' : ''}
            label='Area Size'
            min={0}
            placeholder='0'
            variant='secondary'
            width={areaWidth}
          />
        )}
      />
      {name === 'wifiIndoor' && (
        <Controller
          control={control}
          name={`${name}.${index}.roomQty`}
          render={({ field }) => (
            <InputNumber
              {...omit(['ref'], field)}
              onChange={(_, value) => field.onChange(value)}
              className={className}
              invalid={errorList[index]?.roomQty?.message}
              inputContainerClassName={field.value === 0 ? 'input--color--disabled' : ''}
              label='Rooms'
              min={0}
              placeholder='0'
              type='number'
              arrowSize='smaller'
              variant='secondary'
              width={areaWidth}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name={`${name}.${index}.note`}
        render={({ field }) => (
          <NoteText
            setValue={(_, value) => field.onChange(value)}
            note={field.value}
            section='wifi'
          />
        )}
      />
      {name === 'wifiIndoor' &&
        (urls[index] || field.plans.length > 0 ? (
          <UploaderContainer ref={uploaderRef}>
            <FloorItem>
              <FloorImgContainer>
                <CloseIcon onClick={() => handleRemovePlan(index, wifi)} className='icon__close' />
                {urls[index]?.url?.includes('application/pdf') ||
                field.plans[0]?.plan.toLowerCase().endsWith('pdf') ? (
                  <FileWrapper>
                    <FileTitle size='label'>
                      {urls[index]?.name || extractFileName(field.plans[0]?.plan)}
                    </FileTitle>
                  </FileWrapper>
                ) : (
                  <FloorImg
                    src={
                      urls[index]?.url || `${config.API_URL}/api/projects/${field.plans[0].plan}`
                    }
                    alt='plan'
                  />
                )}
              </FloorImgContainer>
            </FloorItem>
            {errorList[index]?.size?.message && (
              <Text color='danger'>{errorList[index]?.size?.message}</Text>
            )}
          </UploaderContainer>
        ) : (
          <UploaderButton onLoad={handleLoadPlan(index, wifi)} name={name} />
        ))}
    </BubbleRow>
  )
}

const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
