import * as op from './operators'
import { handleAsyncAction } from 'store/operators'
import { AsyncFuncState } from 'shared'

import type { Context } from 'store'

const handleAction = handleAsyncAction('rewards')

export const list = handleAction<{ professionalId: string }, void>(op.listRewards)

export const reset = ({ state }: Context) => {
  state.rewards = {
    list: [],
    status: AsyncFuncState.SUCCESS,
  }
}
