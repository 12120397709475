import { makeAwsUrl } from 'helpers'
import { pipe, split, map, head, join, slice, toUpper } from 'ramda'

type CapitalizedHeadOfSeparatedWord = (end: number) => (string: string) => string
export const capitalizedHeadOfSeparatedWord: CapitalizedHeadOfSeparatedWord = (end) =>
  pipe<any, any, any, any, any, any>(split(/-|\s/), map(head), join(''), slice(0, end), toUpper)

type GetInnerInitials = (slice: number) => (name: string) => string
export const getInnerInitials: GetInnerInitials = (slice) => (name) => {
  return name.split(/-|\s/).length === 1
    ? pipe<[string], string, string>(head, toUpper)(name)
    : capitalizedHeadOfSeparatedWord(slice)(name)
}

interface GetInitialsOptions {
  slice?: number
}
type GetInitials = (stringArray: string[], opts?: GetInitialsOptions) => string
export const getInitials: GetInitials = (stringArray, { slice = 2 } = {}) =>
  pipe<any, any, any>(map(getInnerInitials(slice)), join(''))(stringArray)

export const isImgUri = (logo: string): boolean => {
  const imgRegex = /.(jpg|jpeg|gif|tif|tiff|bmp|png|eps|svg)$/
  return imgRegex.test(logo)
}

export const renderLogo = (logo: string): JSX.Element => {
  const source = logo.startsWith('http') ? logo : makeAwsUrl(logo)
  return <img src={source} alt='Company logo' />
}

const handleAvatar = (name: string[], logo: string | null): JSX.Element => {
  return <>{logo ? renderLogo(logo) : getInitials(name)}</>
}

export default handleAvatar
