import { Button, Text } from '@ubnt/ui-components'
import NoClientsImg from 'images/NoClients.png'
import { FC } from 'react'
import { useActions } from 'store'
import styled from 'styled-components'

export const NoClients: FC = () => {
  const { setModal } = useActions().modal

  const handleAdd = () => {
    setModal({
      modal: 'add_client',
      props: {
        modalName: 'Add a Client',
      },
    })
  }

  return (
    <NoClientsContainer>
      <StyledImage src={NoClientsImg} />
      <Text size='body' color='tertiary' style={{ marginBottom: '30px' }}>
        You don&apos;t have any clients yet
      </Text>
      <ButtonWrapper>
        <Button variant='primary' onClick={handleAdd}>
          + Add Client
        </Button>
      </ButtonWrapper>
    </NoClientsContainer>
  )
}

const NoClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 300px;
`

const StyledImage = styled.img`
  width: 117px;
  height: 98px;
  margin-bottom: 15px;
`
