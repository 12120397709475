import { omit } from 'ramda'
import type { Context } from 'store'
import { whenPropIs } from 'store/operators'
import type { User } from 'types/user.type'
import { GetUserOptions } from './types'

export const getCompanyMemebers = ({ effects, state }: Context) =>
  effects.users.api
    .getCompanyMembers() //
    .then((users) => {
      state.users.list = users
    })

export const listUsers = ({ effects, state }: Context, opts: GetUserOptions) =>
  effects.users.api
    .listUsers(opts) //
    .then((data) => {
      state.users.list = data.data
      state.users.page = omit(['data'], data)
    })

export const updateUser = <A extends { user: Pick<User, 'ssoId'> }>(
  { effects }: Context,
  value: A,
): Promise<A> => effects.users.api.putUser(value.user).then((u) => ({ ...value, user: u }))

export const removeUserFromCompany = <A extends { id: string }>(
  { effects }: Context,
  value: A,
): Promise<A & { user: User }> =>
  effects.users.api.removeUserFromCompany(value.id).then((u) => ({ ...value, user: u }))

export const deleteUser = <A extends { id: string }>(
  { effects }: Context,
  value: A,
): Promise<A & { user: User }> =>
  effects.users.api.deleteUser(value.id).then((u) => ({ ...value, user: u }))

export const getUsers = whenPropIs<GetUserOptions & { getAllProUsers: boolean }, void>(
  'getAllProUsers',
  true,
  {
    true: listUsers,
    false: getCompanyMemebers,
  },
)
