import { curry } from 'ramda'
import styled from 'styled-components'

import { Text, Tooltip } from '@ubnt/ui-components'
import { Avatar, Badge, BadgeProps, Loader } from 'components'
import { capitalise } from 'helpers'
import { AsyncFuncState, QuoteStatus } from 'shared'
import { PayoutStatus } from 'store/payments/types'

interface RenderClientOptions {
  asBodyText?: boolean
}

const stockDict: Record<string, BadgeProps['type']> = {
  Backorder: 'yellow',
  'Out Of Stock': 'red',
}

export const renderClient = (
  name: string[],
  logo: string,
  { asBodyText = false }: RenderClientOptions = {},
) => (
  <CellLogo>
    <Avatar name={name} logoUrl={logo} imgSize={24} fontSize={9} fontWeight='bold' />
    <Tooltip description={name.join(' ')} portal>
      <Text size='body' className='ellipsis' style={asBodyText ? { maxWidth: '180px' } : {}}>
        {name.join(' ')}
      </Text>
    </Tooltip>
  </CellLogo>
)

const _renderBadge = <A extends string>(dictionary: Record<A, BadgeProps['type']>, status: A) => {
  const newStatusText = status === QuoteStatus.FINAL_PAYMENT_PENDING ? 'Pending Payment' : status
  return (
    <Badge type={dictionary[status]} size='small'>
      {capitalise(newStatusText)}
    </Badge>
  )
}

export const renderBadge = curry(_renderBadge)

const quoteStatusDict: Record<QuoteStatus & 'Pending' & 'Deducted', BadgeProps['type']> = {
  [QuoteStatus.DRAFT]: 'grey',
  [QuoteStatus.SENT]: 'navy',
  [QuoteStatus.PENDING_FULFILMENT]: 'blue',
  [QuoteStatus.PARTIALLY_COMPLETED]: 'blue',
  [QuoteStatus.FINAL_PAYMENT_PENDING]: 'yellow',
  [QuoteStatus.COMPLETED]: 'green',
  Pending: 'yellow',
  Deducted: 'red',
}

export const renderQuoteBadge = renderBadge(quoteStatusDict)

const payoutStatusDict: Record<PayoutStatus, BadgeProps['type']> = {
  [PayoutStatus.FAILED]: 'red',
  [PayoutStatus.PAID]: 'green',
  [PayoutStatus.PROCESSING]: 'blue',
  [PayoutStatus.REFUND_REQUESTED]: 'yellow',
  [PayoutStatus.REJECTED]: 'red',
}

export const renderPayoutBadge = renderBadge(payoutStatusDict)

export const renderSpinner = (status: AsyncFuncState) => <Loader status={status} type='small' />

export const renderStockBadge = renderBadge(stockDict)

const CellLogo = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;

  > div {
    // Custom width needed for ellipsis of the wrapped Text, because CellLogo breaks the UI Table's inherent ellipsis
    width: calc(100% - 35px);
  }
`
