import * as yup from 'yup'

const errorMessages = {
  name: 'Must be at least 2 characters',
  email: 'Must be valid email address',
}

const _nameSchema = yup.string().min(2, errorMessages.name).required()

export const nameSchema = (
  validateName?: (name?: string | undefined) => boolean | Promise<boolean>,
) =>
  validateName
    ? _nameSchema.test('not-taken', 'The company name already exists', validateName)
    : _nameSchema

export const locationSchema = yup
  .object()
  .shape({
    street: yup.string().required('Street name cannot be empty'),
    streetNumber: yup.string().required('Street number cannot be empty'),
    formatted: yup.string().required(),
    city: yup.string().required('City cannot be empty'),
    country: yup.string().required('Country cannot be empty'),
    state: yup.string().required('State cannot be empty'),
    zipCode: yup
      .string()
      .matches(/^\d{5}$/, {
        excludeEmptyString: false,
        message: 'Must be a valid ZIP code',
      })
      .required('zip code cannot be empty'),
    other: yup.string(),
  })
  .required()

export const emailSchema = (
  validateEmail: (email?: string | undefined) => boolean | Promise<boolean>,
) =>
  yup
    .string()
    .email(errorMessages.email)
    .required(errorMessages.email)
    .test('valid-email', '', validateEmail)

export const phoneSchema = (excludeEmptyString = false) =>
  yup.string().matches(/^\+?\d{9,12}$/, {
    message: 'Please enter valid phone number',
    excludeEmptyString,
  })

export const enumSchema = <T extends Record<string, string>>(enumerate: T) =>
  yup.string().oneOf(Object.values(enumerate)).required('Field cannot be empty')
