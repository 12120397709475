import { Modal as UIModal } from '@ubnt/ui-components'
import { useActions, useOverState } from 'store'
import { ModalType, ProjectRemoveModal } from 'store/modal/types'
import styled from 'styled-components'

export const RemoveProjectModal = () => {
  const { modalProps } = useOverState().modal as ModalType<ProjectRemoveModal>
  const { modal: actions } = useActions()

  return (
    <Modal
      actions={[
        {
          autoFocus: true,
          onClick: actions.closeModal,
          text: 'Cancel',
          variant: 'tertiary',
        },
        {
          autoFocus: false,
          onClick: modalProps.onRemove,
          text: 'Remove',
          color: 'danger',
          variant: 'primary',
        },
      ]}
      onRequestClose={actions.closeModal}
      title='Remove Project'
      size='small'
      isOpen
      contentClassName='modal__content'
    >
      This project will be permanently removed. Are you sure you want to continue?
    </Modal>
  )
}

const Modal = styled(UIModal)`
  .modal__content {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    font-size: 14px;
  }
`
