import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '@ubnt/ui-components'
import { memoizeWith } from 'ramda'
import { FC, MouseEventHandler, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { emailRegex } from 'shared'
import { useActions, useEffects, useOverState } from 'store'
import { EMPTY_CLIENT } from './constants'
import { addContactSchema } from './schemas'
import { ClientForm } from './sharedComponents'
import type { AddContactFields } from './types'

export const AddContact: FC = () => {
  const {
    clientManagement: {
      currentClient: { id: clientId },
    },
    modal: {
      modal,
      modalProps: { newContact },
    },
  } = useOverState()
  const {
    clients: { addContact },
    modal: { closeModal },
  } = useActions()
  const { app } = useEffects()

  const [emailError, setEmailError] = useState('')

  const validateEmail = memoizeWith(toString, (email?: string) => {
    if (!email || !emailRegex.test(email.toLowerCase())) return false
    return app.api
      .validateEmail({ email, client: true, pro: true, user: true })
      .then(({ isInvalid, message }) => {
        setEmailError(message)
        return !isInvalid
      })
  })

  const methods = useForm<AddContactFields>({
    resolver: yupResolver(addContactSchema(validateEmail)),
    mode: 'onChange',
    defaultValues: {
      clientId: '',
      clients: [],
    },
  })

  useEffect(() => {
    methods.reset({
      clientId,
      clients: [{ ...EMPTY_CLIENT, email: newContact }],
    })
  }, [clientId, newContact])

  const handleClose: MouseEventHandler = (event) => {
    event.preventDefault()
    closeModal()
  }

  const handleAddContact = () => {
    const { clientId, clients } = methods.getValues()
    addContact({ clientId, client: clients[0] })
  }

  return (
    <Modal
      title='Add Additional Contact'
      isOpen={modal === 'add_contact'}
      onRequestClose={closeModal}
      size='small'
      height='medium'
      shouldCloseOnOverlayClick={false}
    >
      <FormProvider {...methods}>
        <ClientForm
          clientIndex={0}
          emailError={emailError}
          close={handleClose}
          onSubmit={handleAddContact}
          isEditMode={false}
          isAddContactForm={true}
        />
      </FormProvider>
    </Modal>
  )
}
