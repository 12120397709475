import { Modal } from '@ubnt/ui-components/Modal'
import { Text } from '@ubnt/ui-components/Text'

import { useActions, useOverState } from 'store'
import _texts from './texts.json'

import type { FC } from 'react'
import type { WithQuoteId } from 'store/clientmanagement/types'
import type { ModalType } from 'store/modal/types'

interface Props {
  modal: 'confirm_complete_quote' | 'confirm_final_bill'
}

export const CompleteConfirmation: FC<Props> = ({ modal }) => {
  const { modalProps } = useOverState().modal as ModalType<WithQuoteId, any>
  const actions = useActions()

  const handleSend = () => {
    modal === 'confirm_complete_quote'
      ? actions.clientManagement.completeQuote({
          quoteId: modalProps.quoteId,
          onSuccess: actions.modal.closeModal,
        })
      : actions.clientManagement.setQuotePending({
          quoteId: modalProps.quoteId,
          onSuccess: actions.modal.closeModal,
        })
  }

  const texts = modal === 'confirm_complete_quote' ? _texts.completeQuote : _texts.finalBill

  return (
    <Modal
      actions={[
        {
          onClick: actions.modal.closeModal,
          text: 'Cancel',
          variant: 'secondary',
        },
        {
          onClick: handleSend,
          text: texts.button,
          variant: 'primary',
        },
      ]}
      isOpen
      onRequestClose={actions.modal.closeModal}
      shouldCloseOnOverlayClick={false}
      size='small'
      title={texts.title}
    >
      <Text size='body'>{texts.body}</Text>
    </Modal>
  )
}
