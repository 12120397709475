import { isNil } from 'ramda'
import { Label as StyledLabed } from './styles'

import type { FC } from 'react'
import type { LabelProps } from './types'

export const Label: FC<LabelProps | undefined> = (props) => {
  if (isNil(props)) return null

  const { count, current, perPage, text } = props
  const from = Math.min(count, current * perPage + 1)
  const to = Math.min(count, (current + 1) * perPage)

  return <StyledLabed size='caption'>{`${from}-${to} of ${count} ${text}`}</StyledLabed>
}
