import styled from 'styled-components'
import { propOr } from 'ramda'
import type { FlexContainerProps } from './types'

export const FlexRow = styled.section<FlexContainerProps>`
  display: flex;
  flex-direction: row;
  flex-grow: ${propOr(0, 'grow')};
  align-items: ${propOr('center', 'alignItems')};
  justify-content: ${propOr('space-between', 'justify')};
  flex-wrap: ${propOr('no-wrap', 'flexWrap')};
  width: ${propOr('100%', 'width')};

  gap: ${({ gap }) => (gap ? `${gap}px` : 0)};
  margin-bottom: ${({ verticalSpacing }) => (verticalSpacing ? `${verticalSpacing}px` : 'none')};
  margin-top: ${({ verticalSpacing }) => (verticalSpacing ? `${verticalSpacing}px` : 'none')};
`
