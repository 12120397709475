import { AddPrimaryIcon } from '@ubnt/icons'
import { Button, Text } from '@ubnt/ui-components'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Avatar, FlexColumn, FlexRow } from 'components'
import { AvatarWrapper, List, PencilIcon, Row, TrashIcon } from './styles'
import { EMPTY_CLIENT } from '../../constants'
import { Form, FormFooter } from '../../styles'

import type { FormClient, FormFields } from '../../types'
import type { VFC } from 'react'

interface Props {
  add: (index: number) => void
  edit: (index: number) => void
  back: (event: React.MouseEvent<Element, MouseEvent>) => void
  onSubmit: () => void
}

export const ContactsForm: VFC<Props> = ({ add, edit, back, onSubmit }) => {
  const { control, handleSubmit } = useFormContext<FormFields>()

  const { append, fields, remove, update } = useFieldArray<FormFields, 'clients'>({
    control,
    name: 'clients',
  })

  const handleAdd = (index: number) => {
    append(EMPTY_CLIENT)
    add(index)
  }

  const handleEdit = (index: number, client: FormClient) => {
    update(index, client)
    edit(index)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <List>
        {fields.map((client, index) => (
          <Row key={client.email} className='list_item' style={{ marginBottom: '15px' }}>
            <FlexRow justify='flex-start'>
              <AvatarWrapper>
                <Avatar
                  name={[client.firstName, client.lastName] || ['']}
                  logoUrl=''
                  imgSize={40}
                  fontSize={14}
                  fontWeight='bold'
                />
              </AvatarWrapper>
              <FlexColumn>
                <Text size='body' weight='bold' truncate style={{ width: '230px' }}>
                  {client.firstName} {client.lastName}
                </Text>
                <Text color='tertiary'>{client.email}</Text>
              </FlexColumn>
            </FlexRow>

            <FlexRow justify='flex-end'>
              <TrashIcon onClick={() => remove(index)} className='trash' />
              <PencilIcon onClick={() => handleEdit(index, client)} />
            </FlexRow>
          </Row>
        ))}
      </List>

      <FlexRow verticalSpacing={20}>
        <Button
          Icon={<AddPrimaryIcon />}
          onClick={() => handleAdd(fields.length)}
          type='button'
          variant='inline'
        >
          Add Additional Contact
        </Button>
      </FlexRow>

      <FormFooter padding='none'>
        <Button variant='tertiary' onClick={back}>
          Back
        </Button>
        <Button variant='primary'>Next</Button>
      </FormFooter>
    </Form>
  )
}
