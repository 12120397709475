import { always, cond, equals, includes, isNil, pathEq, propSatisfies, T, where, __ } from 'ramda'
import { QuoteStatus } from 'shared'
import { Quote } from 'types'

const newItem = {
  hardware: 'hardware',
  proHardware: 'hardware',
  proService: 'service',
}

interface TooltipOptions {
  quote: Quote | null
  item: 'hardware' | 'proHardware' | 'proService'
}

export const makeTooltipText = cond<[TooltipOptions], string | null>([
  [
    propSatisfies(isNil, 'quote'),
    ({ item }) =>
      `In order to add this ${newItem[item]} to your quote, please select a client and then create a quote`,
  ],
  [
    pathEq(QuoteStatus.COMPLETED, ['quote', 'status']),
    always('You are not able to add new items to the quote, as it has been completed'),
  ],
  [
    where({
      quote: propSatisfies(
        includes(__, [QuoteStatus.PENDING_FULFILMENT, QuoteStatus.PARTIALLY_COMPLETED]),
        'status',
      ),
      item: equals('hardware'),
    }),
    always(
      'Since UniFi Hardware has already been purchased, you are not able to add more UniFi Hardware to this quote',
    ),
  ],
  [
    where({
      quote: where({
        status: includes(__, [QuoteStatus.PARTIALLY_COMPLETED, QuoteStatus.SENT]),
        adjusting: equals(false),
      }),
    }),
    always('In order to adjust your quote, please click Edit Quote below'),
  ],
  [T, always(null)],
])
