import styled from 'styled-components'

export interface TableWrapperProps {
  isLoading?: boolean
}

export const TableWrapper = styled.section<TableWrapperProps>`
  margin: 10px 0px;

  ${({ isLoading }) => (isLoading ? 'tbody { opacity: 0.5 };' : '')}

  .selected-table-row {
    background-color: ${({ theme }) => theme.colors.Light.color.blue.b1};
  }
`
