import { add, defaultTo, head, is, match, pipe, toString, when } from 'ramda'
import { length } from './text'
import config from 'config'

interface CurrencyOptions {
  decimals?: number
}

const decimalRegEx = /\.\d+$/
export const countDecimals = pipe<
  [number | string],
  string,
  string[],
  string | undefined,
  string,
  number,
  number
>(when(is(Number), toString), match(decimalRegEx), head, defaultTo('.'), length, add(-1))

export const displayDecimal = (num: number | string, precision = 2) =>
  countDecimals(num) > precision ? round(Number(num), precision) : num

export const toLocale =
  (locale = 'en-US') =>
  (num: number, { decimals = 2 }: CurrencyOptions = {}) =>
    num.toLocaleString(locale, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })

export const toCurrentLocale = toLocale(config.LOCALE)

export const toLocaleCurrency = (num: number, opts?: CurrencyOptions) =>
  `$${toCurrentLocale(num, opts)}`

const _round =
  (rounder: (x: number) => number) =>
  (number: number, precision = 2) => {
    const power = 10 ** precision
    return rounder(number * power) / power
  }

export const floor = _round(Math.floor)
export const round = _round(Math.round)
