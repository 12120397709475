import { PanelCard } from '@ubnt/ui-components'
import { useOverState } from 'store'
import { ModalType, ProjectSidebar } from 'store/modal/types'
import { items } from './overviewFields'

export const Overview = () => {
  const {
    current: { data },
  } = useOverState().projects
  const { sidebarProps } = useOverState().modal as ModalType<any, ProjectSidebar>

  const filtered =
    data &&
    items.filter(
      (element) =>
        element.id === 'general' ||
        (element.id === 'phoneSystem' && data?.voIP?.qty > 0) ||
        (element.id === 'doorAccess' && data?.doors.qty > 0) ||
        (element.id === 'camera' && data?.cameras.qty > 0) ||
        (data?.wifiIndoor.length > 0 && element.id === 'wifi'),
    )

  if (sidebarProps.newProject) return null
  return (
    filtered && (
      <>
        {filtered.map((item) => {
          return (
            <PanelCard header={item.label} defaultIsOpen width='100%' key={item.id}>
              {item.renderContent()}
            </PanelCard>
          )
        })}
      </>
    )
  )
}
