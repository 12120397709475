import { Checkbox as BaseCheckbox } from '@ubnt/ui-components/Checkbox'
import { Text } from '@ubnt/ui-components/Text'

import { FlexColumn } from 'components'

import type { FC } from 'react'
import type { CheckboxProps } from '../types'

export const Checkbox: FC<CheckboxProps> = ({ checked, disabled, label, onChange, text }) => (
  <FlexColumn verticalSpacing={6}>
    {label && <Text size='caption'>{label}</Text>}
    <BaseCheckbox id={label ?? text} checked={checked} disabled={disabled} onChange={onChange}>
      {text}
    </BaseCheckbox>
  </FlexColumn>
)
