import { TextArea } from '@ubnt/ui-components'
import { ChangeEvent } from 'react'
import styled from 'styled-components'

interface Props {
  note: string
  setValue: (onChange: ChangeEvent<HTMLTextAreaElement>, value: string) => void
  section?: string
}

export const NoteText = ({ note, setValue, section }: Props) => {
  const isWifi = section === 'wifi'
  return (
    <TextAreaNote
      label='Note (Optional)'
      width={isWifi ? 304 : 335}
      height={118}
      onChange={setValue}
      value={note}
      variant='secondary'
      size='body'
      inputClassName='no-resize-x'
    />
  )
}

export const TextAreaNote = styled(TextArea)`
  margin-top: 10px;
`
