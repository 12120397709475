import { Status } from './style'

import { ProductDisplayStatus } from 'types'

const productStatus: Record<ProductDisplayStatus, string> = {
  [ProductDisplayStatus.AVAILABLE]: 'In Stock',
  [ProductDisplayStatus.BACKORDER]: 'Backorder',
  [ProductDisplayStatus.COMING_SOON]: 'Coming Soon',
  [ProductDisplayStatus.SOLD_OUT]: 'Out Of Stock',
}

export type ClassName = 'modal__status' | 'card__status'

export interface Props {
  status: ProductDisplayStatus
  className?: string
}

export const StatusBadge = ({ status, className }: Props) => (
  <Status status={status.toLocaleLowerCase()} className={className}>
    {productStatus[status]}
  </Status>
)
