import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { renderFooter, renderItems } from './helpers'

import type { FC } from 'react'
import type { ItemListTooltipProps } from './types'

export const ItemListTooltip: FC<ItemListTooltipProps> = ({ children, footer, items }) => {
  return (
    <Tooltip
      description={
        <>
          {renderItems(items)}
          {renderFooter(footer)}
        </>
      }
      portal
      position='bottomStart'
      width={240}
    >
      {children}
    </Tooltip>
  )
}
