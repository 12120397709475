import { Button, Input, PopoverCard } from '@ubnt/ui-components'
import addressHelpers from 'helpers/address'
import { usePlacesAutoComplete } from 'hooks/usePlacesAutoComplete'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

export const GoogleAddressAutoComplete = ({
  handleChange,
  value,
  setValue,
  width,
  ...restProps
}) => {
  const [localInvalid, setLocalInvalid] = useState('')
  const [open, setIsOpen] = useState(false)

  const predictions = usePlacesAutoComplete(value)

  const onMount = async () => {
    await addressHelpers.initGoogleApi()

    // Needed for PlacesAutocomplete to init without crashing.
    // There is actually no callback, autocomplete lib will overwrite this global
    // key with their callback
    // https://github.com/hibiken/react-places-autocomplete/blob/dbb297486d21740dd1f15ec35fb3b45f7eebcb61/src/PlacesAutocomplete.js#L41
    window.placesAutocompleteInit && window.placesAutocompleteInit()
  }

  useEffect(() => {
    onMount()
  }, [onMount])

  const getLocation = (placeId) =>
    addressHelpers
      .geocodeByPlaceId(placeId)
      .then((data) => addressHelpers.geocodeParser(data[0]))
      .then((l) => {
        handleChange(l)
      })
      .catch((err) => (err ? setLocalInvalid('Please enter address') : setLocalInvalid('')))

  return (
    <Container width={width}>
      <Input
        name='location'
        label='Address'
        autoComplete='off'
        autoFocus
        value={value}
        invalid={localInvalid}
        onChange={(event) => {
          setIsOpen(true)
          setValue(event.target.value)
        }}
        width={width}
        {...restProps}
      />
      <PopoverCard
        width={width}
        className='popover-card'
        align='bottomCenter'
        open={open && value.length > 0}
      >
        {open &&
          predictions.map((p) => (
            <StyledButton
              onClick={(e) => {
                e.preventDefault()
                getLocation(p.place_id)
                setIsOpen(false)
              }}
              variant='tertiary'
              key={p.place_id}
            >
              {p.description}
            </StyledButton>
          ))}
      </PopoverCard>
    </Container>
  )
}

const StyledButton = styled(Button)`
  > span {
    display: flex;
    justify-content: flex-start !important;
    flex: 1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > span:hover {
    overflow: visible;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .popover-card {
    position: absolute;
    top: 45px;
    z-index: 10;
    width: ${({ width }) => (!isNil(width) && `${width}px`) || '100%'};
    padding: 5px;
  }
`
