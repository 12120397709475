import { groupBy, isEmpty, pipe, prop, propSatisfies, reject, values } from 'ramda'
import { FC } from 'react'
import styled from 'styled-components'

import { Accordion as UIAccordion } from '@ubnt/ui-components/Accordion'
import { Text } from '@ubnt/ui-components/Text'
import { FulfillmentList } from './FulfillmentList'

import type { FulfillmentItem } from 'types'

interface Props {
  fulfilled: FulfillmentItem[]
  unfulfilled: FulfillmentItem[]
}
export const Fulfillments: FC<Props> = ({ fulfilled, unfulfilled }) => {
  const _accordionItems = [
    { title: 'Fulfilled Items', items: fulfilled },
    { title: 'Unfulfilled Items', items: unfulfilled },
  ]
  const accordionItems = reject(propSatisfies(isEmpty, 'items'), _accordionItems)

  return (
    <Accordion
      variant='tertiary'
      multiOpen
      panelClassName='panel'
      items={accordionItems.map((x, index) => ({
        id: `${index}-${x.title}`,
        openByDefault: true,
        label: (
          <Text size='body' weight='bold'>
            {x.title}
          </Text>
        ),
        renderContent: () => {
          const groupedItems: FulfillmentItem[][] = pipe(
            groupBy<any>(prop('trackingNumber')),
            values,
          )(x.items) as any

          return groupedItems.map((items) => (
            <FulfillmentList key={JSON.stringify(items)} items={items} />
          ))
        },
      }))}
    />
  )
}

const Accordion = styled(UIAccordion)`
  li {
    border-color: ${({ theme }) => theme.colors.Light.color.neutral.n4} !important;
  }

  .panel {
    height: 64px;
  }
`
