import { Table } from '@ubnt/ui-components/Table'
import { TableLoadingState } from 'components'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useEffects, useOverState } from 'store'
import { MaterialsEntity } from 'store/hardware/types'
import styled from 'styled-components'
import { columns } from './columns'
import { ProductItem } from './types'

interface Props {
  projectId: string
  setSelectedHardware: (selectedHW: ProductItem[]) => void
}

const Hardware: FC<Props> = ({ projectId, setSelectedHardware }) => {
  const { api } = useEffects().hardware
  const { products } = useOverState().hardware
  const [importedHardware, setImportedHardware] = useState<MaterialsEntity[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (projectId) {
      setLoading(true)
      api.getDcHardware(projectId).then(({ materials }) => {
        setImportedHardware(materials)
      })
    }
  }, [projectId])

  const projectHW = useMemo(() => {
    const materials = Object.fromEntries(importedHardware.map((x) => [x.sku, x]))

    const result = products.list
      .filter((product) => Object.prototype.hasOwnProperty.call(materials, product.sku))
      .map((product) => ({
        id: product.id,
        qty: materials[product.sku]?.amount as number,
        name: product.title,
        price: Number(product.price),
        thumbnail: product.thumbnail,
      }))
    setLoading(false)
    return result
  }, [projectId, importedHardware])

  const handleSelectedHardware = useCallback(
    (ids) => {
      setSelectedHardware(projectHW.filter((h) => ids.includes(h.id)))
    },
    [projectHW],
  )

  return (
    <Container>
      <TableWrapper>
        {loading ? (
          <TableLoadingState columns={columns} initialSortBy='value' rowCount={5} />
        ) : (
          <Table
            columns={columns}
            items={projectHW}
            idField='id'
            initialSortBy='name'
            disableColumnFilters
            rowHeight={50}
            multiSelectMode
            onChangeSelection={handleSelectedHardware}
            renderPlaceholder={() => 'No Hardware in this Design Center Project'}
          />
        )}
      </TableWrapper>
    </Container>
  )
}

export default Hardware

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 0 16px 16px 16px;
  height: 100%;
`

export const TableWrapper = styled.section`
  height: 400px;
  overflow-y: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  .contract-row:hover {
    cursor: pointer;
  }
`
