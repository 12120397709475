import { Text } from '@ubnt/ui-components'
import { useOverState } from 'store'
import { WifiBubble } from './WifiBubble'
import { FlexRow } from 'components'
import styled from 'styled-components'

export const Wifi = () => {
  const { projects } = useOverState()
  const { data } = projects.current

  return (
    <>
      {data?.wifiIndoor && (
        <SectionContainer>
          <FlexRow>
            <Text size='body'>Wifi Coverage</Text>
            <Text size='body'>
              {data.wifiIndoor.length > 0 && data.wifiOutdoor.length > 0
                ? 'Indoor & Outdoor'
                : 'Indoor'}
            </Text>
          </FlexRow>
          <WifiBubble label='Indoor' />
          {data.wifiOutdoor.length > 0 && <WifiBubble label='Outdoor' />}
        </SectionContainer>
      )}
    </>
  )
}

export const SectionContainer = styled.section`
  > section {
    margin-bottom: 8px;
  }
`
