import { DropdownOption, Modal, Tab } from '@ubnt/ui-components'
import axios from 'axios'
import { capitalise } from 'helpers'
import { omit } from 'ramda'
import { FC, useEffect, useState } from 'react'
import { useActions, useOverState } from 'store'
import styled from 'styled-components'
import { ProHardware, ProHardwareStates, ProService, ProServiceStates } from 'types'
import { AsideNav } from './AsideNav'
import { emptyHardware, emptyService, HW_CATEGORIES, SERVICES, tabs } from './constants'
import { HardwareForm } from './HardwareForm'
import { appendAndParseDropdownOpts, appendAndParseDropdownOptsFromApi } from './helpers'
import { ServiceForm } from './ServiceForm'

export const AddEditProItems: FC = () => {
  const {
    modal,
    modalProps: { tab: selectedTab, addItem, editItem, hardwareItem, serviceItem },
  } = useOverState().modal
  const { closeModal } = useActions().modal
  const { list: proHardware, hash: proHardwareHash } = useOverState().hardware.proHardware
  const { list: proServices, hash: proServicesHash } = useOverState().services.services
  const { deleteProService, bulkUpdateProServices } = useActions().services
  const { deleteProHardware, bulkUpdateProHardware } = useActions().hardware

  const [currentHardware, setCurrentHardware] = useState<ProHardwareStates>(
    hardwareItem || emptyHardware,
  )
  const [currentService, setCurrentService] = useState<ProServiceStates>(
    serviceItem || emptyService,
  )
  const [isValidForm, setIsValidForm] = useState(false)
  const [isDisabledAsideNav, setIsDisabledAsideNav] = useState(!isValidForm)
  const [isDisabledFields, setIsDisabledFields] = useState(false)
  const [isEditing, setIsEditing] = useState(editItem)
  const [tab, setTab] = useState(selectedTab && selectedTab === 'services' ? tabs[1] : tabs[0])
  const [hardware, setHardware] = useState<Record<string, ProHardwareStates>>(proHardwareHash)
  const [services, setServices] = useState<Record<string, ProServiceStates>>(proServicesHash)
  const [editedHardware, setEditedHardware] = useState<Record<string, ProHardwareStates>>({})
  const [editedServices, setEditedServices] = useState<Record<string, ProServiceStates>>({})
  const [manufacturerOptions, setManufacturerOptions] = useState<DropdownOption[]>([])
  const [hardwareCategories, setHardwareCategories] = useState<DropdownOption[]>(
    appendAndParseDropdownOpts(HW_CATEGORIES, proHardware, 'category'),
  )
  const [serviceOptions, setServiceOptions] = useState<DropdownOption[]>(
    appendAndParseDropdownOpts(SERVICES, proServices, 'name'),
  )

  //fails when using http helper because it sends credentials
  const getHardwareManufacturers = () =>
    axios
      .get<{ vendor_ids: Record<number, string> }>(
        'https://static.ubnt.com/fingerprint/0/devicelist.json',
      )
      .then(({ data: { vendor_ids } }) =>
        appendAndParseDropdownOptsFromApi(vendor_ids, proHardware, 'manufacturer'),
      )
      .then(setManufacturerOptions)

  useEffect(() => setServices(proServicesHash), [proServicesHash])
  useEffect(() => setHardware(proHardwareHash), [proHardwareHash])
  useEffect(() => setIsDisabledAsideNav(!isValidForm), [isValidForm])

  useEffect(() => {
    if (hardwareItem) {
      setEditedHardware((prev) => ({ [hardwareItem.id]: hardwareItem, ...prev }))
    }
    if (serviceItem) {
      setEditedServices((prev) => ({ [serviceItem.id]: serviceItem, ...prev }))
    }
  }, [])

  useEffect(() => {
    getHardwareManufacturers()

    if (addItem) handleAdd()
    if (editItem) setIsDisabledAsideNav(false)
    if (editItem && (hardwareItem || serviceItem)) return setIsDisabledFields(false)
    if (editItem && (!hardwareItem || !serviceItem)) setIsDisabledFields(true)
  }, [])

  const handleAdd = () => {
    if (tab.id === 'services') {
      const newService = { ...emptyService, id: 'temp_' + Math.random() }

      setCurrentService(newService)
      setServices((prev) => ({ [newService.id]: newService, ...prev }))
      setEditedServices((prev) => ({ [newService.id]: newService, ...prev }))
    }
    if (tab.id === 'hardware') {
      const newHardware = { ...emptyHardware, id: 'temp_' + Math.random() }

      setCurrentHardware(newHardware)
      setHardware((prev) => ({ [newHardware.id]: newHardware, ...prev }))
      setEditedHardware((prev) => ({ [newHardware.id]: newHardware, ...prev }))
    }

    setIsDisabledAsideNav(true)
    setIsDisabledFields(false)
    setIsEditing(false)
  }

  const deleteService = (id: string) => {
    setServices(omit([id]))
    setEditedServices(omit([id]))
  }
  const deleteHardware = (id: string) => {
    setHardware(omit([id]))
    setEditedHardware(omit([id]))
  }

  const handleDelete = (id: string) => (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation()

    setIsDisabledAsideNav(false)
    setIsDisabledFields(true)
    setIsEditing(true)

    if (id.includes('temp') && tab.id === 'services') return deleteService(id)
    if (id.includes('temp') && tab.id === 'hardware') return deleteHardware(id)

    if (tab.id === 'services') return deleteProService({ id, onSuccess: () => deleteService(id) })
    if (tab.id === 'hardware') return deleteProHardware({ id, onSuccess: () => deleteHardware(id) })
  }

  const handleTabSelection = (selectedTab: Tab) => {
    setTab(selectedTab)

    setCurrentService(emptyService)
    setCurrentHardware(emptyHardware)

    setIsDisabledAsideNav(false)
    setIsDisabledFields(true)
  }

  const handleSelectedItem = (item: ProService | ProHardware, type: 'services' | 'hardware') => {
    if (type === 'services') {
      const _item = item as ProService
      setCurrentService(_item)
      setEditedServices((prev) => ({ [_item.id]: _item, ...prev }))
    }

    if (type === 'hardware') {
      const _item = item as ProHardware
      setCurrentHardware(_item)
      setEditedHardware((prev) => ({ [_item.id]: _item, ...prev }))
    }

    setIsDisabledFields(false)
    setIsEditing(true)
  }

  const handleSubmit = () => {
    bulkUpdateProServices({
      services: editedServices as Record<string, ProService>,
      onSuccess: () => {
        setEditedServices({})
        setCurrentService(emptyService)
      },
    })
    bulkUpdateProHardware({
      hardware: editedHardware as Record<string, ProHardware>,
      onSuccess: () => {
        setEditedHardware({})
        setCurrentHardware(emptyHardware)
      },
    })
  }

  const isDisabled = !isValidForm

  const modalName = isEditing
    ? `Edit ${capitalise(tab.id).replace(/s$/, '')} Information`
    : `Add New ${capitalise(tab.id).replace(/s$/, '')}`

  return (
    <Modal
      title={modalName}
      isOpen={modal === 'add_edit_pro_items'}
      onRequestClose={closeModal}
      size='large'
      height='medium'
      shouldCloseOnOverlayClick={false}
      actions={[
        {
          text: 'Cancel',
          onClick: closeModal,
          variant: 'tertiary',
        },
        {
          text: 'Save',
          variant: 'primary',
          onClick: handleSubmit,
          disabled: isDisabled,
        },
      ]}
    >
      <Container>
        <AsideNav
          tabs={tabs}
          tab={tab}
          type={tab.id === 'services' ? 'services' : 'hardware'}
          items={tab.id === 'services' ? Object.values(services) : Object.values(hardware)}
          selectedService={currentService.id}
          selectedHardware={currentHardware.id}
          isDisabledAsideNav={isDisabledAsideNav}
          addItem={handleAdd}
          deleteItem={handleDelete}
          handleTabSelection={handleTabSelection}
          handleSelectedItem={handleSelectedItem}
        />

        {tab.id === 'hardware' && (
          <HardwareForm
            state={currentHardware}
            setState={setCurrentHardware}
            onSubmit={handleSubmit}
            setIsValidForm={setIsValidForm}
            setHardware={setHardware}
            setEditedHardware={setEditedHardware}
            isDisabledFields={isDisabledFields}
            manufacturerOptions={manufacturerOptions}
            setManufacturerOptions={setManufacturerOptions}
            hardwareCategories={hardwareCategories}
            setHardwareCategories={setHardwareCategories}
          />
        )}
        {tab.id === 'services' && (
          <ServiceForm
            state={currentService}
            setState={setCurrentService}
            onSubmit={handleSubmit}
            setIsValidForm={setIsValidForm}
            setServices={setServices}
            setEditedServices={setEditedServices}
            isDisabledFields={isDisabledFields}
            serviceOptions={serviceOptions}
            setServiceOptions={setServiceOptions}
          />
        )}
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`
