import { useEffect, VFC } from 'react'
import { useActions, useOverState } from 'store'

export const SocketListener: VFC = () => {
  const {
    app: { user },
  } = useOverState()
  const { app } = useActions()

  useEffect(() => {
    if (user?.ssoId) app.connectSocket()

    return app.disconnectSocket
  }, [user])

  return null
}
